const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 4,
    cluster_name: 'Redis cluster (sharded)',
    cluster_type: 'REDIS_SHARDED',
    configuration_file: '/etc/cmon.d/cmon_4.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_4.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'redis',
    version: '7.2',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 4,
        created_after: null,
        critical: 0,
        reported_after: null,
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2023-02-08T02:15:46.025Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 19,
            hostname: '10.0.8.14',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.14',
            lastseen: 1712575052,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 92,
            port: 6380,
            readonly: false,
            role: 'primary',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575052,
            unique_id: 18,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    hash_slot_info: '0-5499',
                    flags: 'master,fail',
                    node_id: 'fdbdfacd2f39a2b9841f3ebd49991545de08950f',
                },
            },
            replication_master: {
                slave_info: {
                    slaves: [
                        {
                            bus_port: '16380',
                            ip: '10.0.8.15',
                            link_state: null,
                            port: '6380',
                        },
                    ],
                },
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 20,
            hostname: '10.0.8.15',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.15',
            lastseen: 1712575053,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 92,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575053,
            unique_id: 19,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    hash_slot_info: '',
                    flags: 'slave,fail',
                    node_id: 'ca391a7c0ecc8937303ea6ac3d4feca50358689f',
                },
            },
            replication_slave: {
                master_host: '10.0.8.14',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 20,
            hostname: '10.0.8.25',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.25',
            lastseen: 1712575053,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 92,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575053,
            unique_id: 19,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: undefined,
                    hash_slot_info: '',
                    node_id: 'ca391a7c0ecc8937303ea6ac3d4feca50358689f',
                },
            },
            replication_slave: {
                master_host: '10.0.8.14',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 21,
            hostname: '10.0.8.16',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.16',
            lastseen: 1712575053,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 104,
            port: 6380,
            readonly: false,
            role: 'primary',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575053,
            unique_id: 20,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: undefined,
                    hash_slot_info: '5500-10999',
                    node_id: 'ad36ca6da144512db294fe580c60e8c38943aba0',
                },
            },
            replication_master: {
                slave_info: {
                    slaves: [
                        {
                            bus_port: '16380',
                            ip: '10.0.8.17',
                            link_state: null,
                            port: '6380',
                        },
                    ],
                },
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 22,
            hostname: '10.0.8.17',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.17',
            lastseen: 1712575054,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 72,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575054,
            unique_id: 21,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: '',
                    hash_slot_info: '',
                    node_id: 'ba1c64fb9d189acd10385475bb0c98ef588012ec',
                },
            },
            replication_slave: {
                master_host: '10.0.8.16',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 22,
            hostname: '10.0.8.27',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.27',
            lastseen: 1712575054,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 72,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575054,
            unique_id: 21,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: null,
                    hash_slot_info: '',
                    node_id: 'ba1c64fb9d189acd10385475bb0c98ef588012ec',
                },
            },
            replication_slave: {
                master_host: '10.0.8.16',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 23,
            hostname: '10.0.8.18',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.18',
            lastseen: 1712575055,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 78,
            port: 6380,
            readonly: false,
            role: 'primary',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575055,
            unique_id: 22,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: undefined,
                    hash_slot_info: '11000-16383',
                    node_id: 'abc705aede33e5e219653c5c3f8f14fa4008deaf',
                },
            },
            replication_master: {
                slave_info: {
                    slaves: [
                        {
                            bus_port: '16380',
                            ip: '10.0.8.19',
                            link_state: null,
                            port: '6380',
                        },
                    ],
                },
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 24,
            hostname: '10.0.8.19',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.19',
            lastseen: 1712575050,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 77,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575050,
            unique_id: 23,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    flags: undefined,
                    hash_slot_info: '',
                    node_id: '326b281e66fbd42b70c2218a0c55c46cc6eddb93',
                },
            },
            replication_slave: {
                master_host: '10.0.8.18',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'RedisShardedHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/redis/redis.conf',
            connected: true,
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 24,
            hostname: '10.0.8.29',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.8.29',
            lastseen: 1712575050,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis_sharded',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 77,
            port: 6380,
            readonly: true,
            role: 'replica',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1712575050,
            unique_id: 23,
            version: '7.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                sharded: {
                    bus_link: 'connected',
                    hash_slot_info: '',
                    node_id: '326b281e66fbd42b70c2218a0c55c46cc6eddb93',
                },
            },
            replication_slave: {
                master_host: '10.0.8.18',
                master_port: 6380,
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            aux_port: -1,
            clusterid: 4,
            configfile: '/etc/cmon.d/cmon_4.cnf',
            connected: true,
            container: false,
            hostId: 25,
            hostname: '178.128.85.233',
            hoststatus: 'CmonHostOnline',
            ip: '178.128.85.233',
            lastseen: 1712575026,
            logfile: '/var/log/cmon_4.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 23960,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1712575026,
            unique_id: 24,
            uptime: 256075,
            version: '1.9.9.8367',
            wallclock: 1712575026,
            wallclocktimestamp: 1712575026,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
        },
        {
            class_name: 'CmonPrometheusHost',
            cdt_path: '/Redis cluster',
            owner_user_id: 6,
            owner_user_name: 'ccadmin',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 4,
            configfile: '/etc/prometheus/prometheus.yml',
            container: false,
            data_retention: '15d',
            data_retention_size: '',
            datadir: '/var/lib/prometheus',
            hostId: 25,
            hostname: '178.128.85.233',
            hoststatus: 'CmonHostOffLine',
            ip: '178.128.85.233',
            lastseen: 1712574974,
            logfile: '/var/log/prometheus/prometheus.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            managed_by_sd: false,
            nodetype: 'prometheus',
            pingstatus: -1,
            pingtime: -1,
            port: 9090,
            scrape_interval: '30s',
            statereason:
                'Failure: Prometheus query failure: Connect (to "localhost:9090") failure: Connection refused.',
            timestamp: 1712574974,
            unique_id: 25,
            version: '2.29.2',
            wallclock: 1712574974,
            wallclocktimestamp: 1712574974,
            distribution: {
                name: 'ubuntu',
                codename: 'bionic',
                release: '18.04',
                type: 'debian',
            },
            exporters: [
                {
                    address: '10.0.8.14:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.14:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.15:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.15:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.16:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.16:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.17:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.17:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.18:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.18:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.19:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '10.0.8.19:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '178.128.85.233:9100',
                    job: 'node',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
                {
                    address: '178.128.85.233:9011',
                    job: 'process',
                    monitored_host: '',
                    up: true,
                    version: '',
                },
            ],
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 4,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 3438,
            FINISHED: 1,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 6,
        owner_user_name: 'ccadmin',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2023-02-08T02:27:22.561Z',
        disabled: false,
        email_address: 'admin@localhost.com',
        first_name: 'Admin',
        last_failed_login: '',
        last_login: '2024-04-08T10:53:02.835Z',
        last_name: 'Admin',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 6,
        user_name: 'ccadmin',
        groups: [
            {
                class_name: 'CmonGroup',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'UTC',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};
export default mock;
