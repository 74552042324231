import useFetch from '../../common/useFetch';
import CmonBackupService from '../../services/cmon/CmonBackupService';
import CcSnapshotRepository from '../../services/models/CcSnapshotRepository';
import { useEffect, useState } from 'react';
import CcBackupService from '../../services/CcBackupService';
import CcSnapshotRepositoryCollection from '../../services/models/CcSnapshotRepositoryCollection';

export default useSnapshotRepositoryList;

export type UseSnapshotRepositoryListProps = {
    clusterId?: number;
};

function useSnapshotRepositoryList({
    clusterId,
}: UseSnapshotRepositoryListProps = {}) {
    const [data, setData] = useState<CcSnapshotRepository[]>([]);
    const {
        loading,
        loaded,
        data: repositoryCollection,
        refresh,
    } = useFetch({
        name: 'snapshot-repositories-list',
        useCache: true,
        fetchFn: async (params, opts) => {
            if (clusterId) {
                const { snapshot_repositories } =
                    await CcBackupService.getSnapshotRepositories(
                        {
                            cluster_id: clusterId,
                            ...params,
                        },
                        opts
                    );
                return new CcSnapshotRepositoryCollection({
                    data: { 0: snapshot_repositories },
                });
            } else {
                const { snapshot_repositories_list } =
                    await CcBackupService.getAllSnapshotRepositories({});
                return new CcSnapshotRepositoryCollection({
                    data: snapshot_repositories_list,
                });
            }
        },
        cancelFn: async ({ requestId }) => {
            await CmonBackupService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        if (repositoryCollection) {
            setData(repositoryCollection.list);
        }
    }, [repositoryCollection]);

    const collection: CcSnapshotRepositoryCollection = repositoryCollection;
    return {
        loading,
        loaded,
        data,
        collection,
        refresh,
    };
}
