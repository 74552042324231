import React, { useCallback, useEffect, useRef, useState } from 'react';
import { groupCollectionBy } from '../../../common/utils/aggregating';
import MonitorVariable, {
    MonitorVariableItem,
} from '../../Monitor/MonitorVariable';
import CcCluster, {
    findHostByHostname,
} from '../../../services/models/CcCluster';
import { Space } from 'antd';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import {
    MonitorDashboardConfig,
    MonitorDashboardTemplatingItemConfig,
} from '../../Monitor/Monitor';
import { ObjectParam, useQueryParam } from 'use-query-params';

export default ClusterDashboardsVariables;

export type ClusterDashboardsVariablesProps = {
    cluster: CcCluster;
    dashboard: MonitorDashboardConfig;
    onFilterLoaded?: (filters: any) => void;
    onFilterChange?: (filters: any) => void;
};

function ClusterDashboardsVariables({
    cluster,
    dashboard,
    onFilterLoaded,
    onFilterChange,
}: ClusterDashboardsVariablesProps) {
    const [queryVars, setQueryVars] = useQueryParam<any, any>(
        'vars',
        ObjectParam
    );

    const filters = useRef<{ [key: string]: any }>({});

    const [filterList, setFilterList] = useState<MonitorVariableItem[]>([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [filtersCurrentSortIndex, setFiltersCurrentSortIndex] = useState(0);
    const [filtersSortKeys, setFiltersSortKeys] = useState<number[]>([]);
    const [vars, setVars] = useState<MonitorVariableItem[]>([]);

    const removeQueryVar = (name: string | string[]) => {
        if (Array.isArray(name)) {
            name.forEach((k) => {
                delete queryVars[k];
            });
        } else {
            delete queryVars[name];
        }
        setQueryVars(
            queryVars && Object.values(queryVars).length > 0
                ? queryVars
                : undefined
        );
    };

    // Need to use hostname, ip or hostnameInternal to query prometheus...
    // we don't know the configuration of the prometheus instance
    const getExtraValuesForInstance = useCallback(
        (value: string) => {
            let extraValues: string[] = [];
            const nodeByHostname = cluster.nodes.find(
                (h) => h.hostname === value
            );
            if (nodeByHostname) {
                extraValues = [
                    ...(nodeByHostname.ip && nodeByHostname.ip !== value
                        ? [nodeByHostname.ip]
                        : []),
                    ...(nodeByHostname.hostnameInternal &&
                    nodeByHostname.hostnameInternal !== value
                        ? [nodeByHostname.hostnameInternal]
                        : []),
                ];
            }
            return extraValues;
        },
        [cluster]
    );

    const resolveFilters = () => {
        const previousFilters = { ...filters.current };
        const newFilters: typeof filters.current = {};
        setFiltersLoaded(false);
        if (
            dashboard.templating &&
            dashboard.templating.list &&
            dashboard.templating.list.length > 0
        ) {
            // currentSortIndex is going to help to load filters in order
            const currentSortIndex = 0;
            setFiltersCurrentSortIndex(currentSortIndex);
            // we group filters by sort field so we load them in correct order.
            const groupedBySortField = groupCollectionBy(
                dashboard.templating.list,
                'sort'
            );
            // getting the keys and cast to int.
            const numericKeys = Object.keys(groupedBySortField).map((k) =>
                parseInt(k, 10)
            );
            const sortKeys = numericKeys.sort();
            setFiltersSortKeys(sortKeys);
            dashboard.templating.list.forEach(
                (v: MonitorDashboardTemplatingItemConfig) => {
                    let value = null;
                    if (previousFilters[v.name]) {
                        value = previousFilters[v.name].value;
                        // } else if (vm.selectedFilters && v.hide !== 1) {
                        //     value = vm.selectedFilters[v.name];
                    }
                    newFilters[v.name] = {
                        key: v.name,
                        // ready will be true for those sorts === currentSortIndex === 0 initially.
                        // and ready field will be used to start loading filters
                        loaded: false,
                        ready: v.sort === sortKeys[currentSortIndex],
                        value,
                        isInstancePicker: !!v.isInstancePicker,
                        variable: v,
                        sort: v.sort,
                        extraValues: v.isInstancePicker
                            ? getExtraValuesForInstance(value)
                            : [],
                        instance: findHostByHostname(cluster, value),
                    };
                }
            );
            const notFoundKeys = Object.keys(queryVars || {}).filter(
                (k) => !newFilters[k]
            );
            removeQueryVar(notFoundKeys);

            filters.current = newFilters;
            setFilterList(Object.values(filters.current));
        } else {
            setFiltersLoaded(true);
        }
    };

    // init
    useEffect(() => {
        resolveFilters();
    }, [dashboard]);

    // filter change
    useEffect(() => {
        if (
            filterList.length ===
                (dashboard.templating &&
                    dashboard.templating.list &&
                    dashboard.templating.list.length) &&
            filterList.every((f) => f.loaded)
        ) {
            if (filtersLoaded) {
                onFilterChange?.(filterList);
            } else {
                onFilterLoaded?.(filterList);
            }
        }
    }, [filterList]);

    const handleVariableLoad = (
        variable: MonitorDashboardTemplatingItemConfig,
        value: string
    ) => {
        if (
            queryVars &&
            queryVars[variable.name] &&
            queryVars[variable.name] !== value
        ) {
            removeQueryVar(variable.name);
        }
        // updating filter in our map
        filters.current[variable.name].value = value;
        filters.current[variable.name].instance = findHostByHostname(
            cluster,
            value
        );
        if (filters.current[variable.name].isInstancePicker) {
            filters.current[
                variable.name
            ].extraValues = getExtraValuesForInstance(value);
        }

        filters.current[variable.name].loaded = true;
        if (variable.hide !== 1) {
            // triggering filter change if variable is not hidden
            // onVariableChange?.(variable.name, value);
        }

        const currentSortFilters = filterList.filter(
            (f) => f.sort === filtersSortKeys[filtersCurrentSortIndex]
        );
        if (currentSortFilters.every((f) => f.loaded)) {
            // current sort fields are loaded so we continue with the next sort
            const newfiltersCurrentSortIndex = filtersCurrentSortIndex + 1;
            setFiltersCurrentSortIndex(newfiltersCurrentSortIndex);
            const nextSortFilters = filterList.filter(
                (f) => f.sort === filtersSortKeys[newfiltersCurrentSortIndex]
            );
            if (newfiltersCurrentSortIndex < filtersSortKeys.length) {
                nextSortFilters.forEach((f) => {
                    setVars(filterList.filter((f) => f.ready));

                    // we set ready next sort filters
                    filters.current[f.key].ready = true;
                });
            }
        }
        const newFilterList = Object.values(filters.current);

        if (newFilterList.every((f) => f.loaded)) {
            // all filters loaded
            setFilterList(newFilterList);
            // trick to set the loaded to true after filterlist change
            setTimeout(() => setFiltersLoaded(true));
        }
    };

    const handleVariableChange = (
        variable: MonitorDashboardTemplatingItemConfig,
        value: string,
        label: string
    ) => {
        if (filters.current[variable.name].value !== value) {
            filters.current[variable.name].value = value;
            filters.current[variable.name].instance = findHostByHostname(
                cluster,
                value
            );

            setQueryVars({ [variable.name]: value });

            // reloading dependent filters
            Object.keys(filters.current).forEach((k) => {
                if (
                    filters.current[k].sort >
                    filters.current[variable.name].sort
                ) {
                    filters.current[k].loaded = false;
                }
            });

            setFilterList(Object.values(filters.current));
            if (variable.hide !== 1) {
                // triggering filter change if variable is not hidden
                // onVariableChange?.()
            }
        }
    };
    return (
        <Space className="ClusterDashboardsVariables">
            {filterList.map((f) => (
                <SpaceDescriptions
                    key={`${cluster.clusterId}-${f.variable.name}-${f.loaded}`}
                    title={f.variable.label}
                    style={f.variable.hide ? { display: 'none' } : {}}
                >
                    <MonitorVariable
                        cluster={cluster}
                        variable={f.variable}
                        selectedValue={queryVars?.[f.variable.name]}
                        vars={vars}
                        onVariableLoad={handleVariableLoad}
                        onVariableChange={handleVariableChange}
                        style={{ minWidth: '200px' }}
                    />
                </SpaceDescriptions>
            ))}
        </Space>
    );
}
