import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import AppRadio from '../../../../../common/DataEntry/AppRadio';
import { Form, Space } from 'antd';
import React from 'react';
import { BackupRestoreType } from '../BackupRestoreWizardForm';
import TypographyText from '../../../../../common/TypographyText';
import {
    getBackupRestoreTypeDescription,
    getBackupRestoreTypeFormItemName,
} from './BackupRestoreTypeForm';

export default BackupRestoreTypeElasticForm;
type BackupRestoreTypeFormProps = {
    cluster: CcCluster;
    form: FormInstance;
};

function BackupRestoreTypeElasticForm({
    cluster,
    form,
}: BackupRestoreTypeFormProps) {
    return (
        <Form.Item name="restoreType">
            <AppRadio.Group>
                <Space direction="vertical">
                    <AppRadio value={BackupRestoreType.NODE}>
                        Restore snapshot
                        {getBackupRestoreTypeDescription(
                            BackupRestoreType.NODE
                        )}
                    </AppRadio>
                    <AppRadio value={BackupRestoreType.SIMPLE_VERIFICATION}>
                        Verify snapshot
                        <TypographyText muted={true}>
                            <ul>
                                <li>Snapshot checksum will be validated</li>
                            </ul>
                        </TypographyText>
                    </AppRadio>
                </Space>
            </AppRadio.Group>
        </Form.Item>
    );
}
