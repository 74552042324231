import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useMemo, useState } from 'react';
import CcBackup, {
    isBackupMethodMariaBackup,
    isBackupMethodXtraBackup,
    CcBackupMethod,
} from '../../../../../services/models/CcBackup';
import { BackupRestoreWizardFormValues } from '../BackupRestoreWizardForm';
import BackupLocationSelectForm from '../../../../../common/Form/BackupLocationSelectForm';
import SpaceWide from '../../../../../common/SpaceWide';
import useBackups from '../../../useBackups';
import { Alert, Skeleton } from 'antd';
import BackupRestoreSQLPITRForm from './BackupRestoreSQLPITRForm';
import { CcClusterTechnology } from '../../../../../services/models/CcCluster';

export default BackupRestoreConfigurationForm;
type BackupRestoreConfigurationFormProps = {
    cluster: CcCluster;
    backup?: CcBackup;
    form: FormInstance<BackupRestoreWizardFormValues>;
};

function BackupRestoreConfigurationForm({
    cluster,
    backup,
    form,
}: BackupRestoreConfigurationFormProps) {
    const [selectedBackup, setSelectedBackup] = useState(backup);
    const { list, refresh, loaded } = useBackups({ pageSize: 0 });
    useEffect(() => {
        (async () => {
            await refresh({ cluster_id: cluster?.clusterId, useCache: true });
        })();
    }, [cluster?.clusterId]);

    const handleBackupSelect = (backup: CcBackup) => {
        setSelectedBackup(backup);
        if (backup?.getMethod() !== CcBackupMethod.MSSQL_LOG) {
            form.setFieldsValue({ pitr: false, pitrTime: undefined });
        }
    };

    return loaded ? (
        <SpaceWide direction="vertical">
            <BackupLocationSelectForm
                form={form}
                cluster={cluster}
                backup={backup}
                onBackupSelect={handleBackupSelect}
                backupSelectProps={{
                    selectFirst: true,
                }}
            />
            {[CcBackupMethod.MSSQL_LOG].includes(
                selectedBackup?.getMethod()
            ) && <BackupRestoreSQLPITRForm form={form} />}
            {selectedBackup && isBackupPartialAndNotAllowed(selectedBackup) && (
                <Alert
                    type="warning"
                    showIcon={true}
                    message="Partial backup is selected"
                    description={
                        <span>
                            <span>
                                Please note that ClusterControl does NOT support
                                the restoration of partial backups that are
                                created using xtrabackup/mariabackup.
                                <br />
                                <br />
                                Here’s what will happen during the restoration
                                process:
                                <br />
                                <ul>
                                    <li>
                                        The restoration job will extract the
                                        backup files to a temporary directory.
                                    </li>
                                    <li>
                                        You will need to manually import the
                                        table into a running MySQL server from
                                        this directory.
                                    </li>
                                </ul>
                                Please refer to Percona Xtrabackup{' '}
                                <a
                                    href="https://docs.severalnines.com/docs/clustercontrol/user-guide-gui/mysql/backup/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    documentation
                                </a>{' '}
                                before performing this exercise.
                            </span>
                        </span>
                    }
                />
            )}
        </SpaceWide>
    ) : (
        <Skeleton active={true} paragraph={{ rows: 3 }} />
    );
}

export function filterBackupRestoreNotAllowed(backups: CcBackup[]) {
    return backups.filter((backup) => !isBackupPartialAndNotAllowed(backup));
}

export function isBackupPartialAndNotAllowed(backup: CcBackup) {
    const method = backup?.getMethod();
    return (
        method &&
        backup?.isPartial() &&
        (isBackupMethodMariaBackup(method) || isBackupMethodXtraBackup(method))
    );
}
