import CcCluster from '../../services/models/CcCluster';
import useFetch from '../../common/useFetch';
import CcNode, { CcNodeProps, CcNodeType } from '../../services/models/CcNode';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import { AppEditorConfigSyntax } from '../../common/AppEditor/AppEditor';

type ClusterNodeConfigFileRecord = {
    content?: string;
    filename?: string;
    hostname?: string;
    path?: string;
    port?: number;
    syntax?: AppEditorConfigSyntax;
};

type ClusterNodeConfigFileItem = ClusterNodeConfigFileRecord & {
    values?: any[];
};

type ClusterNodeConfigRecord = {
    host: CcNodeProps;
    files: ClusterNodeConfigFileRecord[];
    config?: {
        files: {
            filename?: string;
            values?: any[];
        }[];
    };
};

type ClusterNodeConfigItem = {
    node: CcNode;
    files: ClusterNodeConfigFileItem[];
};

type UseNodeConfigsProps = {
    cluster: CcCluster;
};

// @todo merge this with ConfigNodeFileProvider and reuse data in ConfigEditParamsModal
export default function useClusterNodesConfigs({
    cluster,
}: UseNodeConfigsProps) {
    const { data, loading, refresh } = useFetch<ClusterNodeConfigItem[]>({
        fetchFn: async ({ ...rest }, opts) => {
            return await requestClusterNodesAllConfigs(cluster);
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    return {
        data,
        loading,
        refresh,
    };
}

export async function requestClusterNodesAllConfigs(
    cluster: CcCluster
): Promise<ClusterNodeConfigItem[]> {
    const data: any = await CmonConfigService.getConfigs({
        cluster_id: cluster?.clusterId,
    });
    return data?.configs
        .map((item: ClusterNodeConfigRecord) => {
            const configFiles = item?.config?.files;
            const files = item?.files?.map(
                (item: ClusterNodeConfigFileRecord) => {
                    const fileConfig = configFiles?.find(
                        (configItem: any) =>
                            configItem.filename === item.filename
                    );
                    return {
                        ...item,
                        values: fileConfig?.values || [],
                    };
                }
            );

            return {
                node: item.host as CcNode,
                files: files || [],
            };
        })
        .filter(
            ({ node }: any) =>
                node.isDatabaseNode() ||
                node.isLoadBalancer() ||
                [CcNodeType.REDIS_SENTINEL].includes(node.nodetype)
        );
}

export async function requestClusterNodesConfig(
    cluster: CcCluster
): Promise<ClusterNodeConfigItem[]> {
    const nodes =
        cluster.nodes?.filter((node) => {
            return node.isDatabaseNode() || node.isLoadBalancer();
        }) || [];
    const data: any[] = await Promise.all(
        nodes.map(async (node) => {
            return await CmonConfigService.getConfig({
                cluster_id: cluster?.clusterId,
                hostname: node?.hostname,
                port: node?.port,
            });
        })
    );

    return data.map((item) => ({
        node: item.config.host as CcNode,
        files: item.files || [],
    }));
}
