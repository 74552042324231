import { Button } from 'antd';
import React from 'react';
import { UserEmail } from './emailNotificationsHelper';
import CcCluster from '../../services/models/CcCluster';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import AppConfirmActionButton, {
    AppConfirmActionButtonProps,
} from '../../common/General/AppConfirmActionButton';

export default EmailNotificationsRemoveRecipientButton;

type EmailNotificationsRemoveRecipientProps = AppConfirmActionButtonProps & {
    userEmail: UserEmail;
    cluster: CcCluster;
    onRemove?: () => void;
    children?: React.ReactNode;
};

function EmailNotificationsRemoveRecipientButton({
    userEmail,
    cluster,
    onRemove,
    children,
    ...rest
}: EmailNotificationsRemoveRecipientProps) {
    const removeUser = async () => {
        try {
            await CmonConfigService.removerecipient({
                cluster_id: cluster.clusterId,
                email: userEmail?.emailAddress,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Email ${userEmail?.emailAddress} removed from recipients list!`,
                cancelText: 'Close',
            });
            await onRemove?.();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const handleConfirm = async () => {
        await removeUser();
    };
    return (
        <AppConfirmActionButton
            confirmTitle={`Are you sure you want to remove ${userEmail?.emailAddress} from recipient list?`}
            danger={userEmail?.active}
            onConfirm={handleConfirm}
            critical={true}
            type="primary"
            size="middle"
            {...rest}
        >
            {children || 'Remove'}
        </AppConfirmActionButton>
    );
}
