import './ProxySQLUsers.less';
import React, { useContext, useEffect } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import ProxySQLUsersModal from './ProxySQLUsersModal';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import { notifyError } from '../../../../../Notifications/uiNotification';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcProxySqlUser from '../../../../../../services/models/CcProxySqlUser';
import { prepareDeleteUserData } from './proxySQLUserHelper';
import ProxySQLImportUsers from './ProxySQLImportUsers';
import AppConfirmActionButton from '../../../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import { getSortAlphabeticFn } from '../../../../../../common/sorting';
import useProxySQLUsers from './useProxySQLUsers';

export default ProxySQLUsers;

export type ProxySQLUsersProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    usersPageSize?: number;
    hostGroupList: { value: string; label: string }[] | undefined;
};

function ProxySQLUsers({
    cluster,
    node,
    usersPageSize = 15,
    hostGroupList,
    ...rest
}: ProxySQLUsersProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const {
        loading,
        list: users,
        refresh: usersServers,
        filter: filterUsers,
        page,
        pageSize,
        total,
    } = useProxySQLUsers({
        clusterId: cluster?.clusterId,
        hostName: node?.hostname,
        pageSize: usersPageSize,
    });

    useEffect(() => {
        usersServers({});
    }, []);

    const deleteUser = async (user: CcProxySqlUser) => {
        try {
            await CmonProxysqlService.deletemysqluser({
                hostName: node?.hostname,
                port: node?.port,
                cluster_id: cluster?.clusterId,
                mysqlUser: prepareDeleteUserData(user),
            });
            usersServers();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const columns = [
        {
            title: 'Username',
            key: 'username',
            dataIndex: 'username',
            sorter: true,
        },
        {
            title: 'Status',
            key: 'status',
            sorter: true,
            render: (record: CcProxySqlUser) => (
                <StatusFormat
                    status={
                        (record.active as any) === '1'
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.unknown
                    }
                    text={(record.active as any) === '1' ? 'Online' : 'Offline'}
                />
            ),
        },
        {
            title: 'Type',
            key: 'type',
            sorter: true,
            render: (record: CcProxySqlUser) => `${getType(record)}`,
        },
        {
            title: 'Default hostgroup',
            key: 'defaultHostgroup',
            dataIndex: 'defaultHostgroup',
            sorter: true,
        },
        {
            title: 'Default schema',
            key: 'defaultSchema',
            dataIndex: 'defaultSchema',
            sorter: true,
        },
        {
            title: 'Max connections',
            key: 'maxConnections',
            dataIndex: 'maxConnections',
            sorter: true,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: CcProxySqlUser) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-user',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <ProxySQLUsersModal
                                            hostGroupList={hostGroupList}
                                            cluster={cluster}
                                            node={node}
                                            onSuccess={usersServers}
                                            userData={record}
                                            edit={true}
                                            title={'Edit user'}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-user',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete User?`}
                                    critical
                                    onConfirm={() => deleteUser(record)}
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'username':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.username
                );
                break;
            case 'status':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.active);
                break;
            case 'type':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) =>
                    getType(x)
                );
                break;
            case 'defaultHostgroup':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.defaultHostgroup
                );
                break;
            case 'defaultSchema':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) =>
                    x.defaultSchema ? x.defaultSchema : ''
                );
                break;
            case 'maxConnections':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.maxConnections
                );
                break;
        }

        filterUsers({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLUsers">
            <Row justify="end" style={{ paddingBottom: '1rem' }}>
                <Space>
                    <ButtonWithForm
                        button={
                            <Button disabled={loading} icon={<PlusOutlined />}>
                                Import users
                            </Button>
                        }
                        form={
                            <ProxySQLImportUsers
                                hostGroupList={hostGroupList}
                                cluster={cluster}
                                node={node}
                                onSuccess={usersServers}
                            />
                        }
                    />
                    <ButtonWithForm
                        button={
                            <Button disabled={loading} icon={<PlusOutlined />}>
                                Create new user
                            </Button>
                        }
                        form={
                            <ProxySQLUsersModal
                                hostGroupList={hostGroupList}
                                cluster={cluster}
                                node={node}
                                onSuccess={usersServers}
                                title={'Add new user'}
                            />
                        }
                    />
                </Space>
            </Row>

            <AppTable
                rowKey={(record: CcProxySqlUser) => record.getKey()}
                className="ProxySQLUsersTable"
                size="small"
                dataSource={users}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                renderEmpty={
                    <AppEmpty
                        loading={loading}
                        description="You haven’t created Users yet. When you do, it'll show up here."
                    />
                }
            />
        </div>
    );
}

function getType(user: CcProxySqlUser) {
    if (Number(user.frontend) && Number(user.backend)) {
        return 'ProxySQL, MySQL';
    } else if (Number(user.frontend)) {
        return 'ProxySQL';
    } else if (Number(user.backend)) {
        return 'MySQL';
    } else {
        return '';
    }
}
