
import CcNode, { CcNodeProps } from '../../models/CcNode';
import CcNodeReplicationSlave, { CcNodeReplicationSlaveProps } from '../../models/CcNodeReplicationSlave';
export interface CmonMySqlHostSlowQueryProps {
    log_not_using_idxs?: string;
    long_query_time?: string;
    slow_query_log?: string;
    slow_query_log_file?: string;
}

export interface CmonMySqlHostReplicationSlaveProps extends CcNodeReplicationSlaveProps {
    exec_master_log_pos?: number;
    executed_gtid_set?: string;
    gtid_binlog_pos?: string;
    gtid_io_pos?: string;
    last_io_errno?: string;
    last_io_error?: string;
    last_io_error_timestamp?: string;
    last_sql_errno?: string;
    last_sql_error?: string;
    last_sql_error_timestamp?: string;
    linkstatus?: number;
    master_log_file?: string;
    master_server_id?: string;
    master_uuid?: string;
    read_master_log_pos?: string;
    relay_master_log_file?: string;
    retrieved_gtid_set?: string;
    semisync_status?: string;
    slave_io_running?: string;
    slave_io_state?: string;
    slave_sql_running?: string;
    slave_sql_state?: string;
    sqldelay?: number;
    status?: string;
    using_gtid?: string;
}

export interface CmonMySqlHostReplicationMasterProps {
    binlog_do_db?: string;
    binlog_ignore_db?: string;
    exec_gtid?: string;
    file?: string;
    position?: string;
    semisync_status?: string;
    slaves_connected?: number;
}

export interface CmonMySqlHostNdbProps {
    nodes?: number;
    nodes_ready?: number;
}

export interface CmonMySqlHostGaleraProps {
    cluster_address?: string;
    clustername?: string;
    gcache_recover?: boolean;
    inactivetimeout?: number;
    provider?: string;
    segmentid?: number;
    socketssl?: boolean;
    sst_method?: string;
}

export interface CmonMySqlHostAuditLogProps {
    plugins?: string;
}


export interface CmonMySqlHostProps  extends CcNodeProps {
    audit_log?: CmonMySqlHostAuditLogProps;
    datacenter?: number;
    elapsedtime?: number;
    errorcode?: number;
    errormsg?: string;
    galera?: CmonMySqlHostGaleraProps;
    isgalera?: boolean;
    log_bin?: boolean;
    log_bin_basename?: string;
    log_bin_index?: string;
    max_binlog_size?: string;
    mysqlstatus?: number;
    ndb?: CmonMySqlHostNdbProps;
    nodeid?: number;
    performance_schema?: boolean;
    rebuild_slave_jobid?: boolean;
    replication_master?: CmonMySqlHostReplicationMasterProps;
    replication_slave?: CmonMySqlHostReplicationSlaveProps;
    serverid?: number;
    skip_name_resolve?: boolean;
    slaves?: string[];
    slow_query?: CmonMySqlHostSlowQueryProps;
    super_read_only?: boolean;
    version_comment?: string;

}

export class CmonMySqlHostSlowQuery {
    public logNotUsingIdxs?: string;
    public longQueryTime?: string;
    public slowQueryLog?: string;
    public slowQueryLogFile?: string;

    constructor(props: CmonMySqlHostSlowQueryProps) {
        this.logNotUsingIdxs = props.log_not_using_idxs;
        this.longQueryTime = props.long_query_time;
        this.slowQueryLog = props.slow_query_log;
        this.slowQueryLogFile = props.slow_query_log_file;

    }}

export class CmonMySqlHostReplicationSlave extends CcNodeReplicationSlave {
    public execMasterLogPos?: number;
    public executedGtidSet?: string;
    public gtidBinlogPos?: string;
    public gtidIoPos?: string;
    public lastIoErrno?: string;
    public lastIoError?: string;
    public lastIoErrorTimestamp?: string;
    public lastSqlErrno?: string;
    public lastSqlError?: string;
    public lastSqlErrorTimestamp?: string;
    public linkstatus?: number;
    public masterLogFile?: string;
    public masterServerId?: string;
    public masterUuid?: string;
    public readMasterLogPos?: string;
    public relayMasterLogFile?: string;
    public retrievedGtidSet?: string;
    public semisyncStatus?: string;
    public slaveIoRunning?: string;
    public slaveIoState?: string;
    public slaveSqlRunning?: string;
    public slaveSqlState?: string;
    public sqldelay?: number;
    public status?: string;
    public usingGtid?: string;

    constructor(props: CmonMySqlHostReplicationSlaveProps) {
        super(props)
        this.execMasterLogPos = props.exec_master_log_pos;
        this.executedGtidSet = props.executed_gtid_set;
        this.gtidBinlogPos = props.gtid_binlog_pos;
        this.gtidIoPos = props.gtid_io_pos;
        this.lastIoErrno = props.last_io_errno;
        this.lastIoError = props.last_io_error;
        this.lastIoErrorTimestamp = props.last_io_error_timestamp;
        this.lastSqlErrno = props.last_sql_errno;
        this.lastSqlError = props.last_sql_error;
        this.lastSqlErrorTimestamp = props.last_sql_error_timestamp;
        this.linkstatus = props.linkstatus;
        this.masterLogFile = props.master_log_file;
        this.masterServerId = props.master_server_id;
        this.masterUuid = props.master_uuid;
        this.readMasterLogPos = props.read_master_log_pos;
        this.relayMasterLogFile = props.relay_master_log_file;
        this.retrievedGtidSet = props.retrieved_gtid_set;
        this.semisyncStatus = props.semisync_status;
        this.slaveIoRunning = props.slave_io_running;
        this.slaveIoState = props.slave_io_state;
        this.slaveSqlRunning = props.slave_sql_running;
        this.slaveSqlState = props.slave_sql_state;
        this.sqldelay = props.sqldelay;
        this.status = props.status;
        this.usingGtid = props.using_gtid;

    }}

export class CmonMySqlHostReplicationMaster {
    public binlogDoDb?: string;
    public binlogIgnoreDb?: string;
    public execGtid?: string;
    public file?: string;
    public position?: string;
    public semisyncStatus?: string;
    public slavesConnected?: number;

    constructor(props: CmonMySqlHostReplicationMasterProps) {
        this.binlogDoDb = props.binlog_do_db;
        this.binlogIgnoreDb = props.binlog_ignore_db;
        this.execGtid = props.exec_gtid;
        this.file = props.file;
        this.position = props.position;
        this.semisyncStatus = props.semisync_status;
        this.slavesConnected = props.slaves_connected;

    }}

export class CmonMySqlHostNdb {
    public nodes?: number;
    public nodesReady?: number;

    constructor(props: CmonMySqlHostNdbProps) {
        this.nodes = props.nodes;
        this.nodesReady = props.nodes_ready;

    }}

export class CmonMySqlHostGalera {
    public clusterAddress?: string;
    public clustername?: string;
    public gcacheRecover?: boolean;
    public inactivetimeout?: number;
    public provider?: string;
    public segmentid?: number;
    public socketssl?: boolean;
    public sstMethod?: string;

    constructor(props: CmonMySqlHostGaleraProps) {
        this.clusterAddress = props.cluster_address;
        this.clustername = props.clustername;
        this.gcacheRecover = props.gcache_recover;
        this.inactivetimeout = props.inactivetimeout;
        this.provider = props.provider;
        this.segmentid = props.segmentid;
        this.socketssl = props.socketssl;
        this.sstMethod = props.sst_method;

    }}

export class CmonMySqlHostAuditLog {
    public plugins?: string;

    constructor(props: CmonMySqlHostAuditLogProps) {
        this.plugins = props.plugins;

    }}



export default class CmonMySqlHost extends CcNode {
    public auditLog?: CmonMySqlHostAuditLog;
    public datacenter?: number;
    public elapsedtime?: number;
    public errorcode?: number;
    public errormsg?: string;
    public galera?: CmonMySqlHostGalera;
    public isgalera?: boolean;
    public logBin?: boolean;
    public logBinBasename?: string;
    public logBinIndex?: string;
    public maxBinlogSize?: string;
    public mysqlstatus?: number;
    public ndb?: CmonMySqlHostNdb;
    public nodeid?: number;
    public performanceSchema?: boolean;
    public rebuildSlaveJobid?: boolean;
    public replicationMaster?: CmonMySqlHostReplicationMaster;
    public replicationSlave?: CmonMySqlHostReplicationSlave;
    public serverid?: number;
    public skipNameResolve?: boolean;
    public slaves?: string[];
    public slowQuery?: CmonMySqlHostSlowQuery;
    public superReadOnly?: boolean;
    public versionComment?: string;


    constructor(props: CmonMySqlHostProps) {
        super(props);
        this.auditLog = props.audit_log && new CmonMySqlHostAuditLog(props.audit_log);
        this.datacenter = props.datacenter;
        this.elapsedtime = props.elapsedtime;
        this.errorcode = props.errorcode;
        this.errormsg = props.errormsg;
        this.galera = props.galera && new CmonMySqlHostGalera(props.galera);
        this.isgalera = props.isgalera;
        this.logBin = props.log_bin;
        this.logBinBasename = props.log_bin_basename;
        this.logBinIndex = props.log_bin_index;
        this.maxBinlogSize = props.max_binlog_size;
        this.mysqlstatus = props.mysqlstatus;
        this.ndb = props.ndb && new CmonMySqlHostNdb(props.ndb);
        this.nodeid = props.nodeid;
        this.performanceSchema = props.performance_schema;
        this.rebuildSlaveJobid = props.rebuild_slave_jobid;
        this.replicationMaster = props.replication_master && new CmonMySqlHostReplicationMaster(props.replication_master);
        this.replicationSlave = props.replication_slave && new CmonMySqlHostReplicationSlave(props.replication_slave);
        this.serverid = props.serverid;
        this.skipNameResolve = props.skip_name_resolve;
        this.slaves = props.slaves;
        this.slowQuery = props.slow_query && new CmonMySqlHostSlowQuery(props.slow_query);
        this.superReadOnly = props.super_read_only;
        this.versionComment = props.version_comment;

    }

}
