import './uiNotification.less';
import {
    Button,
    Modal,
    ModalFuncProps,
    ModalProps,
    notification,
    Result,
    Space,
} from 'antd';
import classNames from 'classnames';
import {
    getStatusFormatIcon,
    getStatusFormatIconCircled,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { ModalFunc, ModalStaticFunctions } from 'antd/lib/modal/confirm';
import { ArgsProps } from 'antd/lib/message';
import SpaceWide from '../../common/SpaceWide';
import * as React from 'react';
import AppDivider from '../../common/AppDivider';
import TextExpand from '../../common/General/TextExpand';

export {
    notify,
    notifyModal,
    notifyToast,
    notifyConfirm,
    notifyConfirmSync,
    notifyAlert,
    notifyError,
    notifyOperationSuccess,
    notifyOperationMessages,
    notifyConfirmCloseWindow,
    getLargeIconUrl,
};

export enum NotifyType {
    MODAL = 'modal',
    TOAST = 'toast',
}
export type ModalApi = Omit<ModalStaticFunctions, 'warn'>;
export type ToastApi = any;
export type NotificationApi = ModalApi | ToastApi;

export type NotifyProps<T> = T & {
    type?: NotifyType;
    status?: StatusFormatStatus;
    title?: React.ReactNode;
    content?: React.ReactNode;
    duration?: number | null;
    showReadMore?: boolean;
    renderFooter?: (modalOrToast?: NotificationApi) => React.ReactNode;
};

function notify({
    type = NotifyType.MODAL,
    title,
    content,
    ...rest
}: NotifyProps<any>) {
    const defaluts = {
        title: title !== undefined ? title : 'Notification title!',
        content: content !== undefined ? content : '',
    };
    if (type === NotifyType.TOAST) {
        return notifyToast({ ...defaluts, ...rest });
    } else {
        return notifyModal({ ...defaluts, ...rest });
    }
}

export type NotifyToastProps = NotifyProps<ArgsProps> & {
    toastApi?: ToastApi;
};

function notifyToast({
    key,
    content,
    title,
    status,
    toastApi,
    renderFooter,
    showReadMore,
    // @ts-ignore
    ...rest
}: NotifyToastProps) {
    ((toastApi || notification) as ToastApi).open({
        key,
        description: (
            <SpaceWide direction="vertical">
                {showReadMore ? <TextExpand content={content} /> : content}
                {renderFooter?.(notification)}
            </SpaceWide>
        ),
        message: title,
        type: status,
        ...rest,
    });
    return (toastApi || notification) as ToastApi;
}

export type NotifyModalProps = Omit<NotifyProps<ModalProps>, 'type'> & {
    size?: 'large' | 'default';
    key?: string | number;
    modalApi?: ModalApi;
    modalFn?: keyof ModalApi;
};
function notifyModal({
    size,
    status,
    content,
    title,
    renderFooter,
    modalApi,
    modalFn = 'confirm',
    onOk,
    okText,
    ...rest
}: NotifyModalProps) {
    const modal = (modalApi || Modal)[modalFn]({});
    const footer = renderFooter ? (
        renderFooter(modal)
    ) : (
        <Space>
            <Button
                className="UiNotification_modal-button-ok"
                type="primary"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.persist();
                    onOk?.(e);
                    modal.destroy();
                }}
            >
                {okText}
            </Button>
        </Space>
    );
    modal.update({
        className: classNames(
            'UiNotification_modal',
            `UiNotification_modal--type-${status}`,
            `UiNotification_modal--size-${size}`
        ),
        ...(size === 'large'
            ? {
                  icon: <div></div>,
                  footer: null,
                  title: null,
                  width: 900,
                  content: (
                      <Result
                          style={{
                              background: 'white',
                              minWidth: '300px',
                              minHeight: '400px',
                          }}
                          status="success"
                          icon={
                              <div
                                  className="UiNotification_icon"
                                  style={{
                                      backgroundImage: `url("${getLargeIconUrl(
                                          status as StatusFormatStatus
                                      )}")`,
                                  }}
                              ></div>
                          }
                          title={title}
                          subTitle={
                              <Space direction="vertical" size={20}>
                                  <div>{content}</div>
                                  {footer}
                              </Space>
                          }
                      ></Result>
                  ),
              }
            : {
                  title,
                  content: (
                      <SpaceWide direction="vertical">
                          <div>{content}</div>
                          <div style={{ textAlign: 'right' }}>{footer}</div>
                      </SpaceWide>
                  ),
                  icon: getStatusFormatIcon(status as StatusFormatStatus),
              }),
        ...rest,
    });
    return modal;
}

function notifyConfirm({
    key,
    title,
    onCancel,
    onOk,
    cancelText = 'Cancel',
    okText = 'Ok',
    type = NotifyType.MODAL,
    renderFooter,
    ...rest
}: NotifyProps<any>) {
    const newKey = key || `confirm-${title}`;
    return notify({
        key: newKey,
        cancelText,
        okText,
        renderFooter: renderFooter
            ? renderFooter
            : (modalOrToast: NotificationApi) => (
                  <SpaceWide
                      justify={type === NotifyType.MODAL ? 'center' : 'right'}
                      style={{
                          ...(type === NotifyType.MODAL
                              ? { width: 'inherit' }
                              : {}),
                      }}
                  >
                      <Button
                          className="UiNotification_modal-button-cancel"
                          size={type === NotifyType.TOAST ? 'small' : 'middle'}
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.persist();
                              onCancel?.(e);
                              (type === NotifyType.TOAST &&
                                  modalOrToast.close(key)) ||
                                  modalOrToast.destroy();
                          }}
                      >
                          {cancelText}
                      </Button>
                      <Button
                          className="UiNotification_modal-button-ok"
                          size={type === NotifyType.TOAST ? 'small' : 'middle'}
                          type="primary"
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.persist();
                              onOk?.(e);
                              (type === NotifyType.TOAST &&
                                  modalOrToast.close(key)) ||
                                  modalOrToast.destroy();
                          }}
                      >
                          {okText}
                      </Button>
                  </SpaceWide>
              ),
        type,
        title,
        ...rest,
    });
}

async function notifyConfirmSync({
    onCancel,
    onOk,
    ...rest
}: NotifyModalProps) {
    let promise = new Promise((resolve) => {
        const handleOkClick = (e: React.MouseEvent<HTMLElement>) => {
            e.persist();
            onOk?.(e);
            resolve(true);
        };
        const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
            e.persist();
            onCancel?.(e);
            resolve(false);
        };
        notifyConfirm({
            onOk: handleOkClick,
            onCancel: handleCloseClick,
            ...rest,
        });
    });

    return promise;
}

export type NotifyAlertProps = NotifyProps<any>;
function notifyAlert({
    type = NotifyType.MODAL,
    okText = 'Ok',
    ...rest
}: NotifyAlertProps) {
    return notify({
        status: StatusFormatStatus.warning,
        okText,
        type,
        ...rest,
    });
}

export type NotifyErrorProps = NotifyAlertProps;

function notifyError({ title, ...rest }: NotifyErrorProps) {
    let modal: any;
    modal = notifyAlert({
        title: title || 'An error has ocurred!',
        status: StatusFormatStatus.error,
        okText: 'Close',
        ...rest,
    });
    return modal;
}

export type NotifyOperationSuccessProps = NotifyProps<any> & {};
function notifyOperationSuccess({ ...rest }: NotifyOperationSuccessProps = {}) {
    return notify({
        status: StatusFormatStatus.success,
        ...rest,
    });
}

export type ConfirmCloseWindowProps = NotifyModalProps & {
    onOk?: (result: boolean) => void;
};
function notifyConfirmCloseWindow({ onOk }: ConfirmCloseWindowProps = {}) {
    let modal: any;

    modal = notifyConfirm({
        title: 'Do you want to close the window?',
        content: 'All of the information you’ve entered will be lost.',
        okText: 'No',
        cancelText: 'Yes',
        onCancel: () => {
            if (onOk) {
                onOk(false);
            }
        },
        icon: getStatusFormatIconCircled(StatusFormatStatus.warning),
    });
    return modal;
}

export type OperationMessagesProps = NotifyOperationSuccessProps & {
    messages: string[];
};

function notifyOperationMessages({
    messages,
    ...rest
}: OperationMessagesProps) {
    // add element AppDivider in between messages skip last one
    const messagesArray = messages.reduce(
        (acc: any[], curr: any, i: number) => {
            const key = `${i}-${Date.now()}`;
            acc.push(
                <span
                    key={`${key}`}
                    dangerouslySetInnerHTML={{
                        __html: curr || '',
                    }}
                ></span>
            );
            acc.push(<AppDivider key={`divider-${key}`} />);
            return acc;
        },
        []
    );
    messagesArray.pop();
    notifyOperationSuccess({
        type: NotifyType.TOAST,
        title: '',
        content: <div>{messagesArray}</div>,
        ...rest,
    });
}

function getLargeIconUrl(type?: StatusFormatStatus) {
    switch (type) {
        case StatusFormatStatus.success:
            return require('./img-check-sonar.svg');
        case StatusFormatStatus.error:
            return require('./img-cross-sonar.svg');
        case StatusFormatStatus.unknown:
        default:
            return require('./img-question-sonar.svg');
    }
}
