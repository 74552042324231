import FormItemInline, { FormItemInlineProps } from '../FormItemInline';
import React, { ReactNode, useEffect, useState } from 'react';
import Switch, { SwitchProps } from './Switch';
import TypographyText from '../TypographyText';
import SpaceWide from '../SpaceWide';

export default FormItemInlineSwitch;

export type FormItemInlineSwitchProps = FormItemInlineProps & {
    extraOnSwitch?: ReactNode;
    extraOffSwitch?: ReactNode;
    disabled?: boolean;
    checked?: boolean;
    spaceSize?: number;
    onChange?: (flag: boolean) => void;
};

function FormItemInlineSwitch({
    extraOnSwitch,
    extraOffSwitch,
    disabled = false,
    checked = false,
    spaceSize = 0,
    onChange,
    label,
    ...rest
}: FormItemInlineSwitchProps) {
    const [value, setValue] = useState<boolean>(checked);
    const handleCheckedChange = (value: boolean) => {
        setValue(value);
        onChange?.(value);
    };
    const extra = value ? extraOnSwitch : extraOffSwitch;
    return (
        <SpaceWide direction="vertical" size={spaceSize}>
            <FormItemInline
                noMargin={!!extraOnSwitch}
                labelStrong={!!value}
                valuePropName="checked"
                label={
                    disabled ? (
                        <TypographyText muted>{label}</TypographyText>
                    ) : (
                        label
                    )
                }
                {...rest}
            >
                <SwitchWrap
                    disabled={disabled}
                    onCheckedChange={handleCheckedChange}
                    onChange={onChange}
                    checked={checked}
                />
            </FormItemInline>
            {extra ? <div>{extra}</div> : null}
        </SpaceWide>
    );
}

type SwitchWrapProps = SwitchProps & {
    onCheckedChange: (checked: boolean) => void;
};
function SwitchWrap({
    onChange,
    checked,
    onCheckedChange,
    ...rest
}: SwitchWrapProps) {
    const handleChange = (value: boolean, event: MouseEvent) => {
        onChange?.(value, event);
    };
    useEffect(() => {
        if (undefined !== checked) {
            onCheckedChange(checked);
        }
    }, [checked]);
    return <Switch onChange={handleChange} checked={checked} {...rest} />;
}
