import React, { useMemo } from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import CcUser from '../../services/models/CcUser';
import UserEnableDisableButton from './Actions/UserEnableDisableButton';
import UserDeleteButton from './Actions/UserDeleteButton';
import UserDetailsButton from './Actions/UserDetailsButton';
import UserEditButton from './Actions/UserEditButton';
import UserChangePasswordButton from '../User/UserChangePasswordButton';
import useCurrentUser from '../User/useCurrentUser';
import {
    ContactsOutlined,
    DeleteOutlined,
    EditOutlined,
    LockOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons';
import AppDivider from '../../common/AppDivider';

export default UserActionMenu;

export type UserActionMenuProps = ActionsMenuProps & {
    user: CcUser;
};

function UserActionMenu({
    user,
    onActionPerformed,
    ...rest
}: UserActionMenuProps) {
    const { record } = useCurrentUser();

    const handleUserDisableSuccess = () => {
        onActionPerformed?.();
    };
    const handleUserDeleteSuccess = () => {
        onActionPerformed?.();
    };
    const handleUserEditSuccess = () => {
        onActionPerformed?.();
    };
    const items = useMemo(() => {
        const isCurrentUser = user.getKey() === record?.getKey();
        const editAllowed = !user.isForbiddenUser();

        const changePasswordActionButton = editAllowed && {
            key: 'change-password',
            icon: <LockOutlined />,
            label: (
                <UserChangePasswordButton
                    user={user}
                    formProps={{
                        noCurrentPassword: true,
                        noRepeatPassword: true,
                    }}
                />
            ),
        };
        const toggleUserActionButton = editAllowed &&
            !isCurrentUser && {
                key: 'enable-disable',
                icon:
                    user?.suspended || user?.disabled ? (
                        <PlayCircleOutlined />
                    ) : (
                        <PauseCircleOutlined />
                    ),
                waitForConfirm: true,
                critical: true,
                label: (
                    <UserEnableDisableButton
                        user={user}
                        onSuccess={handleUserDisableSuccess}
                    />
                ),
            };

        const deleteActionButton = editAllowed &&
            !isCurrentUser && {
                key: 'delete',
                waitForConfirm: true,
                critical: true,
                icon: <DeleteOutlined />,
                label: (
                    <UserDeleteButton
                        user={user}
                        onSuccess={handleUserDeleteSuccess}
                    />
                ),
            };

        const criticalDivider =
            toggleUserActionButton || deleteActionButton
                ? {
                      type: 'divider',
                      label: <AppDivider noMargin={true} />,
                  }
                : undefined;

        return [
            {
                key: 'view',
                icon: <ContactsOutlined />,
                label: (
                    <UserDetailsButton
                        user={user}
                        onEdit={handleUserEditSuccess}
                    />
                ),
            },
            {
                key: 'edit',
                icon: <EditOutlined />,
                label: (
                    <UserEditButton
                        user={user}
                        onSuccess={handleUserEditSuccess}
                    />
                ),
            },
            changePasswordActionButton,
            criticalDivider,
            toggleUserActionButton,
            deleteActionButton,
        ].filter(Boolean);
    }, [user, record]);
    return <ActionsMenu items={items as ActionMenuItem[]} {...rest} />;
}
