import React, { useContext, useEffect } from 'react';
import './ProxySQLTopQueries.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import useListFetch from '../../../../../../common/useListFetch';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcProxySqlTopQueries from '../../../../../../services/models/CcProxySqlTopQueries';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import TopQueryFullDigestModal from './TopQueryFullDigestModal';
import ProxySQLRulesWizardModal from '../ProxySQLRules/ProxySQLRulesWizardModal';
import { RuleIdType } from '../ProxySQLRules/ProxySQLRules';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import AppDateFormat from '../../../../../../common/AppDateFormat';
import useTableFilterColumns from '../../../../../../common/hooks/useTableFilterColumns';
import useTableFilter from '../../../../../../common/hooks/useTableFilter';

export default ProxySQLTopQueries;

export type ProxySQLTopQueriesProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    queryPageSize?: number;
    hostGroupList: { value: string; label: string }[] | undefined;
    onQueriesLoad: () => Promise<void>;
    queryRuleId?: RuleIdType;
};

function ProxySQLTopQueries({
    cluster,
    node,
    queryPageSize = 15,
    hostGroupList,
    onQueriesLoad,
    queryRuleId,
    ...rest
}: ProxySQLTopQueriesProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const { filterParams, handleTableChange: onTableChange } = useTableFilter({
        defaultParams: {
            sort: 'sum_time',
            order: 'descend',
        },
    });
    const {
        loading,
        list: queryResponse,
        refresh: refreshQueries,
        page,
        pageSize,
        total,
    } = useListFetch({
        name: 'topqueries',
        pageSize: queryPageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.topqueries(
                {
                    cluster_id: cluster.clusterId,
                    hostName: node.hostname,
                    port: node.port,
                    orderByColumn: 'sum_time',
                    sortOrder: 'desc',
                    ...rest,
                },
                opts
            );

            return {
                list: (response.queryResults as CcProxySqlTopQueries[]).map(
                    (res, index) => {
                        return { key: `${res.firstSeen} ${index}`, ...res };
                    }
                ),
                total: response.queryResultCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        refreshQueries();
        onQueriesLoad();
    }, []);

    const handleTableChange = async (
        pagination: any,
        filter: any,
        sorters: any
    ) => {
        await refreshQueries({
            page: pagination.current,
            pageSize: pagination.pageSize,
            orderByColumn: sorters.columnKey,
            sortOrder: sorters.order === 'ascend' ? 'desc' : 'asc',
        });
    };

    const { columns } = useTableFilterColumns({
        columns: [
            {
                title: 'Digest text',
                key: 'digest_text',
                dataIndex: 'digestText',
                sorter: true,
            },
            {
                title: 'Hostgroup',
                key: 'hostgroup',
                dataIndex: 'hostgroup',
                sorter: true,
            },
            {
                title: 'Schema',
                key: 'schemaname',
                dataIndex: 'schemaname',
                sorter: true,
            },
            {
                title: 'Count star',
                key: 'count_star',
                dataIndex: 'countStar',
                width: 120,
                sorter: true,
            },
            {
                title: 'Sum time',
                key: 'sum_time',
                width: 100,
                sorter: true,
                render: (record: CcProxySqlTopQueries) => {
                    return (
                        <DurationFormat
                            short={true}
                            units={['y', 'mo', 'w', 'd', 'h', 'm', 's', 'ms']}
                        >
                            {Number(record.sumTime / 1000)}
                        </DurationFormat>
                    );
                },
            },
            {
                title: 'Min time',
                key: 'min_time',
                width: 120,
                sorter: true,
                render: (record: CcProxySqlTopQueries) => {
                    return (
                        <DurationFormat
                            short={true}
                            units={['y', 'mo', 'w', 'd', 'h', 'm', 's', 'ms']}
                        >
                            {Number(record.minTime / 1000)}
                        </DurationFormat>
                    );
                },
            },
            {
                title: 'Max time',
                key: 'max_time',
                width: 120,
                sorter: true,
                render: (record: CcProxySqlTopQueries) => {
                    return (
                        <DurationFormat
                            short={true}
                            units={['y', 'mo', 'w', 'd', 'h', 'm', 's', 'ms']}
                        >
                            {Number(record.maxTime / 1000)}
                        </DurationFormat>
                    );
                },
            },
            {
                title: 'First seen',
                key: 'first_seen',
                sorter: true,
                render: (record: CcProxySqlTopQueries) => {
                    return (
                        <AppDateFormat fromNow>
                            {new Date(record.firstSeen * 1000)}
                        </AppDateFormat>
                    );
                },
            },
            {
                title: 'Last seen',
                key: 'last_seen',
                sorter: true,
                render: (record: CcProxySqlTopQueries) => {
                    return (
                        <AppDateFormat fromNow>
                            {new Date(record.lastSeen * 1000)}
                        </AppDateFormat>
                    );
                },
            },
            {
                title: 'Actions',
                key: '',
                dataIndex: '',
                render: (record: CcProxySqlTopQueries) => (
                    <ActionsMenu
                        items={[
                            {
                                key: 'create-rule',
                                label: (
                                    <ButtonWithForm
                                        button={
                                            <Button type="link">
                                                Create rule
                                            </Button>
                                        }
                                        form={
                                            <ProxySQLRulesWizardModal
                                                title="Create new rule"
                                                onSuccess={onQueriesLoad}
                                                nextQueryRuleId={
                                                    queryRuleId?.nextRuleId
                                                }
                                                cluster={cluster}
                                                node={node}
                                                hostGroupList={hostGroupList}
                                                topQueryCreate
                                                topQueryData={{
                                                    digest: record?.digest,
                                                    digestText:
                                                        record?.digestText,
                                                    hostgroup: `${record?.hostgroup}`,
                                                    schemaName:
                                                        record?.schemaname,
                                                }}
                                            />
                                        }
                                    />
                                ),
                            },
                            {
                                key: 'cache-query',
                                label: (
                                    <ButtonWithForm
                                        button={
                                            <Button type="link">
                                                Cache query
                                            </Button>
                                        }
                                        form={
                                            <ProxySQLRulesWizardModal
                                                title="Cache query"
                                                onSuccess={onQueriesLoad}
                                                nextQueryRuleId={
                                                    queryRuleId?.nextCacheId
                                                }
                                                cluster={cluster}
                                                node={node}
                                                hostGroupList={hostGroupList}
                                                topQueryCache
                                                topQueryData={{
                                                    digest: record?.digest,
                                                    digestText:
                                                        record?.digestText,
                                                    hostgroup: `${record?.hostgroup}`,
                                                    schemaName:
                                                        record?.schemaname,
                                                }}
                                            />
                                        }
                                    />
                                ),
                            },
                            {
                                key: 'full-digest',
                                label: (
                                    <TopQueryFullDigestModal
                                        digestText={record.digestText}
                                    />
                                ),
                            },
                        ]}
                        {...rest}
                    />
                ),
            },
        ],
        filterParams,
    });

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLTopQueries">
            <AppTable
                className="ProxySQLTopQueriesTable"
                size="small"
                dataSource={queryResponse}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                renderEmpty={
                    <AppEmpty
                        loading={loading}
                        description="You don't have queries yet."
                    />
                }
            />
        </div>
    );
}
