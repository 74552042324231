import React from 'react';

import FormCheckbox, { FormCheckboxProps } from '../../DataEntry/FormCheckbox';

export default DisableSelinuxField;

export type DisableSelinuxFieldProps = FormCheckboxProps & {};

function DisableSelinuxField({ name, ...rest }: DisableSelinuxFieldProps) {
    return (
        <FormCheckbox
            name={name ? name : 'disableSeLinux'}
            label="Disable SELinux/AppArmor"
            {...rest}
        />
    );
}
