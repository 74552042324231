import React from 'react';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import useJobWatcher from './useJobWatcher';
import useCreateJob from './useCreateJob';
import { CcClusterType } from '../../services/models/CcCluster';
import JobButtonBarrier from '../../common/Barrier/JobButtonBarrier';
import AppConfirmActionButton, {
    AppConfirmActionButtonProps,
} from '../../common/General/AppConfirmActionButton';
import ButtonWithSwitch from '../../common/General/ButtonWithSwitch';
import AppActionButton from '../../common/General/AppActionButton';

export default JobCreateActionButton;

export type JobCreateActionButtonProps = AppConfirmActionButtonProps & {
    clusterId?: number;
    clusterType?: CcClusterType;
    command: CmonJobInstanceCommand;
    title: string;
    jobTitle?: string; // if no specified, title will be used
    confirmTitle?: React.ReactNode;
    confirm?: boolean;
    children?: React.ReactNode;
    onSuccess?: () => void;
    jobData?: any;
    extraKey?: (string | number | undefined)[];
    switchValue?: boolean;
    showLabel?: boolean;
    tooltip?: React.ReactNode;
};

function JobCreateActionButton({
    clusterId,
    clusterType,
    command,
    title,
    jobTitle,
    confirmTitle,
    confirm = true,
    children,
    onSuccess,
    jobData,
    extraKey,
    switchValue,
    okText,
    showLabel = true,
    disabled,
    tooltip,
    ...rest
}: JobCreateActionButtonProps) {
    const { loading: loadingJob } = useJobWatcher({
        command,
        clusterId,
        extraKey,
    });
    const { loading, send } = useCreateJob({
        clusterId,
        title: jobTitle || title,
        command: command,
        onSuccess,
    });
    const handleConfirm = async () => {
        await send(jobData);
    };
    return (
        <JobButtonBarrier command={command} clusterType={clusterType}>
            <ButtonWithSwitch
                switchValue={switchValue}
                disabled={disabled}
                button={
                    confirm ? (
                        <AppConfirmActionButton
                            className="JobCreateActionButton"
                            confirmTitle={confirmTitle || `${title}?`}
                            onConfirm={handleConfirm}
                            loading={loading || loadingJob}
                            okText={okText || children || title}
                            disabled={disabled}
                            tooltip={tooltip}
                            {...rest}
                        >
                            {showLabel ? children || title : null}
                        </AppConfirmActionButton>
                    ) : (
                        <AppActionButton
                            className="JobCreateActionButton"
                            onClick={handleConfirm}
                            loading={loading || loadingJob}
                            centerLoading={true}
                            disabled={disabled}
                            tooltip={tooltip}
                            {...rest}
                        >
                            {showLabel ? children || title : null}
                        </AppActionButton>
                    )
                }
            />
        </JobButtonBarrier>
    );
}
