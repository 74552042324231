import React, { useEffect } from 'react';
import ClusterDashboards from './Pages/ClusterDashboards';
import { AppState } from '../../appReducer';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import CcCluster from '../../services/models/CcCluster';
import useBreadcrumb from '../../common/useBreadcrumb';
import ClusterNodes from './Pages/ClusterNodes';
import ClusterBackups from './Pages/ClusterBackups';
import ClusterAlarms from './Pages/ClusterAlarms';
import ClusterLogs from './Pages/ClusterLogs';
import ClusterJobs from './Pages/ClusterJobs';
import ClusterFormat from './ClusterFormat';
import ClusterAuditLog from './Pages/ClusterAuditLog';
import ClusterSystemLogs from './Pages/ClusterSystemLogs';
import ReportsPage from '../Report/ReportsPage';
import ClusterPerformancePage from './Pages/ClusterPerformancePage';
import ClusterManagePage from './Pages/ClusterManagePage';
import ClusterSettingsPage from './Pages/ClusterSettingsPage';
import AppSubLayout from '../../common/Layout/AppSubLayout';
import ClusterPageMenu from './ClusterPageMenu';

export default ClusterPage;

export type ClusterPageProps = { page?: string };

function ClusterPage({ page = 'dashboard' }: ClusterPageProps) {
    const { clusterId } = useParams<{
        clusterId: string;
    }>();
    const [cluster]: [
        CcCluster | undefined
    ] = useSelector(({ clusters }: AppState) => [
        (clusterId && clusters.get(clusterId)) || undefined,
    ]);
    const { setVariable } = useBreadcrumb();
    const { page: currentPage = page }: any = useParams<{
        page: string;
    }>();

    useEffect(() => {
        if (cluster) {
            setVariable(
                'clusterId',
                `${cluster.clusterId || 0}`,
                (cluster && (
                    <ClusterFormat
                        cluster={cluster}
                        showStatus={false}
                        showPopover={true}
                        showType={false}
                        popoverPlacement="bottom"
                    />
                )) ||
                    ''
            );
        }
    }, [cluster]);

    if (!cluster) {
        return <Navigate to="/not-found" replace={true} />;
    }

    let pageToRender;
    switch (currentPage) {
        case 'dashboard':
            pageToRender = <ClusterDashboards cluster={cluster} />;
            break;
        case 'nodes':
            pageToRender = <ClusterNodes cluster={cluster} />;
            break;
        case 'performance':
            pageToRender = <ClusterPerformancePage cluster={cluster} />;
            break;
        case 'backups':
            pageToRender = (
                <ClusterBackups
                    cluster={cluster}
                    baseUrl={`/clusters/${cluster.clusterId}/backups`}
                />
            );
            break;
        case 'alarms':
            pageToRender = <ClusterAlarms cluster={cluster} />;
            break;
        case 'logs':
            pageToRender = <ClusterLogs cluster={cluster} />;
            break;
        case 'jobs':
            pageToRender = <ClusterJobs cluster={cluster} />;
            break;
        case 'audit':
            pageToRender = <ClusterAuditLog cluster={cluster} />;
            break;
        case 'settings':
            pageToRender = <ClusterSettingsPage cluster={cluster} />;
            break;
        case 'system-logs':
            pageToRender = <ClusterSystemLogs cluster={cluster} />;
            break;
        case 'reports':
            pageToRender = <ReportsPage cluster={cluster} />;
            break;
        case 'manage':
            pageToRender = <ClusterManagePage cluster={cluster} />;
            break;
        default:
            pageToRender = <Navigate to="/not-found" replace={true} />;
            break;
    }
    return (
        <div>
            <ClusterPageMenu activeKey={page} />
            <AppSubLayout>{pageToRender}</AppSubLayout>
        </div>
    );
}
