import React, { Fragment } from 'react';
import { Col, Form, Input, InputNumber, Row, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import VendorRepositoryField from '../../../../common/Form/Fields/VendorRepositoryField';
import { FormInstance } from 'antd/lib/form';
import PasswordInput from '../../../../common/DataEntry/PasswordInput';
import InputNumberWide from '../../../../common/DataEntry/InputNumberWide';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import { CcClusterType } from '../../../../services/models/CcCluster';

export default RedisShardedNodeConfiguration;

export type RedisShardedNodeConfigurationProps = {
    form: FormInstance;
    hasRepository?: boolean;
    hasTemplate?: boolean;
    importCluster?: boolean;
};

function RedisShardedNodeConfiguration({
    form,
    hasRepository = true,
    hasTemplate = true,
    importCluster = false,
}: RedisShardedNodeConfigurationProps) {
    const { details } = form.getFieldsValue(true);
    return (
        <div className="RedisDeploymentNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Node configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'redisShardedPort']}
                        label={
                            <Space>
                                Redis Port
                                <InfoIcon info="The redis service port." />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the redis nodes port.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={55353}
                            style={{ width: '100%' }}
                            placeholder="Enter redis port."
                            onChange={(value: number) =>
                                form.setFieldsValue({
                                    nodeConfig: {
                                        clusterBusPort: 10000 + value,
                                    },
                                })
                            }
                        ></InputNumber>
                    </Form.Item>
                </Col>

                {!importCluster ? (
                    <Fragment>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={['nodeConfig', 'clusterBusPort']}
                                label={
                                    <Space>
                                        Cluster bus port
                                        <InfoIcon info="Cluster bus is a node-to-node communication channel that uses a binary protocol. By default, the cluster bus port is set by adding 10000 to the data port (e.g., 16379)" />
                                    </Space>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please enter the cluster bus Port.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0}
                                    max={65353}
                                    disabled={details.version < 7}
                                    placeholder="Enter cluster bus Port"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={['nodeConfig', 'nodeTimeout']}
                                label={
                                    <Space>
                                        <span>Node timeout (ms)</span>
                                        <InfoIcon info="The maximum amount of time a Redis Cluster node can be unavailable, without it being considered as failing. If a master node is not reachable for more than the specified amount of time, it will be failed over by its replicas. This parameter controls other important things in Redis Cluster. Notably, every node that can't reach the majority of master nodes for the specified amount of time, will stop accepting queries." />
                                    </Space>
                                }
                                rules={[
                                    {
                                        pattern: new RegExp(/^[1-9]\d*$/),
                                        message:
                                            'Please enter a number greater than zero.',
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter node timeout.',
                                    },
                                ]}
                            >
                                <InputNumberWide
                                    min={0}
                                    placeholder="Enter node timeout (ms)"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item shouldUpdate={true} noStyle={true}>
                                {() => {
                                    const timeout = Number(
                                        form.getFieldValue([
                                            'nodeConfig',
                                            'nodeTimeout',
                                        ])
                                    );
                                    const factor = Number(
                                        form.getFieldValue([
                                            'nodeConfig',
                                            'replicaValidityFactor',
                                        ])
                                    );
                                    const seconds =
                                        !Number.isNaN(timeout) &&
                                        !Number.isNaN(factor)
                                            ? (timeout * factor) / 1000
                                            : null;
                                    return (
                                        <Form.Item
                                            name={[
                                                'nodeConfig',
                                                'replicaValidityFactor',
                                            ]}
                                            label={
                                                <Space>
                                                    <span>
                                                        Replica validity factor
                                                    </span>
                                                    <InfoIcon info="Set factor to 0 to always consider a replica valid to failover. If the value is positive, a maximum disconnection time is calculated as the node timeout value multiplied by the factor provided with this option, and if the node is a replica, it will not try to start a failover if the master link was disconnected for more than the specified amount of time." />
                                                </Space>
                                            }
                                            rules={[
                                                {
                                                    pattern: new RegExp(
                                                        /^\d+$/
                                                    ),
                                                    message:
                                                        'Please enter a positive value.',
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter replica validity factor.',
                                                },
                                            ]}
                                            extra={
                                                seconds !== null ? (
                                                    seconds === 0 ? (
                                                        <small>
                                                            A replica will be
                                                            always valid to
                                                            failover its
                                                            primary.
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            A replica
                                                            disconnected from
                                                            the primary for more
                                                            than{' '}
                                                            <strong>
                                                                {(form.getFieldValue(
                                                                    [
                                                                        'nodeConfig',
                                                                        'nodeTimeout',
                                                                    ]
                                                                ) *
                                                                    form.getFieldValue(
                                                                        [
                                                                            'nodeConfig',
                                                                            'replicaValidityFactor',
                                                                        ]
                                                                    )) /
                                                                    1000}{' '}
                                                                seconds
                                                            </strong>{' '}
                                                            will not try to
                                                            failover its
                                                            primary.
                                                        </small>
                                                    )
                                                ) : null
                                            }
                                        >
                                            <InputNumberWide placeholder="Enter replica validity factor" />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Fragment>
                ) : null}

                {hasTemplate ? (
                    <Col xs={24} sm={24} md={12}>
                        <NodeConfigurationTemplateField
                            name={['nodeConfig', 'configurationTemplate']}
                            vendor={details.vendor}
                            version={details.version}
                            clusterType={CcClusterType.TYPE_REDIS_SHARDED}
                        />
                    </Col>
                ) : null}

                {hasRepository ? (
                    <Col xs={24} sm={24} md={12}>
                        <VendorRepositoryField
                            name={['nodeConfig', 'repository']}
                            form={form}
                        />
                    </Col>
                ) : null}

                <Col span={24}>
                    <h3>Authentication</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'userName']}
                        label="Username"
                        rules={[
                            ...(!!importCluster
                                ? []
                                : [
                                      {
                                          required: true,
                                          message: 'Please enter a username.',
                                      },
                                  ]),
                        ]}
                    >
                        <Input placeholder="Enter username" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name={['nodeConfig', 'password']}
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a password.',
                            },
                        ]}
                    >
                        <PasswordInput placeholder="Enter password" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
