import React, { useCallback, useEffect } from 'react';
import useClusterList from './components/Clusters/useClusterList';
import useRunningJobList from './components/Jobs/useRunningJobList';
import useCurrentAlarmsList from './components/Alarms/useCurrentAlarmsList';
import useScheduledMaintenancesList from './components/Maintenance/useScheduledMaintenancesList';
import useTree from './components/UserManagement/useTree';
import useJobWatcher from './components/Jobs/useJobWatcher';
import {
    getClusterRelatedJobs,
    getNodeRelatedJobs,
} from './services/models/CcJob';

export default useBackgroundPolling;

export type UseBackgroundPollingProps = {};

function useBackgroundPolling() {
    const { refresh: refreshClusters } = useClusterList({
        name: 'cluster-list-from-background-polling',
    });
    const {
        refresh: refreshRunningJobs,
        stopAutoRefresh: stopAutoRefreshRunningJobs,
    } = useRunningJobList({
        name: 'running-jobs-list-from-background-polling',
    });
    const {
        refresh: refreshCurrentAlarms,
        stopAutoRefresh: stopAutoRefreshCurrentAlarms,
    } = useCurrentAlarmsList({
        name: 'current-alarms-list-from-background-polling',
    });
    const {
        refresh: refreshScheduledMaintenances,
        stopAutoRefresh: stopAutoRefreshScheduledMaintenances,
    } = useScheduledMaintenancesList({
        name: 'scheduled-maintenances-list-from-background-polling',
    });

    const {
        refresh: refreshTree,
        stopAutoRefresh: stopAutoRefreshTree,
    } = useTree({
        name: 'tree-from-background-polling',
    });

    const { stoppedJobs } = useJobWatcher({
        command: [...getClusterRelatedJobs(), ...getNodeRelatedJobs()],
    });

    const start = useCallback(async () => {
        await Promise.all([
            refreshRunningJobs({
                autoRefresh: 10000,
            }),
            refreshCurrentAlarms({
                autoRefresh: 5000,
            }),
            refreshScheduledMaintenances({
                autoRefresh: 60000,
            }),
            refreshTree({
                autoRefresh: 30000,
            }),
        ]);
    }, [
        refreshRunningJobs,
        refreshCurrentAlarms,
        refreshTree,
        refreshScheduledMaintenances,
    ]);

    const stop = useCallback(async () => {
        stopAutoRefreshCurrentAlarms();
        stopAutoRefreshRunningJobs();
        stopAutoRefreshScheduledMaintenances();
        stopAutoRefreshTree();
    }, []);

    useEffect(() => {
        if (
            stoppedJobs?.find((job) =>
                [...getClusterRelatedJobs(), ...getNodeRelatedJobs()].includes(
                    job.getCommand()
                )
            )
        ) {
            (async () => {
                await refreshClusters();
            })();
        }
    }, [stoppedJobs]);

    return {
        start,
        stop,
    };
}
