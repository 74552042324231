import React from 'react';
import CcCluster, {
    CcClusterTechnology,
    CcClusterType,
} from '../../../services/models/CcCluster';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import ClusterToggleAutoRecoveryButton from './ClusterToggleAutoRecoveryButton';
import ClusterToggleNodeAutoRecoveryButton from './ClusterToggleNodeAutoRecoveryButton';
import ClusterToggleReadonlyButton from './ClusterToggleReadonlyButton';
import ClusterRollingRestartButton from './ClusterRollingRestartButton';
import ClusterDeleteButton from './ClusterDeleteButton';
import AddMaintenanceButton from '../../Maintenance/AddMaintenanceButton';
import AddRpcButton from './RpcToken/AddRpcButton';
import DisableMaintenanceButton from '../../Maintenance/DisableMaintenanceButton';
import useMaintenanceWatcher from '../../Maintenance/useMaintenanceWatcher';
import ManageMaintenanceButton from '../../Maintenance/ManageMaintenanceButton';
import ClusterAddReplicationNode from './ClusterAddReplicationNode';
import ClusterAddNode from './ClusterAddNode';
import CcPostgreSqlNode from '../../../services/models/CcPostgreSqlNode';
import ClusterEnableTimescaledbButton from './ClusterEnableTimescaledbButton';
import ClusterBootstrapButton from './ClusterBootstrapButton';
import ClusterCloneButton from './ClusterCloneButton';
import ClusterFindMostAdvancedNodeButton from './ClusterFindMostAdvancedNodeButton';
import ClusterCreateReplicationButton from './ClusterCreateReplicationButton';
import LoadBalancerWizardButton from '../../LoadBalancer/LoadBalancerWizardButton';
import ClusterStopButton from './ClusterStopButton';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import ClusterAddShardButton from './ClusterAddShardButton';
import ClusterConvertToShardButton from './ClusterConvertToShardButton';
import ClusterRemoveShardButton from './ClusterRemoveShard/ClusterRemoveShardButton';
import ClusterDisableSSLButton from '../../Security/ClusterDisableSSLButton';
import ClusterEnableSSLButton from '../../Security/ClusterEnableSSLButton';
import GaleraDisableSSLButton from './GaleraDisableSSLButton';
import DisableAuditLogButton from '../../Security/DisableAuditLogButton';
import EnableAuditLogButton from '../../Security/EnableAuditLogButton';
import AppDivider from '../../../common/AppDivider';
import ClusterEditDetailsButton from './ClusterEditDetails/ClusterEditDetailsButton';
import {
    ClusterOutlined,
    DeleteOutlined,
    FieldTimeOutlined,
    KeyOutlined,
    LockOutlined,
    PartitionOutlined,
    PlusCircleOutlined,
    RobotOutlined,
    SearchOutlined,
    SisternodeOutlined,
    StopOutlined,
    ToolOutlined,
    ToTopOutlined,
} from '@ant-design/icons';
import { ReloadIcon, SettingsIcon } from '../../../common/icons/icons';
import {
    addDividerToMenuGroup,
    groupSimilarMenuItemsInSubmenu,
} from '../../../common/Navigation/actionMenuHelpers';
import ClusterChangeCertificateSSLButton from '../../Security/ClusterChangeCertificateSSLButton';
import ClusterNodesUpgradeWizardButton from '../Upgrade/ClusterNodesUpgradeWizardButton';
import { CcNodeRole } from '../../../services/models/CcNode';

export default ClusterActionsMenu;

export type ClusterActionsMenuProps = ActionsMenuProps & {
    cluster: CcCluster;
    onActionPerformed?: () => void;
};

function ClusterActionsMenu({
    cluster,
    onActionPerformed,
    ...rest
}: ClusterActionsMenuProps) {
    const {
        active: activeMaintenance,
        next: nextMaintenance,
    } = useMaintenanceWatcher({
        clusterId: cluster.clusterId,
    });

    const handleActionSuccess = () => {
        onActionPerformed?.();
    };

    const enableAuditLog =
        cluster.isAuditLogSupported() && !cluster.isAuditLogEnabled()
            ? {
                  key: 'enable-audit-log',
                  noPadding: true,
                  label: <EnableAuditLogButton cluster={cluster} />,
              }
            : undefined;

    const disableAuditLog =
        cluster.isAuditLogSupported() && cluster.isAuditLogEnabled()
            ? {
                  key: 'disable-audit-log',
                  noPadding: true,
                  label: <DisableAuditLogButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const regularEnableSSL =
        cluster.isRegularSSLSupported() && !cluster.isRegularSSLEnabled()
            ? {
                  key: 'cluster-enable-ssl',
                  label: <ClusterEnableSSLButton cluster={cluster} />,
              }
            : undefined;
    const regularChangeSSL =
        cluster.isRegularSSLSupported() && cluster.isRegularSSLEnabled()
            ? {
                  key: 'cluster-change-ssl',
                  label: (
                      <ClusterChangeCertificateSSLButton cluster={cluster} />
                  ),
              }
            : undefined;

    const regularDisableSSL =
        cluster.isRegularSSLSupported() &&
        cluster.isDisablingRegularSSLSupported() &&
        cluster.isRegularSSLEnabled()
            ? {
                  key: 'cluster-disable-ssl',
                  label: <ClusterDisableSSLButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const galeraEnableSSL =
        cluster.isReplicationSSLSupported() &&
        !cluster.isReplicationSSLEnabled()
            ? {
                  key: 'galera-cluster-enable-ssl',
                  label: (
                      <ClusterEnableSSLButton
                          cluster={cluster}
                          isGalera={true}
                      />
                  ),
              }
            : undefined;
    const galeraChangeSSL =
        cluster.isReplicationSSLSupported() && cluster.isReplicationSSLEnabled()
            ? {
                  key: 'galera-cluster-change-ssl',
                  label: (
                      <ClusterChangeCertificateSSLButton
                          cluster={cluster}
                          isGalera={true}
                      />
                  ),
              }
            : undefined;

    const galeraDisableSSL =
        cluster.isReplicationSSLSupported() && cluster.isReplicationSSLEnabled()
            ? {
                  key: 'galera-cluster-disable-ssl',
                  label: <GaleraDisableSSLButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const addLoadBalancer = [
        CcClusterType.TYPE_REPLICATION,
        CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_POSTGRESQL,
        CcClusterType.TYPE_TIMESCALEDB,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-load-balancer',
              label: <LoadBalancerWizardButton cluster={cluster} />,
              submenuLabel: (
                  <LoadBalancerWizardButton cluster={cluster}>
                      Load balancer
                  </LoadBalancerWizardButton>
              ),
          }
        : undefined;

    const clusterChangeRPCAPItoken = {
        key: 'cluster-change-rpc-token',
        icon: <KeyOutlined />,
        label: <AddRpcButton cluster={cluster} />,
    };

    const clusterEnableMaintenanceModeButton = {
        key: 'cluster-enable-maintenance-mode',
        label: <AddMaintenanceButton cluster={cluster} />,
        submenuLabel: (
            <AddMaintenanceButton cluster={cluster}>
                Schedule
            </AddMaintenanceButton>
        ),
    };

    const clusterManageMaintenanceModeButton =
        nextMaintenance || activeMaintenance
            ? {
                  key: 'cluster-manage-maintenance-mode',
                  label: (
                      <ManageMaintenanceButton
                          cluster={cluster}
                          onSuccess={handleActionSuccess}
                      />
                  ),
                  submenuLabel: (
                      <ManageMaintenanceButton
                          cluster={cluster}
                          onSuccess={handleActionSuccess}
                      >
                          Manage
                      </ManageMaintenanceButton>
                  ),
              }
            : undefined;

    const clusterDisableMaintenanceModeButton = cluster.isMaintenanceModeEnabled()
        ? {
              key: 'cluster-disable-maintenance-mode',
              label: (
                  <DisableMaintenanceButton
                      cluster={cluster}
                      onSuccess={handleActionSuccess}
                  />
              ),
              submenuLabel: (
                  <DisableMaintenanceButton
                      cluster={cluster}
                      onSuccess={handleActionSuccess}
                  >
                      Stop
                  </DisableMaintenanceButton>
              ),
              waitForConfirm: true,
          }
        : undefined;

    const clusterAddReplicationSecondary = [
        CcClusterType.TYPE_REPLICATION,
        CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_POSTGRESQL,
        CcClusterType.TYPE_TIMESCALEDB,
        CcClusterType.TYPE_REDIS_SHARDED,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-add-replication-secondary',
              label: (
                  <ClusterAddReplicationNode
                      cluster={cluster}
                      nodeRole={CcNodeRole.REPLICA}
                  >
                      Add a replica node
                  </ClusterAddReplicationNode>
              ),
              submenuLabel: (
                  <ClusterAddReplicationNode
                      cluster={cluster}
                      nodeRole={CcNodeRole.REPLICA}
                  >
                      Replica node
                  </ClusterAddReplicationNode>
              ),
          }
        : undefined;
    const clusterAddReplicationPrimary = [
        CcClusterType.TYPE_REDIS_SHARDED,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-add-replication-primary',
              label: (
                  <ClusterAddReplicationNode
                      cluster={cluster}
                      nodeRole={CcNodeRole.PRIMARY}
                  >
                      Add a primary node
                  </ClusterAddReplicationNode>
              ),
              submenuLabel: (
                  <ClusterAddReplicationNode
                      cluster={cluster}
                      nodeRole={CcNodeRole.PRIMARY}
                  >
                      Primary node
                  </ClusterAddReplicationNode>
              ),
          }
        : undefined;

    const clusterAddNode = [
        // CcClusterType.TYPE_GALERA,
        CcClusterType.TYPE_MONGODB,
        CcClusterType.TYPE_MONGODB_SHARDS,
        CcClusterType.TYPE_ELASTIC,
        CcClusterType.TYPE_REDIS,
        // CcClusterType.TYPE_REDIS_SHARDED,
        CcClusterType.TYPE_MSSQL_AO_ASYNC,
    ].includes(cluster.clusterType)
        ? {
              key: 'cluster-add-node',
              label: <ClusterAddNode cluster={cluster} />,
              submenuLabel: (
                  <ClusterAddNode cluster={cluster}>Node</ClusterAddNode>
              ),
          }
        : undefined;

    const clusterAddGaleraNode = [CcClusterType.TYPE_GALERA].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-add-galera-node',
              label: (
                  <ClusterAddNode
                      cluster={cluster}
                      wizardTitle={'a primary node'}
                  >
                      Add a primary node
                  </ClusterAddNode>
              ),
              submenuLabel: (
                  <ClusterAddNode
                      cluster={cluster}
                      wizardTitle={'a primary node'}
                  >
                      Primary node
                  </ClusterAddNode>
              ),
          }
        : undefined;

    const clusterAddShard = [CcClusterType.TYPE_MONGODB_SHARDS].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-add-shard',
              label: <ClusterAddShardButton cluster={cluster} />,
              submenuLabel: (
                  <ClusterAddShardButton cluster={cluster}>
                      Shards
                  </ClusterAddShardButton>
              ),
          }
        : undefined;

    const clusterConvertToShard = [CcClusterType.TYPE_MONGODB].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-convert-to-shard',
              icon: <PartitionOutlined />,
              label: <ClusterConvertToShardButton cluster={cluster} />,
          }
        : undefined;

    const clusterRemoveShard = [CcClusterType.TYPE_MONGODB_SHARDS].includes(
        cluster.clusterType
    )
        ? {
              key: 'cluster-remove-shard',
              icon: <DeleteOutlined />,
              label: <ClusterRemoveShardButton cluster={cluster} />,
          }
        : undefined;
    const fineMostAdvancedNode = cluster.isType(CcClusterType.TYPE_GALERA)
        ? {
              key: 'find-most-advanced-node',
              waitForConfirm: true,
              icon: <SearchOutlined />,
              label: <ClusterFindMostAdvancedNodeButton cluster={cluster} />,
          }
        : undefined;

    const upgradeNodes = !cluster.isType(CcClusterType.TYPE_REDIS_SHARDED)
        ? {
              key: 'upgrade-nodes',
              icon: <ToTopOutlined />,
              label: <ClusterNodesUpgradeWizardButton cluster={cluster} />,
          }
        : undefined;

    const clusterToggleReadonly = cluster.isTechnology(
        CcClusterTechnology.TECHNOLOGY_MYSQL
    )
        ? {
              key: 'readonly',
              noPadding: true,
              waitForConfirm: true,
              label: <ClusterToggleReadonlyButton cluster={cluster} />,
          }
        : undefined;

    const clusterEnableTimescaledb =
        [CcClusterType.TYPE_POSTGRESQL].includes(cluster.clusterType) &&
        !cluster
            .getDatabaseNodes()
            .find((n) => (n as CcPostgreSqlNode).isTimescaledb())
            ? {
                  key: 'cluster-enable-timescaledb',
                  icon: <FieldTimeOutlined />,
                  label: <ClusterEnableTimescaledbButton cluster={cluster} />,
                  waitForConfirm: true,
              }
            : undefined;

    const editDetails = {
        key: 'edit',
        icon: <SettingsIcon />,
        label: (
            <ClusterEditDetailsButton
                cluster={cluster}
                onSuccess={handleActionSuccess}
            />
        ),
    };

    const clusterClone = cluster.isType(CcClusterType.TYPE_GALERA)
        ? {
              key: 'clone-cluster',
              label: <ClusterCloneButton cluster={cluster} />,
          }
        : undefined;

    const clusterReplication =
        cluster.isType(CcClusterType.TYPE_GALERA) ||
        cluster.isType(CcClusterType.TYPE_POSTGRESQL) ||
        cluster.isType(CcClusterType.TYPE_TIMESCALEDB)
            ? {
                  key: 'create-replica-cluster',
                  label: <ClusterCreateReplicationButton cluster={cluster} />,
              }
            : undefined;

    const rollingRestart = cluster.isType(CcClusterType.TYPE_GALERA)
        ? {
              key: 'rolling-restart',
              icon: <ReloadIcon />,
              critical: true,
              label: <ClusterRollingRestartButton cluster={cluster} />,
          }
        : undefined;

    const bootstrapCluster = cluster.isType(CcClusterType.TYPE_GALERA)
        ? {
              key: 'bootstrap-cluster',
              icon: <ClusterOutlined />,
              critical: true,
              label: <ClusterBootstrapButton cluster={cluster} />,
          }
        : undefined;

    const stopCluster = cluster.isType(CcClusterType.TYPE_GALERA)
        ? {
              key: 'stop-cluster',
              icon: <StopOutlined />,
              critical: true,
              label: <ClusterStopButton cluster={cluster} />,
          }
        : undefined;
    const deleteCluster = {
        key: 'delete',
        icon: <DeleteOutlined />,
        critical: true,
        label: <ClusterDeleteButton cluster={cluster} />,
    };

    const addGroup = [
        clusterAddNode,
        clusterAddGaleraNode,
        clusterAddShard,
        clusterAddReplicationPrimary,
        clusterAddReplicationSecondary,
        addLoadBalancer,
    ].filter(Boolean);

    const recoveryGroup = [
        {
            key: 'toggle-autorecovery',
            label: <ClusterToggleAutoRecoveryButton cluster={cluster} />,
        },
        {
            key: 'toggle-node-autorecovery',
            label: <ClusterToggleNodeAutoRecoveryButton cluster={cluster} />,
        },
    ];
    const encryptionGroup = [
        regularEnableSSL,
        regularChangeSSL,
        regularDisableSSL,
        galeraEnableSSL,
        galeraChangeSSL,
        galeraDisableSSL,
    ].filter(Boolean);
    const maintenanceGroup = [
        clusterEnableMaintenanceModeButton,
        clusterManageMaintenanceModeButton,
        clusterDisableMaintenanceModeButton,
    ].filter(Boolean);

    const cloneReplicationGroup = [clusterClone, clusterReplication].filter(
        Boolean
    );

    const add = groupSimilarMenuItemsInSubmenu(
        {
            key: 'cluster-add-item',
            label: 'Add new',
            waitForConfirm: true,
            icon: <PlusCircleOutlined />,
        },
        addGroup
    );

    const recovery = {
        key: 'recovery',
        label: 'Cluster/node recovery',
        children: recoveryGroup,
        icon: <RobotOutlined />,
    };

    const encryption = groupSimilarMenuItemsInSubmenu(
        {
            key: 'encryption',
            label: 'Encryption settings',
            waitForConfirm: true,
            icon: <LockOutlined />,
        },
        encryptionGroup
    );

    const maintenance = groupSimilarMenuItemsInSubmenu(
        {
            key: 'maintenance',
            label: 'Maintenance',
            icon: <ToolOutlined />,
        },
        maintenanceGroup
    );

    const clone = groupSimilarMenuItemsInSubmenu(
        {
            key: 'clone',
            label: 'Clone/Replicate the cluster',
            icon: <SisternodeOutlined />,
        },
        cloneReplicationGroup
    );

    const toggleItems: any = addDividerToMenuGroup([
        enableAuditLog,
        disableAuditLog,
        clusterToggleReadonly,
    ]);

    const items: ActionMenuItem[] = [
        add,
        { type: 'divider', label: <AppDivider noMargin={true} /> },
        maintenance,
        clusterConvertToShard,
        clusterRemoveShard,
        encryption,
        recovery,
        clusterChangeRPCAPItoken,
        { type: 'divider', label: <AppDivider noMargin={true} /> },
        ...toggleItems,
        clone,
        fineMostAdvancedNode,
        upgradeNodes,
        clusterEnableTimescaledb,
        editDetails,
        { type: 'divider', label: <AppDivider noMargin={true} /> },
        rollingRestart,
        bootstrapCluster,
        stopCluster,
        deleteCluster,
    ] as ActionMenuItem[];

    return <ActionsMenu items={items} {...rest} />;
}
