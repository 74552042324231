import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import ClusterFormat from '../Clusters/ClusterFormat';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import BackupScheduleStatusFormat from './BackupScheduleStatusFormat';
import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Space } from 'antd';
import BackupScheduleActionsMenu from './BackupScheduleActionsMenu';
import useSchedulesList from './useSchedulesList';
import { TablePaginationConfig } from 'antd/lib/table';
import {
    CcClusterTechnology,
    CcClusterType,
} from '../../services/models/CcCluster';
import { getRedisBackupMethod } from './BackupMethodSelect';
import AppEmpty from '../../common/Feedback/AppEmpty';
import AppDateFormat from '../../common/AppDateFormat';
import { CcBackupMethod } from '../../services/models/CcBackup';
import SnapshotRepositoryType from '../SnapshotRepository/SnapshotRepositoryType';
import { CcSnapshotRepositoryType } from '../../services/models/CcSnapshotRepository';
import AppTooltip from '../../common/Feedback/AppTooltip';
import AppTable from '../../common/DataDisplay/AppTable';
import UserAclManageCluster from '../User/UserAclManageCluster';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';

export default forwardRef(BackupSchedulesList);

export interface BackupSchedulesListApi {
    refresh: () => Promise<void>;
}

export type BackupSchedulesListProps = {
    clusterId?: number;
    excludeColumns?: string[];
};

function BackupSchedulesList(
    { clusterId, excludeColumns = [] }: BackupSchedulesListProps,
    ref: any
) {
    const { filterParams, handleTableChange, resetFilters } = useTableFilter({
        params: {
            cluster: ArrayParam,
        },
    });

    const { responsive } = useContext(ResponsiveContext);
    useImperativeHandle(
        ref,
        (): BackupSchedulesListApi => ({
            async refresh() {
                resetFilters();
                await refreshSchedules();
            },
        })
    );
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const {
        list: schedules,
        repositoryCollection,
        loading: loadingSchedules,
        refresh: refreshSchedules,
        page,
        pageSize,
        total: totalSchedules,
    } = useSchedulesList({});

    const refresh = async () => {
        let refreshParams: any = {
            cluster_ids: undefined,
            page: filterParams.page,
            pageSize,
            autoRefresh: 20000,
        };
        if (clusterId) {
            refreshParams = {
                ...refreshParams,
                clusterId,
            };
        } else if (filterParams.cluster) {
            refreshParams = {
                ...refreshParams,
                cluster_ids: filterParams.cluster,
            };
        }
        await refreshSchedules(refreshParams);
    };

    const pagination: TablePaginationConfig = {
        pageSize: pageSize,
        current: page,
        total: totalSchedules || undefined,
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [clusterId, filterParams]);

    const rowKey = useCallback((record) => {
        return record.getKey();
    }, []);

    const handleOnRow = useCallback(
        (record, index: number) => ({
            'data-testid': `schedules-list-row-${index}`,
        }),
        []
    );

    const handleActionPerformed = async () => {
        await refreshSchedules();
    };

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Name',
                    key: 'name',
                    render: (record: CcBackupSchedule) => record.title,
                },

                {
                    title: 'Cluster',
                    key: 'cluster',
                    render: (record: CcBackupSchedule) => (
                        <ClusterFormat
                            clusterLink={true}
                            linkDestination={'backups/schedules'}
                            cluster={clustersMap.get(record.getClusterKey())}
                            showPopover={true}
                        />
                    ),
                    filterType: TableFilterType.CLUSTER,
                },

                {
                    title: 'Method',
                    key: 'method',
                    render: (record: CcBackupSchedule) =>
                        clustersMap
                            .get(record.getClusterKey())
                            ?.isTechnology(CcClusterTechnology.TECHNOLOGY_REDIS)
                            ? getRedisBackupMethod()
                            : clustersMap.get(record.getClusterKey())
                                  ?.clusterType === CcClusterType.TYPE_ELASTIC
                            ? CcBackupMethod.ELASTIC_SNAPSHOT
                            : record.getBackupMethod(),
                },
                {
                    title: 'Status',
                    key: 'status',
                    render: (record: CcBackupSchedule) => (
                        <BackupScheduleStatusFormat
                            schedule={record}
                            type={StatusFormatType.dot}
                        />
                    ),
                },
                {
                    title: 'Schedule',
                    key: 'schedule',
                    render: (record: CcBackupSchedule) => (
                        <span>
                            <CronFormat>{record.getCrontabFormat()}</CronFormat>{' '}
                            ({record.getTz()})
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Backup Host
                        </span>
                    ),
                    key: 'backup-host',
                    render: (record: CcBackupSchedule) =>
                        record.spec?.job_data?.hostname || 'N/A',
                },
                {
                    title: (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Storage Host
                        </span>
                    ),
                    key: 'storage-host',
                    render: (record: CcBackupSchedule) => {
                        const repository = repositoryCollection?.getBySchedule(
                            record
                        );
                        if (repository) {
                            // @todo figure out why tooltip isn't showing
                            return repository?.type ===
                                CcSnapshotRepositoryType.S3 ? (
                                <AppTooltip
                                    title={
                                        <span>
                                            S3 compatible cloud snapshot
                                            repository
                                        </span>
                                    }
                                >
                                    <SnapshotRepositoryType
                                        type={CcSnapshotRepositoryType.S3}
                                    >
                                        {repository.name}
                                    </SnapshotRepositoryType>
                                </AppTooltip>
                            ) : (
                                repository.storageHost
                            );
                        }
                        return record.spec?.job_data?.storageHost
                            ? record.spec.job_data.storageHost
                            : record.spec?.job_data?.cc_storage
                            ? clustersMap
                                  .get(record.getClusterKey())
                                  ?.getControllerNode()?.hostname
                            : record.spec?.job_data?.hostname;
                    },
                },
                {
                    title: 'Storage Location',
                    key: 'storage-location',
                    render: (record: CcBackupSchedule) =>
                        repositoryCollection
                            ?.getBySchedule(record)
                            ?.getLocation() || record.getLocationDirectory(),
                },
                {
                    title: (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Last Execution
                        </span>
                    ),
                    key: 'lastExec',
                    render: (record: CcBackupSchedule) => (
                        <>
                            <AppDateFormat fromNow>
                                {record.lastExecuted
                                    ? new Date(record.lastExecuted)
                                    : undefined}
                            </AppDateFormat>
                        </>
                    ),
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcBackupSchedule) => {
                        return (
                            <UserAclManageCluster
                                cluster={clustersMap.get(
                                    record.getClusterKey()
                                )}
                            >
                                <BackupScheduleActionsMenu
                                    schedule={record}
                                    onActionPerformed={handleActionPerformed}
                                />
                            </UserAclManageCluster>
                        );
                    },
                    onCell: () => ({ style: { padding: '2px 10px' } }),
                },
            ],
            [clustersMap, repositoryCollection]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const filteredColumns = useMemo(
        () =>
            excludeColumns?.length
                ? columns.filter(
                      ({ key }) => !excludeColumns.includes(key as string)
                  )
                : columns,
        [columns, excludeColumns]
    );

    return (
        <AppTable
            loading={loadingSchedules}
            rowKey={rowKey}
            dataSource={schedules}
            renderEmpty={
                filterParams.cluster ? (
                    false
                ) : (
                    <AppEmpty
                        loading={loadingSchedules}
                        description="You haven’t created backups yet. When you do, it'll show up here."
                    />
                )
            }
            columns={filteredColumns}
            pagination={pagination}
            size="middle"
            onChange={handleTableChange}
            responsive={responsive}
            footer={() => <Space size="large" />}
            onRow={handleOnRow}
        />
    );
}
