import React from 'react';
import { To } from 'react-router-dom';
import AppLink, { AppLinkProps } from '../../common/AppLink';

export default ActivityCenterJobsLink;

export type ActivityCenterJobsLinkProps = Omit<AppLinkProps, 'to'> & {
    to?: To;
};

function ActivityCenterJobsLink({
    to = '/activity-center/jobs',
    children,
    ...rest
}: ActivityCenterJobsLinkProps) {
    return (
        <AppLink to={to} {...rest}>
            {children || 'Activity center'}
        </AppLink>
    );
}
