const result = {
    controller_id: 'b01765ae-2db1-4fb2-bc29-06b62b64d447',
    request_processed: '2024-04-18T18:35:42.969Z',
    request_status: 'Ok',
    request_user_id: 5,
    total: 96,
    backup_records: [
        {
            version: 2,
            cloud_locations: [
                {
                    bucket_and_path: 'alex-s9s/BACKUP-13833',
                    cloud_location_uuid: '94918549-d69d-4b31-b597-7d33d30393f5',
                    created_time: '2024-04-18T18:03:38.000Z',
                    credentials_id: 4,
                    finished_time: '2024-04-18T18:04:21.000Z',
                    provider: 'aws',
                    retention: 2,
                    storage_service: 's3',
                },
            ],
            host_locations: [
                {
                    created_time: '2024-04-18T18:00:16.000Z',
                    finished_time: '2024-04-18T18:03:04.177Z',
                    host_location_uuid: '56cae783-76ca-458f-b101-d36d27af95f2',
                    retention: 3,
                    root_dir: '/root/backups/BACKUP-13833',
                    storage_host: 'c4',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c4',
                backup_name: '',
                backup_tool_version: '10.4.18',
                chain_up: 0,
                children: 0,
                cid: 1,
                cloud_only: false,
                compressed: true,
                created: '2024-04-18T18:00:16.000Z',
                created_by: '',
                db_vendor: 'mariadb',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2024-04-18T18:03:04.177Z',
                id: 13833,
                job_id: 43372,
                log_file: '',
                lsn: 800875369,
                method: 'mariabackupfull',
                mysqldump_type: '',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-13833',
                schedule_id: 0,
                server_version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
                snapshot_repository: '',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c4',
                timezone: 'UTC',
                title: 'BACKUP-13833',
                total_datadir_size: 11769625893,
                use_for_pitr: true,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        ag: '',
                        database_names: [],
                        db: '',
                        start_time: '2024-04-18T18:03:04.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2024-04-18T18:02:57.000Z',
                                hash: 'md5:45723856945f474019a03c748fe6bbe5',
                                path: 'backup-full-2024-04-18_180110.xbstream.gz',
                                size: 111753225,
                                type: 'full',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c4',
                    backupMethod: 'mariabackupfull',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 3,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    cloud_bucket: 'alex-s9s',
                    cloud_only: false,
                    cloud_provider: 'aws',
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: 1,
                    compressionTool: 'gzip -6',
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    includeDatabases: null,
                    includeTables: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: true,
                    port: 3306,
                    set_gtid_purged_off: true,
                    snapshot_repository: '',
                    stanzaName: '',
                    storageHost: 'c4',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    useQpress: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: true,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2024-04-18T15:00:27.000Z',
                    finished_time: '2024-04-18T15:07:27.449Z',
                    host_location_uuid: '23dd92cb-99e4-44b1-acab-a16ee87058b2',
                    retention: 5,
                    root_dir: '/home/ubuntu/backups/BACKUP-13830',
                    storage_host: '143.198.228.20',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c9',
                backup_name: '',
                backup_tool_version: '',
                chain_up: 0,
                children: 0,
                cid: 3,
                cloud_only: false,
                compressed: true,
                created: '2024-04-18T15:00:27.000Z',
                created_by: '',
                db_vendor: '',
                description: '',
                encrypted: true,
                encryption_md5: '5293aea9d57586386bde62184261fc43',
                finished: '2024-04-18T15:07:27.449Z',
                id: 13830,
                job_id: 43364,
                log_file: '',
                lsn: 0,
                method: 'pg_basebackup',
                mysqldump_type: '',
                parent_id: 0,
                root_dir: '/home/ubuntu/backups/BACKUP-13830',
                schedule_id: 0,
                server_version: '',
                snapshot_repository: '',
                stanza_name: '',
                status: 'Completed',
                storage_host: '143.198.228.20',
                timezone: '',
                title: 'BACKUP-13830',
                total_datadir_size: 647055077,
                use_for_pitr: false,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        ag: '',
                        database_names: ['all'],
                        db: 'all',
                        start_time: '2024-04-18T15:04:06.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2024-04-18T15:03:28.000Z',
                                hash: 'md5:170695832a6bd1fd377a053351c2ec17',
                                path: 'base.tar.gz',
                                size: 3123248,
                                type: 'pg_basebackup',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/home/ubuntu/backups',
                    backupHost: 'c9',
                    backupMethod: 'pg_basebackup',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: '',
                    backup_password: '',
                    backup_retention: 5,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: true,
                    cloud_only: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: true,
                    includeDatabases: null,
                    mysqldump_type: '',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 5432,
                    scheduleId: null,
                    set_gtid_purged_off: true,
                    snapshot_repository: '',
                    stanzaName: '',
                    storageHost: '',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    useQpress: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2024-04-18T13:28:10.000Z',
                    finished_time: '2024-04-18T13:33:03.632Z',
                    host_location_uuid: '41177591-341e-4034-a7f2-5e9348160504',
                    root_dir: '/root/backups/BACKUP-13826',
                    storage_host: '143.198.228.20',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c3',
                backup_name: '',
                backup_tool_version:
                    '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
                chain_up: 0,
                children: 0,
                cid: 1,
                cloud_only: false,
                compressed: true,
                created: '2024-04-18T13:28:10.000Z',
                created_by: '',
                db_vendor: 'mariadb',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2024-04-18T13:33:03.632Z',
                id: 13826,
                job_id: 43343,
                log_file: '',
                lsn: 0,
                method: 'mysqldump',
                mysqldump_type: 'SchemaAndData',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-13826',
                schedule_id: 0,
                server_version: '10.4.18-MariaDB-1:10.4.18+maria~bionic-log',
                snapshot_repository: '',
                stanza_name: '',
                status: 'Completed',
                storage_host: '143.198.228.20',
                timezone: 'UTC',
                title: 'BACKUP-13826',
                total_datadir_size: 11771156976,
                use_for_pitr: false,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        ag: '',
                        database_names: ['*'],
                        db: '*',
                        start_time: '2024-04-18T13:32:56.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2024-04-18T13:32:10.000Z',
                                hash: 'md5:8d422adfc09fced3a54fd48958cbae30',
                                path: 'mysqldump_2024-04-18_132923_complete.sql.gz',
                                size: 95464551,
                                type: 'data,schema',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c3',
                    backupMethod: 'mysqldump',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: '',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: true,
                    checkHost: false,
                    cloud_only: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    excludeTables: null,
                    extended_insert: true,
                    includeDatabases: null,
                    includeTables: null,
                    mysqldump_type: 'SchemaAndData',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    snapshot_repository: '',
                    stanzaName: '',
                    storageHost: '143.198.228.20',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    useQpress: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
        {
            version: 2,
            cloud_locations: [],
            host_locations: [
                {
                    created_time: '2024-04-18T06:07:02.000Z',
                    finished_time: '2024-04-18T06:09:11.189Z',
                    host_location_uuid: '5f46972e-79cb-41ae-b4cf-6cee6733ae7f',
                    root_dir: '/root/backups/BACKUP-13818',
                    storage_host: 'c5',
                },
            ],
            metadata: {
                class_name: 'CmonBackupRecord',
                backup_host: 'c5',
                backup_name: '',
                backup_tool_version: '8.0.22-13',
                chain_up: 0,
                children: 0,
                cid: 2,
                cloud_only: false,
                compressed: true,
                created: '2024-04-18T06:07:02.000Z',
                created_by: '',
                db_vendor: 'percona',
                description: '',
                encrypted: false,
                encryption_md5: '',
                finished: '2024-04-18T06:09:11.189Z',
                id: 13818,
                job_id: 43328,
                log_file: '',
                lsn: 0,
                method: 'mysqldump',
                mysqldump_type: 'SchemaAndData',
                parent_id: 0,
                root_dir: '/root/backups/BACKUP-13818',
                schedule_id: 0,
                server_version: '8.0.22-13',
                snapshot_repository: '',
                stanza_name: '',
                status: 'Completed',
                storage_host: 'c5',
                timezone: 'UTC',
                title: 'BACKUP-13818',
                total_datadir_size: 1059500344,
                use_for_pitr: false,
                backup: [
                    {
                        class_name: 'CmonBackupRecordEntry',
                        ag: '',
                        database_names: ['*'],
                        db: '*',
                        start_time: '2024-04-18T06:09:10.000Z',
                        files: [
                            {
                                class_name: 'CmonBackupFile',
                                created: '2024-04-18T06:09:01.000Z',
                                hash: '',
                                path: 'mysqldump_2024-04-18_060725_complete.sql.gz',
                                size: 95567885,
                                type: 'data,schema',
                            },
                        ],
                    },
                ],
                config: {
                    backupDir: '/root/backups',
                    backupHost: 'c5',
                    backupMethod: 'mysqldump',
                    backupSubDir: 'BACKUP-%I',
                    backupToIndividualFiles: false,
                    backup_failover: false,
                    backup_failover_host: 'auto',
                    backup_password: '',
                    backup_retention: 0,
                    backup_system_db: false,
                    backup_title: '',
                    ccStorage: false,
                    checkHost: false,
                    cloud_only: false,
                    compression: true,
                    compressionLevel: 6,
                    compressionThreads: -1,
                    createdBy: 'ccadmin',
                    description: null,
                    encrypt_backup: false,
                    excludeTables: null,
                    extended_insert: true,
                    includeDatabases: null,
                    includeTables: null,
                    mysqldump_type: 'SchemaAndData',
                    netcat_port: 0,
                    pitr_compatible: false,
                    port: 3306,
                    set_gtid_purged_off: true,
                    snapshot_repository: '',
                    stanzaName: '',
                    storageHost: 'c5',
                    throttle_rate_iops: 0,
                    throttle_rate_netbw: 0,
                    usePigz: false,
                    useQpress: false,
                    wsrep_desync: false,
                    xtrabackupParallellism: 1,
                    xtrabackup_lock_ddl_per_table: false,
                    xtrabackup_locks: false,
                },
                verified: {
                    message: '',
                    status: 'NotVerified',
                    verified_time: '',
                },
            },
        },
    ],
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};

export default result;
