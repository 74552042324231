import React, { useState } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    closeActivityQuickView,
    openActivityQuickView,
} from '../../appReducer';
import ActivityBadge from './ActivityBadge';
import AppDivider from '../../common/AppDivider';
import { AlarmsIcon, JobsIcon, LogsIcon } from '../../common/icons/icons';
import JobsBadge from '../Jobs/JobsBadge';
import AlarmsBadge from '../Alarms/AlarmsBadge';
import { CcAlarmSeverity } from '../../services/models/CcAlarm';
import AppPopover from '../../common/Feedback/AppPopover';
import ActivityCenterJobsLink from './ActivityCenterJobsLink';
import ActivityCenterAlarmsLink from './ActivityCenterAlarmsLink';

export default OpenActivityCenterButton;

export type OpenActivityCenterButtonProps = ButtonProps & {
    children?: React.ReactNode;
};

function OpenActivityCenterButton({
    children,
    ...rest
}: OpenActivityCenterButtonProps) {
    const [activityQuickViewOpened]: [
        boolean
    ] = useSelector(({ activityQuickViewOpened }: AppState) => [
        activityQuickViewOpened,
    ]);
    const [visible, setVisible] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        if (activityQuickViewOpened) {
            dispatch(closeActivityQuickView());
        } else {
            dispatch(openActivityQuickView());
        }
    };
    const handleVisibleChange = (visible: boolean) => {
        setVisible(visible);
    };

    const handleLinkClick = () => {
        setVisible(false);
    };
    return (
        <AppPopover
            getPopupContainer={(triggerNode) => triggerNode}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            content={
                <Space split={<AppDivider type="vertical" />}>
                    <ActivityCenterAlarmsLink onClick={handleLinkClick}>
                        <Space>
                            <AlarmsIcon />
                            <AlarmsBadge
                                inline={true}
                                size="default"
                                severity={CcAlarmSeverity.ALARM_CRITICAL}
                            />
                            <AlarmsBadge
                                inline={true}
                                size="default"
                                severity={CcAlarmSeverity.ALARM_WARNING}
                            />
                        </Space>
                    </ActivityCenterAlarmsLink>

                    <ActivityCenterJobsLink onClick={handleLinkClick}>
                        <Space>
                            <JobsIcon />
                            <JobsBadge inline={true} size="default" />
                        </Space>
                    </ActivityCenterJobsLink>
                </Space>
            }
        >
            <ActivityBadge>
                <Button
                    size="small"
                    className="OpenActivityCenterButton"
                    onClick={handleClick}
                    {...rest}
                >
                    {children || 'Open activity center'}
                </Button>
            </ActivityBadge>
        </AppPopover>
    );
}
