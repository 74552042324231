import React from 'react';
import { Alert, Button, Form, Space } from 'antd';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';

export default NodePromoteSlaveForm;

export type NodePromoteSlaveFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodePromoteSlaveFormValues = {
    forcePromotion?: boolean;
};

function NodePromoteSlaveForm({
    node,
    onSuccess,
    onCancel,
}: NodePromoteSlaveFormProps) {
    const [form] = Form.useForm<NodePromoteSlaveFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Promote replica',
        command: CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE,
        onSuccess,
    });

    const handleSubmit = async (values: NodePromoteSlaveFormValues) => {
        await send({
            force: values.forcePromotion,
            slave_address: node.getHostWithPort(),
        });
    };
    return (
        <ModalDefaultForm
            title="Promote replica"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodePromoteSlaveForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ forcePromotion: false }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <FormItemInlineSwitch
                        justify
                        noMargin
                        name="forcePromotion"
                        label={<Space>Force promotion of replica</Space>}
                        valuePropName="checked"
                        spaceSize={10}
                        extraOnSwitch={
                            <Alert
                                type="warning"
                                message={
                                    <span>
                                        Forcing switchover may cause data
                                        integrity problems
                                    </span>
                                }
                            />
                        }
                    />
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Promote
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
