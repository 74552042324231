import { Col, Form, Input, Row, Select, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import React from 'react';
import { CcBackupStorageLocationHostType } from '../../../services/models/CcBackup';
import BackupFormConfigurator from '../Config/BackupFormConfigurator';
import CcCluster from '../../../services/models/CcCluster';
import ClusterHostInput from '../../../common/DataEntry/ClusterHostInput';

export type BackupStorageFormProps = {
    configurator: BackupFormConfigurator;
    cluster?: CcCluster;
};
const BackupStorageForm = ({
    configurator,
    cluster,
}: BackupStorageFormProps) => {
    return (
        <div className="BackupStorageForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Configure storage</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                {cluster && configurator.available('storageLocation') && (
                    <Col span={12}>
                        <Form.Item
                            name="storageLocation"
                            label={
                                <Space>
                                    Storage location
                                    <InfoIcon
                                        info={
                                            <span>
                                                {configurator
                                                    .getStorageLocationOptions(
                                                        cluster.clusterType
                                                    )
                                                    .includes(
                                                        CcBackupStorageLocationHostType.CONTROLLER
                                                    ) && (
                                                    <>
                                                        <b>
                                                            Controller Storage
                                                        </b>
                                                        : A backup stored on the
                                                        controller can be
                                                        restored on any of the
                                                        hosts in the cluster.
                                                        However, the backup will
                                                        be streamed from the
                                                        database server to the
                                                        controller making the
                                                        backup time a function
                                                        also of network speed.
                                                        <br />
                                                    </>
                                                )}
                                                {configurator
                                                    .getStorageLocationOptions(
                                                        cluster.clusterType
                                                    )
                                                    .includes(
                                                        CcBackupStorageLocationHostType.NODE
                                                    ) && (
                                                    <>
                                                        <b>Node Storage</b>: A
                                                        backup stored on a node
                                                        can only be restored on
                                                        this node. Use this
                                                        option of the backup dir
                                                        is on a SAN/NAS/NFS
                                                        mount.
                                                    </>
                                                )}
                                            </span>
                                        }
                                    />
                                </Space>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select storage location.',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                data-testid="backup-storage-location-select"
                                disabled={configurator.disabled(
                                    'storageLocation'
                                )}
                            >
                                {configurator
                                    .getStorageLocationOptions(
                                        cluster.clusterType
                                    )
                                    .map((o) => (
                                        <Select.Option key={o} value={o}>
                                            {getBackupStorageLocationName(o)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {cluster && configurator.available('storageHost') && (
                    <Col span={12}>
                        <Form.Item label="Storage host" name={'storageHost'}>
                            <ClusterHostInput cluster={cluster} />
                        </Form.Item>
                    </Col>
                )}
            </Row>

            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="storageDirectory"
                        label={<Space>Storage directory</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter storage directory.',
                            },
                        ]}
                    >
                        <Input
                            disabled={configurator.disabled('storageDirectory')}
                            placeholder="Enter storage directory"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="storageSubdirectory"
                        label={
                            <Space>
                                Backup subdirectory
                                <InfoIcon
                                    info={
                                        <span>
                                            Pattern used for generating backup
                                            filenames, available fields to be
                                            substituted: %B - creation date
                                            <br />
                                            %H - hostname
                                            <br />
                                            %i - cluster number
                                            <br />
                                            %I - backup number
                                            <br />
                                            %J - job number
                                            <br />
                                            %M - backup method
                                            <br />
                                            %O - user
                                            <br />
                                            %S - storage host'
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter backup subdirectory',
                            },
                        ]}
                    >
                        <Input
                            disabled={configurator.disabled(
                                'storageSubdirectory'
                            )}
                            placeholder="Enter backup subdirectory"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export function getBackupStorageLocationName(
    location: CcBackupStorageLocationHostType
) {
    switch (location) {
        case CcBackupStorageLocationHostType.NODE:
            return 'Store on node';
        case CcBackupStorageLocationHostType.CONTROLLER:
            return 'Store on controller';
    }
}

export function getBackupStorageValidate() {
    return ['storageLocation', 'storageDirectory', 'storageSubdirectory'];
}

export default BackupStorageForm;
