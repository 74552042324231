import React, { useContext, useEffect, useRef, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppTable from '../../common/DataDisplay/AppTable';
import SpaceWide from '../../common/SpaceWide';
import DurationFormat, {
    formatDuration,
} from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import TypographyText from '../../common/TypographyText';
import QueryMonitorEnabledWrapper from './QueryMonitorEnabledWrapper';
import QueryMonitorNodeSelect from './QueryMonitorNodeSelect';
import useTableFilter from '../../common/hooks/useTableFilter';
import { BooleanParam, NumberParam, StringParam } from 'use-query-params';
import AppDateFormat from '../../common/AppDateFormat';
import useQMOutliersList from './useQMOutliersList';
import moment from 'moment/moment';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import { AppState, AppStateUser } from '../../appReducer';
import { useSelector } from 'react-redux';
import { microToSec } from '../../common/dateTime';
import { formatNumber } from '@severalnines/bar-frontend-components/build/lib/Format/NumberFormat';
import useTableFilterColumns from '../../common/hooks/useTableFilterColumns';

export default QueryOutliersPage;

export type QueryOutliersPageProps = {
    cluster: CcCluster;
};

function QueryOutliersPage({ cluster }: QueryOutliersPageProps) {
    const [user]: [AppStateUser] = useSelector(({ user }: AppState) => [user]);
    const { responsive } = useContext(ResponsiveContext);
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);

    const range = 60 * 15; // 15 min
    const {
        filterParams,
        handleTableChange: onTableChange,
        addFilterParams,
    } = useTableFilter({
        params: {
            host: StringParam,
            from: NumberParam,
            to: NumberParam,
            shift: BooleanParam,
        },
        defaultParams: {
            sort: 'last_seen',
            order: 'descend',
        },
    });
    const [startTs, setStartTs] = useState<number>(
        filterParams.from || moment().unix() - range
    );
    const [endTs, setEndTs] = useState<number>(
        filterParams.to || moment().unix()
    );

    const [selectedHostname, setSelectedHostname] = useState<
        string | undefined
    >(filterParams.host || 'all');

    const { refresh, list, loading, page, pageSize, total } = useQMOutliersList(
        {
            clusterId: cluster.clusterId!,
        }
    );

    const handleNodeChange = async (value: string) => {
        monitorToolbarRef.current?.resetTimeout();
        setSelectedHostname(value);
        if (value === 'all') {
            addFilterParams({
                host: null,
                page: 1,
            });
        } else {
            addFilterParams({ host: value, page: 1 });
        }
    };
    const handleTimeRangeChange = async (from: number, to: number) => {
        monitorToolbarRef.current?.resetTimeout();
        setStartTs(from);
        setEndTs(to);
        addFilterParams({ page: 1, from, to, shift: undefined });
    };

    const handleTimeRangeShift = async (from: number, to: number) => {
        setStartTs(from);
        setEndTs(to);
        addFilterParams({ page: 1, from, to, shift: true });
    };

    const handleRefresh = async (triggerClick?: boolean) => {
        if (triggerClick) {
            addFilterParams({ shift: false });
        }
    };

    useEffect(() => {
        const {
            host,
            shift,
            sort,
            from,
            to,
            order,
            page,
            pageSize,
            ...restFilter
        } = filterParams;
        const [hostId, hostname, port] = (host || '')?.split(':');
        (async () => {
            await refresh({
                hostId: hostId ? Number.parseInt(hostId) : undefined,
                hostname,
                port: port ? Number.parseInt(port) : undefined,
                showLoading: !shift,
                starttime: new Date(startTs * 1000).toISOString(),
                endtime: new Date(endTs * 1000).toISOString(),
                sort,
                order,
                page,
                pageSize,
            });
        })();
    }, [filterParams, cluster?.clusterId]);

    const { columns } = useTableFilterColumns({
        columns: [
            {
                title: 'Time',
                key: 'last_seen',
                render: (record: any) => (
                    <AppDateFormat fromNow={true}>
                        {new Date(record.last_seen * 1000)}
                    </AppDateFormat>
                ),
                sorter: true,
            },
            {
                title: 'ID',
                key: 'query_id',
                render: (record: any) => record.query_id,
            },
            {
                title: 'Query',
                key: 'query',
                render: (record: any) => (
                    <TypographyText
                        ellipsis={{ tooltip: record.info || record.canonical }}
                        style={{ width: '250px' }}
                    >
                        {record.info || record.canonical}
                    </TypographyText>
                ),
            },
            {
                title: 'Query time',
                key: 'query_time',
                render: (record: any) =>
                    formatDuration(
                        formatNumber(microToSec(record.query_time), 1),
                        {
                            round: false,
                            short: true,
                            seconds: true,
                        }
                    ),
                align: 'right',
                sorter: true,
            },
            {
                title: 'Average query time',
                key: 'avg_query_time',
                render: (record: any) =>
                    formatDuration(
                        formatNumber(microToSec(record.avg_query_time), 1),
                        {
                            round: false,
                            short: true,
                            seconds: true,
                        }
                    ),
                align: 'right',
                sorter: true,
            },
            {
                title: 'Stddev',
                key: 'stdev',
                render: (record: any) =>
                    formatDuration(formatNumber(microToSec(record.stddev), 1), {
                        round: false,
                        short: true,
                        seconds: true,
                    }),
                align: 'right',
                sorter: true,
            },
            {
                title: 'Max query time',
                key: 'max_query_time',
                render: (record: any) =>
                    formatDuration(
                        formatNumber(microToSec(record.max_query_time), 1),
                        {
                            round: false,
                            short: true,
                            seconds: true,
                        }
                    ),
                align: 'right',
                sorter: true,
            },
            {
                title: 'Lock time',
                key: 'lock_time',
                render: (record: any) =>
                    formatDuration(
                        formatNumber(microToSec(record.lock_time), 1),
                        {
                            round: false,
                            short: true,
                            seconds: true,
                        }
                    ),
                align: 'right',
                sorter: true,
            },
        ],
        filterParams,
    });
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        onTableChange(pagination, filter, sorters);
        // need to reset timeout in another tick so page is updated
        setTimeout(() => {
            monitorToolbarRef.current?.resetTimeout();
        });
    };
    /**
     * Usage of index is not recommended but is needed here since we don't have
     * any unique combination or values to identify the rows from backend
     * https://severalnines.atlassian.net/browse/CLUS-3242
     */
    const rowKey = (record: any, index: number) =>
        `${index}-${record.last_seen}${record.query_id}`;
    return (
        <QueryMonitorEnabledWrapper loading={loading} cluster={cluster}>
            <SpaceWide
                direction="vertical"
                size={24}
                className="QueryOutliersPage"
            >
                <Alert message="Queries deviating more than 2 sigmas from the avg time. At least 100 samples of a query are required." />
                <MonitorToolbar
                    ref={monitorToolbarRef}
                    timeRangeProps={{}}
                    onRefresh={handleRefresh}
                    onTimeRangeChange={handleTimeRangeChange}
                    onTimeRangeShift={handleTimeRangeShift}
                    selectors={[
                        <QueryMonitorNodeSelect
                            key="query-mointor-node-select"
                            cluster={cluster}
                            onChange={handleNodeChange}
                            value={selectedHostname}
                        />,
                    ]}
                    timezone={user?.timezone?.name}
                />

                <AppTable
                    className="QueryOutliersPageTable"
                    rowKey={rowKey}
                    dataSource={list}
                    columns={columns}
                    pagination={{ current: page, pageSize, total }}
                    size="middle"
                    responsive={responsive}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </QueryMonitorEnabledWrapper>
    );
}
