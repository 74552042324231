import CcCluster from '../../../services/models/CcCluster';
import useTableFilter from '../../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import React, { useEffect, useMemo } from 'react';
import useAdvisorSchedules from './useAdvisorSchedules';
import AdvisorResultsTable from './AdvisorResultsTable';
import AppLink from '../../../common/AppLink';
import SpaceWide from '../../../common/SpaceWide';

export default AdvisorResultsList;
type AdvisorResultsListProps = {
    cluster: CcCluster;
};

function AdvisorResultsList({ cluster }: AdvisorResultsListProps) {
    const { filterParams, handleTableChange } = useTableFilter({
        params: {
            advisor: ArrayParam,
            instance: ArrayParam,
            tags: ArrayParam,
            status: ArrayParam,
        },
        defaultParams: {
            sort: 'status',
            order: 'ascend',
            page: 1,
        },
    });

    const { loading, list, refresh, loaded } = useAdvisorSchedules({
        clusterId: cluster.clusterId,
    });

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [cluster?.clusterId]);

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        handleTableChange(pagination, filters, sorter);
    };

    const enabledList = useMemo(() => {
        return list?.filter((item) => item.enabled) || [];
    }, [list]);

    return (
        <SpaceWide direction={'vertical'}>
            <SpaceWide justify="end">
                <AppLink to={`/clusters/${cluster.clusterId}/manage/advisors`}>
                    Manage advisors
                </AppLink>
            </SpaceWide>
            <AdvisorResultsTable
                loading={loading}
                loaded={loaded}
                schedules={enabledList}
                onChange={handleChange}
                filterParams={filterParams}
            />
        </SpaceWide>
    );
}
