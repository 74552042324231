import React, { useRef } from 'react';
import './NodesInputForm.less';
import { Space, Typography } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import TypographyText from '../TypographyText';
import HostAddInput, { HostAddInputProps } from './HostAddInput';
import FormItem, { FormItemProps } from './FormItem';

export default NodesInputForm;

export type NodesInputFormProps = {
    extra?: React.ReactNode;
    hasPrimary?: boolean;
    hasSecondary?: boolean;
    index?: number;
    onPrimaryAdd?: (host: string, extraData?: any) => void;
    onSecondaryAdd?: (host: string, extraData?: any) => void;
    primaryDisabled?: boolean;
    primaryExtra?: React.ReactNode;
    primaryInfo?: React.ReactNode;
    primaryPlaceholder?: string;
    primaryRequired?: boolean;
    primaryTitle?: React.ReactNode;
    secondaryExtra?: React.ReactNode;
    secondaryInfo?: React.ReactNode;
    secondaryDisabled?: boolean;
    secondaryPlaceholder?: string;
    secondaryTitle?: React.ReactNode;
    secondaryTitleExtra?: React.ReactNode;
    switchFocus?: boolean;
    primaryInputProps?: HostAddInputProps;
    secondaryInputProps?: HostAddInputProps;
    primaryFormItemProps?: FormItemProps;
    secondaryFormItemProps?: FormItemProps;
    hideAddButton?: boolean;
    disableEnterKey?: boolean;
};

function NodesInputForm({
    index = 0,
    primaryRequired = true,
    primaryDisabled = false,
    hasSecondary = false,
    primaryTitle = 'Node',
    primaryPlaceholder = 'Type an IP address/hostname',
    primaryInfo,
    primaryExtra,
    secondaryTitle = 'Secondary nodes',
    secondaryPlaceholder = 'Type an IP address/hostname',
    secondaryInfo,
    secondaryExtra,
    secondaryTitleExtra,
    secondaryDisabled,
    onPrimaryAdd,
    onSecondaryAdd,
    switchFocus = true,
    extra,
    primaryInputProps,
    secondaryInputProps,
    primaryFormItemProps,
    secondaryFormItemProps,
    hideAddButton = false,
    disableEnterKey = false,
}: NodesInputFormProps) {
    const primaryInputRef = useRef<any>(null);
    const secondaryInputRef = useRef<any>(null);

    const handleAddPrimary = (value: string, extraData?: any) => {
        if (value) {
            setTimeout(() => {
                if (switchFocus && secondaryInputRef.current) {
                    secondaryInputRef.current.input.focus();
                } else if (primaryInputRef.current) {
                    primaryInputRef.current.input.focus();
                }
            });

            onPrimaryAdd?.(value, extraData);
        }
    };

    const handleAddSecondary = (value: string, extraData?: any) => {
        if (value) {
            // setting the focus to secondary node input
            setTimeout(() => {
                secondaryInputRef.current?.input.focus();
            });
            onSecondaryAdd?.(value, extraData);
        }
    };

    return (
        <Space direction="vertical" className="NodesInputForm">
            <FormItem
                label={
                    primaryTitle ? (
                        <Space>
                            <span>
                                {primaryRequired ? (
                                    <Typography.Text type="danger">
                                        *
                                    </Typography.Text>
                                ) : null}
                            </span>
                            {primaryTitle}
                            {primaryInfo ? (
                                <InfoIcon info={primaryInfo} />
                            ) : undefined}
                        </Space>
                    ) : null
                }
                extra={primaryExtra}
                {...primaryFormItemProps}
            >
                <HostAddInput
                    className="NodesInputForm_input"
                    ref={primaryInputRef}
                    disabled={primaryDisabled}
                    onAdd={handleAddPrimary}
                    placeholder={primaryPlaceholder}
                    hideAddButton={hideAddButton}
                    disableEnterKey={disableEnterKey}
                    buttonProps={{
                        className: `NodesInputForm_add-primary-button-${index}`,
                    }}
                    {...primaryInputProps}
                />
            </FormItem>
            {hasSecondary && (
                <FormItem
                    label={
                        secondaryTitle ? (
                            <Space>
                                <span>{secondaryTitle}</span>
                                {secondaryInfo ? (
                                    <InfoIcon info={secondaryInfo} />
                                ) : undefined}
                                {secondaryTitleExtra || (
                                    <TypographyText muted={true}>
                                        (optional)
                                    </TypographyText>
                                )}
                            </Space>
                        ) : null
                    }
                    extra={secondaryExtra}
                    shouldUpdate={true}
                    {...secondaryFormItemProps}
                >
                    <HostAddInput
                        className="NodesInputForm_input"
                        ref={secondaryInputRef}
                        disabled={secondaryDisabled}
                        placeholder={secondaryPlaceholder}
                        onAdd={handleAddSecondary}
                        buttonProps={{
                            className: `NodesInputForm_add-secondary-button-${index}`,
                            disabled: secondaryDisabled,
                        }}
                        {...secondaryInputProps}
                    />
                </FormItem>
            )}
            {extra}
        </Space>
    );
}
