import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import useClustersOverview from '../Clusters/useClustersOverview';
import ClusterListMini from '../Clusters/ClusterListMini';
import { Typography } from 'antd';
import { StatusPieDiagramItem } from '../../common/StatusPieDiagram';
import CcClusterStats from '../../services/models/CcClusterStats';
import {
    getClusterStateFormatText,
    getClusterStateFormatType,
} from '../Clusters/ClusterStateFormat';
import { CcClusterState } from '../../services/models/CcCluster';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import DashboardOverviewCard from './DashboardOverviewCard';
import CcStatsOverviewInterface from '../../services/types/CcStatsOverviewInterface';
import { ClustersIcon } from '../../common/icons/icons';
import AppLink from '../../common/AppLink';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

export default DashboardClustersCard;

export type DashboardClustersCardProps = {};
function getOverviewPieData(overview?: CcClusterStats): StatusPieDiagramItem[] {
    return (
        overview?.getSatesCountPairs().map(([status, count]) => ({
            name: `${count} ${getClusterStateFormatText(
                status as CcClusterState
            )}`,
            value: count,
            status,
            type: getClusterStateFormatType(status as CcClusterState),
        })) || [
            {
                name: 'Online',
                value: 0,
                status: CcClusterState.CLUSTER_STARTED,
                type: StatusFormatStatus.success,
            },
            {
                name: 'Down',
                value: 0,
                status: CcClusterState.CLUSTER_FAILURE,
                type: StatusFormatStatus.error,
            },
        ]
    );
}

function DashboardClustersCard({ ...rest }: DashboardClustersCardProps) {
    const navigate = useNavigate();
    const {
        loading: loadingClustersOverview,
        refresh: refreshClustersOverview,
        record: clustersOverview,
    } = useClustersOverview({
        name: 'clusters-overview-dashboard',
    });

    const [pieData, setPieData] = useState<StatusPieDiagramItem[]>(
        getOverviewPieData()
    );
    useEffect(() => {
        if (clustersOverview) {
            setPieData(getOverviewPieData(clustersOverview));
        }
    }, [clustersOverview]);

    useEffect(() => {
        (async () => {
            await refreshClustersOverview({});
        })();
    }, []);

    const handlePieElementClick = (evtData: any) => {
        navigate(`/clusters?state=${evtData.data.type}`);
    };
    return (
        <DashboardOverviewCard
            loading={loadingClustersOverview}
            overview={clustersOverview as CcStatsOverviewInterface}
            pieData={pieData}
            onPieElementClick={handlePieElementClick}
            totalSummary={
                <Text type="secondary">
                    {clustersOverview && clustersOverview.total} Clusters
                </Text>
            }
            title={
                <Space>
                    <ClustersIcon />
                    <span>Clusters</span>
                </Space>
            }
            extra={<AppLink to="/clusters">View clusters</AppLink>}
            rightSide={<ClusterListMini />}
            {...rest}
        />
    );
}
