import './AppEditor.less';
import React, { useEffect, useMemo, useState } from 'react';
import { DataNode, DirectoryTreeProps } from 'antd/lib/tree';
import AppDirectoryTree from '../General/AppDirectoryTree';
import TypographyText from '../TypographyText';
import { Button, Skeleton, Space, Tabs, Tooltip } from 'antd';
import AppSpin from '../General/AppSpin';
import AppSplitLayout from '../Layout/AppSplitLayout';
import ButtonWithForm from '../General/ButtonWithForm';
import SpaceWide from '../SpaceWide';
import AppEditorDiffModal from './AppEditorDiffModal';
import { notifyError } from '../../components/Notifications/uiNotification';
import AppButton from '../Control/AppButton';
import {
    CloseOutlined,
    FileOutlined,
    FolderOpenOutlined,
} from '@ant-design/icons';
import AppEmpty from '../Feedback/AppEmpty';
import AppMonacoEditor from './AppMonacoEditor';

/**
 * copy form backend Syntax enum
 */
export enum AppEditorConfigSyntax {
    /** Just the usual, very similar to the one Windows 3.1 invented. */
    GenericConfigSyntax = 'GenericConfigSyntax',
    /** We all love MySql... */
    MySqlConfigSyntax = 'MySqlConfigSyntax',
    /** PostgreSQL has little differences */
    PostgreSqlConfigSyntax = 'PostgreSqlConfigSyntax',
    /** Yes, this is terrible. */
    ProxySqlConfigSyntax = 'ProxySqlConfigSyntax',
    /** This is also terrible. */
    HaProxyConfigSyntax = 'HaProxyConfigSyntax',
    /** PgBouncer uses ini syntax but with ';' to mark comments. */
    PgBouncerConfigSyntax = 'PgBouncerConfigSyntax',
    /** For the pg_hba.conf file. */
    PgHbaSyntax = 'PgHbaSyntax',
    /** This is a generic name, Mongo uses it. Most terrible... */
    YamlSyntax = 'YamlSyntax',
    /** Yes, Mongo. God save us! */
    MongoConfigSyntax = 'MongoConfigSyntax',
    /** Encodes a syntax that we still need to recognize. */
    UnknownSyntax = 'UnknownSyntax',
    /** Wont do any parsing, must be selected explicitly. */
    PlainTextSyntax = 'PlainTextSyntax',
}

export default AppEditor;
export type EditorTreeDataNode = Omit<DataNode, 'children'> & {
    content?: string;
    name?: string;
    value?: string;
    type?: string | 'file' | 'dir';
    children?: EditorTreeDataNode[];
    loading?: boolean;
    filename?: string;
    hostname?: string;
    language?: string;
};
export type AppEditorProps = {
    treeData?: EditorTreeDataNode[];
    loading?: boolean;
    contentLoading?: boolean;
    onSelect?: DirectoryTreeProps['onSelect'];
    onNodeSaveClick?: (node: EditorTreeDataNode) => Promise<void>;
    emptyState?: React.ReactNode;
    contentEmptyState?: React.ReactNode;
    footerTopExtra?:
        | React.ReactNode
        | ((node?: EditorTreeDataNode) => React.ReactNode);
    footerLeftExtra?:
        | React.ReactNode
        | ((node?: EditorTreeDataNode) => React.ReactNode);
    readOnly?: boolean;
};

function AppEditor({
    treeData,
    loading = false,
    contentLoading = false,
    onSelect,
    onNodeSaveClick,
    emptyState,
    contentEmptyState,
    footerTopExtra,
    footerLeftExtra,
    readOnly = false,
}: AppEditorProps) {
    const [currentNode, setCurrentNode] = useState<EditorTreeDataNode | null>();
    const [openedFiles, setOpenedFiles] = useState<EditorTreeDataNode[]>([]);

    const handleSelect: DirectoryTreeProps['onSelect'] = async (keys, e) => {
        const node = e.node;
        const file = openedFiles.find((item) => item.key === node.key);
        if (file) {
            setCurrentNode(file);
        } else {
            setCurrentNode(node);
            setOpenedFiles([...openedFiles, node]);
        }
        onSelect?.(keys, e);
    };

    const handleValueChange = (
        key: EditorTreeDataNode['key'],
        newValue: string,
        e: any
    ) => {
        const file = openedFiles.find((item) => item.key === key);
        if (file) {
            file.value = newValue;
            setCurrentNode({ ...file });
        }
    };

    const handleTabChange = (activeKey: string) => {
        const file = openedFiles.find((item) => item.key === activeKey);
        if (file) {
            setCurrentNode({ ...file });
        }
    };

    const handleSaveClick = async () => {
        if (!currentNode) {
            notifyError({ content: 'No file selected' });
            return;
        }
        await onNodeSaveClick?.(currentNode);
    };

    const handleRevertClick = () => {
        if (!currentNode) {
            notifyError({ content: 'No file selected' });
            return;
        }
        setCurrentNode({ ...currentNode, value: currentNode.content });
        setOpenedFiles(
            openedFiles.map((item) => {
                if (item.key === currentNode.key) {
                    item.value = currentNode.content;
                }
                return item;
            })
        );
    };

    const handleCloseClick = (node: EditorTreeDataNode) => {
        setOpenedFiles(openedFiles.filter((item) => item.key !== node.key));
    };

    const alteredTreeData = useMemo(() => {
        return treeData?.map(alterTreeNode) || [];
    }, [treeData]);

    useEffect(() => {
        const getFiles = (
            files: EditorTreeDataNode[]
        ): EditorTreeDataNode[] => {
            return files
                .map((item) => {
                    if (item.type === 'dir' && item.children) {
                        return getFiles(item.children);
                    }
                    return [item];
                })
                .flat();
        };

        const alteredFiles = getFiles(alteredTreeData);
        const newOpenedFiles = openedFiles
            .map((item) => {
                const node = alteredFiles.find((node) => node.key === item.key);
                if (!node) {
                    return undefined;
                }
                return {
                    ...node,
                    value: item.value,
                };
            })
            .filter((item) => !!item) as EditorTreeDataNode[];

        setOpenedFiles(newOpenedFiles);
        if (newOpenedFiles) {
            const newCurrentNode = newOpenedFiles.find(
                (item) => item.key === currentNode?.key
            );
            if (newCurrentNode) {
                setCurrentNode(newCurrentNode);
            }
        }
    }, [alteredTreeData]);

    const currentNodeChanged =
        currentNode?.value && currentNode?.value !== currentNode?.content;

    const footerTopExtraContent =
        typeof footerTopExtra === 'function'
            ? footerTopExtra(currentNode || undefined)
            : footerTopExtra;

    const footerLeftExtraContent =
        typeof footerLeftExtra === 'function'
            ? footerLeftExtra(currentNode || undefined)
            : footerLeftExtra;

    const contentFooter = (
        <SpaceWide direction="vertical">
            {footerTopExtraContent}
            <SpaceWide justify="space-between" className="AppEditor_TabsFooter">
                <Space>{footerLeftExtraContent || <span></span>}</Space>

                {readOnly ? (
                    <span></span>
                ) : (
                    <Space>
                        <ButtonWithForm
                            button={
                                currentNodeChanged ? (
                                    <Button>Show diff</Button>
                                ) : (
                                    <Tooltip title="No changes done yet">
                                        <span>
                                            <Button disabled={true}>
                                                Show diff
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )
                            }
                            form={
                                <AppEditorDiffModal
                                    original={currentNode?.content || ''}
                                    value={currentNode?.value || ''}
                                    title={currentNode?.title}
                                    onSaveClick={handleSaveClick}
                                    onRevertClick={handleRevertClick}
                                />
                            }
                        />
                        {currentNodeChanged ? (
                            <AppButton
                                type="primary"
                                asyncLoading={true}
                                onClick={handleSaveClick}
                            >
                                Save
                            </AppButton>
                        ) : (
                            <Tooltip title="No changes done yet">
                                <Button type="primary" disabled={true}>
                                    Save
                                </Button>
                            </Tooltip>
                        )}
                    </Space>
                )}
            </SpaceWide>
        </SpaceWide>
    );

    const right = (openedFiles && openedFiles.length > 0 && (
        <>
            <Tabs
                onChange={handleTabChange}
                type="card"
                className="AppEditor_Tabs"
                style={{ width: '100%' }}
                tabPosition="top"
                activeKey={`${currentNode?.key || ''}`}
            >
                {openedFiles.map((node) => {
                    return (
                        <Tabs.TabPane
                            tab={
                                <Space size={20}>
                                    <span>{node.title}</span>
                                    <CloseOutlined
                                        onClick={() => handleCloseClick(node)}
                                    />
                                </Space>
                            }
                            key={`${node.key}`}
                        >
                            <AppMonacoEditor
                                loading={contentLoading || node.loading}
                                language={node.language}
                                value={node.value || node.content}
                                editorDidMount={(instance) => {
                                    // @todo implement scrolling down for logs
                                    // instance.revealLine(
                                    //     instance
                                    //         ?.getModel()
                                    //         ?.getLineCount() || 0
                                    // );
                                }}
                                options={{
                                    automaticLayout: true,
                                    scrollbar: {
                                        vertical: 'hidden',
                                        verticalScrollbarSize: 0,
                                    },
                                    overviewRulerBorder: false,
                                    minimap: {
                                        size: 'fit',
                                    },
                                    readOnly,
                                }}
                                onChange={(newValue: string, e: any) =>
                                    handleValueChange(node.key, newValue, e)
                                }
                            />
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
            {contentFooter}
        </>
    )) || (
        <SpaceWide
            direction="vertical"
            justify="space-between"
            style={{ height: '100%' }}
        >
            {contentEmptyState || <AppEmpty description="No files selected" />}
            {contentFooter}
        </SpaceWide>
    );
    if (loading) {
        return (
            <AppSplitLayout
                className="AppEditor"
                noSplitBorder={true}
                left={
                    <Space direction="vertical" style={{ width: 200 }}>
                        <Skeleton
                            avatar={true}
                            active={loading}
                            paragraph={{ rows: 2 }}
                        />
                        <Skeleton
                            avatar={true}
                            active={loading}
                            paragraph={{ rows: 2 }}
                        />
                        <Skeleton
                            avatar={true}
                            active={loading}
                            paragraph={{ rows: 2 }}
                        />
                    </Space>
                }
                right={
                    <div className="AppEditor_TabsWrap">
                        <div className="AppEditor_ContentEmptyWrap">
                            <Skeleton
                                active={loading}
                                paragraph={{ rows: 11 }}
                            />
                        </div>
                    </div>
                }
            />
        );
    }
    if (alteredTreeData.length > 0) {
        return (
            <AppSpin tall={true} spinning={loading}>
                <AppSplitLayout
                    noSplitBorder={true}
                    className="AppEditor"
                    type="tab-content"
                    noPaddingRight={true}
                    left={
                        <div className="AppEditor_TreeWrap">
                            <AppDirectoryTree
                                defaultExpandedKeys={alteredTreeData?.map(
                                    (item) => item.key
                                )}
                                onSelect={handleSelect}
                                treeData={alteredTreeData}
                                titleRender={(node) => (
                                    <TypographyText nowrap={true}>
                                        {node.title}
                                    </TypographyText>
                                )}
                            />
                        </div>
                    }
                    right={<div className="AppEditor_TabsWrap">{right}</div>}
                />
            </AppSpin>
        );
    }

    return <>{emptyState || <AppEmpty description="No files found" />}</>;
}

function alterTreeNode(item: EditorTreeDataNode): EditorTreeDataNode {
    switch (item.type) {
        case 'file':
            return {
                ...item,
                title: item.title || (
                    <Space>
                        <FileOutlined />
                        {item.name}
                    </Space>
                ),
                icon: <></>,
            };
        case 'dir':
            return {
                ...item,
                children: item.children?.map(alterTreeNode) || undefined,
                title: item.title || (
                    <TypographyText nowrap={true}>
                        <Space>
                            <FolderOpenOutlined />
                            {item.name}
                        </Space>
                    </TypographyText>
                ),
                icon: <></>,
            };
    }

    return item;
}

export function updateTreeDataNode(
    treeData: EditorTreeDataNode[],
    node: EditorTreeDataNode
): EditorTreeDataNode[] {
    return treeData.map((item) => {
        let result = item;
        if (item.children) {
            result = {
                ...item,
                children: updateTreeDataNode(item.children, node),
            };
        }
        if (item.key === node.key) {
            result = {
                ...item,
                ...node,
            };
        }
        return result;
    });
}

export function getAppEditorConfigSyntax(syntax: AppEditorConfigSyntax) {
    switch (syntax) {
        case AppEditorConfigSyntax.GenericConfigSyntax:
        case AppEditorConfigSyntax.MySqlConfigSyntax:
        case AppEditorConfigSyntax.PostgreSqlConfigSyntax:
            return 'ini';
        case AppEditorConfigSyntax.YamlSyntax:
        case AppEditorConfigSyntax.MongoConfigSyntax:
            return 'yaml';
        case AppEditorConfigSyntax.HaProxyConfigSyntax:
        case AppEditorConfigSyntax.PgBouncerConfigSyntax:
        case AppEditorConfigSyntax.PgHbaSyntax:
        case AppEditorConfigSyntax.ProxySqlConfigSyntax:
        case AppEditorConfigSyntax.UnknownSyntax:
        case AppEditorConfigSyntax.PlainTextSyntax:
        default:
            return 'plaintext';
    }
}
