import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import KeyManagementActionMenuMoveButton from './KeyManagementActionMenuMoveButton';
import KeyManagementActionMenuDeleteButton from './KeyManagementActionMenuDeleteButton';
import React from 'react';
import { DeleteOutlined, DoubleRightOutlined } from '@ant-design/icons';

export default KeyManagementActionMenu;

export type KeyManagementActionMenuProps = ActionsMenuProps & {
    list: any;
    selectedRow: any;
    onActionPerformed: () => void;
    onDelete: (id: number) => void;
};

function KeyManagementActionMenu({
    list,
    selectedRow,
    onActionPerformed,
    onDelete,
}: KeyManagementActionMenuProps) {
    return (
        <ActionsMenu
            items={[
                {
                    key: 'move',
                    icon: <DoubleRightOutlined />,
                    label: (
                        <KeyManagementActionMenuMoveButton
                            list={list}
                            selectedRow={selectedRow}
                            onSuccess={onActionPerformed}
                        />
                    ),
                },
                {
                    key: 'delete',
                    critical: true,
                    waitForConfirm: true,
                    icon: <DeleteOutlined />,
                    label: (
                        <KeyManagementActionMenuDeleteButton
                            selectedRow={selectedRow}
                            onSuccess={onDelete}
                        />
                    ),
                },
            ]}
        />
    );
}
