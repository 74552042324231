import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../../Jobs/JobCreateActionButton';

export default ClusterEnableTimescaledbButton;

export type ClusterEnableTimescaledbButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterEnableTimescaledbButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterEnableTimescaledbButtonProps) {
    const jobData = useMemo(
        () => ({
            extension: 'timescaledb',
        }),
        []
    );
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.SETUP_EXTENSION}
            confirmTitle={'Enable TimescaleDB?'}
            title={`Enable TimescaleDB`}
            onSuccess={onSuccess}
            jobData={jobData}
            {...rest}
        />
    );
}
