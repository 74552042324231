import CmonConfigService from '../../../../services/cmon/CmonConfigService';
import { PgBouncerPoolFormValues } from './PgBouncerPoolModal';
import CcCluster from '../../../../services/models/CcCluster';
import CcPostgreSqlNode from '../../../../services/models/CcPostgreSqlNode';

export function parseConfigPools(config: any) {
    const file = config?.files?.find(
        (item: any) => item.filename === 'pgbouncer.ini'
    );
    if (!file?.values) {
        return;
    }
    return file?.values
        .filter(
            (item: any) =>
                item.section === 'databases' && item.variablename !== '*'
        )
        .map((item: any, index: number) => {
            const result: any = {
                key: `${item.variablename} ${index}`,
                database: item.variablename,
                pool_size: '',
                max_db_connections: '',
            };
            item.value
                .split(' ')
                .map((item: any) => item.split('='))
                .filter((item: any) => item[0] && item[1])
                .forEach((item: any) => {
                    result[item[0].trim()] = item[1].trim();
                });
            if (result.pool_size) {
                result.pool_size = +result.pool_size;
            }
            if (result.max_db_connections) {
                result.max_db_connections = +result.max_db_connections;
            }
            return result;
        });
}

export function initialValue(edit: boolean | undefined, pool: any) {
    return edit && pool
        ? {
              username: pool.user,
              poolMode: pool.pool_mode ? pool.pool_mode : 'default',
              poolSize: pool.pool_size,
              maxConnections: pool.max_db_connections,
          }
        : {
              poolMode: 'default',
          };
}

export function createPool(
    values: PgBouncerPoolFormValues,
    cluster: CcCluster
) {
    return values.hostname.map((host) => {
        const [hostName, port] = host.split('+');
        return CmonConfigService.setConfig({
            clusterid: cluster?.clusterId,
            hostName: hostName.trim(),
            port: +port.trim(),
            configuration: [
                {
                    group: 'databases',
                    name: values.poolName,
                    value: `user=${values.username} ${
                        values.poolMode !== 'default'
                            ? 'pool_mode=' + values.poolMode
                            : ''
                    } ${
                        values.poolSize !== null &&
                        values.poolSize !== undefined
                            ? 'pool_size=' + values.poolSize
                            : ''
                    } ${
                        values.maxConnections
                            ? 'max_db_connections=' + values.maxConnections
                            : 'max_db_connections=0'
                    }`,
                },
            ],
        });
    });
}
