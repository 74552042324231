import { SelectProps } from 'antd';
import ClusterFormat from './ClusterFormat';
import React, { useEffect, useMemo, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import useClusterList from './useClusterList';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import SpaceWide from '../../common/SpaceWide';
import SelectWrap from '../../common/DataEntry/SelectWrap';
import { DefaultOptionType } from 'rc-select/lib/Select';

export const SELECT_ALL_CLUSTER_OPTION = -1;

export type ClusterSelectDropdownProps = SelectProps & {
    onClusterSelect?: (cluster: CcCluster | undefined) => void;
    onClustersSelect?: (cluster: CcCluster[] | undefined) => void;
    selectFirst?: boolean;
    useGlobalState?: boolean;
    filterClusters?: (cluster: CcCluster) => boolean;
    showId?: boolean;
    extra?: React.ReactNode;
    multiple?: boolean;
    addAllOption?: boolean;
    clusterNameMaxWidth?: number;
};
export default function ClusterSelectDropdown({
    onClusterSelect,
    onClustersSelect,
    onChange,
    selectFirst = false,
    useGlobalState = false,
    filterClusters,
    value,
    showId = false,
    extra,
    multiple = false,
    addAllOption = false,
    clusterNameMaxWidth = 180,
    ...rest
}: ClusterSelectDropdownProps) {
    const [selectedCluster, setSelectedCluster] = useState<
        number[] | undefined | null
    >();
    const [clusters, setClusters] = useState<CcCluster[]>();
    const {
        list: clustersList,
        filter: getClusters,
        refresh: refreshClusters,
    } = useClusterList({ name: 'cluster-list' });

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    useEffect(() => {
        if (clustersList) {
            if (filterClusters) {
                setClusters(clustersList.filter(filterClusters));
            } else {
                setClusters(clustersList);
            }
        }
    }, [clustersList, filterClusters]);

    useEffect(() => {
        if (useGlobalState) {
            getClusters();
        } else {
            (async () => {
                await refreshClusters();
            })();
        }
    }, []);

    useEffect(() => {
        if (value && selectedCluster === undefined) {
            setSelectedCluster(value);
        }
    }, [value]);

    const handleSelectCluster = (
        clusterId: number[],
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        if (multiple && onClustersSelect) {
            if (clusterId[clusterId.length - 1] === SELECT_ALL_CLUSTER_OPTION) {
                onClustersSelect([SELECT_ALL_CLUSTER_OPTION] as any);
                setSelectedCluster([SELECT_ALL_CLUSTER_OPTION]);
            } else if (Array.isArray(clusterId)) {
                const index = clusterId.indexOf(SELECT_ALL_CLUSTER_OPTION);
                if (index > -1) {
                    clusterId.splice(index, 1);
                }
                onClustersSelect(
                    clusterId
                        .map((clusterId) =>
                            clustersMap.has(`${clusterId}`)
                                ? clustersMap.get(`${clusterId}`)
                                : null
                        )
                        .filter(Boolean) as CcCluster[]
                );
                setSelectedCluster(clusterId);
            }
        } else if (clustersMap.has(`${clusterId}`) && onClusterSelect) {
            onClusterSelect(clustersMap.get(`${clusterId}`));
            onChange?.(clusterId, option);
            setSelectedCluster(clusterId);
        }
    };

    const options = useMemo(() => {
        const opts =
            clusters &&
            clusters.map((cluster) => {
                return {
                    value: cluster.clusterId || 0,
                    label: (
                        <ClusterFormat
                            cluster={cluster}
                            showId={showId}
                            clusterNameMaxWidth={clusterNameMaxWidth}
                            showPopover={false}
                        />
                    ),
                };
            });
        if (addAllOption) {
            opts &&
                opts.unshift({
                    value: SELECT_ALL_CLUSTER_OPTION,
                    label: <span>All Clusters</span>,
                });
        }
        return opts;
    }, [clusters, addAllOption]);
    return (
        <SpaceWide direction="vertical" size={10}>
            <SelectWrap
                disabled={!clusters || clusters.length < 1}
                {...rest}
                selectFirst={selectFirst}
                data-testid="cluster-select-dropdown-select"
                value={selectedCluster}
                onChange={handleSelectCluster}
                onSelect={handleSelectCluster}
                loading={!clusters}
                mode={multiple ? 'multiple' : undefined}
                options={options}
            />
            {extra}
        </SpaceWide>
    );
}
