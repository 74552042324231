
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';


export interface GetDbVersionsRequestData {
    cluster_type?: string;
    patch_number?: boolean;
    request_created?: string;
    request_id?: number;
    use_vendor_api?: boolean;
    vendor?: string;
    [key: string]: any;
}

export interface GetClusterTypesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetVendorsRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonDbversionsService extends CmonRequestService {
    
    public static module(): string {
        return 'dbversions';
    }
    
    public static async getDbVersions(
        data: GetDbVersionsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getDbVersions', data, opts);
    }

    public static async getClusterTypes(
        data: GetClusterTypesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getClusterTypes', data, opts);
    }

    public static async getVendors(
        data: GetVendorsRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getVendors', data, opts);
    }

}
