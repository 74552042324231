import MysqlReplicationConfigurator from '../../MysqlReplication/MysqlReplicationConfigurator';

import merge from 'deepmerge';
import React from 'react';
import MysqlNodeConfiguration from '../../Mysql/MysqlNodeConfiguration';
import { MysqlFormValues } from '../../Mysql/MysqlConfigurator';
import MysqlAdvancedNodeConfiguration from '../../Mysql/MysqlAdvancedNodeConfiguration';
import { CcClusterType } from '../../../../../services/models/CcCluster';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import TopologySummary from '../../TopologySummary';
import { FormInstance } from 'antd/es';
import ClusterNodesForm from '../../ClusterNodesForm';

export default class MysqlReplicationImportConfigurator extends MysqlReplicationConfigurator {
    public static getDefaults(): MysqlFormValues {
        return merge(MysqlReplicationConfigurator.getDefaults(), {
            nodeConfig: {
                informationSchemaQueries: false,
                clusterAutoRecovery: false,
                nodeAutoRecovery: false,
                semiSynchronous: true,
            },
        });
    }

    public static getJobData(formValues: MysqlFormValues): any {
        const { nodeConfig } = formValues;

        return merge(MysqlReplicationConfigurator.getJobData(formValues), {
            mysql_semi_sync: nodeConfig.semiSynchronous,
            enable_information_schema_queries:
                nodeConfig.informationSchemaQueries,
            enable_cluster_autorecovery: nodeConfig.clusterAutoRecovery,
            enable_node_autorecovery: nodeConfig.nodeAutoRecovery,
        });
    }

    public static getJobOptions(formValues: MysqlFormValues): any {
        return merge(MysqlReplicationConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import MySQL Replication Cluster',
            },
        });
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return (
            <>
                <MysqlNodeConfiguration
                    hasTemplate={false}
                    clusterType={CcClusterType.TYPE_REPLICATION}
                    configurator={this}
                    {...props}
                />
                <MysqlAdvancedNodeConfiguration />
            </>
        );
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return (
            <NodeConfigurationSummary hasAdvancedNodeConfiguration {...props} />
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{ onlyPrimaries: true, singleNode: true }}
            />
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <TopologySummary form={form} singleNode={true} />;
    }
}
