import React, { useMemo, useRef, useState } from 'react';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import ModalDefault from '../../../common/ModalDefault';
import DeploymentWizard from '../../Services/Cluster/Deploy/DeploymentWizard';
import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import { ClusterConfiguratorFormValues } from '../../Services/Cluster/ClusterConfigurator';
import { notifyConfirmCloseWindow } from '../../Notifications/uiNotification';
import MotionShake, { MotionApi } from '../../../common/motion/MotionShake';
import { BackupsIcon, NodesIcon } from '../../../common/icons/icons';
import { ServiceClusterWizardStep } from '../../Services/Cluster/ServiceClusterWizardStep';
import { useNotificationContext } from '../../Notifications/NotificationProvider';

export default ClusterCreateReplicationForm;

export enum ClusterCreateReplicationFormAction {
    STREAM_FROM_PRIMARY_CLUSTER = 'stream',
    STAGE_FROM_BACKUP = 'stage',
}
export enum ClusterCreateReplicationFormStep {
    ACTION = 'action',
    DEPLOY_WIZARD = 'deploy_wizard',
}

export type ClusterCreateReplicationFormProps = {
    cluster: CcCluster;
    activeStep?: ClusterCreateReplicationFormStep;
    defaultAction?: ClusterCreateReplicationFormAction;
    defaultDeployActiveStep?: ServiceClusterWizardStep;
    onSuccess?: (values: ClusterConfiguratorFormValues) => void;
    onCancel?: () => void;
    onActionTypeChange?: (action: ClusterCreateReplicationFormAction) => void;
};

function ClusterCreateReplicationForm({
    cluster,
    activeStep,
    defaultAction,
    defaultDeployActiveStep,
    onSuccess,
    onCancel,
    onActionTypeChange,
}: ClusterCreateReplicationFormProps) {
    const [visible, setVisible] = useState(true);
    const [confirmClose, setConfirmClose] = useState(false);
    const [step, setStep] = useState<ClusterCreateReplicationFormStep>(
        activeStep || ClusterCreateReplicationFormStep.ACTION
    );
    const [actionType, setActionType] = useState<
        ClusterCreateReplicationFormAction | undefined
    >(defaultAction);
    const animationRef = useRef<MotionApi | undefined>();

    const handleDeploymentWizardCancel = () => {
        setStep(ClusterCreateReplicationFormStep.ACTION);
    };
    const handleActionSelect = (value: string) => {
        setActionType(value as ClusterCreateReplicationFormAction);
        setStep(ClusterCreateReplicationFormStep.DEPLOY_WIZARD);
        onActionTypeChange &&
            onActionTypeChange(value as ClusterCreateReplicationFormAction);
    };
    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    setVisible(false);
                    onCancel?.();
                },
            });
        } else {
            setVisible(false);
            onCancel?.();
        }
    };

    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };

    const handleSuccess = (values: ClusterConfiguratorFormValues) => {
        setVisible(false);
        onSuccess?.(values);
    };

    const handleServiceFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };
    const steps = useMemo(() => {
        return [
            {
                step: ClusterCreateReplicationFormStep.ACTION,
                content: (
                    <WizardSelectCardStep
                        title={
                            <div>
                                Create replica cluster from{' '}
                                {cluster.clusterName}
                            </div>
                        }
                        description={''}
                        onSelect={handleActionSelect}
                    >
                        <WizardSelectCardStep.Item
                            title="Stream data from primary cluster"
                            action={
                                ClusterCreateReplicationFormAction.STREAM_FROM_PRIMARY_CLUSTER
                            }
                            size="small"
                            icon={<NodesIcon />}
                        />
                        <WizardSelectCardStep.Item
                            title={'Stage cluster from a backup'}
                            action={
                                ClusterCreateReplicationFormAction.STAGE_FROM_BACKUP
                            }
                            size="small"
                            disabled={cluster.isType(
                                CcClusterType.TYPE_POSTGRESQL
                            )}
                            icon={<BackupsIcon />}
                        />
                    </WizardSelectCardStep>
                ),
            },
            {
                step: ClusterCreateReplicationFormStep.DEPLOY_WIZARD,
                content: (
                    <div className="ClusterCreateReplicationForm_deployment-wizard-wrapper">
                        <DeploymentWizard
                            key={`cluster-create-replication-${actionType}`}
                            initialValues={
                                {
                                    primaryCluster: {
                                        clusterId: cluster.clusterId,
                                        bidirectionalReplication: true,
                                    },
                                } as ClusterConfiguratorFormValues
                            }
                            defaultActiveStep={defaultDeployActiveStep}
                            onCancel={handleDeploymentWizardCancel}
                            clusterType={cluster.clusterType}
                            onSuccess={handleSuccess}
                            onTouchedChange={handleFormTouchedChange}
                            onStepErrorInsist={handleServiceFormStepErrorInsist}
                            primaryCluster={cluster}
                            fromBackup={
                                actionType ===
                                ClusterCreateReplicationFormAction.STAGE_FROM_BACKUP
                            }
                        />
                    </div>
                ),
            },
        ];
    }, [cluster, actionType]);

    return (
        <ModalDefault
            title={
                step === ClusterCreateReplicationFormStep.DEPLOY_WIZARD
                    ? `Replicate from ${cluster.clusterName}`
                    : null
            }
            animateHeight={true}
            onCancel={handleCancel}
            width={900}
            visible={visible}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
        >
            <WizardConfiguration activeStep={step} steps={steps} />
        </ModalDefault>
    );
}
