import {
    CcBackupMethod,
    CcBackupMySQLDumpType,
    CcBackupRetentionType,
    CcBackupStorageLocationHostType,
    isBackupMethodPgBackRest,
} from '../../../services/models/CcBackup';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import BackupAvailableFieldsConfig from './BackupAvailableFieldsConfig';
import { removeObjectProperties } from '../../../common/objectHelpers';
import { CcCloudProviderType } from '../../../services/models/CcCloudCredentials';
import { getBackupMethodsByClusterType } from '../BackupMethodSelect';
import { InstallBackupToolStatus } from '../InstallBackupToolInput';
import CcPostgreSqlNode, {
    CcPostgreSqlNodeArchiveMode,
} from '../../../services/models/CcPostgreSqlNode';

const PG_BACKREST_BACKUP_METHODS = [
    CcBackupMethod.PGBACKREST_FULL,
    CcBackupMethod.PGBACKREST_INC,
    CcBackupMethod.PGBACKREST_DIFF,
];

export enum BackupPartialTablesFilter {
    INCLUDE = 'include',
    EXCLUDE = 'exclude',
}

export enum BackupFormWizardStep {
    CONFIGURATION = 'configuration',
    ADVANCED = 'advanced',
    CLOUD = 'cloud',
    SCHEDULE = 'schedule',
    VERIFY = 'verify',
    STORAGE = 'storage',
    PREVIEW = 'preview',
}

export type BackupFormFieldItemType = keyof BackupFormFieldsType;

export type BackupFormFieldsType = {
    clusterType?: CcClusterType;
    isSchedule?: boolean;
    installBackupTool?: boolean;
    toolStatus?: InstallBackupToolStatus | null;
    backupToolJobId?: number | null;
    toolInstallStarted?: boolean;

    // configuration
    clusterId?: number;
    method?: string;
    host?: string;
    port?: number;
    dumpType?: CcBackupMySQLDumpType;
    compression?: boolean;
    compressionLevel?: number;
    enableRetention?: boolean;
    retentionType?: CcBackupRetentionType;
    defaultRetention?: number;
    cloudUpload?: boolean;
    elasticRepository?: string | null;

    // advanced
    splitDumpFiles?: boolean;
    enablePartial?: boolean;
    partialDatabases?: string[];
    partialTables?: string[];
    tableList?: string[];
    includeTables?: boolean;
    excludeTables?: boolean;
    partialTableFilter?: BackupPartialTablesFilter;
    verifyBackup?: boolean;
    failoverBackup?: boolean;
    failOverHost?: string | null;
    extendedInsert?: boolean;
    encryptBackup?: boolean;
    customRetention?: number | null;
    useQPress?: boolean;
    wsrepDeSync?: boolean;
    xtrabackupBackupLocks?: boolean;
    xtrabackupLockDdlPerTable?: boolean;
    xtrabackupParallellism?: number;
    throttleRateNet?: number;
    usePIGZ?: boolean;
    backupSystemDatabases?: boolean;
    //elastic
    dataStreamsAndIndexes?: boolean;
    allowPartialIndexes?: boolean;
    ignoreUnavailableIndexes?: boolean;
    includeGlobalState?: boolean;

    // cloud
    cloudCredentials?: string;
    cloudEnableRetention?: boolean;
    cloudDefaultRetention?: number;
    cloudCustomRetention?: number | null;
    cloudLocationPath?: string;
    deleteAfterUpload?: boolean;
    cloudBucket?: string;
    cloudProvider?: CcCloudProviderType | '';

    // storage
    storageLocation?: CcBackupStorageLocationHostType;
    storageHost?: string | null;
    storageDirectory?: string;
    storageSubdirectory?: string;

    // schedule
    scheduleCron?: string;
    scheduleName?: string;

    // verification
    verificationHost?: any;
    verificationInstallSoftware?: boolean;
    verificationDisableFirewall?: boolean;
    verificationDisableSELinux?: boolean;
    verificationShutdownAfter?: boolean;
    verificationStartIn?: number;

    //restore
    backupPath?: string;
    pitrCompatible?: boolean;
    tmpDir?: string;
    dumpSetsDatabase?: boolean;
    serverAddress?: string;
    sourceAddress?: string;
    database?: string;
    backupDataDirBeforeRestore?: boolean;
    bootstrap?: boolean;
};

export type BackupFormConfigFunction = (
    fields: BackupFormFieldsType
) => boolean;

export type BackupFormFieldsConfigType = {
    [key in keyof BackupFormFieldsType]: BackupFormConfigFunction;
};

export interface BackupFormFieldsConfigInterface
    extends BackupFormFieldsConfigType {}

export type BackupFormConfiguratorProps = {
    form: FormInstance<BackupFormFieldsType>;
    defaults: BackupFormFieldsType;
    clusterType?: CcClusterType;
};

export default class BackupFormConfigurator {
    readonly form: FormInstance<BackupFormFieldsType>;
    readonly defaults: BackupFormFieldsType;
    readonly clusterType?: CcClusterType;
    readonly disabledConfig: BackupFormFieldsConfigType;

    constructor(props: BackupFormConfiguratorProps) {
        this.form = props.form;
        this.defaults = props.defaults;
        this.clusterType = props.clusterType;

        this.disabledConfig = this.clusterType
            ? getBackupDisabledFieldsConfig(this.clusterType)
            : {};
    }

    available(field: BackupFormFieldItemType) {
        if (this.defaults[field] === undefined) {
            return false;
        }
        const config =
            BackupAvailableFieldsConfig as BackupFormFieldsConfigType;

        if (config[field]) {
            return config[field]?.(this.form.getFieldsValue(true)) || false;
        }

        return true;
    }

    disabled(field: BackupFormFieldItemType) {
        return (
            this.disabledConfig[field]?.(this.form.getFieldsValue(true)) ||
            false
        );
    }

    stepAvailable(step: BackupFormWizardStep) {
        return getBackupStepAvailableConfig(step)(
            this.form.getFieldsValue(true)
        );
    }

    // @todo remove this when form fields snapshot is implemented
    getAvailableFields(): BackupFormFieldsType {
        const currentFields = this.form.getFieldsValue(true);
        return removeObjectProperties(currentFields, ([field]: any) => {
            return !this.available(field);
        });
    }

    getStorageLocationOptions(
        type: CcClusterType
    ): CcBackupStorageLocationHostType[] {
        switch (type) {
            case CcClusterType.TYPE_MSSQL_SINGLE:
            case CcClusterType.TYPE_MSSQL_AO_ASYNC:
                return [CcBackupStorageLocationHostType.NODE];
            default:
                return [
                    CcBackupStorageLocationHostType.CONTROLLER,
                    CcBackupStorageLocationHostType.NODE,
                ];
        }
    }
}

export function getBackupDisabledFieldsConfig(
    type: CcClusterType
): BackupFormFieldsConfigType {
    switch (type) {
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_REPLICATION: // test this
        case CcClusterType.TYPE_MYSQL_SINGLE:
        case CcClusterType.TYPE_MYSQL_CLUSTER:
            return {
                compression: (fields: BackupFormFieldsType) => {
                    return fields.method === CcBackupMethod.MYSQLDUMP;
                },
                cloudUpload: (fields: BackupFormFieldsType) => {
                    return fields.method === CcBackupMethod.MYSQL_BINLOG;
                },
                deleteAfterUpload: (fields: BackupFormFieldsType) => {
                    return fields.method === CcBackupMethod.MYSQL_BINLOG;
                },
            };
        case CcClusterType.TYPE_MSSQL_SINGLE:
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return {
                storageLocation: () => true,
            };
        case CcClusterType.TYPE_ELASTIC:
            return {
                method: () => true,
            };
        default:
            return {};
    }
}

export function getBackupStepAvailableConfig(step: BackupFormWizardStep) {
    switch (step) {
        case BackupFormWizardStep.ADVANCED:
            return (fields: BackupFormFieldsType) => {
                return (
                    fields.method !== CcBackupMethod.MYSQL_BINLOG
                );
            };
        case BackupFormWizardStep.STORAGE:
            return (fields: BackupFormFieldsType) => {
                return ![
                    CcBackupMethod.MYSQL_BINLOG,
                    CcBackupMethod.MONGODB_PERCONA,
                    ...PG_BACKREST_BACKUP_METHODS,
                    ...getBackupMethodsByClusterType(
                        CcClusterType.TYPE_ELASTIC
                    ),
                ].includes(fields.method as CcBackupMethod);
            };
        case BackupFormWizardStep.CLOUD:
            return (fields: BackupFormFieldsType) => {
                return (
                    fields.method === CcBackupMethod.MYSQL_BINLOG ||
                    (!!fields.cloudUpload &&
                        BackupAvailableFieldsConfig.cloudUpload(fields))
                );
            };
        case BackupFormWizardStep.VERIFY:
            return (fields: BackupFormFieldsType) => {
                return (
                    !!fields.verifyBackup &&
                    BackupAvailableFieldsConfig.verifyBackup(fields)
                );
            };
        default:
            return () => false;
    }
}

export function isPostgresBackupMethodPITRAvailable(
    cluster: CcCluster,
    method: CcBackupMethod
) {
    if ([CcBackupMethod.PGDUMP, CcBackupMethod.PGDUMPALL].includes(method)) {
        return false;
    }
    const node = cluster.primaryNode as CcPostgreSqlNode;
    if (
        ![
            CcPostgreSqlNodeArchiveMode.ON,
            CcPostgreSqlNodeArchiveMode.ALWAYS,
        ].includes(node?.archiveMode as CcPostgreSqlNodeArchiveMode)
    ) {
        return false;
    }
    if (isBackupMethodPgBackRest(method)) {
        if (!node.isPitrMethodPGBackRest()) {
            return false;
        }
    } else if (!node.isPitrMethodLocalWalArchive()) {
        return false;
    }
    return true;
}
