import CcCluster, {
    CcClusterTechnology,
} from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import { Alert, Col, Form, Input, Row, Space } from 'antd';
import InstallSoftwareSwitch from '../../../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableFirewallField from '../../../../../common/Form/Fields/DisableFirewallField';
import DisableSelinuxField from '../../../../../common/Form/Fields/DisableSelinuxField';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import React from 'react';
import { BackupRestoreWizardFormValues } from '../BackupRestoreWizardForm';
import { isBackupMethodXtraBackup } from '../../../../../services/models/CcBackup';
import BackupRestoreUseMemoryField from './BackupRestoreUseMemoryField';
import HostCheckInputField from '../../../../../common/DataEntry/HostCheckInputField';
import BackupRestoreMySqlPITRForm from './BackupRestoreMySqlPITRForm';

export default BackupRestoreVerifySettingsForm;
type BackupRestoreVerifySettingsForm = {
    cluster: CcCluster;
    form: FormInstance;
};

function BackupRestoreVerifySettingsForm({
    cluster,
    form,
}: BackupRestoreVerifySettingsForm) {
    const { backup }: BackupRestoreWizardFormValues = form.getFieldsValue(true);
    const method = backup?.getMethod();
    const isXtraBackup = method && isBackupMethodXtraBackup(method);

    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <HostCheckInputField
                        validateTrigger={false}
                        label={<span>Restore backup on</span>}
                        form={form}
                        cluster={cluster}
                        name="restoreHost"
                        rules={[
                            {
                                required: true,
                                message: 'Please add host to restore backup on',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="tmpDirectory"
                        label={<span>Temporary directory</span>}
                    >
                        <Input placeholder="Enter temporary directory" />
                    </Form.Item>
                </Col>

                {isXtraBackup && (
                    <Col xs={24} sm={24} md={12}>
                        <BackupRestoreUseMemoryField />
                    </Col>
                )}
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableFirewallField />
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <DisableSelinuxField />
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name="shutdownAfterRestore"
                        label={
                            <span>
                                Shutdown the server after the backup is restored
                            </span>
                        }
                        valuePropName="checked"
                    />
                </Col>
            </Row>
            <Form.Item noStyle={true} shouldUpdate={true}>
                {() => {
                    const shutdownAfterRestore = form.getFieldValue(
                        'shutdownAfterRestore'
                    );
                    return (
                        <Row>
                            <Alert
                                showIcon={true}
                                type={shutdownAfterRestore ? 'warning' : 'info'}
                                description={
                                    shutdownAfterRestore ? (
                                        <span>
                                            The Backup Verification Server will
                                            be terminated when the Verify Backup
                                            job finishes and the datadir and
                                            configuration files will be removed.
                                            Thus, "Install Software" must be
                                            specified for the next Verify Backup
                                            job to set up the datadir and
                                            configuration files.
                                        </span>
                                    ) : (
                                        <Space direction="vertical" size={0}>
                                            <span>
                                                1. The Backup Verification
                                                Server will not be terminated
                                                after restore.
                                            </span>
                                            <span>
                                                2. The Backup Verification
                                                Server may be reused by
                                                subsequent Backup Verifications
                                                [Recommended]
                                            </span>
                                        </Space>
                                    )
                                }
                            />
                        </Row>
                    );
                }}
            </Form.Item>
        </>
    );
}
