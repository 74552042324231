import React, { useState } from 'react';
import { ModalDefaultProps } from '../../common/ModalDefault';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import { Form, Row, Col, Select, InputNumber } from 'antd';
import ClusterSelectDropdown from '../Clusters/ClusterSelectDropdown';
import FormFooter from '../../common/FormFooter';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import CcReport from '../../services/models/CcReport';

export default OperationalReportCreateReportModal;

export type OperationalReportCreateReportModalProps = {
    templates?: CcReport[];
    onCancel?: () => void;
    onSuccess?: () => void;
} & Omit<ModalDefaultProps, 'children'>;

type OperationalReportCreateReportFormFields = {
    type: string;
    clusterId: number;
    days: number;
    recipients: string;
};
const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

export async function emailValidator(rule: any, value: string[]) {
    value?.map((email: string) => {
        if (!email || regex.test(email) === false) {
            throw new Error(`Invalid "${email}" email address!`);
        }
        return true;
    });
    return true;
}

function OperationalReportCreateReportModal({
    templates,
    onCancel,
    onSuccess,
}: OperationalReportCreateReportModalProps) {
    const [form] = useForm<OperationalReportCreateReportFormFields>();
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();

    const handleFormFinish = async ({
        clusterId,
        type,
        recipients,
        days,
    }: OperationalReportCreateReportFormFields) => {
        setLoading(true);
        try {
            await CmonReportsService.generateReport({
                cluster_id: clusterId,
                report: {
                    report_type: type,
                    recipients: recipients,
                    days: `${days}`,
                },
            });
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Report successfully created',
            });
            onSuccess?.();
        } catch (err) {
            setLoading(false);
            notifyError({ content: err.message });
            log.error(err);
        }
    };

    const handleClusterSelect = () => {};

    return (
        <ModalDefaultForm
            title="Generate new report"
            form={form}
            footer={[]}
            width={450}
            defaultVisible={true}
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleFormFinish}
                layout="vertical"
                initialValues={{
                    days: 7,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} key="report">
                        <Form.Item
                            name="clusterId"
                            label="Cluster"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select cluster!',
                                },
                            ]}
                        >
                            <ClusterSelectDropdown
                                placeholder="Select cluster"
                                onClusterSelect={handleClusterSelect}
                                showId={true}
                                clusterNameMaxWidth={270}
                            />
                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select report type!',
                                },
                            ]}
                        >
                            <Select
                                data-testid="report-type-select"
                                placeholder="Select report type"
                                loading={loading}
                                options={templates?.map((record: CcReport) => ({
                                    value: record.reportType,
                                    label: record.title,
                                }))}
                            />
                        </Form.Item>

                        <Form.Item
                            name="days"
                            label="Data range"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select data range!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={1}
                                style={{ width: '100%' }}
                                placeholder="Enter data range"
                                addonBefore={'Days'}
                            />
                        </Form.Item>

                        <Form.Item
                            name="recipients"
                            label="Recipients"
                            rules={[
                                {
                                    validator: emailValidator,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Enter email recipients"
                                data-testid="report-recipients-select"
                                loading={loading}
                                mode="tags"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormFooter
                    loading={loading}
                    submitButtonText="Create"
                    showSubmitButton={true}
                    showCancelButton={true}
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
