const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: [],
    cluster_auto_recovery: true,
    cluster_id: 1148,
    cluster_name: 'Redis Sentinel with IP in host',
    cluster_type: 'REDIS',
    configuration_file: '/etc/cmon.d/cmon_1148.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_1148.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: true,
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'redis',
    version: '7.2',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 1148,
        created_after: null,
        critical: 1,
        reported_after: null,
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2023-01-01T20:53:59.140Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/redis.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 9620,
            hostname: 'ulc2',
            hostname_data: 'ulc2',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.59',
            lastseen: 1716301174,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 65,
            port: 6379,
            readonly: true,
            role: 'slave',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4399,
            uptime: 7046,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_master: {
                slave_info: {
                    slaves: [],
                },
            },
            replication_slave: {
                linkstatus: 1,
                master_host: '10.8.9.230',
                master_port: '6379',
                seconds_behind_master: '0',
                status: 'up',
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/redis.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 9621,
            hostname: 'ulc3',
            hostname_data: 'ulc3',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.230',
            lastseen: 1716301174,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 51,
            port: 6379,
            readonly: false,
            role: 'master',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4400,
            uptime: 7042,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_master: {
                slave_info: {
                    slaves: [
                        {
                            ip: '10.8.9.78',
                            lag: 0,
                            offset: 1439291,
                            port: 6379,
                            state: 'online',
                        },
                        {
                            ip: '10.8.9.59',
                            lag: 0,
                            offset: 1439291,
                            port: 6379,
                            state: 'online',
                        },
                    ],
                },
            },
            replication_slave: {
                linkstatus: 1,
                master_host: '10.8.9.59',
                master_port: '6379',
                seconds_behind_master: '1',
                status: 'up',
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonRedisHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/redis.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: '',
            hostId: 9622,
            hostname: 'ulc4',
            hostname_data: 'ulc4',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.78',
            lastseen: 1716301174,
            logfile: '/var/log/redis/redis-server.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'redis',
            pidfile: '/run/redis/redis-server.pid',
            pingstatus: 1,
            pingtime: 62,
            port: 6379,
            readonly: true,
            role: 'slave',
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4401,
            uptime: 7038,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            redis: {
                appendlogname: 'appendonly.aof',
                appendonly: true,
                dbfilename: 'dump.rdb',
            },
            replication_slave: {
                linkstatus: 1,
                master_host: '10.8.9.230',
                master_port: '6379',
                seconds_behind_master: '0',
                status: 'up',
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/sentinel.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 9620,
            hostname: 'ulc2',
            hostname_data: 'ulc2',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.59',
            lastseen: 1716301174,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 65,
            port: 26379,
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4402,
            uptime: 7036,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_1148',
                        address: '10.8.9.230:6379',
                        id: 'master0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'ok',
                    },
                ],
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/sentinel.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 9621,
            hostname: 'ulc3',
            hostname_data: 'ulc3',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.230',
            lastseen: 1716301174,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 51,
            port: 26379,
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4403,
            uptime: 7033,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_1148',
                        address: '10.8.9.230:6379',
                        id: 'master0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'ok',
                    },
                ],
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonRedisSentinelHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            clusterid: 1148,
            configfile: '/etc/redis/sentinel.conf',
            container: true,
            datadir: '/var/lib/redis',
            description: 'All is fine.',
            hostId: 9622,
            hostname: 'ulc4',
            hostname_data: 'ulc4',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            ip: '10.8.9.78',
            lastseen: 1716301174,
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'sentinel',
            pingstatus: 1,
            pingtime: 62,
            port: 26379,
            sshfailcount: 0,
            statereason: 'Up and running.',
            status: 10,
            timestamp: 1716301182,
            unique_id: 4404,
            uptime: 7030,
            version: '7.2.4',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'focal',
                release: '20.04',
                type: 'debian',
            },
            sentinel: {
                master_info: [
                    {
                        name: 'cluster_1148',
                        address: '10.8.9.230:6379',
                        id: 'master0',
                        sentinels: 3,
                        slaves: 2,
                        status: 'ok',
                    },
                ],
            },
            ssl_certs: {
                server: {
                    ssl_enabled: true,
                },
            },
        },
        {
            class_name: 'CmonHost',
            cdt_path: '/ulc234',
            owner_user_id: 6,
            owner_user_name: 'ccalvaro',
            owner_group_id: 1,
            owner_group_name: 'admins',
            acl: 'user::rwx,group::rwx,other::---',
            aux_port: -1,
            clusterid: 1148,
            configfile: '/etc/cmon.d/cmon_1148.cnf',
            connected: true,
            container: false,
            hostId: 9626,
            hostname: '192.168.1.38',
            hoststatus: 'CmonHostOnline',
            ip: '192.168.1.38',
            lastseen: 1716301174,
            logfile: '/var/log/cmon_1148.log',
            maintenance_mode: 'none',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            pid: 1970568,
            pingstatus: -1,
            pingtime: -1,
            port: 9500,
            role: 'controller',
            status: 10,
            timestamp: 1716301175,
            unique_id: 4405,
            uptime: 3356,
            version: '2.0.1.None',
            wallclock: 1716301175,
            wallclocktimestamp: 1716301175,
            distribution: {
                name: 'ubuntu',
                codename: 'kinetic',
                release: '22.10',
                type: 'debian',
            },
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 1148,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 2,
            FINISHED: 6,
            RUNNING: 0,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 6,
        owner_user_name: 'ccalvaro',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2023-01-01T21:40:13.003Z',
        disabled: false,
        email_address: 'alvaro@severalnines.com',
        last_failed_login: '',
        last_login: '2024-05-21T12:40:16.707Z',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 6,
        user_name: 'ccalvaro',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2023-01-01T20:53:59.140Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Central European Time',
            abbreviation: 'CET',
            offset: -3600,
            use_dst: false,
        },
    },
};
export default mock;
