import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import React, { ReactElement, useState } from 'react';
import CcCluster, {
    CcClusterTechnology,
    CcClusterType,
} from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import CcBackup, {
    CcBackupMethod,
    isBackupMethodPgBackRest,
    isBackupMethodPgBaseBackup,
} from '../../../../services/models/CcBackup';
import BackupRestoreWizardSummary from './BackupRestoreWizardSummary';
import CcBackupStorageLocation from '../../../../services/models/CcBackupStorageLocation';
import BackupRestoreSettingsForm from './FormParts/BackupRestoreSettingsForm';
import BackupRestoreVerifySettingsForm from './FormParts/BackupRestoreVerifySettingsForm';
import BackupRestoreConfigurationForm from './FormParts/BackupRestoreConfigurationForm';
import BackupRestoreTypeForm from './FormParts/BackupRestoreTypeForm';
import moment from 'moment';
import BackupRestoreTypeElasticForm from './FormParts/BackupRestoreTypeElasticForm';

export default BackupRestoreWizardForm;

export enum BackupRestoreType {
    NODE = 'NODE',
    SIMPLE_VERIFICATION = 'SIMPLE_VERIFICATION',
    STANDALONE_HOST = 'STANDALONE_HOST',
    CLUSTER = 'CLUSTER',
}

export enum BackupRestorePITRType {
    TIME = 'time',
    POSITION = 'position',
}

enum BackupRestoreWizardFormSteps {
    TYPE = 'type',
    CONFIGURATION = 'configuration',
    SETTINGS = 'settings',
}

export type BackupRestoreWizardFormValues = {
    // Config
    backup?: CcBackup;
    backupLocation?: string;
    restoreType?: BackupRestoreType;

    // PITR
    pitr?: boolean;
    pitrType?: BackupRestorePITRType;
    pitrTime?: string;
    pitrBinLogName?: string;
    pitrLogStopPosition?: string;

    // Settings common
    tmpDirectory?: string;
    downloadDirectory?: string;

    // Settings mysql
    databases?: string[];
    restoreSystemDatabase?: boolean;
    xtraUseMemory?: number;

    // Settings restore on node
    node?: string;
    bootstrapCluster?: boolean;
    copyDataDir?: boolean;

    // Settings restore on standalone host
    restoreHost?: string;
    installSoftware?: boolean;
    disableFirewall?: boolean;
    disableSeLinux?: boolean;
    shutdownAfterRestore?: boolean;
    immediate?: boolean;
};

type BackupRestoreWizardFormProps = {
    cluster: CcCluster;

    form: FormInstance<BackupRestoreWizardFormValues>;
    backup?: CcBackup;
    onSubmit?: (values: BackupRestoreWizardFormValues) => Promise<void>;
    onCancel?: () => void;
    loading?: boolean;
};

function BackupRestoreWizardForm({
    cluster,
    backup,
    form,
    onSubmit,
    onCancel,
    loading = false,
}: BackupRestoreWizardFormProps) {
    const [restoreType, setRestoreType] = useState<BackupRestoreType>(
        BackupRestoreType.NODE
    );
    const handleValuesChange = (values: any) => {
        if (values.restoreType) {
            setRestoreType(values.restoreType);
        }
    };

    const handleSubmit = async () => {
        await onSubmit?.(form.getFieldsValue(true));
    };

    const isMongo = cluster.isTechnology(
        CcClusterTechnology.TECHNOLOGY_MONGODB
    );
    const isMssql = cluster.isTechnology(
        CcClusterTechnology.TECHNOLOGY_MICROSOFT
    );
    const isElastic = cluster.isTechnology(
        CcClusterTechnology.TECHNOLOGY_ELASTIC
    );
    const isRedisSharded = cluster.isType(CcClusterType.TYPE_REDIS_SHARDED);
    const isVerificationAvailable = !isMongo && !isMssql && !isRedisSharded;

    const configurationStep = (
        <WizardFormConfiguration.Step
            key={BackupRestoreWizardFormSteps.CONFIGURATION}
            title="Configuration"
            subTitle=" "
            validate={['backup', 'backupLocation', 'pitrTime']}
            hasRequiredFields={true}
        >
            <BackupRestoreConfigurationForm
                cluster={cluster}
                backup={backup}
                form={form}
            />
        </WizardFormConfiguration.Step>
    );

    const typeStep = (
        <WizardFormConfiguration.Step
            key={BackupRestoreWizardFormSteps.TYPE}
            title="How to restore"
            subTitle=" "
            validate={['restoreType']}
            hasRequiredFields={true}
        >
            <BackupRestoreTypeForm cluster={cluster} form={form} />
        </WizardFormConfiguration.Step>
    );
    const typeElasticStep = (
        <WizardFormConfiguration.Step
            key={BackupRestoreWizardFormSteps.TYPE}
            title="How to restore"
            subTitle=" "
            validate={['restoreType']}
            hasRequiredFields={true}
        >
            <BackupRestoreTypeElasticForm cluster={cluster} form={form} />
        </WizardFormConfiguration.Step>
    );

    const nodeSettingsStep =
        restoreType === BackupRestoreType.NODE ? (
            <WizardFormConfiguration.Step
                key={BackupRestoreWizardFormSteps.SETTINGS}
                title="Settings"
                subTitle=" "
                validate={[
                    'pitrTime',
                    'pitrBinLogName',
                    'pitrLogStopPosition',
                    'downloadDirectory',
                ]}
                hasRequiredFields={true}
            >
                <BackupRestoreSettingsForm
                    cluster={cluster}
                    form={form}
                    verificationAvailable={isVerificationAvailable}
                />
            </WizardFormConfiguration.Step>
        ) : undefined;

    const standaloneHostSettingsStep =
        restoreType === BackupRestoreType.STANDALONE_HOST ? (
            <WizardFormConfiguration.Step
                key={BackupRestoreWizardFormSteps.SETTINGS}
                title="Settings"
                subTitle=" "
                validate={['restoreHost', 'restoreHostTopologyItems']}
                hasRequiredFields={true}
            >
                <BackupRestoreVerifySettingsForm
                    cluster={cluster}
                    form={form}
                />
            </WizardFormConfiguration.Step>
        ) : undefined;

    const summaryStep = (
        <WizardFormConfiguration.Step
            key={BackupRestoreWizardFormSteps.SETTINGS}
            title="Summary"
            subTitle=" "
            validate={[]}
            hasRequiredFields={true}
        >
            <BackupRestoreWizardSummary form={form} />
        </WizardFormConfiguration.Step>
    );
    let steps = !isVerificationAvailable
        ? [configurationStep, nodeSettingsStep, summaryStep]
        : [
              configurationStep,
              typeStep,
              nodeSettingsStep,
              standaloneHostSettingsStep,
              summaryStep,
          ];

    if (isMssql || isMongo) {
        steps = [configurationStep];
    } else if (isElastic) {
        steps = [configurationStep, typeElasticStep, summaryStep];
    }

    steps = steps.filter(Boolean) as ReactElement[];

    return (
        <WizardFormConfiguration
            form={form}
            loading={loading}
            onValuesChange={handleValuesChange}
            steps={steps as ReactElement[]}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            showCancelButton={true}
            cancelButtonText="Cancel"
            initialValues={{
                backup,
                restoreType: BackupRestoreType.NODE,
                pitrType: 'time',
                copyDataDir: false,
                bootstrapCluster: true,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
                xtraUseMemory: 100,
                downloadDirectory: '',
                immediate: true,
            }}
        />
    );
}

export function getRestoreBackupFormJobData(
    fieldValues: BackupRestoreWizardFormValues
) {
    const backup = fieldValues.backup;
    const backupLocation: CcBackupStorageLocation =
        (fieldValues.backupLocation &&
            backup?.getLocation(
                fieldValues.backupLocation
            )) as CcBackupStorageLocation;

    let pitrTime = undefined;
    if (fieldValues.pitrTime) {
        pitrTime = moment(fieldValues.pitrTime)
            .tz('UTC')
            .format('YYYY-MM-DD HH:mm:ss');
    }
    let pitr = {};
    if (fieldValues.pitr) {
        pitr = {
            pitr_stop_log: fieldValues.pitrBinLogName,
            pitr_stop_pos: fieldValues.pitrLogStopPosition,
            pitr_stop_time: pitrTime,
        };
    }

    let location = {};

    if (backupLocation?.isTypeCloud()) {
        location = {
            cloud_backup_download: {
                backupdir: fieldValues.downloadDirectory,
                cloud_location_uuid: backupLocation.getUuid(),
                storage_host: backup?.getBackupHost(),
            },
        };
    } else if (backupLocation?.isTypeHost()) {
        location = {
            host_location_uuid: backupLocation.getUuid(),
        };
    }

    let methodBased = {};
    const method = fieldValues.backup?.getMethod();
    switch (method) {
        case CcBackupMethod.MONGODB_CONSISTENT:
        case CcBackupMethod.MONGODUMP:
        case CcBackupMethod.MONGODB_PERCONA:
            methodBased = {
                backup_datadir_before_restore: true,
            };
            break;
        case CcBackupMethod.PGDUMP:
            break;
        case CcBackupMethod.PGBASEBACKUP:
        case CcBackupMethod.PGBACKREST_DIFF:
        case CcBackupMethod.PGBACKREST_FULL:
        case CcBackupMethod.PGBACKREST_INC:
            methodBased = {
                bootstrap: fieldValues.bootstrapCluster,
            };
            pitr = {};
            if (fieldValues.pitr) {
                pitr = {
                    pitr_stop_time: pitrTime,
                };
            }
            break;
        case CcBackupMethod.MYSQLDUMP:
            methodBased = {
                database: fieldValues.databases?.join(','),
                pitr_compatible: undefined, // @todo check external backup
                restore_system_db: fieldValues.restoreSystemDatabase,
                restore_tmpdir: fieldValues.tmpDirectory,
            };
            break;
        case CcBackupMethod.XTRABACKUP_FULL:
        case CcBackupMethod.XTRABACKUP_INCR:
            methodBased = {
                backup_datadir_before_restore: fieldValues.copyDataDir,
                xtrabackup_use_memory: fieldValues.xtraUseMemory,
                bootstrap: fieldValues.bootstrapCluster,
                restore_tmpdir: fieldValues.tmpDirectory,
            };
            break;
        case CcBackupMethod.MARIABACKUP_FULL:
        case CcBackupMethod.MARIABACKUP_INCR:
            methodBased = {
                backup_datadir_before_restore: fieldValues.copyDataDir,
                bootstrap: fieldValues.bootstrapCluster,
                restore_tmpdir: fieldValues.tmpDirectory,
            };
            break;
        default:
            break;
    }
    let immediate = {};
    if (
        isBackupMethodPgBackRest(method) ||
        isBackupMethodPgBaseBackup(method)
    ) {
        immediate = {
            psql_immediate: fieldValues.immediate,
        };
    }
    return {
        backupid: fieldValues.backup?.getId(),
        server_address: fieldValues.node,
        ...methodBased,
        ...location,
        ...pitr,
        ...immediate,
    };
}

export function getVerifyOnStandAloneNodeBackupFormJobData(
    fieldValues: BackupRestoreWizardFormValues
) {
    let pitr = {};
    if (fieldValues.pitr) {
        pitr = {
            pitr_stop_log: fieldValues.pitrBinLogName,
            pitr_stop_pos: fieldValues.pitrLogStopPosition,
            pitr_stop_time: moment(fieldValues.pitrTime)
                .tz('UTC')
                .format('YYYY-MM-DD HH:mm:ss'),
        };
    }
    return {
        backupid: fieldValues.backup?.getId(),
        server_address: fieldValues.restoreHost,
        terminate_db_server: fieldValues.shutdownAfterRestore,
        disable_firewall: fieldValues.disableFirewall,
        disable_selinux: fieldValues.disableSeLinux,
        install_software: fieldValues.installSoftware,
        xtrabackup_use_memory: 100,
        restore_tmpdir: fieldValues.tmpDirectory,
        ...pitr,
    };
}
