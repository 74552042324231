import FormItemInline from '../../../../common/FormItemInline';
import React from 'react';
import Switch, { SwitchProps } from '../../../../common/DataEntry/Switch';
export default NodeAutoRecovery;
export type AutoRecoveryProps = {
    clusterAutoRecoverySwitchProps?: SwitchProps;
    nodeAutoRecoverySwitchProps?: SwitchProps;
};
function NodeAutoRecovery({
    clusterAutoRecoverySwitchProps,
    nodeAutoRecoverySwitchProps,
}: AutoRecoveryProps) {
    return (
        <>
            <FormItemInline
                justify={true}
                name={['nodeConfig', 'clusterAutoRecovery']}
                label="Cluster auto-recovery"
                valuePropName="checked"
                noMargin
            >
                <Switch {...clusterAutoRecoverySwitchProps} />
            </FormItemInline>
            <FormItemInline
                justify={true}
                name={['nodeConfig', 'nodeAutoRecovery']}
                label="Node auto-recovery"
                valuePropName="checked"
                noMargin
            >
                <Switch {...nodeAutoRecoverySwitchProps} />
            </FormItemInline>
        </>
    );
}
