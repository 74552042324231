import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import NodeUnregisterForm from './NodeUnregisterForm';
import JobActionButton from '../../Jobs/JobActionButton';
import { CcCluster } from '../../../services/models';

export default NodeUnregisterButton;

export type NodeUnregisterButtonProps = ButtonProps & {
    node: CcNode;
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeUnregisterButton({
    node,
    cluster,
    children,
    onSuccess,
    ...rest
}: NodeUnregisterButtonProps) {
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.REMOVENODE}
                    clusterId={node.clusterid}
                    critical={true}
                    extraKey={[node.hostname, node.port]}
                    {...rest}
                >
                    {children || 'Remove node'}
                </JobActionButton>
            }
            form={
                <NodeUnregisterForm
                    node={node}
                    onSuccess={onSuccess}
                    cluster={cluster}
                />
            }
        />
    );
}
