const mock = {
    class_name: 'CmonClusterInfo',
    cdt_path: '/',
    acl: 'user::rwx,group::rwx,other::---',
    tags: ['postgresql', 'development'],
    cluster_auto_recovery: false,
    cluster_id: 3,
    cluster_name: 'PostgreSQL 12 single node',
    cluster_type: 'POSTGRESQL_SINGLE',
    configuration_file: '/etc/cmon.d/cmon_3.cnf',
    effective_privileges: 'rwx',
    log_file: '/var/log/cmon_3.log',
    maintenance_mode_active: false,
    managed: true,
    node_auto_recovery: false,
    rpc_version: '2.0',
    state: 'STARTED',
    status_text: 'All nodes are operational.',
    vendor: 'postgres',
    version: '12',
    alarm_statistics: {
        class_name: 'CmonAlarmStatistics',
        cluster_id: 3,
        created_after: '1970-01-01T00:00:00.000Z',
        critical: 0,
        reported_after: '1970-01-01T00:00:00.000Z',
        warning: 0,
    },
    group_owner: {
        class_name: 'CmonGroup',
        cdt_path: '/groups',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::rwx,other::---',
        created: '2021-04-28T22:57:55.328Z',
        group_id: 1,
        group_name: 'admins',
    },
    hosts: [
        {
            acl: 'user::rwx,group::rwx,other::---',
            application_name: 'pgsql_0_node_0',
            archive_command: '',
            archive_mode: 'off',
            cdt_path: '/PG_SLAVE',
            class_name: 'CmonPostgreSqlHost',
            clusterid: 493,
            configfile: ['/etc/postgresql/11/main/postgresql.conf'],
            connected: true,
            connected_slaves: 0,
            container: false,
            data_directory: '/var/lib/postgresql/11/main',
            datadir: '/var/lib/postgresql/11/main',
            description: '',
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            extensions: [
                { name: 'plpgsql', version: '1.0' },
                { name: 'pg_stat_statements', version: '1.6' },
            ],
            hostId: 4957,
            hostname: '10.10.10.18',
            hostname_data: '10.10.10.18',
            hostname_internal: '',
            hoststatus: 'CmonHostOnline',
            hot_standby: true,
            ip: '10.10.10.18',
            lastseen: 1571661365,
            logfile:
                '/var/lib/postgresql/11/main/log/postgresql-2019-10-21_000000.log',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'postgres',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pgname: 'main',
            pid: 3926,
            pidfile: '/var/run/postgresql/11-main.pid',
            pingstatus: 1,
            pingtime: 158,
            port: 5432,
            readonly: true,
            received_location: '0/70E67A8',
            replication_slave: {
                master_cluster_id: 481,
                master_host: '10.10.10.16',
                master_port: '5432',
                replay_location: '0/70E67A8',
                seconds_behind_master: '0',
            },
            replication_state: 'streaming',
            role: 'master',
            service_started: 1571202763,
            socket: '/var/run/postgresql/.s.PGSQL.5432',
            sshfailcount: 0,
            ssl_certs: {
                server: {
                    ca: '',
                    id: 0,
                    key: '/var/lib/postgresql/11/main/server.key',
                    path: '/var/lib/postgresql/11/main/server.crt',
                    ssl_enabled: false,
                },
            },
            timestamp: 1571661369,
            trigger_file: '/tmp/failover_5432.trigger',
            unique_id: 1054,
            uptime: 458599,
            version: '11.5 (Ubuntu 11.5-3.pgdg16.04+1)',
            wallclock: 1571661369,
            wallclocktimestamp: 1571661369,
        },
        {
            acl: 'user::rwx,group::rwx,other::---',
            cdt_path: '/PG_SLAVE',
            class_name: 'CmonHost',
            clusterid: 493,
            configfile: '/etc/cmon.d/cmon_493.cnf',
            connected: true,
            container: false,
            distribution: {
                codename: 'xenial',
                name: 'ubuntu',
                release: '16.04',
                type: 'debian',
            },
            hostId: 4960,
            hostname: '10.0.1.13',
            hoststatus: 'CmonHostOnline',
            ip: '10.0.1.13',
            lastseen: 1571661364,
            logfile: '/var/log/cmon_493.log',
            maintenance_mode_active: false,
            message: 'Up and running.',
            nodetype: 'controller',
            owner_group_id: 1,
            owner_group_name: 'admins',
            owner_user_id: 1,
            owner_user_name: 'system',
            pid: 32496,
            pingstatus: 1,
            pingtime: 222,
            port: 9500,
            role: 'controller',
            timestamp: 1571661368,
            unique_id: 1057,
            uptime: 754,
            version: '1.7.4',
            wallclock: 1571661368,
            wallclocktimestamp: 1571661368,
        },
    ],
    job_statistics: {
        class_name: 'CmonJobStatistics',
        cluster_id: 3,
        by_state: {
            ABORTED: 0,
            DEFINED: 0,
            DEQUEUED: 0,
            FAILED: 1,
            FINISHED: 0,
            PAUSED: 1,
            RUNNING: 0,
            SCHEDULED: 1,
        },
    },
    owner: {
        class_name: 'CmonUser',
        cdt_path: '/',
        acl: 'user::rwx,group::r--,other::r--',
        disabled: false,
        suspended: false,
        groups: [],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Coordinated Universal Time',
            abbreviation: 'UTC',
            offset: 0,
            use_dst: false,
        },
    },
};

export default mock;
