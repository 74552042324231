import CcTreeItem from '../../../../services/models/CcTreeItem';

const result = {
    cdt: new CcTreeItem({
        class_name: 'CmonTreeItem',
        owner_user_id: 1,
        owner_user_name: 'system',
        owner_group_id: 1,
        owner_group_name: 'admins',
        effective_privileges: 'rwx',
        item_acl: 'user::rwx,group::rwx,other::rwx',
        item_name: '',
        item_path: '/',
        item_type: 'Folder',
        sub_items: [
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 1,
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:asdfss:r--,group:admins:rwx,group:lowpermission:rwx,other::---',
                item_name: 'cluster_1',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 2,
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:asdfss:r--,group:admins:rwx,group:lowpermission:r--,other::---',
                item_name: 'cluster_2',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 3,
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:asdfss:r--,group:admins:rwx,group:lowpermission:r--,other::---',
                item_name: 'cluster_2',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 4,
                effective_privileges: 'rwx',
                item_acl:
                    'user::rwx,group::rwx,group:lowpermission:---,other::---',
                item_name: 'cluster_4',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 5,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 6,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 7,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 8,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 9,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 10,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 11,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 12,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 13,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 14,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 15,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 16,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 17,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 5,
                owner_user_name: 'pablo',
                owner_group_id: 1,
                owner_group_name: 'admins',
                cluster_id: 18,
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::---',
                item_name: 'cluster_5',
                item_path: '/',
                item_type: 'Cluster',
            },
            {
                class_name: 'CmonTreeItem',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                effective_privileges: 'rwx',
                item_acl: 'user::rwx,group::rwx,other::r--',
                item_name: '.runtime',
                item_path: '/',
                item_type: 'Folder',
                sub_items: [
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'LDAP',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rw-,group::---,group:asdfss:rwx,group:admins:rwx,group:lowpermission:rwx,other::---',
                                item_name: 'configuration',
                                item_path: '/.runtime/LDAP',
                                item_type: 'File',
                                major_device_number: 120,
                                minor_devide_number: 4,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'jobs',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 2,
                                owner_group_name: 'users',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rwx,group::r-x,group:asdfss:rwx,group:admins:rwx,group:lowpermission:rwx,other::---',
                                item_name: 'jobExecutor',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                size: 0,
                            },
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 2,
                                owner_group_name: 'users',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rwx,group::r-x,group:admins:rwx,group:asdfss:rwx,group:lowpermission:rwx,other::---',
                                item_name: 'jobExecutorCreateCluster',
                                item_path: '/.runtime/jobs',
                                item_type: 'File',
                                size: 0,
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl: 'user::rwx,group::rwx,other::r--',
                        item_name: 'ui',
                        item_path: '/.runtime',
                        item_type: 'Folder',
                        sub_items: [
                            {
                                class_name: 'CmonTreeItem',
                                owner_user_id: 1,
                                owner_user_name: 'system',
                                owner_group_id: 1,
                                owner_group_name: 'admins',
                                effective_privileges: 'rwx',
                                item_acl:
                                    'user::rwx,group::rwx,group:asdfss:rwx,group:admins:rwx,group:lowpermission:rwx,other::---',
                                item_name: 'configuration',
                                item_path: '/.runtime/ui',
                                item_type: 'File',
                            },
                        ],
                    },
                    {
                        class_name: 'CmonTreeItem',
                        owner_user_id: 1,
                        owner_user_name: 'system',
                        owner_group_id: 1,
                        owner_group_name: 'admins',
                        effective_privileges: 'rwx',
                        item_acl:
                            'user::r--,group::---,group:asdfss:---,group:admins:rwx,group:lowpermission:---,other::---',
                        item_name: 'user_manager',
                        item_path: '/.runtime',
                        item_type: 'File',
                        major_device_number: 30,
                        minor_devide_number: 1,
                    },
                ],
            },
        ],
    }),
};

export default result;
