import React from 'react';
import {
    CcNodeRole,
    CcNodeType,
    getDatabaseNodeTypes,
} from '../../services/models/CcNode';
import {
    CodershipIcon,
    ControllerIcon,
    ElasticsearchIcon,
    HaProxyIcon,
    MongodbIcon,
    MsSQLIcon,
    MysqlIcon,
    PostgresqlIcon,
    PrometheusIcon,
    RedisIcon,
    ReplicationIcon,
    TimescaledbIcon,
} from '../../common/icons/icons';
import { getArrayFromEnum } from '../../common/utils/enums';
import { getVendorIcon } from '../Misc/VendorIcon';
import { Space } from 'antd';
import { toUpperCaseFirst } from '../../common/utils/string';
import { CcMongoNodeMemberRole } from '../../services/models/CcMongoNode';
import { CcElasticRole } from '../../services/models/CcElasticNode';
import {
    CcClusterType,
    CcClusterVendor,
} from '../../services/models/CcCluster';
import { getVendorText } from '../Clusters/ClusterTypeFormat';

export default NodeTypeFormat;

export function getNodeRoleText(
    role?: string,
    fallback?: string,
    clusterType?: CcClusterType
): string {
    switch (role) {
        case CcElasticRole.MASTER:
        case CcNodeRole.MASTER:
            if (clusterType === CcClusterType.TYPE_ELASTIC) {
                return 'Master';
            }
            return 'Primary';
        case CcNodeRole.PRIMARY:
        case CcMongoNodeMemberRole.PRIMARY:
            return 'Primary';
        case CcNodeRole.SLAVE:
        case CcNodeRole.REPLICA:
            return 'Replica';
        case CcNodeRole.SECONDARY:
        case CcMongoNodeMemberRole.SECONDARY:
            return 'Secondary';
        case CcNodeRole.MULTI:
            return 'Multi-Primary';
        case CcNodeRole.INTERMEDIATE:
            return 'Intermediate';
        case CcNodeRole.BVS:
            return 'Backup Verification Server';
        case CcNodeRole.MONGO_DATA_NODE:
            return `Data Node`;
        case CcNodeRole.MONGO_CONFIG_SERVER:
            return 'Config Server';
        case CcNodeRole.MONGO_MONGOS:
            return 'Mongos';
        case CcNodeRole.MONGO_ARBITER:
            return 'Arbiter';
        case CcElasticRole.MASTER_DATA:
            return 'Master-Data';
        case CcElasticRole.DATA:
            return 'Data';
        case CcElasticRole.MULTI_ROLE:
            return 'Multi role';
        case CcElasticRole.COORDINATOR:
            return 'Coordinator';
        case CcNodeRole.CONTROLLER:
            return 'Controller';
        case CcNodeRole.BACKUP_REPO:
            return 'Backup Repository';
        case CcNodeRole.RESOLVING:
            return 'Resolving';
        // @todo: review if we need to handle NONE
        // case CcNodeRole.NONE:
        //     return 'N/A';
        default:
            return fallback || (role && toUpperCaseFirst(role)) || 'Unknown';
    }
}

export function getNodeRoleInitial(role?: string, fallback?: string): string {
    switch (role) {
        case CcNodeRole.MASTER:
        case CcNodeRole.PRIMARY:
        case CcMongoNodeMemberRole.PRIMARY:
            return 'P';
        case CcNodeRole.SLAVE:
        case CcNodeRole.REPLICA:
            return 'R';
        case CcNodeRole.SECONDARY:
        case CcMongoNodeMemberRole.SECONDARY:
            return 'S';
        case CcNodeRole.MULTI:
            return 'MP';
        case CcNodeRole.INTERMEDIATE:
            return 'I';
        case CcNodeRole.BVS:
            return 'BVS';
        case CcNodeRole.MONGO_DATA_NODE:
            return `DN`;
        case CcNodeRole.MONGO_CONFIG_SERVER:
            return 'CS';
        case CcNodeRole.MONGO_MONGOS:
            return 'MO';
        case CcNodeRole.MONGO_ARBITER:
            return 'AR';
        case CcElasticRole.MASTER:
            return 'M';
        case CcElasticRole.MASTER_DATA:
            return 'MD';
        case CcElasticRole.DATA:
            return 'D';
        case CcElasticRole.MULTI_ROLE:
            return 'Mu';
        case CcNodeRole.RESOLVING:
            return 'Re';
        default:
            return role || fallback || '?';
    }
}

export function getNodeTypeText(
    type?: string,
    role?: string,
    vendor?: CcClusterVendor
): string {
    switch (type) {
        case CcNodeType.CONTROLLER:
            return 'Controller';
        case CcNodeType.MYSQL:
            return role
                ? getNodeRoleText(role, 'MySQL')
                : getVendorText(vendor) || 'MySQL';
        case CcNodeType.GALERA:
            return role
                ? getNodeRoleText(role, 'Galera')
                : vendor === CcClusterVendor.PERCONA
                  ? 'XtraDB'
                  : 'Galera';
        case CcNodeType.GROUP_REPLICATION:
            return 'Group Replication';
        case CcNodeType.POSTGRESQL:
            return role ? getNodeRoleText(role, 'PostgreSQL') : 'PostgreSQL';
        case CcNodeType.TIMESCALEDB:
            return role ? getNodeRoleText(role, 'TimescaleDb') : 'TimescaleDb';
        case CcNodeType.MONGO:
            return role ? getNodeRoleText(role, 'MongoDB') : 'MongoDB';
        case CcNodeType.ELASTIC:
            return role ? getNodeRoleText(role, 'Elastic') : 'Elastic';
        case CcNodeType.HAPROXY:
            return 'HAProxy';
        case CcNodeType.KEEPALIVED:
            return 'Keepalived';
        case CcNodeType.PROXYSQL:
            return 'ProxySQL';
        case CcNodeType.NDB:
            return 'MySQL NDB';
        case CcNodeType.NDB_MGMD:
            return 'MySQL NDB Management';
        case CcNodeType.GARBD:
            return 'Garbd';
        case CcNodeType.MEMCACHED:
            return 'Memcached';
        case CcNodeType.MAXSCALE:
            return 'MaxScale';
        case CcNodeType.PGBOUNCER:
            return 'PgBouncer';
        case CcNodeType.PROMETHEUS:
            return 'Prometheus';
        case CcNodeType.REDIS:
            return role ? getNodeRoleText(role, 'Redis') : 'Redis';
        case CcNodeType.REDIS_SENTINEL:
            return 'RedisSentinel';
        case CcNodeType.REDIS_SHARDED:
            return role
                ? getNodeRoleText(role, 'Redis Cluster')
                : 'Redis Cluster';
        case CcNodeType.MSSQL:
            return role ? getNodeRoleText(role, 'SQL Server') : 'SQL Server';
        case CcNodeType.PGBACKREST:
            return 'PgBackRest';
        case CcNodeType.PBM_AGENT:
            return 'Percona Backup MongoDB';
        case CcNodeType.CMON_AGENT:
            return 'CMON Agent';
        default:
            return 'Unknown';
    }
}

export function getNodeTypeInitial(type?: string, role?: string): string {
    switch (type) {
        case CcNodeType.CONTROLLER:
            return 'C';
        case CcNodeType.MYSQL:
            return role ? getNodeRoleInitial(role, 'MS') : 'MS';
        case CcNodeType.GALERA:
            return role ? getNodeRoleInitial(role, 'G') : 'G';
        case CcNodeType.GROUP_REPLICATION:
            return 'GRep';
        case CcNodeType.POSTGRESQL:
            return role ? getNodeRoleInitial(role, 'PG') : 'PG';
        case CcNodeType.TIMESCALEDB:
            return role ? getNodeRoleInitial(role, 'TS') : 'TS';
        case CcNodeType.MONGO:
            return role ? getNodeRoleInitial(role, 'Mo') : 'Mo';
        case CcNodeType.ELASTIC:
            return role ? getNodeRoleInitial(role, 'El') : 'El';
        case CcNodeType.HAPROXY:
            return 'HA';
        case CcNodeType.KEEPALIVED:
            return 'K';
        case CcNodeType.PROXYSQL:
            return 'PS';
        case CcNodeType.NDB:
            return 'NDB';
        case CcNodeType.NDB_MGMD:
            return 'NDBM';
        case CcNodeType.GARBD:
            return 'G';
        case CcNodeType.MEMCACHED:
            return 'Mem';
        case CcNodeType.MAXSCALE:
            return 'MS';
        case CcNodeType.PGBOUNCER:
            return 'PB';
        case CcNodeType.PROMETHEUS:
            return 'Pr';
        case CcNodeType.REDIS:
            return role ? getNodeRoleInitial(role, 'Re') : 'Re';
        case CcNodeType.REDIS_SENTINEL:
            return 'ReS';
        case CcNodeType.REDIS_SHARDED:
            return role ? getNodeRoleInitial(role, 'ReC') : 'ReC';
        case CcNodeType.MSSQL:
            return role ? getNodeRoleInitial(role, 'S') : 'S';
        case CcNodeType.PGBACKREST:
            return 'PbR';
        case CcNodeType.CMON_AGENT:
            return 'CA';
        default:
            return '?';
    }
}

export function getNodeTypeFormatIcon(
    type?: CcNodeType,
    vendor?: string,
    iconProps?: any
) {
    if (vendor && getDatabaseNodeTypes().includes(type as CcNodeType)) {
        const icon = getVendorIcon(vendor, iconProps);
        if (icon) {
            return icon;
        }
    }
    switch (type) {
        case CcNodeType.MYSQL:
            return <MysqlIcon {...iconProps} />;
        case CcNodeType.GALERA:
            return <CodershipIcon {...iconProps} />;
        case CcNodeType.POSTGRESQL:
        case CcNodeType.PGBOUNCER:
        case CcNodeType.PGBACKREST:
            return <PostgresqlIcon {...iconProps} />;
        case CcNodeType.TIMESCALEDB:
            return <TimescaledbIcon {...iconProps} />;
        case CcNodeType.MONGO:
            return <MongodbIcon {...iconProps} />;
        case CcNodeType.ELASTIC:
            return <ElasticsearchIcon {...iconProps} />;
        case CcNodeType.REDIS:
        case CcNodeType.REDIS_SENTINEL:
        case CcNodeType.REDIS_SHARDED:
            return <RedisIcon {...iconProps} />;
        case CcNodeType.CONTROLLER:
            return <ControllerIcon {...iconProps} />;
        case CcNodeType.PROMETHEUS:
            return <PrometheusIcon {...iconProps} />;
        case CcNodeType.HAPROXY:
            return <HaProxyIcon {...iconProps} />;
        case CcNodeType.MSSQL:
            return <MsSQLIcon {...iconProps} />;
        default:
            return <ReplicationIcon {...iconProps} />;
    }
}

export type NodeTypeFormatProps = {
    type?: CcNodeType;
    vendor?: string;
    showIcon?: boolean;
    showDesc?: boolean;
    iconProps?: any;
};

function NodeTypeFormat({
    type,
    vendor,
    showDesc = true,
    showIcon = false,
}: NodeTypeFormatProps) {
    return (
        <Space className="NodeTypeFormat">
            {showIcon ? getNodeTypeFormatIcon(type, vendor) : null}
            {showDesc ? getNodeTypeText(type, undefined, vendor) : null}
        </Space>
    );
}

export function getNodeTypesTextValue() {
    return (getArrayFromEnum(CcNodeType) as CcNodeType[]).map((s) => ({
        text: getNodeTypeText(s),
        value: s,
    }));
}

export function getNodeVendorText(vendor?: CcClusterVendor) {
    switch (vendor) {
        case CcClusterVendor.MARIADB:
            return 'MariaDB';
        case CcClusterVendor.PERCONA:
            return 'Percona';
        case CcClusterVendor.ORACLE:
            return 'MySQL';
        default:
            return '';
    }
}
