import React from 'react';
import CcCluster, {
    CcClusterType,
    CcClusterVendor,
} from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import { Col, Row } from 'antd';
import ClusterTypeFormat from '../components/Clusters/ClusterTypeFormat';
import galera from './dataMocks/clusters/galera';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import mongodb1 from './dataMocks/clusters/mongodb1';
import postgesWithLb from './dataMocks/clusters/postgresWithLb';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import redis6sentinel from './dataMocks/clusters/redis6Sentinel3Nodes';
import mssqlReplication from './dataMocks/clusters/mssqlReplication';
import elasticHA3master from './dataMocks/clusters/elasticHA3master';
import mongoShards from './dataMocks/clusters/mongoShards';
import redisSharded from './dataMocks/clusters/redisSharded3x3';

const clusters = []
    .concat([
        {
            ...mysqlReplication,
            cluster_type: CcClusterType.TYPE_MYSQL_SINGLE,
            vendor: CcClusterVendor.MARIADB,
        },
        {
            ...mysqlReplication,
            cluster_type: CcClusterType.TYPE_MYSQL_SINGLE,
            vendor: CcClusterVendor.PERCONA,
        },
        {
            ...mysqlReplication,
            cluster_type: CcClusterType.TYPE_MYSQL_SINGLE,
            vendor: CcClusterVendor.ORACLE,
        },
        {
            ...mysqlReplication,
            vendor: CcClusterVendor.MARIADB,
        },
        {
            ...mysqlReplication,
            vendor: CcClusterVendor.PERCONA,
        },
        {
            ...mysqlReplication,
            vendor: CcClusterVendor.ORACLE,
        },
        {
            ...galera,
            vendor: CcClusterVendor.MARIADB,
        },
        {
            ...galera,
            vendor: CcClusterVendor.PERCONA,
        },
        { ...mongodb1, vendor: CcClusterVendor.TEN_GEN },
        { ...mongodb1, vendor: CcClusterVendor.MONGODB_ENTERPRISE },
        mongodb1,
        { ...mongoShards, vendor: CcClusterVendor.TEN_GEN },
        { ...mongoShards, vendor: CcClusterVendor.MONGODB_ENTERPRISE },
        mongoShards,
        postgesWithLb,
        {
            ...postgresWithLb,
            cluster_name: 'Postgres with timescaledb',
            hosts: postgresWithLb.hosts.map((h) =>
                h.nodetype === 'postgres'
                    ? { ...h, extensions: [{ name: 'timescaledb' }] }
                    : h
            ) as any,
        },
        redis6sentinel,
        redisSharded,
        mssqlReplication,
        elasticHA3master,
    ])
    .map((c) => new CcCluster(c as any));

export default DebugClusterFormat;

export type DebugClusterFormatProps = {};

function DebugClusterFormat({}: DebugClusterFormatProps) {
    return (
        <Row className="DebugClusterFormat" gutter={[24, 24]}>
            <Col span={12}>
                <SpaceDescriptions
                    title="ClusterTypeFormat component"
                    direction={'vertical'}
                >
                    {clusters.map((c) => {
                        return (
                            <Row>
                                <Col span={6}>Type: {c.clusterType}</Col>
                                <Col span={6}>Vendor: {c.vendor}</Col>
                                <Col span={1}>{'==>'}</Col>
                                <Col span={6}>
                                    <ClusterTypeFormat
                                        key={c.clusterName}
                                        type={c.clusterType}
                                        vendor={c.vendor}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </SpaceDescriptions>
            </Col>
        </Row>
    );
}
