import React, { useCallback, useMemo, useEffect } from 'react';
import AppTable from '../../common/DataDisplay/AppTable';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import OperationalReportScheduleActionMenu from './OperationalReportScheduleActionMenu';
import CcScheduleReport from '../../services/models/CcScheduleReport';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import ClusterFormat from '../Clusters/ClusterFormat';
import CcReport from '../../services/models/CcReport';
import SpaceWide from '../../common/SpaceWide';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import useListFetch from '../../common/useListFetch';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import OperationalReportScheduleModal from './OperationalReportScheduleModal';
import TypographyText from '../../common/TypographyText';

export default OperationalReportSchedulesTable;

export type OperationalReportSchedulesTableProps = {
    templates?: CcReport[];
};

function OperationalReportSchedulesTable({
    templates,
}: OperationalReportSchedulesTableProps) {
    const handleOnRow = useCallback(
        (record, index: number) => ({
            'data-testid': `schedules-list-row-${index}`,
        }),
        []
    );

    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const { list, refresh, loading } = useListFetch({
        name: 'reports',
        fetchFn: async ({}, opts) => {
            const response = await CmonReportsService.listSchedules({});
            const schedules = response.data.map((sched: CcScheduleReport) => {
                if (sched.arguments) {
                    const args = sched.arguments.split(',');
                    args.forEach((arg: string, index: number) => {
                        if (arg.includes('=')) {
                            const val = arg.split('=');
                            if (val.length === 2 && val[0] === 'days') {
                                sched.days = val[1];
                            }
                        } else if (index === 0) {
                            sched.clusterId = parseInt(arg, 10);
                        }
                    });
                }
                return sched;
            });
            return {
                list: schedules,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonReportsService.cancelRequest(requestId);
        },
    });

    const columns = useMemo(
        () => [
            {
                title: 'Schedule',
                key: 'schedule',
                render: (record: CcScheduleReport) => (
                    <span>
                        <CronFormat>{record.schedule}</CronFormat>{' '}
                    </span>
                ),
            },
            {
                title: 'Report type',
                key: 'command',
                render: (record: CcScheduleReport) => {
                    return (
                        templates?.find(
                            (template: any) =>
                                template.reportType === record.command
                        )?.title || 'N/A'
                    );
                },
            },
            {
                title: 'Cluster',
                key: 'clusterId',
                render: (record: CcScheduleReport) => (
                    <ClusterFormat
                        cluster={clustersMap.get(`${record.clusterId}`)}
                        showPopover={true}
                    />
                ),
            },
            {
                title: 'Data range',
                key: 'arguments',
                render: (record: CcScheduleReport) => {
                    const days = record.arguments?.split('=')?.[1];
                    return (
                        <Space>
                            <span>{days}</span>
                            <TypographyText muted={true}>days</TypographyText>
                        </Space>
                    );
                    return record.arguments?.split('=')?.[1];
                },
            },
            {
                title: 'Recipients',
                key: 'recipients',
                render: (record: CcScheduleReport) => record.recipients,
            },
            {
                title: 'Actions',
                align: 'center',
                render: (record: CcScheduleReport) => {
                    return (
                        <OperationalReportScheduleActionMenu
                            schedule={record}
                            templates={templates}
                            onActionPerformed={handleOnRefresh}
                        />
                    );
                },
            },
        ],
        []
    );

    const handleOnRefresh = async () => {
        await refresh({});
    };

    useEffect(() => {
        (async () => {
            await handleOnRefresh();
        })();
    }, []);

    return (
        <SpaceWide direction="vertical">
            <SpaceWide direction="vertical" align="end">
                <ButtonWithForm
                    button={
                        <Button type="primary" icon={<PlusOutlined />}>
                            Create schedule
                        </Button>
                    }
                    form={
                        <OperationalReportScheduleModal
                            templates={templates}
                            onSuccess={handleOnRefresh}
                        />
                    }
                />
            </SpaceWide>
            <AppTable
                loading={loading}
                columns={columns}
                dataSource={list || []}
                onRow={handleOnRow}
                size="middle"
            />
        </SpaceWide>
    );
}
