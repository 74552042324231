import React, { useMemo } from 'react';
import ModalDefault from '../../../../common/ModalDefault';

import CcCluster from '../../../../services/models/CcCluster';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Form } from 'antd';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import ClusterConvertToShardConfigurationForm from './ClusterConvertToShardConfigurationForm';
import MongoShardsDeploymentConfigurator, {
    getConfigurationServersJobData,
    getRoutersMongosJobData,
} from '../../../Services/Cluster/Deploy/MongoShards/MongoShardsDeploymentConfigurator';
import TypographyText from '../../../../common/TypographyText';
import { TopologyItem } from '../../../Topology/TopologyItem';
import ClusterConvertToShardTopologyForm from './ClusterConvertToShardTopologyForm';
import ClusterConvertToShardSummary from './ClusterConvertToShardSummary';

export default ClusterConvertToShardModal;

export enum ClusterConvertToShardFormWizardStep {
    CONFIG = 'CONFIG',
    TOPOLOGY = 'TOPOLOGY',
    SUMMARY = 'SUMMARY',
}

export interface ClusterConvertToShardFormValues {
    serverDataDirectory: string;
    disableFirewall: boolean;
    disableSeLinux: boolean;
    installSoftware: boolean;
    configurationTemplate: string;
    routerConfigurationTemplate: string;
    configurationServersTopology: TopologyItem[];
    configurationServersPort: number;
    routersMongosTopology: TopologyItem[];
    routersMongosPort: number;
}

export type ClusterConvertToShardModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function ClusterConvertToShardModal({
    cluster,
    onSuccess,
    onCancel,
}: ClusterConvertToShardModalProps) {
    const [form] = Form.useForm<ClusterConvertToShardFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Convert to shard',
        command: CmonJobInstanceCommand.CONVERT_TO_SHARDED_CLUSTER,
        onSuccess,
    });

    const handleSubmit = async () => {
        const values = form.getFieldsValue(true);
        await send({
            dataDir: values.serverDataDirectory,
            disable_firewall: values.disableFirewall,
            disable_selinux: values.disableSeLinux,
            enable_uninstall: false,
            install_software: values.installSoftware,
            mongodb_conf_template: values.configurationTemplate,
            mongos_conf_template: values.routerConfigurationTemplate,
            config_servers: getConfigurationServersJobData(
                values.configurationServersTopology,
                values.configurationServersPort,
                values.topologyDataIps
            ),
            mongos_servers: getRoutersMongosJobData(
                values.routersMongosTopology,
                values.routersMongosPort,
                values.topologyDataIps
            ),
        });
    };
    const steps = useMemo(
        () => [
            <WizardFormConfiguration.Step
                key={ClusterConvertToShardFormWizardStep.CONFIG}
                title={getClusterConvertToShardWizardStepName(
                    ClusterConvertToShardFormWizardStep.CONFIG
                )}
                subTitle=" "
                hasRequiredFields={true}
            >
                <ClusterConvertToShardConfigurationForm cluster={cluster} />
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={ClusterConvertToShardFormWizardStep.TOPOLOGY}
                title={getClusterConvertToShardWizardStepName(
                    ClusterConvertToShardFormWizardStep.TOPOLOGY
                )}
                validate={MongoShardsDeploymentConfigurator.getTopologyValidate(
                    form
                )}
                subTitle=" "
                hasRequiredFields={true}
            >
                <ClusterConvertToShardTopologyForm
                    form={form}
                    clusterId={cluster.clusterId}
                />
            </WizardFormConfiguration.Step>,
            <WizardFormConfiguration.Step
                key={ClusterConvertToShardFormWizardStep.SUMMARY}
                title={getClusterConvertToShardWizardStepName(
                    ClusterConvertToShardFormWizardStep.SUMMARY
                )}
                subTitle=" "
            >
                <Form.Item noStyle={true} shouldUpdate={true}>
                    {() => {
                        return <ClusterConvertToShardSummary form={form} />;
                    }}
                </Form.Item>
            </WizardFormConfiguration.Step>,
        ],
        [cluster]
    );
    const initialValues: ClusterConvertToShardFormValues = useMemo(() => {
        const {
            nodeConfig,
            sshConfig,
            configurationServersPort,
            configurationServersTopology,
            routersMongosPort,
            routersMongosTopology,
        } = MongoShardsDeploymentConfigurator.getDefaults();
        return {
            ...nodeConfig,
            ...sshConfig,
            configurationServersPort,
            configurationServersTopology,
            routersMongosPort,
            routersMongosTopology,
        } as ClusterConvertToShardFormValues;
    }, []);

    return (
        <ModalDefault
            title={<div>Convert to shard</div>}
            visible={true}
            onCancel={onCancel}
        >
            <WizardFormConfiguration
                activeKey={ClusterConvertToShardFormWizardStep.CONFIG}
                initialValues={initialValues}
                form={form}
                steps={steps}
                onSubmit={handleSubmit}
                loading={loading}
            />
        </ModalDefault>
    );
}

export function getClusterConvertToShardWizardStepName(
    step: ClusterConvertToShardFormWizardStep
) {
    switch (step) {
        case ClusterConvertToShardFormWizardStep.CONFIG:
            return <TypographyText nowrap>Database settings</TypographyText>;
        case ClusterConvertToShardFormWizardStep.TOPOLOGY:
            return 'Configuration Servers and Routers';
        case ClusterConvertToShardFormWizardStep.SUMMARY:
            return 'Preview';
    }
}
