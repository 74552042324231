import './MonitorToolbar.less';
import { Button, Dropdown, Menu, Space } from 'antd';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import TimeRange, {
    TimeRangeApi,
    TimeRangeProps,
} from '../../common/DataEntry/TimeRange';
import { RedoOutlined, PauseOutlined } from '@ant-design/icons';
import AppDivider from '../../common/AppDivider';
import DurationFormat from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { MenuInfo } from 'rc-menu/lib/interface';
import TypographyText from '../../common/TypographyText';
import SpaceWide from '../../common/SpaceWide';

export default forwardRef(MonitorToolbar);

export interface MonitorToolbarApi {
    resumeRefresh: () => void;
    pauseRefresh: () => void;
    setTimeRange: (timeRange: [number, number]) => void;
    resetTimeout: () => void;
}

export type MonitorToolbarProps = {
    timeRange?: number;
    disabled?: boolean;
    selectors?: React.ReactElement[];
    extra?: React.ReactElement;
    onTimeRangeChange?: (from: number, to: number) => void;
    onTimeRangeShift?: (from: number, to: number) => void;
    onRefresh?: (triggerClick?: boolean) => void;
    autoRefresh?: boolean;
    minimalUnit?: 'day' | 'second';
    timeRangeProps?: TimeRangeProps;
    enableTimerange?: boolean;
    defaultRefreshTimeout?: number;
    timezone?: string;
};

const REFRESH_TIMEOUTS = [10, 20, 30, 60, 120, 0];

function MonitorToolbar(
    {
        timeRange,
        disabled,
        selectors,
        extra,
        onRefresh,
        onTimeRangeChange,
        onTimeRangeShift,
        autoRefresh = true,
        timeRangeProps,
        minimalUnit,
        enableTimerange = true,
        defaultRefreshTimeout = 10,
        timezone,
    }: MonitorToolbarProps,
    ref: any
) {
    const [refreshTimeout, setRefreshTimeout] = useState(defaultRefreshTimeout);
    const [remainSeconds, setRemainSeconds] = useState(0);
    const [paused, setPaused] = useState<boolean>(disabled || false);
    const refreshTime = useRef<number>(0);
    const timeRangeRef = useRef<TimeRangeApi>();
    const timerRef = useRef<any>();

    const resumeRefresh = () => {
        setPaused(false);
    };

    const pauseRefresh = () => {
        setPaused(true);
    };

    const createInterval = () => {
        timerRef.current = setInterval(() => {
            if (refreshTime.current > 0) {
                const remain = Math.ceil(
                    (refreshTime.current - Date.now()) / 1000
                );
                if (remain < 2) {
                    setRemainSeconds(1);
                    if (!paused) {
                        refresh(true);
                    }
                } else {
                    setRemainSeconds(remain);
                }
            }
        }, 1000);
    };

    const removeInterval = () => {
        timerRef.current && clearInterval(timerRef.current);
    };

    useImperativeHandle(
        ref,
        (): MonitorToolbarApi => ({
            resumeRefresh() {
                resumeRefresh();
            },
            pauseRefresh() {
                pauseRefresh();
            },
            setTimeRange(timeRange: [number, number]) {
                timeRangeRef.current?.setTimeRange(timeRange);
                pauseRefresh();
            },
            resetTimeout() {
                removeInterval();
                setRefreshTime();
                createInterval();
            },
        })
    );
    const handleRefreshTimeoutClick = (info: MenuInfo) => {
        const key = parseInt(info.key);
        setRefreshTimeout(key);
        if (!key) {
            pauseRefresh();
        } else {
            resumeRefresh();
        }
    };

    const handleTimeRangeChange = (
        from: number,
        to: number,
        quickRange: number
    ) => {
        if (quickRange === 0 || quickRange > 60 * 60) {
            setPaused(true);
        } else {
            setPaused(false);
        }
        onTimeRangeChange?.(from, to);
    };

    const refresh = (shifting?: boolean, triggerClick?: boolean) => {
        onRefresh?.(triggerClick);
        setRefreshTime();
        if (timeRangeRef.current) {
            const [from, to, quickRange] = timeRangeRef.current.getTimeRange();
            if (quickRange === 0) {
                setPaused(true);
            } else {
                setPaused(false);
            }
            if (shifting) {
                onTimeRangeShift?.(from, to);
            } else {
                onTimeRangeChange?.(from, to);
            }
        }
    };

    const handleRefreshClick = () => {
        refresh(false, true);
    };

    useEffect(() => {
        if (autoRefresh) {
            createInterval();
        }
        return () => {
            removeInterval();
        };
    }, []);

    useEffect(() => {
        setRefreshTime();
    }, [refreshTimeout]);

    return (
        <SpaceWide className="MonitorToolbar">
            <SpaceWide>{selectors}</SpaceWide>
            <Space
                split={
                    <AppDivider
                        type="vertical"
                        orientation="left"
                        fullHeight
                        inline
                    />
                }
            >
                {enableTimerange ? (
                    <TimeRange
                        disabled={disabled}
                        range={timeRange}
                        ref={timeRangeRef}
                        minimalUnit={minimalUnit}
                        onChange={handleTimeRangeChange}
                        timezone={timezone}
                        {...timeRangeProps}
                    />
                ) : null}

                {autoRefresh ? (
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={handleRefreshTimeoutClick}
                                className="MonitorToolbar_refresh-menu"
                                items={[
                                    {
                                        type: 'group',
                                        label: (
                                            <TypographyText strong>
                                                Refresh every:
                                            </TypographyText>
                                        ),
                                    },
                                    ...REFRESH_TIMEOUTS.map((item) => ({
                                        key: item,
                                        label:
                                            item === 0 ? (
                                                'Never refresh'
                                            ) : (
                                                <DurationFormat
                                                    precision={1}
                                                    units={['m', 's']}
                                                >
                                                    {item * 1000}
                                                </DurationFormat>
                                            ),
                                    })),
                                ]}
                            />
                        }
                        placement="bottom"
                        disabled={disabled}
                    >
                        {paused ? (
                            <Button
                                disabled={disabled}
                                size="middle"
                                className="MonitorToolbar_refresh-menu-button"
                            >
                                <PauseOutlined />
                                <span>paused</span>
                            </Button>
                        ) : (
                            <Button
                                disabled={disabled}
                                size="middle"
                                className="MonitorToolbar_refresh-menu-button"
                                style={{
                                    minWidth: refreshTimeout > 99 ? 105 : 95,
                                }}
                            >
                                <RedoOutlined />
                                <DurationFormat
                                    precision={1}
                                    languages={{
                                        shortEn: {
                                            s: () => 'sec',
                                        },
                                    }}
                                    short
                                    noSpace={false}
                                    units={['s']}
                                >
                                    {remainSeconds * 1000}
                                </DurationFormat>
                            </Button>
                        )}
                    </Dropdown>
                ) : null}

                <Button
                    disabled={disabled}
                    onClick={handleRefreshClick}
                    icon={<RedoOutlined />}
                />
                {extra}
            </Space>
        </SpaceWide>
    );

    function setRefreshTime() {
        refreshTime.current = refreshTimeout
            ? Date.now() + refreshTimeout * 1000
            : 0;
    }
}
