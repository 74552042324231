import React from 'react';
import ConfirmActionButton, {
    ConfirmActionButtonProps,
} from '@severalnines/bar-frontend-components/build/lib/General/ConfirmActionButton';
import './AppConfirmActionButton.less';
import AppTooltip from '../Feedback/AppTooltip';

export default AppConfirmActionButton;

export type AppConfirmActionButtonProps = ConfirmActionButtonProps & {
    tooltip?: React.ReactNode;
};

function AppConfirmActionButton({
    tooltip,
    ...rest
}: AppConfirmActionButtonProps) {
    const content = (
        <ConfirmActionButton className="AppConfirmActionButton" {...rest} />
    );
    return tooltip ? (
        <AppTooltip title={tooltip}>{content}</AppTooltip>
    ) : (
        content
    );
}
