import React, { useEffect, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import CcNode, { CcNodeType, CcNodeStatus } from '../../services/models/CcNode';
import { AutoComplete, Select, SelectProps } from 'antd';
import { getNodeHostWithDesc } from '../../components/Nodes/NodeFormat';
import SelectWrap from './SelectWrap';

export default ClusterNodeSelect;

export type ClusterNodeSelectProps = SelectProps<string> & {
    cluster: CcCluster;
    primaryCandidatesOnly?: boolean;
    databaseNodes?: boolean;
    types?: CcNodeType[];
    filter?: (node: CcNode) => boolean;
    extraOptionsTop?: any[];
    extraOptionsBottom?: any[];
    extractNodeValue?: (node: CcNode) => string | CcNode | undefined;
    autoComplete?: boolean;
    showAllOption?: boolean;
    allOptionLabel?: string;
    allOptionValue?: string;
    selectFirst?: boolean;
    showOnlineNodes?: boolean;
};

function ClusterNodeSelect({
    cluster,
    primaryCandidatesOnly = true,
    databaseNodes = true,
    types = [],
    filter,
    extraOptionsTop = [],
    extraOptionsBottom = [],
    extractNodeValue,
    autoComplete = false,
    showAllOption,
    allOptionLabel = 'All',
    allOptionValue = 'all',
    selectFirst = false,
    showOnlineNodes = false,
    ...rest
}: ClusterNodeSelectProps) {
    const [nodes, setNodes] = useState<CcNode[]>();
    const { disabled: restDisabled } = rest;
    useEffect(() => {
        let finalNodes;
        if (primaryCandidatesOnly) {
            finalNodes = cluster.getPrimaryCandidates();
        } else if (databaseNodes) {
            finalNodes = cluster.getDatabaseNodes();
        } else {
            finalNodes = cluster?.nodes;
        }
        if (types?.length) {
            finalNodes = finalNodes?.filter((node) =>
                types.includes(node.nodetype as CcNodeType)
            );
        }
        if (showOnlineNodes) {
            finalNodes = finalNodes.filter(
                (node) => node.hoststatus === CcNodeStatus.CmonHostOnline
            );
        }
        if (filter) {
            finalNodes = finalNodes?.filter(filter);
        }
        setNodes(finalNodes);
    }, [cluster]);
    return (
        <div className="ClusterNodeSelect">
            {autoComplete ? (
                <AutoComplete
                    data-testid="node-autocomplete"
                    placeholder="Type a hostname or IP address"
                    disabled={!nodes || restDisabled}
                    dropdownMatchSelectWidth={false}
                    {...rest}
                    options={[
                        ...extraOptionsTop,
                        ...(showAllOption
                            ? [
                                  {
                                      value: allOptionValue,
                                      label: allOptionLabel,
                                      key: 'all',
                                  },
                              ]
                            : []),
                        ...(nodes?.map((node) => ({
                            key: node.getHostWithPort(),
                            value:
                                extractNodeValue?.(node) ||
                                node.getHostWithPort(),
                            label: getNodeHostWithDesc(node),
                        })) || []),
                        ...extraOptionsBottom,
                    ]}
                />
            ) : (
                <SelectWrap
                    selectFirst={selectFirst}
                    data-testid="node-select"
                    placeholder="Choose a node"
                    disabled={!nodes || restDisabled}
                    dropdownMatchSelectWidth={false}
                    {...rest}
                    options={[
                        ...extraOptionsTop,
                        ...(showAllOption
                            ? [
                                  {
                                      value: allOptionValue,
                                      label: allOptionLabel,
                                      key: 'all',
                                  },
                              ]
                            : []),
                        ...(nodes?.map((node) => ({
                            key: node.getHostWithPort(),
                            value:
                                extractNodeValue?.(node) ||
                                node.getHostWithPort(),
                            label: getNodeHostWithDesc(node),
                        })) || []),
                        ...extraOptionsBottom,
                    ]}
                />
            )}
        </div>
    );
}
