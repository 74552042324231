import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcJob from '../../services/models/CcJob';
import AppActionButton from '../../common/General/AppActionButton';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import JobDetailsModal, { JobDetailsModalProps } from './JobDetailsModal';
import './JobDetailsButton.less';

export default JobDetailsButton;

export type JobDetailsButtonProps = ButtonProps & {
    job: CcJob;
    children?: React.ReactNode;
    onActionPerformed?: JobDetailsModalProps['onActionPerformed'];
};

function JobDetailsButton({
    job,
    children,
    onActionPerformed,
    ...rest
}: JobDetailsButtonProps) {
    return (
        <ButtonWithForm
            button={
                <AppActionButton {...rest}>
                    {children || 'Details'}
                </AppActionButton>
            }
            form={
                <JobDetailsModal
                    job={job}
                    onActionPerformed={onActionPerformed}
                />
            }
        />
    );
}
