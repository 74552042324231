import merge from 'deepmerge';
import React from 'react';
import {
    ClusterConfiguratorFormValues,
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';
import { FormInstance } from 'antd/lib/form';
import RedisShardedNodeConfiguration, {
    RedisShardedNodeConfigurationProps,
} from './RedisShardedNodeConfiguration';
import RedisShardedNodeSummary, {
    RedisShardedNodeSummaryProps,
} from './RedisShardedNodeSummary';
import { ServiceClusterWizardStep } from '../ServiceClusterWizardStep';
import RedisShardedShardFormWrapper from './RedisShardedShardFormWrapper';
import { WizardFormConfigurationStepProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import {
    getShardKeys,
    getShardValidator,
} from '../Deploy/MongoShards/MongoShardsReplicaSetFormWrapper';

export type RedisShardedFormShardHostname = string;
export type RedisShardedFormShard = {
    shardName: string;
    topology: TopologyItem[];
    perNode: {
        [key: RedisShardedFormShardHostname]: {
            dataIp?: string;
        };
    };
};
export type RedisShardedFormShardsMap = {
    [key: string]: RedisShardedFormShard;
};

export interface RedisShardedFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        redisShardedPort?: number;
        nodeTimeout?: number;
        clusterBusPort?: number;
        clusterAutoRecovery?: boolean;
        nodeAutoRecovery?: boolean;
        replicaValidityFactor?: number;
        userName?: string;
        password?: string;
    };
    shards: RedisShardedFormShardsMap;
}

export default class RedisShardedConfigurator extends ClusterConfigurator {
    public static getDefaults(): RedisShardedFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                redisShardedPort: 6379,
            },
        });
    }

    public static getJobData(formValues: RedisShardedFormValues): any {
        const { nodeConfig } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_REDIS_SHARDED.toLowerCase(),
            nodes: getShardsJobData(formValues),
            node_timeout_ms: nodeConfig.nodeTimeout,
            redis_sharded_bus_port: nodeConfig.clusterBusPort,
            redis_sharded_port: nodeConfig.redisShardedPort,
            redis_cluster_replica_validity_factor:
                nodeConfig.replicaValidityFactor,
            db_password: nodeConfig.password,
            db_user: nodeConfig.userName,
            type: 'redis_sharded',
            config_template: nodeConfig.configurationTemplate,
        });
    }

    public static getVendors() {
        return [
            {
                name: 'Redis',
                value: 'redis',
            },
        ];
    }

    public static getNodeConfigurationStep({
        form,
    }: RedisShardedNodeConfigurationProps): React.ReactNode {
        return <RedisShardedNodeConfiguration form={form} />;
    }

    public static getNodeConfigurationValidate(): string[][] {
        return [
            ['nodeConfig', 'redisShardedPort'],
            ['nodeConfig', 'clusterBusPort'],
            ['nodeConfig', 'userName'],
            ['nodeConfig', 'password'],
        ];
    }

    public static getNodeConfigurationSummary(
        props: RedisShardedNodeSummaryProps
    ): React.ReactNode {
        return <RedisShardedNodeSummary {...props} />;
    }

    public static getDeploymentSteps(
        form: FormInstance,
        formValues?: RedisShardedFormValues
    ): (
        | ServiceClusterWizardStep
        | [ServiceClusterWizardStep | string, WizardFormConfigurationStepProps]
    )[] {
        const { shards } = formValues || {};

        return [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            ...(shards
                ? getShardStepsConfigurations(
                      shards,
                      form,
                      undefined,
                      (item: TopologyItem) => {
                          console.log(item);
                          let hostCount = 0;
                          Object.entries(shards).forEach(([key, values]) => {
                              if (Array.isArray(values?.topology)) {
                                  hostCount += values.topology.filter(
                                      (value) =>
                                          value?.extraData?.hostname ===
                                          item?.extraData?.hostname
                                  ).length;
                              }
                          });
                          if (hostCount > 0) {
                              throw new Error(
                                  `${item?.extraData?.hostname} is already in the list of shards. Please enter different node.`
                              );
                          }
                          return item;
                      }
                  )
                : []),
            ServiceClusterWizardStep.PREVIEW,
        ];
    }
}

export function getShardStepsConfigurations(
    shards: RedisShardedFormShardsMap,
    form: FormInstance,
    clusterId?: number,
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem
) {
    const shardKeyList = getShardKeys(shards);
    return shardKeyList.map((key) => [
        key,
        {
            title: shards[key].shardName || 'Shard 1',
            subTitle: ' ',
            validate: [getShardValidator(shards[key])],
            children: (
                <RedisShardedShardFormWrapper
                    form={form}
                    shards={shards}
                    shardKey={key}
                    validateItem={validateItem}
                    clusterId={clusterId}
                />
            ),
        },
    ]) as [ServiceClusterWizardStep | string, any][];
}

export function getShardsJobData(formValues: RedisShardedFormValues) {
    const result: any = [];
    const { shards = {}, nodeConfig } = formValues;
    Object.values(shards).forEach((shard) => {
        // Check if the shard is defined
        if (shard) {
            const primaryHost = shard.topology.find(
                (node) => node.type === 'primary'
            );
            if (primaryHost) {
                result.push({
                    class_name: 'RedisShardedHost',
                    hostname: primaryHost.extraData.hostname,
                    port: nodeConfig.redisShardedPort,
                    role: 'primary',
                });
            }
            shard.topology.forEach((node) => {
                if (node.type === 'secondary') {
                    result.push({
                        class_name: 'RedisShardedHost',
                        hostname: node.extraData.hostname,
                        role: 'replica',
                    });
                }
            });
        }
    });
    return result;
}
