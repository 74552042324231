import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../services/models/CcCluster';
import LoadBalancerModal from './LoadBalancerWizardModal';

export default LoadBalancerWizardButton;

export type LoadBalancerButton = {
    children?: React.ReactNode;
    cluster?: CcCluster;
};

function LoadBalancerWizardButton({ children, cluster }: LoadBalancerButton) {
    return (
        <ButtonWithForm
            button={
                <ActionButton>{children || 'Add load balancer'}</ActionButton>
            }
            form={<LoadBalancerModal cluster={cluster} />}
        />
    );
}
