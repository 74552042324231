
import {CmonRequestServiceResponseData} from '../CmonRequestService';

export default class CmonError
    extends Error {

    public readonly json: {};
    public readonly code: string | undefined;

    constructor(jsonError: CmonRequestServiceResponseData) {
        super(jsonError.error_string || 'Unknown json error');
        this.json = jsonError;
        this.code = jsonError.request_status;
    }

}
