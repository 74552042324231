import PostgreSqlConfigurator, {
    PostgreSqlFormValues,
} from '../../PostgreSql/PostgreSqlConfigurator';
import merge from 'deepmerge';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import React from 'react';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../../ClusterNodesForm';
import PostgreSqlNodeConfiguration from '../../PostgreSql/PostgreSqlNodeConfiguration';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import { Col, Form, Input, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { ServiceClusterWizardStep } from '../../ServiceClusterWizardStep';

export default class PostgreSqlDeploymentConfigurator extends PostgreSqlConfigurator {
    public static getDefaults(): PostgreSqlFormValues {
        return merge(PostgreSqlConfigurator.getDefaults(), {
            details: {
                vendor: 'default',
                version: '15',
            },
            nodeConfig: {
                sslEncryption: true,
            },
            synchronousReplication: {},
        });
    }

    public static getJobData(formValues: PostgreSqlFormValues): any {
        const {
            topology,
            nodeConfig,
            synchronousReplication,
            topologyDataIps,
            databaseExtension,
        } = formValues;
        return merge(PostgreSqlConfigurator.getJobData(formValues), {
            pg_extensions: Object.entries(databaseExtension || {})
                .filter(([, value]) => value)
                .map(([key]) => key),
            nodes: topology.reduce(
                (accumulator: any[], currentNode: TopologyItem) => {
                    accumulator = [
                        ...accumulator,
                        {
                            port: nodeConfig.serverPort,
                            hostname: currentNode.extraData.hostname,
                            hostname_data: currentNode.extraData.hostname,
                            hostname_internal:
                                topologyDataIps?.[
                                    currentNode.extraData.hostname
                                ] || '',
                            synchronous: !!synchronousReplication?.[
                                currentNode.extraData.hostname
                            ],
                        },
                    ];
                    return accumulator;
                },
                []
            ),
        });
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        return merge(PostgreSqlConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Deploy PostgreSQL Cluster',
            },
        });
    }

    public static getTopologyStep(form: FormInstance) {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{
                    mutateItem: (item: TopologyItem) => {
                        return {
                            ...item,
                            footer:
                                item.fromKey &&
                                item.status === StatusFormatStatus.success ? (
                                    <FormItemInlineSwitch
                                        justify
                                        noMargin
                                        label="Synchronous Replication"
                                        name={[
                                            'synchronousReplication',
                                            item.extraData.hostname,
                                        ]}
                                    />
                                ) : null,
                        };
                    },
                }}
            />
        );
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return (
            <PostgreSqlNodeConfiguration hasSslEncryption={true} {...props} />
        );
    }

    public static getDeploymentSteps(
        form: FormInstance,
        values: ClusterConfiguratorFormValues
    ) {
        const steps = [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            ServiceClusterWizardStep.TOPOLOGY,
        ];
        const version = +(form.getFieldValue('details')?.version || 0);
        if (version >= 15) {
            return [
                ...steps,
                ServiceClusterWizardStep.EXTENSION,
                ServiceClusterWizardStep.PREVIEW,
            ];
        } else {
            return [...steps, ServiceClusterWizardStep.PREVIEW];
        }
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <NodeConfigurationSummary hasSslEncryption={true} {...props} />;
    }

    public static clusterDetailsExtra(form?: FormInstance): React.ReactNode {
        const enterpriseToken = () => {
            const { details } = form?.getFieldsValue(true) || {};
            if (details?.vendor === 'enterprisedb') {
                return (
                    <Col xs={24} sm={24} md={16}>
                        <Form.Item
                            name={['details', 'enterpriseToken']}
                            label={
                                <Space>
                                    EDB token
                                    <InfoIcon
                                        info={
                                            <span>
                                                To be able to deploy EDB
                                                cluster, you have to provide a
                                                valid Repo Token. To receive the
                                                Token, please log in with your
                                                EDB credentials on{' '}
                                                <a
                                                    rel="noreferrer"
                                                    href="https://www.enterprisedb.com/accounts/login"
                                                    target="_blank"
                                                >
                                                    Account management | EDB
                                                </a>{' '}
                                                page and follow the Repo Access
                                                link
                                            </span>
                                        }
                                    />
                                </Space>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter EDB token',
                                },
                            ]}
                        >
                            <Input placeholder="Enter EDB token" />
                        </Form.Item>
                    </Col>
                );
            }
            return null;
        };
        return (
            <Form.Item noStyle={true} shouldUpdate={true}>
                {enterpriseToken}
            </Form.Item>
        );
    }
}
