import merge from 'deepmerge';
import React from 'react';
import {
    ClusterConfiguratorFormValues,
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';
import RedisNodeConfiguration, {
    RedisNodeConfigurationProps,
} from './RedisNodeConfiguration';
import RedisNodeSummary, { RedisNodeSummaryProps } from './RedisNodeSummary';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../ClusterNodesForm';

export interface RedisFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        redisPort?: number;
        redisSentinelPort?: number;
        dbUser?: string;
        dbPassword?: string;
        clusterAutoRecovery?: boolean;
        nodeAutoRecovery?: boolean;
        replicationPassword?: string;
        replicationUser?: string;
        sentinelPassword?: string;
    };
}

export default class RedisConfigurator extends ClusterConfigurator {
    public static getDefaults(): RedisFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                redisPort: 6379,
                redisSentinelPort: 26379,
                dbUser: 'admin',
                dbPassword: Math.random().toString(36).slice(-8),
            },
        });
    }

    public static getJobData(formValues: RedisFormValues): any {
        const { topology, nodeConfig, topologyDataIps } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_REDIS.toLowerCase(),
            db_user: nodeConfig.dbUser,
            db_password: nodeConfig.dbPassword,
            replication_password: nodeConfig.replicationPassword,
            replication_user: nodeConfig.replicationUser,
            sentinel_password: nodeConfig.sentinelPassword,
            nodes: topology.reduce(
                (accumulator: any[], currentNode: TopologyItem) => {
                    accumulator = [
                        ...accumulator,
                        {
                            class_name: 'CmonRedisHost',
                            hostname: currentNode.extraData.hostname,
                            hostname_data: currentNode.extraData.hostname,
                            hostname_internal:
                                topologyDataIps?.[
                                    currentNode.extraData.hostname
                                ] || '',
                            port: nodeConfig.redisPort,
                        },
                        {
                            class_name: 'CmonRedisSentinelHost',
                            hostname: currentNode.extraData.hostname,
                            hostname_data: currentNode.extraData.hostname,
                            hostname_internal:
                                topologyDataIps?.[
                                    currentNode.extraData.hostname
                                ] || '',
                            port: nodeConfig.redisSentinelPort,
                        },
                    ];
                    return accumulator;
                },
                []
            ),
        });
    }

    public static getVendors() {
        return [
            {
                name: 'Redis',
                value: 'redis',
            },
        ];
    }

    public static getNodeConfigurationStep({
        form,
    }: RedisNodeConfigurationProps): React.ReactNode {
        return <RedisNodeConfiguration hasSslEncryption={true} form={form} />;
    }

    public static getNodeConfigurationValidate(): string[][] {
        return [
            ['nodeConfig', 'redisPort'],
            ['nodeConfig', 'redisSentinelPort'],
            ['nodeConfig', 'dbUser'],
            ['nodeConfig', 'dbPassword'],
        ];
    }

    public static getNodeConfigurationSummary(
        props: RedisNodeSummaryProps
    ): React.ReactNode {
        return <RedisNodeSummary {...props} />;
    }

    public static getTopologyStep(form: FormInstance) {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{
                    formProps: {
                        primaryExtra: 'You may add only one primary node.',
                        secondaryExtra:
                            'Recommended at least two replica nodes',
                    },
                }}
            />
        );
    }

    public static getTopologyValidate(form: FormInstance) {
        return [
            ...ClusterConfigurator.getTopologyValidate(form),
            () => {
                const topology: TopologyItem[] = form.getFieldValue('topology');
                if (topology.length > 5) {
                    throw new Error('A maximum of five nodes can be specified');
                } else if (topology.length % 2 === 0) {
                    throw new Error(
                        'Total number of nodes should be an odd number: 1, 3 or 5'
                    );
                }
            },
        ];
    }
}
