import React from 'react';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../../services/models/CcCluster';
import ClusterNodesUpgradeWizard from './ClusterNodesUpgradeWizard';

export default ClusterNodesUpgradeWizardButton;

export type LoadBalancerButton = {
    cluster: CcCluster;
};

function ClusterNodesUpgradeWizardButton({ cluster }: LoadBalancerButton) {
    return (
        <ButtonWithForm
            button={<ActionButton>Upgrades</ActionButton>}
            form={<ClusterNodesUpgradeWizard cluster={cluster} />}
        />
    );
}
