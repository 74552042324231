import React from 'react';
import CcNode, { CcNodeStatus } from '../../services/models/CcNode';
import StatusFormat, {
    StatusFormatProps,
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { getArrayFromEnum } from '../../common/utils/enums';
import { Space } from 'antd';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';
import AppTooltip from '../../common/Feedback/AppTooltip';
import NodeRunningJobsFormat from './NodeRunningJobsFormat';
import './NodeStatusFormat.less';

export default NodeStatusFormat;

export function getNodeStatusFormatType(hoststatus?: CcNodeStatus) {
    switch (hoststatus) {
        case CcNodeStatus.CmonHostOnline:
            return StatusFormatStatus.success;
        case CcNodeStatus.CmonHostFailed:
        case CcNodeStatus.CmonHostOffLine:
            return StatusFormatStatus.error;
        case CcNodeStatus.CmonHostUnknown:
        case CcNodeStatus.CmonHostRecovery:
            return StatusFormatStatus.warning;
        case CcNodeStatus.CmonHostShutDown:
            return StatusFormatStatus.info;
        default:
            return StatusFormatStatus.warning;
    }
}

export function getNodeStatusFormatText(hoststatus?: CcNodeStatus) {
    switch (hoststatus) {
        case CcNodeStatus.CmonHostOnline:
            return 'Operational';
        case CcNodeStatus.CmonHostOffLine:
            return 'Offline';
        case CcNodeStatus.CmonHostFailed:
            return 'Failed';
        case CcNodeStatus.CmonHostShutDown:
            return 'Shut Down';
        case CcNodeStatus.CmonHostRecovery:
            return 'Recovering';
        case CcNodeStatus.CmonHostUnknown:
            return 'Unknown State';
        default:
            return 'Status N/A';
    }
}

export type NodeStatusFormatProps = StatusFormatProps & {
    node: CcNode;
    showText?: boolean;
    showMaintenance?: boolean;
    showRunningJobs?: boolean;
    loading?: boolean;
};

function NodeStatusFormat({
    node,
    showText = true,
    showMaintenance,
    showRunningJobs,
    showTooltip = true,
    loading,
    ...rest
}: NodeStatusFormatProps) {
    const content = (
        <Space>
            <StatusFormat
                className="NodeStatusFormat"
                status={
                    loading
                        ? StatusFormatStatus.loading
                        : getNodeStatusFormatType(node.hoststatus)
                }
                text={showText ? getNodeStatusFormatText(node.hoststatus) : ''}
                showTooltip={false}
                {...rest}
            />
            {showMaintenance ? (
                <MaintenanceFormat node={node} showTooltip={showTooltip} />
            ) : null}
            {showRunningJobs ? <NodeRunningJobsFormat node={node} /> : null}
        </Space>
    );
    return showTooltip ? (
        <AppTooltip title={node.getMessage()}>{content}</AppTooltip>
    ) : (
        content
    );
}

export function getNodeStatusesTextValue() {
    return (getArrayFromEnum(CcNodeStatus) as CcNodeStatus[]).map((s) => ({
        text: getNodeStatusFormatText(s),
        value: s,
    }));
}
