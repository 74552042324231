import React from 'react';
import { Checkbox, Form } from 'antd';
import TypographyText from '../TypographyText';

export type FormCheckboxProps = {
    name: string | string[];
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    strong?: boolean;
    label?: string;
};

function FormCheckbox({
    name,
    checked,
    onChange,
    label,
    strong,
}: FormCheckboxProps) {
    return (
        <Form.Item
            name={name}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
        >
            <Checkbox checked={checked} onChange={onChange}>
                <TypographyText strong={strong}>{label}</TypographyText>
            </Checkbox>
        </Form.Item>
    );
}

export default FormCheckbox;
