
import CcStats, { CcStatsProps } from '../../models/CcStats';


export interface CmonProxySqlStatsProps  extends CcStatsProps {
    ACTIVE_TRANSACTIONS?: number;
    BACKEND_QUERY_TIME_NSEC?: number;
    CLIENT_CONNECTIONS_ABORTED?: number;
    CLIENT_CONNECTIONS_CONNECTED?: number;
    CLIENT_CONNECTIONS_CREATED?: number;
    COM_AUTOCOMMIT?: number;
    COM_AUTOCOMMIT_FILTERED?: number;
    COM_COMMIT?: number;
    COM_COMMIT_FILTERED?: number;
    COM_ROLLBACK?: number;
    COM_ROLLBACK_FILTERED?: number;
    CONNPOOL_MEMORY_BYTES?: number;
    MYSQL_BACKEND_BUFFERS_BYTES?: number;
    MYSQL_FRONTEND_BUFFERS_BYTES?: number;
    MYSQL_MONITOR_WORKERS?: number;
    MYSQL_SESSION_INTERNAL_BYTES?: number;
    MYSQL_THREAD_WORKERS?: number;
    QUERIES_BACKENDS_BYTES_RECV?: number;
    QUERIES_BACKENDS_BYTES_SENT?: number;
    QUERY_CACHE_BYTES_IN?: number;
    QUERY_CACHE_BYTES_OUT?: number;
    QUERY_CACHE_COUNT_GET?: number;
    QUERY_CACHE_COUNT_GET_OK?: number;
    QUERY_CACHE_COUNT_SET?: number;
    QUERY_CACHE_ENTRIES?: number;
    QUERY_CACHE_MEMORY_BYTES?: number;
    QUERY_CACHE_PURGED?: number;
    QUERY_PROCESSOR_TIME_NSEC?: number;
    QUESTIONS?: number;
    SERVERS_TABLE_VERSION?: number;
    SERVER_CONNECTIONS_ABORTED?: number;
    SERVER_CONNECTIONS_CONNECTED?: number;
    SERVER_CONNECTIONS_CREATED?: number;
    SLOW_QUERIES?: number;

}



export default class CmonProxySqlStats extends CcStats {
    public ACTIVE_TRANSACTIONS?: number;
    public BACKEND_QUERY_TIME_NSEC?: number;
    public CLIENT_CONNECTIONS_ABORTED?: number;
    public CLIENT_CONNECTIONS_CONNECTED?: number;
    public CLIENT_CONNECTIONS_CREATED?: number;
    public COM_AUTOCOMMIT?: number;
    public COM_AUTOCOMMIT_FILTERED?: number;
    public COM_COMMIT?: number;
    public COM_COMMIT_FILTERED?: number;
    public COM_ROLLBACK?: number;
    public COM_ROLLBACK_FILTERED?: number;
    public CONNPOOL_MEMORY_BYTES?: number;
    public MYSQL_BACKEND_BUFFERS_BYTES?: number;
    public MYSQL_FRONTEND_BUFFERS_BYTES?: number;
    public MYSQL_MONITOR_WORKERS?: number;
    public MYSQL_SESSION_INTERNAL_BYTES?: number;
    public MYSQL_THREAD_WORKERS?: number;
    public QUERIES_BACKENDS_BYTES_RECV?: number;
    public QUERIES_BACKENDS_BYTES_SENT?: number;
    public QUERY_CACHE_BYTES_IN?: number;
    public QUERY_CACHE_BYTES_OUT?: number;
    public QUERY_CACHE_COUNT_GET?: number;
    public QUERY_CACHE_COUNT_GET_OK?: number;
    public QUERY_CACHE_COUNT_SET?: number;
    public QUERY_CACHE_ENTRIES?: number;
    public QUERY_CACHE_MEMORY_BYTES?: number;
    public QUERY_CACHE_PURGED?: number;
    public QUERY_PROCESSOR_TIME_NSEC?: number;
    public QUESTIONS?: number;
    public SERVERS_TABLE_VERSION?: number;
    public SERVER_CONNECTIONS_ABORTED?: number;
    public SERVER_CONNECTIONS_CONNECTED?: number;
    public SERVER_CONNECTIONS_CREATED?: number;
    public SLOW_QUERIES?: number;


    constructor(props: CmonProxySqlStatsProps) {
        super(props);
        this.ACTIVE_TRANSACTIONS = props.ACTIVE_TRANSACTIONS;
        this.BACKEND_QUERY_TIME_NSEC = props.BACKEND_QUERY_TIME_NSEC;
        this.CLIENT_CONNECTIONS_ABORTED = props.CLIENT_CONNECTIONS_ABORTED;
        this.CLIENT_CONNECTIONS_CONNECTED = props.CLIENT_CONNECTIONS_CONNECTED;
        this.CLIENT_CONNECTIONS_CREATED = props.CLIENT_CONNECTIONS_CREATED;
        this.COM_AUTOCOMMIT = props.COM_AUTOCOMMIT;
        this.COM_AUTOCOMMIT_FILTERED = props.COM_AUTOCOMMIT_FILTERED;
        this.COM_COMMIT = props.COM_COMMIT;
        this.COM_COMMIT_FILTERED = props.COM_COMMIT_FILTERED;
        this.COM_ROLLBACK = props.COM_ROLLBACK;
        this.COM_ROLLBACK_FILTERED = props.COM_ROLLBACK_FILTERED;
        this.CONNPOOL_MEMORY_BYTES = props.CONNPOOL_MEMORY_BYTES;
        this.MYSQL_BACKEND_BUFFERS_BYTES = props.MYSQL_BACKEND_BUFFERS_BYTES;
        this.MYSQL_FRONTEND_BUFFERS_BYTES = props.MYSQL_FRONTEND_BUFFERS_BYTES;
        this.MYSQL_MONITOR_WORKERS = props.MYSQL_MONITOR_WORKERS;
        this.MYSQL_SESSION_INTERNAL_BYTES = props.MYSQL_SESSION_INTERNAL_BYTES;
        this.MYSQL_THREAD_WORKERS = props.MYSQL_THREAD_WORKERS;
        this.QUERIES_BACKENDS_BYTES_RECV = props.QUERIES_BACKENDS_BYTES_RECV;
        this.QUERIES_BACKENDS_BYTES_SENT = props.QUERIES_BACKENDS_BYTES_SENT;
        this.QUERY_CACHE_BYTES_IN = props.QUERY_CACHE_BYTES_IN;
        this.QUERY_CACHE_BYTES_OUT = props.QUERY_CACHE_BYTES_OUT;
        this.QUERY_CACHE_COUNT_GET = props.QUERY_CACHE_COUNT_GET;
        this.QUERY_CACHE_COUNT_GET_OK = props.QUERY_CACHE_COUNT_GET_OK;
        this.QUERY_CACHE_COUNT_SET = props.QUERY_CACHE_COUNT_SET;
        this.QUERY_CACHE_ENTRIES = props.QUERY_CACHE_ENTRIES;
        this.QUERY_CACHE_MEMORY_BYTES = props.QUERY_CACHE_MEMORY_BYTES;
        this.QUERY_CACHE_PURGED = props.QUERY_CACHE_PURGED;
        this.QUERY_PROCESSOR_TIME_NSEC = props.QUERY_PROCESSOR_TIME_NSEC;
        this.QUESTIONS = props.QUESTIONS;
        this.SERVERS_TABLE_VERSION = props.SERVERS_TABLE_VERSION;
        this.SERVER_CONNECTIONS_ABORTED = props.SERVER_CONNECTIONS_ABORTED;
        this.SERVER_CONNECTIONS_CONNECTED = props.SERVER_CONNECTIONS_CONNECTED;
        this.SERVER_CONNECTIONS_CREATED = props.SERVER_CONNECTIONS_CREATED;
        this.SLOW_QUERIES = props.SLOW_QUERIES;

    }

}
