import './SwitchBox.less';
import React, { Fragment, ReactNode, useState } from 'react';
import FormValueListener from './FormValueListener';
import FormItemInlineSwitch from './FormItemInlineSwitch';
import classNames from 'classnames';
import { Space } from 'antd';
import TypographyText from '../TypographyText';
import { NamePath } from 'rc-field-form/lib/interface';

export default SwitchBox;
type SwitchBoxProps = {
    name: NamePath;
    title?: ReactNode;
    description?: ReactNode;
    extra?: ReactNode;
};

function SwitchBox({ name, title, description, extra }: SwitchBoxProps) {
    const [value, setValue] = useState<boolean>(false);
    return (
        <Fragment>
            <FormValueListener
                name={name}
                onChange={(value) => {
                    setValue(value);
                }}
            />
            <FormItemInlineSwitch
                className={classNames('SwitchBox', {
                    'SwitchBox--checked': value,
                })}
                name={name}
                labelStrong={false}
                label={
                    <Space direction="vertical" className="SwitchBox_label">
                        <TypographyText>{title}</TypographyText>
                        <TypographyText muted={true}>
                            {description}
                        </TypographyText>
                        {extra}
                    </Space>
                }
            />
        </Fragment>
    );
}
