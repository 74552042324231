import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../../Jobs/JobCreateActionButton';

export default ClusterFindMostAdvancedNodeButton;

export type ClusterFindMostAdvancedNodeButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterFindMostAdvancedNodeButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterFindMostAdvancedNodeButtonProps) {
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.FIND_MOST_ADVANCED}
            confirmTitle={'A job to find most advanced node will be triggered?'}
            title={'Find most advanced node'}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
