import React from 'react';
import useForm from 'antd/lib/form/hooks/useForm';
import { AddNodeWizardAction, AddNodeWizardStep } from '../AddNodeWizard';
import CcCluster from '../../../../../services/models/CcCluster';
import DatabaseFormWizard, {
    getImportDatabaseNodeJobDataDTO,
    getNewDatabaseNodeJobDataDTO,
} from './DatabaseFormWizard';
import { CmonJobInstanceCommand } from '../../../../../services/cmon/models/CmonJobInstance';
import { AddNodeFormWizardFields } from '../AddNodeFormWizard';
import AddNodeModal from '../AddNodeModal';

export default AddNodeDatabaseModal;

export type AddNodeDatabaseModalProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
    onCommandChange?: (command: CmonJobInstanceCommand) => void;
    defaultWizardStep?: AddNodeWizardStep;
    title?: string;
};

function AddNodeDatabaseModal({
    cluster,
    onCancel,
    onSuccess,
    onCommandChange,
    defaultWizardStep,
    title = 'a database node',
}: AddNodeDatabaseModalProps) {
    const [form] = useForm<any>();

    const handleSubmit = (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => {
        return action === AddNodeWizardAction.NEW
            ? getNewDatabaseNodeJobDataDTO(fields, cluster)
            : getImportDatabaseNodeJobDataDTO(fields, cluster);
    };

    return (
        <AddNodeModal
            cluster={cluster}
            form={form}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            onCommandChange={onCommandChange}
            titleNew={`Create ${title}`}
            titleImport={`Import ${title}`}
            commandNew={CmonJobInstanceCommand.ADDNODE}
            commandImport={CmonJobInstanceCommand.REGISTERNODE}
            defaultWizardStep={defaultWizardStep}
            formCreateWizard={
                <DatabaseFormWizard
                    action={AddNodeWizardAction.NEW}
                    form={form}
                    cluster={cluster}
                />
            }
            formImportWizard={
                <DatabaseFormWizard
                    action={AddNodeWizardAction.IMPORT}
                    form={form}
                    cluster={cluster}
                />
            }
        />
    );
}
