import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import BackupDeleteModal from '../BackupDeleteModal';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import React from 'react';
import { BackupAction, getBackupActionFormatText } from '../BackupActionsMenu';
import CcCluster from '../../../services/models/CcCluster';
import CcBackup, { CcBackupStatus } from '../../../services/models/CcBackup';
import { Space } from 'antd';
import TypographyText from '../../../common/TypographyText';
import { InfoCircleOutlined } from '@ant-design/icons';
import ButtonWithTooltip from '../../../common/Control/ButtonWithTooltip';

export default BackupDeleteActionButton;
type BackupDeleteActionButtonProps = {
    cluster: CcCluster;
    backup: CcBackup;
    onSuccess?: () => void;
};

function BackupDeleteActionButton({
    cluster,
    backup,
    onSuccess,
}: BackupDeleteActionButtonProps) {
    const pgBackrestBlockDelete = backup?.isMethodPgBackrest();

    return pgBackrestBlockDelete ? (
        <ButtonWithTooltip
            type="link"
            size="small"
            disabled={true}
            tooltipProps={{
                title: (
                    <span>
                        A backup record pointing to existing PgBackRest backup
                        data can not be deleted from Cluster Control as
                        pgbackrestfull, pgbackrestdiff and pgbackrestincr
                        backups lifecycle are handled by pgbackrest process.
                        <br />
                        <br />
                        Backups removed by PgBackRest (by its retention) will be
                        recognised by Cluster Control but the backup record will
                        not be removed, only marked with missing data. This
                        record will be removed by ClusterControl's retention
                        policy.
                    </span>
                ),
            }}
        >
            <Space>
                <TypographyText muted={true}>
                    {getBackupActionFormatText(BackupAction.DELETE)}
                </TypographyText>
                <TypographyText muted={true}>
                    <InfoCircleOutlined />
                </TypographyText>
            </Space>
        </ButtonWithTooltip>
    ) : (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={CmonJobInstanceCommand.DELETE_BACKUP}
                    clusterId={cluster?.clusterId}
                    extraKey={[backup.getId()]}
                    critical={true}
                >
                    {getBackupActionFormatText(BackupAction.DELETE)}
                </JobActionButton>
            }
            form={<BackupDeleteModal backup={backup} onSuccess={onSuccess} />}
        />
    );
}
