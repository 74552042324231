import { ClusterConfiguratorFormValues } from '../../ClusterConfigurator';
import merge from 'deepmerge';
import { RepositoryItemKey } from '../../../../../common/DataEntry/RepositoryInput';
import MysqlGaleraConfigurator from '../../MysqlGalera/MysqlGaleraConfigurator';
import MysqlConfigurator, {
    MysqlFormValues,
} from '../../Mysql/MysqlConfigurator';
import React from 'react';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';

export default class MysqlGaleraDeploymentConfigurator extends MysqlGaleraConfigurator {
    public static getDefaults(): MysqlFormValues {
        return merge(MysqlGaleraConfigurator.getDefaults(), {
            details: {
                vendor: 'mariadb',
                version: '10.11',
            },
            nodeConfig: {
                repository: RepositoryItemKey.USE_VENDOR,
                sslEncryption: true,
            },
        });
    }

    public static getVendors() {
        const vendors = [...MysqlConfigurator.getVendors()];
        return vendors.map((vendor) => {
            if (vendor.value === 'percona') {
                return { ...vendor, extended: true, name: 'Percona XtraDB' };
            }
            return { ...vendor, extended: true };
        });
    }

    public static getJobOptions(
        formValues: ClusterConfiguratorFormValues
    ): any {
        const { primaryCluster } = formValues;
        return merge(MysqlGaleraConfigurator.getJobOptions(formValues), {
            job: {
                title: primaryCluster
                    ? `Create replica cluster`
                    : 'Deploy MySQL Galera Cluster',
            },
        });
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        return <NodeConfigurationSummary hasSslEncryption={true} {...props} />;
    }
}
