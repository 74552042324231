const result = {
    authenticated: true,
    cluster_type: 'REDIS',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: ['6', '7'],
    request_processed: '2024-03-14T09:58:57.065Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    vendor: 'redis',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
