import React from 'react';
import NodePortField from '../../../../common/Form/Fields/NodePortField';
import ClusterNodesForm, { ClusterNodesFormProps } from '../ClusterNodesForm';

export default MongoShardsConfigurationServerClusterNodesForm;

export type MongoShardsConfigurationServerClusterNodesFormProps = ClusterNodesFormProps & {
    clusterId?: number;
};

function MongoShardsConfigurationServerClusterNodesForm({
    clusterId,
    form,
    ...rest
}: MongoShardsConfigurationServerClusterNodesFormProps) {
    return (
        <div className="MongoShardsConfigurationServerClusterNodesForm">
            <ClusterNodesForm
                title="Configuration servers"
                form={form}
                topologyNames={['configurationServersTopology']}
                formItemProps={{ name: 'configurationServersTopology' }}
                nodesInputProps={{
                    onlyPrimaries: true,
                    formProps: {
                        primaryTitle: 'Add configuration server',
                        primaryExtra:
                            'Please note at least 3 configuration servers are recommended for production setups.',
                        extra: (
                            <div>
                                <NodePortField name="configurationServersPort" />
                            </div>
                        ),
                    },
                    mutateItem: (item) => ({
                        ...item,
                        description: 'Configuration server',
                    }),
                    clusterId,
                }}
                {...rest}
            />
        </div>
    );
}
