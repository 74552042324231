import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../Jobs/JobCreateActionButton';
import TypographyText from '../../common/TypographyText';

export default ClusterDisableSSLButton;

export type ClusterDisableSSLButtonProps = ButtonProps & {
    cluster: CcCluster;
    onSuccess?: () => void;
};

function ClusterDisableSSLButton({
    cluster,
    onSuccess,
    children,
    ...rest
}: ClusterDisableSSLButtonProps) {
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={
                CmonJobInstanceCommand.SETUP_SSL.toLowerCase() as CmonJobInstanceCommand
            }
            jobData={{
                action: 'disable',
                clusterId: `${cluster.clusterId}`,
                rolling_restart: true,
            }}
            confirmTitle={
                <TypographyText style={{ maxWidth: '450px', display: 'block' }}>
                    To disable SSL encryption,
                    <TypographyText strong>
                        {' '}
                        the nodes will be restarted
                    </TypographyText>{' '}
                    with a rolling restart. Applications may be affected during
                    this restart. Are you sure you want to disable SSL
                    encryption?
                </TypographyText>
            }
            title={'SSL encryption'}
            okText="Disable"
            onSuccess={onSuccess}
            switchValue={true}
            {...rest}
        />
    );
}
