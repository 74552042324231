import CcCluster from '../../../services/models/CcCluster';
import useTableFilter from '../../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import React, { useContext, useEffect, useMemo } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppTable from '../../../common/DataDisplay/AppTable';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import useTableFilterColumns, {
    TableFilterType,
} from '../../../common/hooks/useTableFilterColumns';
import AdvisorStatusFormat from './AdvisorStatusFormat';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import TagsList from '../../../common/DataDisplay/TagsList';
import AppDateFormat from '../../../common/AppDateFormat';
import AdvisorActionsMenu from './AdvisorActionsMenu';
import TypographyText from '../../../common/TypographyText';
import CcAdvisorSchedule, {
    getAdvisorSeverityPriority,
} from '../../../services/models/CcAdvisorSchedule';
import useAdvisorSchedules from './useAdvisorSchedules';
import useAdvisorTags from './useAdvisorTags';
import useAdvisorStatuses from './useAdvisorStatuses';
import AdvisorFormat from './AdvisorFormat';
import { getSortAlphabeticFn, getSortNumberFn } from '../../../common/sorting';

export default AdvisorList;
type AdvisorListProps = {
    cluster: CcCluster;
};

function AdvisorList({ cluster }: AdvisorListProps) {
    const { responsive } = useContext(ResponsiveContext);

    const { filterParams, handleTableChange } = useTableFilter({
        params: {
            tags: ArrayParam,
            status: ArrayParam,
        },
        defaultParams: {
            page: 1,
        },
    });

    const { loading, list, refresh } = useAdvisorSchedules({
        clusterId: cluster.clusterId,
    });

    useEffect(() => {}, [filterParams]);

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [cluster?.clusterId]);

    const tags = useAdvisorTags({ schedules: list || [] });
    const statues = useAdvisorStatuses({ schedules: list || [] });

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Advisor',
                    key: 'advisor',
                    sorter: (a: CcAdvisorSchedule, b: CcAdvisorSchedule) => {
                        return getSortAlphabeticFn(
                            'ascend',
                            (x: CcAdvisorSchedule) => {
                                return x?.getTitle() || '';
                            }
                        )?.(a, b);
                    },
                    render: (record: CcAdvisorSchedule) => (
                        <AdvisorFormat
                            advisorSchedule={record}
                            link={`/clusters/${cluster.clusterId}/performance/advisors?advisor=${record.scheduleid}`}
                        />
                    ),
                },
                {
                    title: 'Tags',
                    key: 'tags',
                    filters: tags.map((tag) => ({
                        value: tag,
                        text: tag,
                    })),
                    onFilter: (value: string, record: CcAdvisorSchedule) => {
                        return record.tags?.includes(value);
                    },
                    render: (record: CcAdvisorSchedule) =>
                        (record.tags?.length || 0) > 0 && (
                            <TagsList tags={record.tags || []} />
                        ),
                },
                {
                    title: 'Status',
                    key: 'status',
                    sorter: (a: CcAdvisorSchedule, b: CcAdvisorSchedule) => {
                        return getSortNumberFn(
                            'ascend',
                            (x: CcAdvisorSchedule) => {
                                return getAdvisorSeverityPriority(
                                    x.getCurrentStatus()?.severity
                                );
                            }
                        )?.(a, b);
                    },
                    filters: statues.map((status) => ({
                        value: status,
                        text: (
                            <AdvisorStatusFormat
                                status={status}
                                nowrap={true}
                            />
                        ),
                    })),
                    onFilter: (value: string, record: CcAdvisorSchedule) => {
                        return record.statusTitle === value;
                    },
                    render: (record: CcAdvisorSchedule) => (
                        <AdvisorStatusFormat
                            status={record.statusTitle}
                            nowrap={true}
                        />
                    ),
                },
                {
                    title: 'Schedule',
                    key: 'schedule',

                    render: (record: CcAdvisorSchedule) => (
                        <TypographyText muted={!record.enabled}>
                            <CronFormat>{record.file?.schedule}</CronFormat>
                        </TypographyText>
                    ),
                    filterType: TableFilterType.CLUSTER,
                },
                {
                    title: 'Last exec',
                    key: 'last_exec',
                    sorter: (a: CcAdvisorSchedule, b: CcAdvisorSchedule) => {
                        return getSortNumberFn(
                            'ascend',
                            (x: CcAdvisorSchedule) => {
                                return x.timestamp;
                            }
                        )?.(a, b);
                    },
                    render: (record: CcAdvisorSchedule) => (
                        <TypographyText muted={!record.enabled}>
                            <AppDateFormat fromNow>
                                {record.timestamp
                                    ? new Date(record.timestamp * 1000)
                                    : undefined}
                            </AppDateFormat>
                        </TypographyText>
                    ),
                    filterType: TableFilterType.CLUSTER,
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcAdvisorSchedule) => (
                        <AdvisorActionsMenu
                            advisorSchedule={record}
                            cluster={cluster}
                            onActionPerformed={async () => {
                                await refresh({});
                            }}
                        />
                    ),
                },
            ],
            [list, filterParams]
        ),
        filterParams,
    });

    return (
        <AppTable
            size={'middle'}
            loading={loading}
            rowKey={(record) => record.filename}
            dataSource={list}
            columns={columns}
            pagination={false}
            onChange={handleTableChange}
            responsive={responsive}
            onRow={() => {}}
            renderEmpty={
                <AppEmpty loading={loading} description="Nothing to show yet" />
            }
        />
    );
}
