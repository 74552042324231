import React from 'react';
import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';
import AppTooltip from '../Feedback/AppTooltip';

export default Switch;

export type SwitchProps = AntSwitchProps & {
    tooltip?: React.ReactNode;
};

function Switch({
    checkedChildren = 'On',
    unCheckedChildren = 'Off',
    tooltip,
    ...rest
}: SwitchProps) {
    const content = (
        <AntSwitch
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            {...rest}
        />
    );
    return tooltip ? (
        <AppTooltip title={tooltip}>{content}</AppTooltip>
    ) : (
        content
    );
}
