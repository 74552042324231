import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../common/AppDivider';
import YesNoFormat from '../../../common/Format/YesNoFormat';

export type ClusterInPlaceUpgradePreviewProps = {
    form: FormInstance;
};

export default ClusterInPlaceUpgradePreview;

function ClusterInPlaceUpgradePreview({
    form,
}: ClusterInPlaceUpgradePreviewProps) {
    const {
        method,
        currentVersion,
        upgradeVersion,
        temporaryMaster,
        temporaryUtcPort,
        deleteNode,
    } = form.getFieldsValue(true);
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                {method && (
                    <SpaceDescriptions.Item label="Method" labelStrong>
                        {method}
                    </SpaceDescriptions.Item>
                )}
                {currentVersion && (
                    <SpaceDescriptions.Item label="Current version" labelStrong>
                        {currentVersion}
                    </SpaceDescriptions.Item>
                )}
                {upgradeVersion && (
                    <SpaceDescriptions.Item label="Upgrade version" labelStrong>
                        {upgradeVersion}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Advanced"
                size="small"
                alignItems="right"
            >
                {temporaryMaster ? (
                    <SpaceDescriptions.Item
                        label="Temporary master port"
                        labelStrong
                    >
                        {temporaryMaster}
                    </SpaceDescriptions.Item>
                ) : null}

                {temporaryUtcPort ? (
                    <SpaceDescriptions.Item
                        label="Temporary upgrade candidate port"
                        labelStrong
                    >
                        {temporaryUtcPort}
                    </SpaceDescriptions.Item>
                ) : null}

                <SpaceDescriptions.Item label={'Delete old node'} labelStrong>
                    <YesNoFormat booleanVar={deleteNode} />
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        </div>
    );
}
