
import CcNode, { CcNodeProps } from '../../models/CcNode';

export interface CmonMsSqlHostReplicaProps {
    availability_mode?: string;
    connected_state?: string;
    failover_mode?: string;
    forced_failover_as_primary?: string;
    recovery_health?: string;
    synchronization_health?: string;
}


export interface CmonMsSqlHostProps  extends CcNodeProps {
    hostname_for_ag?: boolean;
    operational_state?: string;
    replica?: CmonMsSqlHostReplicaProps;
    synchronous?: boolean;

}

export class CmonMsSqlHostReplica {
    public availabilityMode?: string;
    public connectedState?: string;
    public failoverMode?: string;
    public forcedFailoverAsPrimary?: string;
    public recoveryHealth?: string;
    public synchronizationHealth?: string;

    constructor(props: CmonMsSqlHostReplicaProps) {
        this.availabilityMode = props.availability_mode;
        this.connectedState = props.connected_state;
        this.failoverMode = props.failover_mode;
        this.forcedFailoverAsPrimary = props.forced_failover_as_primary;
        this.recoveryHealth = props.recovery_health;
        this.synchronizationHealth = props.synchronization_health;

    }}



export default class CmonMsSqlHost extends CcNode {
    public hostnameForAg?: boolean;
    public operationalState?: string;
    public replica?: CmonMsSqlHostReplica;
    public synchronous?: boolean;


    constructor(props: CmonMsSqlHostProps) {
        super(props);
        this.hostnameForAg = props.hostname_for_ag;
        this.operationalState = props.operational_state;
        this.replica = props.replica && new CmonMsSqlHostReplica(props.replica);
        this.synchronous = props.synchronous;

    }

}
