import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateClustersMap,
    AppStateGlobalFilters,
    setClusters,
    setLicenseInfo,
    setNodeTypes,
    setTags,
    setTechnologies,
} from '../../appReducer';
import Immutable from 'immutable';
import {
    arrayFilter,
    arrayIsSubset,
    arrayPages,
    arrayUnique,
    collectionUniqueBy,
} from '../../common/filtering';

import {
    listReducer,
    initialListState,
    setListAll,
} from '../../common/listReducer';
import { getSortAlphabeticFn } from '../../common/sorting';
import CcCluster, {
    CcClusterState,
    CcClusterType,
    getClusterTypesByTechnology,
} from '../../services/models/CcCluster';
import useListFetch from '../../common/useListFetch';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import CcLicenseInfo from '../../services/models/CcLicenseInfo';
import CcNodeReplicationSlave from '../../services/models/CcNodeReplicationSlave';
import { arrayToMap } from '../../common/helpers';

export function getFiltersForClient(filterMap: AppStateGlobalFilters) {
    const filters = [];
    if (filterMap) {
        if (
            filterMap.get('technologies') &&
            filterMap.get('technologies').length > 0
        ) {
            filters.push((c: CcCluster) =>
                filterMap
                    .get('technologies')
                    .map((t: any) => getClusterTypesByTechnology(t))
                    .flat()
                    .includes(c.clusterType || '')
            );
        }
        if (filterMap.get('tags') && filterMap.get('tags').length > 0) {
            filters.push(
                (c: CcCluster) =>
                    c.tagList && arrayIsSubset(filterMap.get('tags'), c.tagList)
            );
        }
    }
    return filters;
}

type ListParams = {
    page?: number;
    pageSize?: number;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & {
    order?: null | ((a: any, b: any) => any);
    arr?: any[];
};

type RefreshFunctionParams = FetchRefreshFunctionParams;

type UseClusterListProps = {
    name?: string | null;
    pageSize?: number;
    excludeGlobalFilters?: boolean;
    useCache?: boolean;
    fromStore?: boolean;
    groupByParent?: boolean;
};
export default function useClusterList({
    name,
    pageSize,
    excludeGlobalFilters,
    useCache = true,
    fromStore = true,
    groupByParent = true,
}: UseClusterListProps = {}) {
    const dispatch = useDispatch();
    const {
        error,
        loading,
        loaded,
        list: clusters,
        refresh: refreshFetch,
        cancel,
        stopAutoRefresh,
    } = useListFetch({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            // await new Promise((resolve) => setTimeout(resolve, 5000));
            const {
                clusters,
                total,
                license,
                license_check,
            } = await CmonClustersService.getAllClusterInfo(
                {
                    ...params,
                    with_hosts: true,
                    with_license_check: true,
                },
                opts
            );
            dispatch(
                setLicenseInfo(
                    new CcLicenseInfo({ license, licenseCheck: license_check })
                )
            );
            return {
                list: clusters ? clusters : [],
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });

    const [storedMap, globalFilters]: [
        AppStateClustersMap,
        AppStateGlobalFilters
    ] = useSelector(({ clusters, globalFilters }: AppState) => [
        clusters,
        globalFilters,
    ]);
    const [list, setList] = useState<CcCluster[]>();
    const [total, setTotal] = useState<number>(0);
    const [totalFiltered, setTotalFiltered] = useState<number>(0);
    const [statuses, setStatuses] = useState<CcClusterState[]>();
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        order: getSortAlphabeticFn('ascend', (x) => x.clusterName),
    });

    const gFilters = useRef<any>(
        !excludeGlobalFilters ? getFiltersForClient(globalFilters) : []
    );

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = (storedMap && storedMap.toList().toArray()) || [],
        } = {}) => {
            const currentOrder = order === null ? undefined : order;
            listDispatch(
                setListAll({
                    page,
                    pageSize,
                    order: currentOrder,
                    filters,
                })
            );

            const filteredArr = arrayFilter({
                filters: [...(filters || []), ...gFilters.current],
                arr,
            });

            setList(
                arrayPages({
                    page,
                    pageSize,
                    order: groupByParent ? undefined : currentOrder,
                    arr: groupByParent
                        ? getSortedClustersByParent(filteredArr, currentOrder)
                        : filteredArr,
                })
            );
            setTotal(filteredArr.length);
            setTotalFiltered(arr.length - filteredArr.length);
            setStatuses(
                arr.reduce((accumulator, current) => {
                    return accumulator.includes(current.state)
                        ? accumulator
                        : [current.state, ...accumulator];
                }, [])
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            listPage,
            listPageSize,
            listOrder,
            listFilters,
            storedMap,
            gFilters.current,
        ]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshFetch({
                filters: gFilters.current,
                ...rest,
            });
        },
        [gFilters.current]
    );

    const hasClusterType = useCallback(
        (type: CcClusterType) =>
            !!(list && list.some((cluster) => cluster.clusterType === type)),
        [list]
    );

    useEffect(() => {
        if (clusters) {
            let clusterList = clusters.map((c: CcCluster) => {
                const replicationSlaveInfo = getReplicationSecondaryMap(c);
                let primaries: CcCluster[] = [];
                let secondaries: CcCluster[] = [];

                // we find relations between clusters
                clusters.forEach((clusterToTest: CcCluster) => {
                    const clusterToTestReplicationSlaveInfo = getReplicationSecondaryMap(
                        clusterToTest
                    );
                    if (c.clusterId !== clusterToTest.clusterId) {
                        if (
                            clusterToTest.clusterId &&
                            replicationSlaveInfo[clusterToTest.clusterId]
                        ) {
                            primaries.push(clusterToTest);
                        }
                        if (
                            c.clusterId &&
                            clusterToTestReplicationSlaveInfo[c.clusterId]
                        ) {
                            secondaries.push(clusterToTest);
                        }
                    }
                });

                c.setReplicationPrimaries(primaries);
                c.setReplicationSecondaries(secondaries);
                return c;
            });
            dispatch(
                setClusters(
                    Immutable.Map(
                        clusterList.map((c: CcCluster) => [c.getKey(), c])
                    )
                )
            );

            // set tags in global state
            const tags = clusterList
                .map((c: CcCluster) => (c.tagList ? c.tagList : []))
                .flat();
            dispatch(setTags(arrayUnique(tags)));

            // set technologies in global state
            const technologies = clusterList.map(
                (c: CcCluster) => c.getTechnology() || ''
            );
            technologies.sort();
            dispatch(setTechnologies(arrayUnique(technologies)));

            // set node types in global state
            const nodeTypes = clusterList
                .map((c: CcCluster) => c.nodeTypes)
                .flat();
            dispatch(setNodeTypes(arrayUnique(nodeTypes)));

            filter({
                arr: clusterList,
            });
        }
    }, [clusters]);

    useEffect(() => {
        if (storedMap && (loaded || fromStore)) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedMap]);

    useEffect(() => {
        if (!excludeGlobalFilters) {
            gFilters.current = getFiltersForClient(globalFilters);
        }
        if (loaded) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters]);

    return {
        error,
        loading,
        loaded,
        list,
        map: storedMap,
        refresh,
        total,
        totalFiltered,
        statuses,
        filter,
        cancel,
        page: listPage,
        pageSize: listPageSize,
        hasClusterType,
        stopAutoRefresh,
    };
}

/**
 * Returns a map of masterClusterId => CcNodeReplicationSlave
 */
function getReplicationSecondaryMap(
    cluster: CcCluster
): {
    [key: number]: CcNodeReplicationSlave;
} {
    return arrayToMap(
        collectionUniqueBy(
            cluster
                .getDatabaseNodes()
                .filter((node) => !!node.replicationSlave?.masterClusterId)
                .map((node) => node.replicationSlave),
            'masterClusterId'
        ),
        (item) => item.masterClusterId
    );
}

/**
 * Returns sorted cluster taking c2c relationships into account
 */
export function getSortedClustersByParent(
    clusters: CcCluster[],
    sortFn?: (a: any, b: any) => any
) {
    // get master clusters and non replicated clusters
    let sortedClusters = arrayUnique(
        clusters.filter(
            (cluster) =>
                !cluster.getReplicationPrimary() ||
                cluster.replicationSecondaries.length > 0
        ),
        (a, b) => a.clusterId === b.clusterId
    );

    if (sortFn) {
        sortedClusters.sort(sortFn);
    }
    sortedClusters = sortedClusters.reduce(
        (accumulator: CcCluster[], current: CcCluster) => {
            const sortedSecondaries = [...current.replicationSecondaries];
            if (sortFn) {
                sortedSecondaries.sort(sortFn);
            }
            // we add master and sorted replicas into the final array
            return [...accumulator, current, ...sortedSecondaries];
        },
        []
    );
    return arrayUnique(sortedClusters, (a, b) => a.clusterId === b.clusterId);
}
