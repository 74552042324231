import React, { useContext, useEffect, useRef } from 'react';
import CcCluster from '../../services/models/CcCluster';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppTable from '../../common/DataDisplay/AppTable';
import SpaceWide from '../../common/SpaceWide';
import AppDateFormat from '../../common/AppDateFormat';
import useListFetch from '../../common/useListFetch';
import CmonStatService from '../../services/cmon/CmonStatService';
import { CcNodeType } from '../../services/models/CcNode';
import NodeFormat from '../Nodes/NodeFormat';
import { Select, Space } from 'antd';
import { ClusterConfigContext } from '../Clusters/Config/ClusterConfigContext';
import AppSpin from '../../common/General/AppSpin';
import AppFormItem from '../../common/Form/AppFormItem';
import CcTransactionLogEntry, {
    CcTransactionLogEntryProps,
} from '../../services/models/CcTransactionLogEntry';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

const DEADLOCK_CHECK_INTERVALS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export default TransactionLogPage;

export type TransactionLogPageProps = {
    cluster: CcCluster;
};

function TransactionLogPage({ cluster }: TransactionLogPageProps) {
    const { responsive } = useContext(ResponsiveContext);
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);
    const { configGroupedLoading, configGrouped, changeValue } = useContext(
        ClusterConfigContext
    );

    const { list, loading, refresh, page, pageSize, total } = useListFetch<
        CcTransactionLogEntry
    >({
        name: 'transaction-log',
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const { data, total } = await CmonStatService.gettxdeadlock(
                {
                    cluster_id: cluster.clusterId,
                    limit: pageSize,
                    offset: ((page || 1) - 1) * pageSize,
                    ...rest,
                },
                opts
            );

            return {
                list: data.map(
                    (item: CcTransactionLogEntryProps) =>
                        new CcTransactionLogEntry({
                            ...item,
                            node: cluster.nodes.find(
                                (n) =>
                                    n.getAddress() ===
                                    `${CcNodeType.MYSQL}://${item.instance}`
                            ),
                        })
                ),
                total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonStatService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [cluster?.clusterId]);

    const columns = [
        {
            title: 'Node',
            key: 'node',
            render: (record: CcTransactionLogEntry) =>
                record.node && (
                    <NodeFormat node={record.node} showRole={false} />
                ),
        },
        {
            title: 'Host',
            key: 'hostname',
            render: (record: CcTransactionLogEntry) => record.host,
        },
        {
            title: 'DB',
            key: 'db',
            render: (record: CcTransactionLogEntry) => record.db,
        },
        {
            title: 'User',
            key: 'user',
            render: (record: CcTransactionLogEntry) => record.user,
        },
        {
            title: 'Tx Info',
            key: 'txid',
            render: (record: CcTransactionLogEntry) => (
                <InfoIcon
                    info={
                        <div>
                            <div>
                                Internal Tx ID: {record.internalTrxId || '-'}
                            </div>
                            <div>MySQL Tx ID: {record.mysqlTrxId || '-'}</div>
                            <div>InnoDB Tx ID: {record.innodbTrxId || '-'}</div>
                        </div>
                    }
                />
            ),
        },
        {
            title: 'Blocking Tx ID',
            key: 'blocking_txid',
            render: (record: CcTransactionLogEntry) => record.blockedByTrxId,
        },
        {
            title: 'Query',
            key: 'query',
            render: (record: CcTransactionLogEntry) =>
                record.sql || record.info || record.message,
        },
        {
            title: 'Duration',
            key: 'duration',
            render: (record: CcTransactionLogEntry) => `${record.duration}s`,
            align: 'right',
        },
        {
            title: 'Detected when',
            key: 'state',
            render: (record: CcTransactionLogEntry) => record.state,
            align: 'right',
        },
        {
            title: 'Last seen',
            key: 'last_seen',
            render: (record: CcTransactionLogEntry) => (
                <AppDateFormat>
                    {record.lastseen && new Date(record.lastseen * 1000)}
                </AppDateFormat>
            ),
        },
    ];
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        // onTableChange(pagination, filter, sorters);
        refresh({ page: pagination.current });
        // need to reset timeout in another tick so page is updated
        setTimeout(() => {
            monitorToolbarRef.current?.resetTimeout();
        });
    };
    const handleRefresh = (triggerClick?: boolean) => {
        refresh({ showLoading: !!triggerClick, page });
    };

    const handleIntervalChange = (value: number) => {
        changeValue('db_deadlock_check_interval', value);
    };
    const rowKey = (record: CcTransactionLogEntry) =>
        `${record.internalTrxId}-${record.innodbTrxId}-${record.mysqlTrxId}-${record.blockedByTrxId}`;

    return (
        <AppSpin spinning={loading || configGroupedLoading}>
            <SpaceWide
                direction="vertical"
                size={24}
                className="TransactionLogPage"
            >
                <MonitorToolbar
                    ref={monitorToolbarRef}
                    enableTimerange={false}
                    onRefresh={handleRefresh}
                    selectors={[
                        <AppFormItem
                            key="deadlock-check-interval"
                            label={
                                <Space>
                                    <span>Deadlock check interval</span>
                                    <InfoIcon
                                        info={
                                            configGrouped?.sampling
                                                ?.db_deadlock_check_interval
                                                ?.description
                                        }
                                    />
                                </Space>
                            }
                            noMarginBottom={true}
                        >
                            <Select
                                value={
                                    configGrouped?.sampling
                                        ?.db_deadlock_check_interval
                                        ?.current_value
                                }
                                options={[
                                    {
                                        label: 'Off',
                                        value: 0,
                                    },
                                    ...DEADLOCK_CHECK_INTERVALS.map((i) => ({
                                        label: `${i}s`,
                                        value: i,
                                    })),
                                ]}
                                dropdownMatchSelectWidth={false}
                                onChange={handleIntervalChange}
                            />
                        </AppFormItem>,
                    ]}
                />
                <AppTable
                    className="ProcessesPageTable"
                    rowKey={rowKey}
                    dataSource={list}
                    columns={columns}
                    pagination={{ current: page, pageSize, total }}
                    size="middle"
                    responsive={responsive}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </AppSpin>
    );
}
