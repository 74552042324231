import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';

import SSLModalWizard from './SSLModalWizard';

export default ClusterChangeCertificateSSLButton;

export type ClusterChangeCertificateSSLButtonProps = {
    cluster?: CcCluster;
    isGalera?: boolean;
};

function ClusterChangeCertificateSSLButton({
    cluster,
    isGalera = false,
}: ClusterChangeCertificateSSLButtonProps) {
    const message = isGalera
        ? 'Change Galera SSL Certificate'
        : 'Change SSL Certificate';

    return (
        <ButtonWithForm
            button={<ActionButton>{message}</ActionButton>}
            form={<SSLModalWizard galera={isGalera} cluster={cluster} />}
        />
    );
}
