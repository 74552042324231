import React, { useRef, useState } from 'react';
import ModalDefault from '../../common/ModalDefault';
import { notifyConfirmCloseWindow } from '../Notifications/uiNotification';
import MotionShake, { MotionApi } from '../../common/motion/MotionShake';

import CcCluster from '../../services/models/CcCluster';
import ServiceWizard, {
    getServiceWizardActionTypeText,
    ServiceWizardChangeDetails,
    ServiceWizardProps,
    ServiceWizardStep,
} from './ServiceWizard';
import { getClusterTypeText } from '../Clusters/ClusterTypeFormat';

export default ServiceWizardModal;

export type ServiceWizardModalProps = {
    wizardProps?: ServiceWizardProps;
    cluster?: CcCluster;
    onCancel?: () => void;
};

function ServiceWizardModal({
    wizardProps,
    onCancel,
}: ServiceWizardModalProps) {
    const [confirmClose, setConfirmClose] = useState(false);

    const [title, setTitle] = useState<string | undefined>(
        getGeneratedTitle(wizardProps?.activeStep, {
            clusterType: wizardProps?.initialClusterType,
            actionType: wizardProps?.defaultActionType,
        })
    );
    const animationRef = useRef<MotionApi | undefined>();

    const stepChangeHandler = (
        step: ServiceWizardStep,
        info: ServiceWizardChangeDetails
    ) => {
        setTitle(getGeneratedTitle(step, info));
    };
    const handleFormTouchedChange = (touched: boolean) => {
        setConfirmClose(touched);
    };
    const handleFormStepErrorInsist = (err: Error) => {
        if (animationRef.current) {
            animationRef.current.animate();
        }
    };

    const handleCancel = () => {
        if (confirmClose) {
            notifyConfirmCloseWindow({
                onOk: () => {
                    onCancel?.();
                },
            });
        } else {
            onCancel?.();
        }
    };

    return (
        <ModalDefault
            title={title}
            animateHeight={true}
            visible={true}
            onCancel={handleCancel}
            modalRender={(content) => (
                <MotionShake ref={animationRef}>{content}</MotionShake>
            )}
        >
            <ServiceWizard
                onChange={stepChangeHandler}
                onSuccess={onCancel}
                onFormTouchedChange={handleFormTouchedChange}
                onFormStepErrorInsist={handleFormStepErrorInsist}
                {...wizardProps}
            />
        </ModalDefault>
    );
}
function getGeneratedTitle(
    step?: ServiceWizardStep,
    info: ServiceWizardChangeDetails = {}
) {
    if (step === ServiceWizardStep.SERVICE) {
        return `${getServiceWizardActionTypeText(info.actionType)} cluster`;
    } else if (
        [
            ServiceWizardStep.DEPLOY_WIZARD,
            ServiceWizardStep.IMPORT_WIZARD,
        ].includes(step)
    ) {
        return `${getServiceWizardActionTypeText(
            info.actionType
        )} ${getClusterTypeText(info.clusterType)} cluster`;
    } else {
        return '';
    }
}
