import { useCallback, useEffect, useReducer, useState } from 'react';
import { arrayFilter, arrayPages } from '../../../../../../common/filtering';
import { FetchRefreshFunctionParams } from '../../../../../../common/useFetch';
import useListFetch from '../../../../../../common/useListFetch';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import {
    initialListState,
    listReducer,
    setListAll,
} from '../../../../../../common/listReducer';
import { getSortAlphabeticFn } from '../../../../../../common/sorting';
import CcProxySqlSchedule from '../../../../../../services/models/CcProxySqlSchedule';

type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => any;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & { arr?: any[] };
type RefreshFunctionParams = FetchRefreshFunctionParams;

export type UseProxySQLSchedulerScriptListProps = {
    pageSize?: number;
    clusterId: number | undefined;
    hostName: string | undefined;
    port: number | undefined;
    filters?: Function[];
};
export default function useProxySQLSchedulerScript({
    pageSize = 15,
    clusterId,
    filters,
    hostName,
    port,
}: UseProxySQLSchedulerScriptListProps) {
    const {
        loading,
        loaded,
        list: variablesList,
        total,
        refresh: refreshVariables,
    } = useListFetch({
        name: 'querySchedules',
        pageSize,
        fetchFn: async ({ pageSize, page, ...rest }, opts) => {
            const response = await CmonProxysqlService.queryschedules(
                {
                    cluster_id: clusterId,
                    hostName: hostName,
                    port: port,
                    ...rest,
                },
                opts
            );

            return {
                list: response?.queryResults,
                total: response?.queryResultTotalCount,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonProxysqlService.cancelRequest(requestId);
        },
    });

    const [list, setList] = useState<CcProxySqlSchedule[]>();

    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize || 0,
        filters: filters,
        order: getSortAlphabeticFn('ascend', (x) => x.id),
    });

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = variablesList || [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            const filteredArr = arrayFilter({
                filters: [...(filters || [])],
                arr,
            });

            setList([
                ...arrayPages({
                    page,
                    pageSize,
                    order,
                    arr: filteredArr,
                }),
            ]);
        },

        [listPage, listPageSize, listOrder, listFilters, variablesList]
    );
    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshVariables({
                ...rest,
            });
        },
        []
    );

    useEffect(() => {
        if (Array.isArray(variablesList)) {
            filter({
                arr: variablesList,
            });
        }
    }, [variablesList]);

    return {
        list,
        loading,
        loaded,
        refresh,
        filter,
        page: listPage,
        pageSize: listPageSize,
        total,
    };
}
