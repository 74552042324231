import React from 'react';
import TopologySummary, { TopologySummaryProps } from '../../TopologySummary';
import AppDivider from '../../../../../common/AppDivider';
export default RedisShardedDeploymentTopologySummary;

export type RedisShardedDeploymentTopologySummaryProps =
    TopologySummaryProps & {};

function RedisShardedDeploymentTopologySummary({
    form,
    ...props
}: RedisShardedDeploymentTopologySummaryProps) {
    const shards = form.getFieldValue('shards');
    const shardKeys: string[] = Object.keys(shards).filter(
        (k) => shards[k] !== undefined
    );
    shardKeys.sort();

    return (
        <div className="RedisShardedDeploymentTopologySummary">
            {shardKeys.map((shardKey: string) => {
                const shard = shards[shardKey];
                const perNode = shard.perNode;
                return (
                    <div key={shardKey}>
                        <TopologySummary
                            key={shardKey}
                            primaryTitle="Primary"
                            title={` ${shard.shardName}`}
                            topologyFieldPath={['shards', shardKey, 'topology']}
                            renderNode={(item) => {
                                const nodeProps = [];
                                const { dataIp } =
                                    perNode[item.extraData.hostname] || {};
                                if (dataIp) {
                                    nodeProps.push(`data: ${dataIp}`);
                                }

                                return (
                                    <span key={item.extraData.hostname}>
                                        {item.title}
                                        {nodeProps.length > 0
                                            ? ` (${nodeProps.join(', ')})`
                                            : ''}
                                    </span>
                                );
                            }}
                            form={form}
                            {...props}
                        />

                        <AppDivider />
                    </div>
                );
            })}
        </div>
    );
}
