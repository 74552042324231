import React from 'react';
import { Button, Col, Form, Row, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ClusterNodeSelect from '../../../common/DataEntry/ClusterNodeSelect';
import CcNode, { CcNodeType } from '../../../services/models/CcNode';
import NodeStopForm from '../../Nodes/Actions/NodeStopForm';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import ClusterFormat from '../ClusterFormat';
import SpaceWide from '../../../common/SpaceWide';

export default ClusterBootstrapForm;

export type ClusterBootstrapFormProps = {
    cluster: CcCluster;
    node?: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type ClusterBootstrapFormValues = {
    stopTimeout?: number;
    forceStop?: boolean;
    startInitial?: boolean;
    autoSelect?: boolean;
    bootstrapNode?: string;
};

function ClusterBootstrapForm({
    cluster,
    node,
    onSuccess,
    onCancel,
}: ClusterBootstrapFormProps) {
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Bootstrap cluster',
        command: CmonJobInstanceCommand.BOOTSTRAP_CLUSTER,
        onSuccess,
    });
    const [form] = Form.useForm<ClusterBootstrapFormValues>();

    const handleSubmit = async (values: ClusterBootstrapFormValues) => {
        await send(
            {
                stop_timeout: values.stopTimeout,
                force: values.forceStop,
                clusterId: cluster.clusterId,
                start_initial: values.startInitial,
                auto_select: values.bootstrapNode === 'auto',
                donor_address:
                    values.bootstrapNode !== 'auto'
                        ? values.bootstrapNode
                        : undefined,
            },
            {
                job: {
                    title:
                        values.bootstrapNode !== 'auto'
                            ? `Bootstrapping Cluster (using donor ${values.bootstrapNode}) `
                            : 'Bootstrapping Cluster',
                },
            }
        );
    };
    return (
        <ModalDefaultForm
            title="Bootstrap cluster"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
        >
            <Form
                className="ClusterBootstrapForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    bootstrapNode: node?.hostname ? node?.hostname : 'auto',
                    stopTimeout: 1800,
                    forceStop: false,
                    startInitial: false,
                }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Bootstrap node"
                            name={'bootstrapNode'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select bootstrap node',
                                },
                            ]}
                        >
                            <ClusterNodeSelect
                                data-testid="cluster-bootstrap-form-node-select"
                                cluster={cluster}
                                primaryCandidatesOnly={false}
                                types={[CcNodeType.GALERA]}
                                extraOptionsTop={[
                                    {
                                        label: 'Auto select',
                                        value: 'auto',
                                    },
                                ]}
                            />
                        </Form.Item>
                        <SpaceWide direction="vertical">
                            <NodeStopForm.StopTimeoutInput />
                            <NodeStopForm.ForceStopInput />
                            <FormItemInlineSwitch
                                justify
                                noMargin
                                name="startInitial"
                                label={
                                    <Space>
                                        <span>
                                            Clear MySQL Datadir on Joining
                                            nodes.
                                        </span>
                                        <InfoIcon
                                            info={
                                                <span>
                                                    It’s often needed after
                                                    restore.
                                                </span>
                                            }
                                        />
                                    </Space>
                                }
                                valuePropName="checked"
                            />
                        </SpaceWide>
                    </Col>
                    <Col span={12}>
                        <SpaceWide direction="vertical">
                            <ClusterFormat
                                cluster={cluster}
                                size="large"
                                wide={true}
                            />
                            <Alert
                                message={
                                    <span>
                                        The following actions will be performed:
                                        <ol>
                                            <li>
                                                The cluster will be initialized
                                                from the selected node.
                                            </li>
                                            <li>
                                                All nodes will be stopped,
                                                unless they are already stopped.
                                            </li>
                                            <li>
                                                When the bootstrap command is
                                                successful, the selected node
                                                will be Synced. The rest of the
                                                nodes will be started as
                                                joiners, one node at a time.
                                            </li>
                                        </ol>
                                    </span>
                                }
                                showIcon={true}
                            />
                            <NodeStopForm.Notice />
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Bootstrap
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
