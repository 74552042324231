import React, { useContext } from 'react';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import { Alert, Form, Select, Space } from 'antd';
import BackupMethodSelect from '../BackupMethodSelect';
import TypographyText from '../../../common/TypographyText';
import CcCluster, {
    CcClusterTechnology,
} from '../../../services/models/CcCluster';
import { ClusterConfigContext } from '../../Clusters/Config/ClusterConfigContext';
import {
    CcBackupMethod,
    isBackupMethodPgBackRest,
} from '../../../services/models/CcBackup';

export default BackupMethodSelectField;
export type BackupMethodSelectFieldProps = FormItemProps & {
    form: FormInstance;
    cluster?: CcCluster;
    disabled?: boolean;
    schedule?: boolean;
};

function BackupMethodSelectField({
    form,
    cluster,
    disabled = false,
    schedule = false,
    ...rest
}: BackupMethodSelectFieldProps) {
    const { configGrouped } = useContext(ClusterConfigContext);
    const stanzaName = isBackupMethodPgBackRest(form.getFieldValue('method'))
        ? `${
              configGrouped?.backup?.pgbackrest_stanza_name?.current_value || ''
          }`
        : undefined;

    const handleBackupMethodChange = () => {
        if (form.getFieldValue('method') === CcBackupMethod.MYSQL_BINLOG)
            form.setFieldsValue({
                cloudUpload: true,
                deleteAfterUpload: true,
            });
    };
    return (
        <Form.Item
            name="method"
            label={<Space>Backup method</Space>}
            rules={[
                {
                    required: true,
                    message: 'Please select backup method.',
                },
            ]}
            {...rest}
        >
            {cluster ? (
                <BackupMethodSelect
                    disabled={disabled}
                    cluster={cluster}
                    allowClear
                    data-testid="backup-configuration-method-select"
                    extra={
                        <>
                            {showBackupPitrNotPossible(
                                form.getFieldValue('method'),
                                cluster
                            ) ? (
                                <Alert
                                    type="warning"
                                    message="PITR is not possible with the selected backup method."
                                />
                            ) : null}
                            {stanzaName ? (
                                <TypographyText
                                    muted
                                >{`Stanza name: ${stanzaName}`}</TypographyText>
                            ) : null}
                        </>
                    }
                    onChange={handleBackupMethodChange}
                    filterMethods={(method) =>
                        schedule || method !== CcBackupMethod.MYSQL_BINLOG
                    }
                />
            ) : (
                <Select />
            )}
        </Form.Item>
    );
}

function showBackupPitrNotPossible(method: CcBackupMethod, cluster: CcCluster) {
    return (
        cluster?.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL) &&
        !isBackupMethodPgBackRest(method) &&
        method !== CcBackupMethod.PGBASEBACKUP
    );
}
