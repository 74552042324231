import React from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import CcNode from '../../services/models/CcNode';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster from '../../services/models/CcCluster';
import ManageMaintenanceModal from './ManageMaintenanceModal';

export default ManageMaintenanceButton;

export type ManageMaintenanceButtonProps = {
    node?: CcNode;
    cluster?: CcCluster;
    onSuccess?: () => void;
    children?: React.ReactNode;
};

function ManageMaintenanceButton({
    node,
    cluster,
    onSuccess,
    children,
}: ManageMaintenanceButtonProps) {
    return (
        <ButtonWithForm
            button={
                <ActionButton>
                    {children || 'Manage scheduled maintenance'}
                </ActionButton>
            }
            form={
                <ManageMaintenanceModal
                    node={node}
                    cluster={cluster}
                    onSuccess={onSuccess}
                />
            }
        />
    );
}
