import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CloudCredentialsModalEdit from './CloudCredentialsModalEdit';
import CloudCredentialsDeleteActionButton from './CloudCredentialsDeleteActionButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default CloudCredentialsActionMenu;

export type CloudCredentialsActionMenuProps = ActionsMenuProps & {
    credentials: CcCloudCredentials;
    onActionPerformed?: () => void;
};

function CloudCredentialsActionMenu({
    credentials,
    onActionPerformed,
    ...rest
}: CloudCredentialsActionMenuProps) {
    const handleActionSuccess = () => {
        onActionPerformed?.();
    };
    return (
        <ActionsMenu
            items={
                credentials.credentials
                    ? [
                          {
                              key: 'edit',
                              icon: <EditOutlined />,
                              label: (
                                  <ButtonWithForm
                                      button={<ActionButton>Edit</ActionButton>}
                                      form={
                                          <CloudCredentialsModalEdit
                                              credentials={credentials}
                                              onSuccess={handleActionSuccess}
                                          />
                                      }
                                  />
                              ),
                          },
                          {
                              key: 'delete',
                              waitForConfirm: true,
                              critical: true,
                              icon: <DeleteOutlined />,
                              label: (
                                  <CloudCredentialsDeleteActionButton
                                      key="delete"
                                      credentials={credentials}
                                      onSuccess={handleActionSuccess}
                                  />
                              ),
                          },
                      ]
                    : []
            }
            {...rest}
        />
    );
}
