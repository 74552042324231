import React, { useEffect } from 'react';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';

import { Space } from 'antd';
import AppDivider from '../common/AppDivider';
import NodeActionsMenu from '../components/Nodes/Actions/NodeActionsMenu';
import { getNodeHostWithDesc } from '../components/Nodes/NodeFormat';
import ClusterFormat from '../components/Clusters/ClusterFormat';
import galera from './dataMocks/clusters/galera';
import {
    CcNodeRole,
    CcNodeStatus,
    CcNodeType,
} from '../services/models/CcNode';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import mssqlSingle from './dataMocks/clusters/mssqlSingle';
import mssqlReplication from './dataMocks/clusters/mssqlReplication';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';
import postgresStreamingReplication from './dataMocks/clusters/postgresStreamingReplication';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import CmonMaintenanceService from '../services/cmon/CmonMaintenanceService';
import { debugModal } from './serviceMock/ServiceMockContext';
import CmonClustersService from '../services/cmon/CmonClustersService';
import getConfig1 from './dataMocks/rpc/clusters/getConfig1';
import mongodb1 from './dataMocks/clusters/mongodb1';
import useScheduledMaintenancesList from '../components/Maintenance/useScheduledMaintenancesList';

const clusters = [
    mongodb1,
    postgresSingleNode,
    postgresStreamingReplication,
    {
        ...postgresStreamingReplication,
        cluster_name: `${postgresStreamingReplication.cluster_name} (with not connected primary)`,
        hosts: postgresStreamingReplication.hosts.map((h) => {
            if (h.nodetype === CcNodeType.POSTGRESQL && h.role === 'master') {
                return {
                    ...h,
                    connected: false,
                };
            } else {
                return h;
            }
        }),
    },
    postgresWithLb,
    {
        ...postgresWithLb,
        cluster_name: `${postgresWithLb.cluster_name} (with sync replication and active maintenance)`,
        hosts: postgresWithLb.hosts.map((h) => {
            if (h.nodetype === CcNodeType.POSTGRESQL && h.role === 'slave') {
                return {
                    ...h,
                    sync_state: 'sync',
                    maintenance_mode_active: true,
                };
            } else {
                return h;
            }
        }),
    },
    mssqlSingle,
    mssqlReplication,
    galera,
    {
        ...galera,
        cluster_name: `${galera.cluster_name} (with shut down nodes)`,
        hosts: galera.hosts.map((h) => {
            if (h.nodetype === CcNodeType.GALERA) {
                return {
                    ...h,
                    hoststatus: CcNodeStatus.CmonHostShutDown,
                };
            } else {
                return h;
            }
        }),
    },
    mysqlReplication,
    {
        ...mysqlReplication,
        cluster_name: `${mysqlReplication.cluster_name} (with replication completely off)`,
        hosts: mysqlReplication.hosts.map((h) => {
            if (
                h.nodetype === CcNodeType.MYSQL &&
                h.role === CcNodeRole.SLAVE
            ) {
                return {
                    ...h,
                    replication_slave: {
                        ...h.replication_slave,
                        slave_io_running: 'No',
                        slave_sql_running: 'No',
                    },
                };
            } else {
                return h;
            }
        }),
    },
    {
        ...mysqlReplication,
        cluster_name: `${mysqlReplication.cluster_name} (with replication IO off)`,
        hosts: mysqlReplication.hosts.map((h) => {
            if (
                h.nodetype === CcNodeType.MYSQL &&
                h.role === CcNodeRole.SLAVE
            ) {
                return {
                    ...h,
                    replication_slave: {
                        ...h.replication_slave,
                        slave_io_running: 'No',
                    },
                };
            } else {
                return h;
            }
        }),
    },
    {
        ...mysqlReplication,
        cluster_name: `${mysqlReplication.cluster_name} (with replication SQL off)`,
        hosts: mysqlReplication.hosts.map((h) => {
            if (
                h.nodetype === CcNodeType.MYSQL &&
                h.role === CcNodeRole.SLAVE
            ) {
                return {
                    ...h,
                    replication_slave: {
                        ...h.replication_slave,
                        slave_sql_running: 'No',
                    },
                };
            } else {
                return h;
            }
        }),
    },
    {
        ...mysqlReplication,
        cluster_name: `${mysqlReplication.cluster_name} (with shut down nodes)`,
        hosts: mysqlReplication.hosts.map((h) => {
            if (
                h.nodetype === CcNodeType.MYSQL &&
                h.role === CcNodeRole.SLAVE
            ) {
                return {
                    ...h,
                    hoststatus: CcNodeStatus.CmonHostShutDown,
                };
            } else {
                return h;
            }
        }),
    },
].map((c) => new CcCluster(c as any));

export default DebugNodeActionsMenu;

export type DebugNodeActionsMenuProps = {};

function DebugNodeActionsMenu({}: DebugNodeActionsMenuProps) {
    CmonMaintenanceService.addMaintenance = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;
    CmonClustersService.getConfig = () => Promise.resolve(getConfig1);
    const {
        refresh: refreshScheduledMaintenances,
    } = useScheduledMaintenancesList({});
    CmonMaintenanceService.addMaintenance = ((data: any, opts: any) => {
        return debugModal(data, opts, {});
    }) as any;

    useEffect(() => {
        (async () => {
            await refreshScheduledMaintenances({});
        })();
    }, []);

    return (
        <Space className="DebugNodeActionsMenu" direction="vertical" size={20}>
            {clusters.map((c) => (
                <div key={c.getKey()}>
                    <SpaceDescriptions
                        title={<ClusterFormat cluster={c} showId={false} />}
                        className="DebugNodeActionsMenu_Cluster"
                        data-testid={`${c.clusterName}`}
                        direction={'horizontal'}
                    >
                        {c.nodes.map((n) => {
                            // console.log(n);
                            return (
                                <SpaceDescriptions
                                    key={n.getName()}
                                    className="DebugNodeActionsMenu_Node"
                                    title={getNodeHostWithDesc(n)}
                                    data-testid={`${
                                        c.clusterName
                                    }-${getNodeHostWithDesc(n)}`}
                                >
                                    <NodeActionsMenu node={n} cluster={c} />
                                </SpaceDescriptions>
                            );
                        })}
                    </SpaceDescriptions>
                    <AppDivider />
                </div>
            ))}
        </Space>
    );
}
