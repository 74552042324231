import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import moment from 'moment';
import { Space } from 'antd';
import { Line, Area, Scatter, Bar, Column } from '@antv/g2plot';
import './TimelineChart.less';
import classNames from 'classnames';
import { getChartTheme } from '../../DataDisplay/AppColumnChart';
import AppSpin from '../../General/AppSpin';

/**
 * Returns collection used for setting data to the chart
 * based on seriesData map
 */
function getDataCollection(seriesData) {
    return Object.values(seriesData).reduce(
        (acc, curr) => [
            ...acc,
            ...curr.data.map(([t, v, cat]) => ({
                t,
                v,
                type: curr.name || cat,
            })),
        ],
        []
    );
}

/**
 * Retuns best format for time range
 */
export function getDateTimeFormatByRange(from, to) {
    const oneDay = 60 * 60 * 24;
    const sevenDays = oneDay * 7;
    const diff = to - from;
    if (diff <= oneDay) {
        return moment.HTML5_FMT.TIME_SECONDS;
    } else if (diff <= sevenDays) {
        return moment.HTML5_FMT.DATE;
    } else {
        return moment.HTML5_FMT.DATE;
    }
}

/**
 * Returns best interval depending on the range
 * Used for setting the ticks
 */
export function getIntervalByRange(from, to) {
    const oneMinute = 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const sevenDays = oneDay * 7;
    const diff = to - from;
    if (diff <= oneHour) {
        return oneMinute;
    } else if (diff <= oneDay) {
        return oneHour;
    } else if (diff <= sevenDays) {
        return oneDay;
    } else {
        return oneDay;
    }
}

function TimelineChart(
    {
        loading,
        data,
        dataUpdate,
        from,
        to,
        series,
        stacked,
        type,
        yMin,
        yMax,
        valueFormatter,
        filters,
        filteredValues,
        legendAsTable,
        timezone,
        onTooltipShow,
        onTooltipHide,
        onRangeSelect,
        onPlotClick,
    },
    ref
) {
    const [finalMin, setFinalMin] = useState(from || 0);
    const [finalMax, setFinalMax] = useState(to || 0);
    const [loaded, setLoaded] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const rawDataRef = useRef([]);
    const serieDataRef = useRef({});
    const plotRef = useRef(null);
    const chartContainerRef = useRef(null);

    useImperativeHandle(ref, () => ({
        showTooltip(coordinate) {
            plotRef.current?.chart?.showTooltip(coordinate);
        },
        hideTooltip() {
            plotRef.current?.chart?.hideTooltip();
        },
    }));

    const [config] = useState(() => ({
        height: 200,
        xField: 't',
        yField: 'v',
        point: {
            size: 0,
        },
        seriesField: 'type',
        isStack: stacked,
        lineStyle: { lineWidth: 1.5 },
        areaStyle: { lineWidth: 1.5 },
        connectNulls: false,
        legend: !!legendAsTable ? false : {},
        meta: {
            t: {
                formatter: (v, key) => {
                    return moment(new Date(v * 1000)).format(
                        getDateTimeFormatByRange(finalMin, finalMax)
                    );
                },
                type: 'time',
                alias: 'time',
                max: finalMax,
                min: finalMin,
                // minLimit: finalMin,
                nice: false,
                tickMethod: 'time',
                tickInterval: getIntervalByRange(finalMin, finalMax),
            },
            v: {
                formatter: (v, key) => {
                    return valueFormatter?.(v) || `${v}`;
                },
                max: yMax,
                min: yMin || 0,
                minLimit: yMin,
            },
        },
        limitInPlot: true,
        animation: false,
        theme: getChartTheme(),
    }));

    const handleMouseMove = (evt) => {
        const { x, y } = evt.gEvent;
        //@todo: check if tooltip is being shown to trigger this event
        onTooltipShow?.({ x, y });
    };
    const handleMouseOut = () => {
        onTooltipHide?.();
    };

    const handleClick = (evt) => {
        const { x, y } = evt;
        const tooltipData = plotRef.current.chart.getTooltipItems({ x, y });
        onPlotClick?.(tooltipData, evt);
    };

    useEffect(() => {
        if (!loaded) {
            if (type === 'area') {
                plotRef.current = new Area(chartContainerRef.current, {
                    data: [],
                    ...config,
                });
            } else if (type === 'scatter') {
                plotRef.current = new Scatter(chartContainerRef.current, {
                    data: [],
                    ...config,
                    size: 1,
                });
            } else if (type === 'bar') {
                // line
                plotRef.current = new Bar(chartContainerRef.current, {
                    data: [],
                    ...config,
                });
            } else if (type === 'column') {
                // line
                plotRef.current = new Column(chartContainerRef.current, {
                    data: [],
                    ...config,
                });
            } else {
                plotRef.current = new Line(chartContainerRef.current, {
                    data: [],
                    ...config,
                });
            }
            plotRef.current.chart.interaction('brush-x', {
                end: [
                    {
                        trigger: 'mouseup',
                        action: [
                            'brush-x:end',
                            'x-rect-mask:end',
                            'x-rect-mask:hide',
                            'tooltip:show',
                        ],
                        callback: (context) => {
                            // used for getting starting point
                            const tooltipAction = context.actions.find(
                                (a) => a.name === 'tooltip'
                            );
                            // used for getting ending point
                            const rangeFilter = context.actions.find(
                                (a) => a.name === 'brush-x'
                            );
                            // offset in plot
                            // [ Y       |------- ---- ---------| ]
                            // [ Y       |------- ---- ---------| ]
                            // [ Y       |------- chart --------| ]
                            // [ Y       |------- ---- ---------| ]
                            // [ Y       |------- ---- ---------| ]
                            // [ 0       |______________________| ]
                            // [         X X X X X X X X X X X X  ]
                            // <-offset-><-   coordinateBBox   -> ]
                            const coordinatesViewOffsetX =
                                context.view.coordinateBBox.minX;
                            let { x: mouseDownX } = rangeFilter.startPoint;
                            let { x: mouseUpX } = tooltipAction.location;

                            // applying offset to mouse clicks x coodinates
                            mouseDownX -= coordinatesViewOffsetX;
                            mouseUpX -= coordinatesViewOffsetX;
                            const coordinatesViewWidth =
                                context.view.coordinateBBox.maxX -
                                context.view.coordinateBBox.minX;
                            const tsDiff = finalMax - finalMin;
                            // get coeficients over geometrical width
                            const startRate = mouseDownX / coordinatesViewWidth;
                            const endRate = mouseUpX / coordinatesViewWidth;
                            // applying coeficients over time width
                            const startTs = Math.floor(
                                finalMin + tsDiff * startRate
                            );
                            const endTs = Math.floor(
                                finalMin + tsDiff * endRate
                            );
                            // triggering event
                            onRangeSelect?.(
                                new CustomEvent('rangeselect', {
                                    detail: { from: startTs, to: endTs },
                                })
                            );
                        },
                    },
                ],
            });

            // plotRef.current.chart.removeInteraction('legend-filter');
            plotRef.current.on('mousemove', handleMouseMove);
            plotRef.current.on('mouseout', handleMouseOut);
            plotRef.current.on('click', handleClick);
            setLoaded(true);
        }
        return () => {};
    }, []);

    const resolveSeriesRef = (data) => {
        const colors =
            Object.keys(serieDataRef.current).length > 10
                ? config.theme.colors20
                : config.theme.colors10;
        serieDataRef.current = data.reduce((result, dataRow, dataRowIdx) => {
            const serieName =
                (dataRow[0] && dataRow[0][2]) || `serie${dataRowIdx}`;
            let min = dataRow[0] && dataRow[0][1];
            let max = dataRow[0] && dataRow[0][1];
            let avg =
                dataRow.reduce((res, d) => {
                    if (d[1] > max || [undefined, null].includes(max)) {
                        max = d[1];
                    }
                    if (d[1] < min || [undefined, null].includes(min)) {
                        min = d[1];
                    }
                    return res + d[1];
                }, 0) / dataRow.length;
            if (!result[serieName]) {
                result[serieName] = {
                    key: serieName,
                    name: serieName,
                    sort: dataRowIdx,
                    color: colors[dataRowIdx],
                };
            }

            result[serieName] = {
                ...result[serieName],
                min: min,
                max: max,
                avg: avg,
                data: dataRow,
            };
            return result;
        }, []);
    };

    // data reloaded
    useEffect(() => {
        if (plotRef.current && data && loaded) {
            setFinalMin(from);
            setFinalMax(to);
            rawDataRef.current = data;
            resolveSeriesRef(rawDataRef.current);
            plotRef.current.changeData(getDataCollection(serieDataRef.current));
            setDataLoaded(true);
        }
    }, [data, loaded]);

    // data update
    useEffect(() => {
        if (plotRef.current && dataUpdate && loaded && dataLoaded) {
            setFinalMin(from);
            setFinalMax(to);
            rawDataRef.current = data;
            resolveSeriesRef(rawDataRef.current);
            plotRef.current.changeData(getDataCollection(serieDataRef.current));
            dataUpdate.forEach((dataRow, dataRowIdx) => {
                const currentDataRow = rawDataRef.current[dataRowIdx];
                if (currentDataRow) {
                    if (dataRow[0]) {
                        let cleanUpdateDataRow = dataRow;
                        if (currentDataRow.length > 0) {
                            const lastRowTs =
                                currentDataRow[currentDataRow.length - 1][0];
                            // purge duplicate entries
                            // @TODO we can do this in more preformant way given the data is sorted by timestamp
                            cleanUpdateDataRow = dataRow.filter(
                                (d) => d[0] > lastRowTs
                            );
                        }
                        rawDataRef.current[dataRowIdx] = [
                            // purge data from the begining
                            // @TODO we can do this in more preformant way given the data is sorted by timestamp
                            ...currentDataRow.filter(
                                (d) => d[0] > finalMin - 10
                            ),
                            ...cleanUpdateDataRow,
                        ];
                    }
                }
            });
            resolveSeriesRef(rawDataRef.current);
            plotRef.current.changeData(getDataCollection(serieDataRef.current));
        }
    }, [dataUpdate]);

    // update config
    useEffect(() => {
        if (plotRef.current) {
            plotRef.current.update({
                meta: {
                    t: {
                        formatter: (v, key) => {
                            return (timezone && moment.tz.zone(timezone)
                                ? moment(new Date(v * 1000)).tz(timezone)
                                : moment(new Date(v * 1000))
                            )?.format(
                                getDateTimeFormatByRange(finalMin, finalMax)
                            );
                        },
                        max: finalMax,
                        min: finalMin,
                        tickInterval: getIntervalByRange(finalMin, finalMax),
                    },
                },
                legend: !legendAsTable,
            });
        }
    }, [finalMax, finalMin, legendAsTable]);

    return (
        <Space
            className="TimelineChart"
            direction="vertical"
            style={{ width: '100%' }}
        >
            <AppSpin spinning={loading}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    {filters && filters.length > 0 ? (
                        <div className="TimelineChart_filters">filters</div>
                    ) : null}

                    <div ref={chartContainerRef}></div>
                    {legendAsTable ? (
                        <table
                            className="TimelineChart_table"
                            cellPadding="0"
                            width="100%"
                        >
                            <tr>
                                <th align="left"></th>
                                <th align="right">Min</th>
                                <th align="right">Max</th>
                                <th align="right">Avg</th>
                            </tr>
                            {Object.values(serieDataRef.current).map(
                                (s, sIdx) => (
                                    <tr
                                        className={classNames({
                                            'TimelineChart_table-tr--odd':
                                                sIdx % 2 !== 0,
                                        })}
                                    >
                                        <td align="left">
                                            <Space>
                                                <div
                                                    className="TimelineChart_legend-color-dot"
                                                    style={{
                                                        backgroundColor:
                                                            s.color,
                                                    }}
                                                ></div>
                                                {s.name}
                                            </Space>
                                        </td>
                                        <td align="right">
                                            {valueFormatter?.(s.min)}
                                        </td>
                                        <td align="right">
                                            {valueFormatter?.(s.max)}
                                        </td>
                                        <td align="right">
                                            {valueFormatter?.(s.avg)}
                                        </td>
                                    </tr>
                                )
                            )}
                        </table>
                    ) : null}
                </Space>
            </AppSpin>
        </Space>
    );
}

export default forwardRef(TimelineChart);
