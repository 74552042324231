const result = {
    user: {
        class_name: 'CmonUser',
        cdt_path: '/',
        owner_user_id: 5,
        owner_user_name: 'pablo',
        owner_group_id: 1,
        owner_group_name: 'admins',
        acl: 'user::rwx,group::r--,other::r--',
        created: '2022-03-24T13:24:02.244Z',
        disabled: false,
        email_address: 'pablo@localhost.xyz',
        first_name: 'pablo',
        last_failed_login: '',
        last_login: '2023-09-11T12:36:13.032Z',
        last_name: '',
        n_failed_logins: 0,
        origin: 'CmonDb',
        suspended: false,
        user_id: 5,
        user_name: 'pablo',
        groups: [
            {
                class_name: 'CmonGroup',
                cdt_path: '/groups',
                owner_user_id: 1,
                owner_user_name: 'system',
                owner_group_id: 1,
                owner_group_name: 'admins',
                acl: 'user::rwx,group::rwx,other::---',
                created: '2022-02-02T13:11:15.734Z',
                group_id: 1,
                group_name: 'admins',
            },
        ],
        timezone: {
            class_name: 'CmonTimeZone',
            name: 'Japan',
            abbreviation: 'JST',
            offset: 540,
            use_dst: false,
        },
    },
};
export default result;
