import React, { useEffect, useMemo } from 'react';
import AppTable, { AppTableProps } from './AppTable';
import useJobWatcher from '../../components/Jobs/useJobWatcher';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import CcJob from '../../services/models/CcJob';
import { ReportItem } from '../../components/Report/ReportsTable';
import JobProgress, {
    JobProgressProps,
} from '../../components/Jobs/JobProgress';
import RunningJobActionsMenu from '../../components/Jobs/RunningJobActionsMenu';
import { TableProps } from 'antd';

interface JobTableRecord {
    deploymentJobId?: number;
}

export type TableWithJobColumnsType = TableProps<
    JobTableRecord | any
>['columns'] & {
    actions?: boolean;
    progress?: boolean;
    progressProps?: JobProgressProps;
    hideWithJob?: boolean;
    hideOn?: (record: JobTableRecord) => boolean;
    inProgress?: (record: JobTableRecord) => boolean;
};

export default TableWithJobRow;
export type TableWithJobRowProps = AppTableProps & {
    columns?: TableWithJobColumnsType;
    command: CmonJobInstanceCommand | CmonJobInstanceCommand[];
    clusterId?: number;
    jobRecord?: (job: CcJob) => any;
    onJobFinished?: (job: CcJob) => void;
    filterJobs?: (job: CcJob) => boolean;
};

function TableWithJobRow({
    columns,
    command,
    clusterId,
    dataSource,
    jobRecord,
    onJobFinished,
    filterJobs,
    ...rest
}: TableWithJobRowProps) {
    const { runningJobs, stoppedJobs } = useJobWatcher({
        command,
        clusterId,
        filterJobs,
    });
    useEffect(() => {
        if (stoppedJobs.length > 0) {
            onJobFinished?.(stoppedJobs[0]);
        }
    }, [stoppedJobs]);

    const data = useMemo(() => {
        if (jobRecord && runningJobs.length > 0) {
            return [
                ...runningJobs.map((job) => jobRecord(job)),
                ...(dataSource || []),
            ];
        }
        return dataSource;
    }, [dataSource, runningJobs]);

    const tableColumns = useMemo(() => {
        const hidden = columns?.filter((column) => column.hideWithJob).length;
        return columns?.map((column) => {
            return {
                ...column,
                render: (record: JobTableRecord) => {
                    if (record.deploymentJobId) {
                        if (column.progress) {
                            return (
                                <JobProgress
                                    jobId={record.deploymentJobId}
                                    command={command}
                                    size="small"
                                    alignTitle="left"
                                    noMargin={true}
                                    fakeProgress={true}
                                    {...column.progressProps}
                                />
                            );
                        }
                        if (column.actions) {
                            return (
                                <RunningJobActionsMenu
                                    jobId={record.deploymentJobId}
                                />
                            );
                        }
                    }
                    return column?.render(record);
                },
                onCell: (record: ReportItem) => {
                    const data = column.onCell?.(record) || {};
                    let colSpan = data.colSpan || 1;
                    if (data.colSpan === undefined) {
                        if (
                            (column.inProgress?.(record) ||
                                record.deploymentJobId) &&
                            !!column.progress
                        ) {
                            colSpan = hidden ? hidden + 1 : 1;
                        } else if (
                            record.deploymentJobId &&
                            column.hideWithJob
                        ) {
                            colSpan = 0;
                        } else if (column.hideOn?.(record)) {
                            colSpan = 0;
                        }
                    }

                    return {
                        ...data,
                        colSpan,
                    };
                },
            };
        });
    }, [columns]);

    return <AppTable dataSource={data} columns={tableColumns} {...rest} />;
}
