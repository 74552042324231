import React, { useState } from 'react';
import './RedisShardedShardFormWrapper.less';
import RedisShardedShardForm from './RedisShardedShardForm';
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es';
import TypographyText from '../../../../common/TypographyText';
import SpaceWide from '../../../../common/SpaceWide';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import classNames from 'classnames';
import { TopologyItem } from '../../../Topology/TopologyItem';
import {
    RedisShardedFormShard,
    RedisShardedFormShardsMap,
} from './RedisShardedConfigurator';
import { getShardValidator } from '../Deploy/MongoShards/MongoShardsReplicaSetFormWrapper';

export default RedisShardedShardFormWrapper;

export type RedisShardedShardFormWrapperProps = {
    form: FormInstance;
    shards: RedisShardedFormShardsMap;
    shardKey?: string;
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem;
    clusterId?: number;
};

function RedisShardedShardFormWrapper({
    form,
    shards,
    shardKey = '0',
    validateItem,
    clusterId,
}: RedisShardedShardFormWrapperProps) {
    const [error, setError] = useState<string | null>(null);

    const shardsLength = Object.keys(shards).length;
    // real length, excluding undefined shards
    const shardsRealLength = Object.values(shards).filter(
        (s) => s !== undefined
    ).length;

    const handleAddShardClick = async () => {
        try {
            setError(null);
            await form.validateFields(['shards', shardKey]);
            await getShardValidator(shards[shardKey])();
            const newShards = { ...shards };
            const newShardKey = `${shardsLength}`;
            newShards[newShardKey] = {
                shardName: `Shard ${shardsLength + 1}`,
                topology: [],
                perNode: {},
            };

            form.setFieldsValue({
                shards: newShards,
            });
        } catch (err) {
            if (!err.errorFields) {
                setError(err.message);
            }
        }
    };

    const handleRemoveShardClick = async (key: string) => {
        form.setFieldsValue({
            shards: { ...shards, [key]: undefined },
        });
    };
    return (
        <div className="RedisShardedShardFormWrapper">
            <RedisShardedShardForm
                form={form}
                shardKey={shardKey}
                validateItem={validateItem}
                clusterId={clusterId}
            />
            <SpaceWide>
                {shardsRealLength > 1 ? (
                    <Button onClick={() => handleRemoveShardClick(shardKey)}>
                        <TypographyText type="danger">
                            <MinusOutlined /> Remove this shard
                        </TypographyText>
                    </Button>
                ) : null}

                <Button
                    className={classNames(
                        'RedisShardedShardFormWrapper_button',
                        {
                            'RedisShardedShardFormWrapper_button--error': error,
                        }
                    )}
                    onClick={handleAddShardClick}
                >
                    <PlusOutlined /> Add another shard
                </Button>

                {error ? (
                    <TypographyText type="danger">{error}</TypographyText>
                ) : null}
            </SpaceWide>
        </div>
    );
}
