
import CcNode, { CcNodeProps } from '../../models/CcNode';


export interface CmonHaProxyHostProps  extends CcNodeProps {
    backend_info?: {[key:string]:any};
    backend_name_ro?: string;
    backend_name_rw?: string;
    bind_node_address?: string;
    connectstring?: string;
    lb_admin?: string;
    lb_name?: string;
    lb_password?: string;
    lb_policy?: string;
    max_connections_be?: number;
    max_connections_fe?: number;
    monitor_password?: string;
    monitor_user?: string;
    ro_port?: string;
    rw_port?: string;
    rw_splitting?: {[key:string]:any};
    server_info?: string;
    stats_socket?: string;
    svc_check_script?: string;
    timeout_client?: number;
    timeout_server?: number;
    xinetd_port?: string;

}



export default class CmonHaProxyHost extends CcNode {
    public backendInfo?: {[key:string]:any};
    public backendNameRo?: string;
    public backendNameRw?: string;
    public bindNodeAddress?: string;
    public connectstring?: string;
    public lbAdmin?: string;
    public lbName?: string;
    public lbPassword?: string;
    public lbPolicy?: string;
    public maxConnectionsBe?: number;
    public maxConnectionsFe?: number;
    public monitorPassword?: string;
    public monitorUser?: string;
    public roPort?: string;
    public rwPort?: string;
    public rwSplitting?: {[key:string]:any};
    public serverInfo?: string;
    public statsSocket?: string;
    public svcCheckScript?: string;
    public timeoutClient?: number;
    public timeoutServer?: number;
    public xinetdPort?: string;


    constructor(props: CmonHaProxyHostProps) {
        super(props);
        this.backendInfo = props.backend_info;
        this.backendNameRo = props.backend_name_ro;
        this.backendNameRw = props.backend_name_rw;
        this.bindNodeAddress = props.bind_node_address;
        this.connectstring = props.connectstring;
        this.lbAdmin = props.lb_admin;
        this.lbName = props.lb_name;
        this.lbPassword = props.lb_password;
        this.lbPolicy = props.lb_policy;
        this.maxConnectionsBe = props.max_connections_be;
        this.maxConnectionsFe = props.max_connections_fe;
        this.monitorPassword = props.monitor_password;
        this.monitorUser = props.monitor_user;
        this.roPort = props.ro_port;
        this.rwPort = props.rw_port;
        this.rwSplitting = props.rw_splitting;
        this.serverInfo = props.server_info;
        this.statsSocket = props.stats_socket;
        this.svcCheckScript = props.svc_check_script;
        this.timeoutClient = props.timeout_client;
        this.timeoutServer = props.timeout_server;
        this.xinetdPort = props.xinetd_port;

    }

}
