import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import AppDivider from '../AppDivider';
import React, { ReactNode } from 'react';

export function addDividerToMenuGroup(
    items: (ActionMenuItem | undefined | null)[],
    unshift: boolean = false
): ActionMenuItem[] {
    const group = items.filter(Boolean);
    if (group.length > 0) {
        const divider = {
            type: 'divider',
            label: <AppDivider noMargin={true} />,
        } as any;
        if (unshift) {
            group.unshift(divider);
        } else {
            group.push(divider);
        }
    }
    return group as ActionMenuItem[];
}

type ActionSubmenuMenuItem = ActionMenuItem & {
    submenuLabel?: ReactNode;
};

export function groupSimilarMenuItemsInSubmenu(
    parentItem: ActionMenuItem,
    items: (ActionSubmenuMenuItem | undefined | null)[]
) {
    const group = items.filter(Boolean) as ActionSubmenuMenuItem[];
    if (group.length > 1) {
        return {
            ...parentItem,
            children: group.map((item) => ({
                ...item,
                label: item?.submenuLabel || item?.label,
            })),
        };
    } else if (group.length > 0) {
        const item = group[0];
        return { ...item, icon: item?.icon || parentItem.icon };
    }
    return undefined;
}
