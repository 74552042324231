import React, { useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import './TextExpand.less';

type TextExpandProps = {
    content: any;
};

export default TextExpand;

function TextExpand({ content }: TextExpandProps) {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div
                className={classNames('TextExpand', {
                    'TextExpand--expanded': expanded,
                })}
            >
                {content}
            </div>
            {!expanded && (
                <Button
                    className="TextExpand__button"
                    type="link"
                    onClick={handleToggleExpand}
                >
                    Read more
                </Button>
            )}
        </>
    );
}
