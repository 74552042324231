import React from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import AppRow from '../../../../common/AppRow';
import { Col } from 'antd';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import DataDirectoryField from '../../../../common/Form/Fields/DataDirectoryField';
import InstallSoftwareSwitch from '../../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableFirewallField from '../../../../common/Form/Fields/DisableFirewallField';
import DisableSelinuxField from '../../../../common/Form/Fields/DisableSelinuxField';
import MongoNodeConfigurationTemplateField from '../../../../common/Form/Fields/MongoNodeConfigurationTemplateField';

export default ClusterConvertToShardConfigurationForm;

export type ClusterConvertToShardConfigurationFormProps = {
    cluster: CcCluster;
};

function ClusterConvertToShardConfigurationForm({
    cluster,
}: ClusterConvertToShardConfigurationFormProps) {
    return (
        <div className="ClusterConvertToShardConfigurationForm">
            <AppRow gutter={24}>
                <Col md={12} xs={24} sm={24}>
                    <NodeConfigurationTemplateField
                        vendor={cluster.vendor as string}
                        version={cluster.version as string}
                        clusterType={cluster.originalClusterType}
                    />
                </Col>
                <Col md={12} xs={24} sm={24}>
                    <MongoNodeConfigurationTemplateField
                        vendor={cluster.vendor as string}
                        version={cluster.version as string}
                        clusterType={cluster.originalClusterType}
                    />
                </Col>
                <Col md={12} xs={24} sm={24}>
                    <DataDirectoryField name={['serverDataDirectory']} />
                </Col>
                <Col xs={24} sm={24} md={12}></Col>

                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch />
                </Col>
            </AppRow>
            <AppRow gutter={[24, 0]}>
                <Col span={12}>
                    <h3>Security configuration</h3>
                    <DisableFirewallField />
                    <DisableSelinuxField />
                </Col>
            </AppRow>
        </div>
    );
}
