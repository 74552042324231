import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { Tag, Space, Switch } from 'antd';
import Search from 'antd/lib/input/Search';
import useFetch from '../../../common/useFetch';
import CcCluster from '../../../services/models/CcCluster';
import CmonStatService from '../../../services/cmon/CmonStatService';
import AppTable from '../../../common/DataDisplay/AppTable';
import AppEmpty from '../../../common/Feedback/AppEmpty';
import QueryMonitorNodeSelect from '../../QueryMonitor/QueryMonitorNodeSelect';
import SpaceWide from '../../../common/SpaceWide';
import { getSortAlphabeticFn } from '../../../common/sorting';
import { PerformancePageSection } from '../PerformancePage';
import './DBStatusPage.less';

export type DbStatusPageProps = {
    type: PerformancePageSection;
    cluster: CcCluster;
};

export default DbStatusPage;

const HOSTNAME_ALL = 'all';

function DbStatusPage({ cluster, type }: DbStatusPageProps) {
    const [showFiltered, setShowFiltered] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [hostnames, setHostnames] = useState<any>([]);
    const [filteredVariables, setFilteredVariables] = useState<any>();
    const [selectedHostIds, setSelectedHostIds] = useState<string[]>([
        HOSTNAME_ALL,
    ]);
    const [selectedHostnames, setSelectedHostnames] = useState<string>([
        HOSTNAME_ALL,
    ]);
    const { refresh, data, loading } = useFetch<any[]>({
        fetchFn: async ({ filtered }) => {
            let response = {};
            let propertyName = '';
            if (type === PerformancePageSection.DB_STATUS) {
                response = await CmonStatService.getdbstatus({
                    cluster_id: cluster.clusterId,
                });
                propertyName = 'statuses';
            } else if (type === PerformancePageSection.DB_VARIABLE) {
                response = await CmonStatService.nodevariables({
                    cluster_id: cluster.clusterId,
                });
                propertyName = 'variables';
            }
            response = response?.data;
            const variables = {};
            const hosts = [];
            response.forEach((record) => {
                if (record.hostId > 0) {
                    const hostAndPort = record.port
                        ? `${record.hostname}:${record.port}`
                        : record.hostname;
                    Object.keys(record[propertyName]).forEach((key) => {
                        if (!variables[key]) {
                            variables[key] = {};
                        }
                        variables[key][hostAndPort] = record[propertyName][key];
                    });
                    if (
                        !hosts.includes(hostAndPort) &&
                        (selectedHostnames.includes(HOSTNAME_ALL) ||
                            selectedHostIds.includes(record.hostId))
                    ) {
                        hosts.push(hostAndPort);
                    }
                }
            });
            setHostnames(hosts);
            return Object.keys(variables).map((key, index) => {
                return {
                    var: key,
                    ...variables[key],
                };
            });
        },
    });

    const columns = useMemo(() => {
        return [
            {
                title: 'Variable',
                key: 'var',
                width: 400,
                sorter: (a: any, b: any) => {
                    return getSortAlphabeticFn('ascend', (x: any) => {
                        return x.var || '';
                    })?.(a, b);
                },
                render: (record: any) => (
                    <span
                        className={
                            isValueDifferent(record) &&
                            'DBStatusPage_Value_Diff'
                        }
                    >
                        {record.var}
                    </span>
                ),
            },
            ...hostnames
                .filter((host) => {
                    if (
                        selectedHostIds.includes(0) ||
                        selectedHostIds.includes(HOSTNAME_ALL) ||
                        selectedHostnames.find((selectedHost) => {
                            const [
                                hostId,
                                hostname,
                                port,
                            ] = selectedHost?.split(':');
                            if (type === PerformancePageSection.DB_STATUS) {
                                return host === hostname;
                            }
                            return host === `${hostname}:${port}`;
                        })
                    ) {
                        return true;
                    }
                    return false;
                })
                .map((host) => {
                    return {
                        title: host,
                        key: host.hostname,
                        sorter: (a: any, b: any) => {
                            return getSortAlphabeticFn('ascend', (x: any) => {
                                return x[host] || '';
                            })?.(a, b);
                        },
                        render: (record: any) => (
                            <span
                                className={
                                    isValueDifferent(record) &&
                                    'DBStatusPage_Value_Diff'
                                }
                            >
                                {record[host]}
                            </span>
                        ),
                    };
                }),
        ];
    }, [hostnames, selectedHostIds]);

    /*
     * Compares the values of the selected nodes.
     * If there are differences, the row will show if the "Show differences" is toggle on and display as red
     */
    const isValueDifferent = (record) => {
        let hosts = hostnames;
        if (!selectedHostnames.includes(HOSTNAME_ALL)) {
            hosts = selectedHostnames.map((host) => {
                const [hostId, hostname, port] = (host || '')?.split(':');
                if (type === PerformancePageSection.DB_STATUS) {
                    return hostname;
                }
                return `${hostname}:${port}`;
            });
        }
        if (hosts.length > 1) {
            for (let index = 0; index < hosts.length - 1; index++) {
                if (record[hosts[index]] !== record[hosts[index + 1]]) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleChange = () => {
        setShowFiltered(!showFiltered);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };

    const handleNodeChange = async (values: string) => {
        if (values.length === 0) {
            values.push(HOSTNAME_ALL);
        }
        if (values[values.length - 1] === HOSTNAME_ALL) {
            setSelectedHostIds([0]);
            setSelectedHostnames([HOSTNAME_ALL]);
        } else {
            if (values.includes(HOSTNAME_ALL)) {
                values.shift();
            }
            const hostIds = values.map((value) => {
                const [hostId, hostname, port] = (value || '')?.split(':');
                return hostId;
            });
            setSelectedHostIds(hostIds);
            setSelectedHostnames(values);
        }
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [cluster.clusterId]);

    useEffect(() => {
        if (data) {
            setFilteredVariables(data);
        }
    }, [data]);

    useEffect(() => {
        let filteredData = data;
        if (
            showFiltered &&
            (selectedHostnames.length > 1 ||
                selectedHostnames.includes(HOSTNAME_ALL))
        ) {
            filteredData = data?.filter((record) => {
                return isValueDifferent(record);
            });
        }
        if (searchString) {
            filteredData = filteredData?.filter((record) => {
                return record.var
                    .toLowerCase()
                    .includes(searchString.toLowerCase());
            });
        }
        setFilteredVariables(filteredData);
    }, [showFiltered, searchString, selectedHostnames]);

    return (
        <div>
            <SpaceWide direction="vertical">
                <SpaceWide>
                    <QueryMonitorNodeSelect
                        key="db-status-select"
                        cluster={cluster}
                        onChange={handleNodeChange}
                        value={selectedHostnames}
                        mode="multiple"
                        dropdownMatchSelectWidth={false}
                    />
                    <Search
                        placeholder="Search variable"
                        onChange={handleSearchChange}
                    />
                    Show differences
                    <Switch
                        disabled={
                            hostnames.length <= 1 ||
                            (selectedHostnames.length === 1 &&
                                !selectedHostnames.includes(HOSTNAME_ALL))
                        }
                        onChange={handleChange}
                    ></Switch>
                </SpaceWide>
                <AppTable
                    loading={loading}
                    dataSource={filteredVariables || []}
                    renderEmpty={
                        <AppEmpty loading={loading} description="No records" />
                    }
                    onRow={() => {}}
                    columns={columns}
                    size="middle"
                    footer={() => <Space size="large" />}
                />
            </SpaceWide>
        </div>
    );
}
