import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import AppDateFormat from '../../common/AppDateFormat';
import AppTable from '../../common/DataDisplay/AppTable';
import ClusterFormat from '../Clusters/ClusterFormat';
import { AppState, AppStateClustersMap } from '../../appReducer';
import CcReport from '../../services/models/CcReport';
import OperationalReportActionMenu from './OperationalReportActionMenu';
import SpaceWide from '../../common/SpaceWide';
import { PlusOutlined } from '@ant-design/icons';
import useListFetch from '../../common/useListFetch';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import OperationalReportCreateReportModal from './OperationalReportCreateReportModal';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import { TablePaginationConfig } from 'antd/es';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import TypographyText from '../../common/TypographyText';
import TagsList from '../../common/DataDisplay/TagsList';

export default OperationalReportTable;

export type OperationalReportTableProps = {
    templates?: CcReport[];
    pageSize?: number;
};

function OperationalReportTable({
    templates,
    pageSize = 10,
}: OperationalReportTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const { filterParams, handleTableChange } = useTableFilter({
        params: {
            cluster: ArrayParam,
        },
        defaultParams: {
            page: 1,
        },
    });

    const { list, total, refresh: refreshReports, loading } = useListFetch({
        name: 'reports',
        fetchFn: async ({ page, ...props }, opts) => {
            const response: any = await CmonReportsService.getReports(
                {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    ...props,
                },
                opts
            );
            return {
                list: response.reports.map((item: CcReport) => item),
                total: response.total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonReportsService.cancelRequest(requestId);
        },
    });

    const refresh = async () => {
        await refreshReports({
            ...{
                page: filterParams.page,
                cluster_id: filterParams.cluster?.[0],
            },
        });
    };

    const handleReportCreatedSuccess = async () => {
        await refresh();
    };

    const handleActionPerformed = async () => {
        await refresh();
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [filterParams]);

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'Created',
                    key: 'created',
                    render: (record: CcReport) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <AppDateFormat fromNow={true}>
                                {record.created
                                    ? new Date(record.created)
                                    : undefined}
                            </AppDateFormat>
                        </span>
                    ),
                },
                {
                    title: 'File name',
                    key: 'path',
                    render: (record: CcReport) =>
                        record.path?.replace(/^.*(\\|\/|:)/, ''),
                },
                {
                    title: 'Report type',
                    key: 'reportType',
                    render: (record: CcReport) => {
                        const type =
                            templates?.find(
                                (template: CcReport) =>
                                    template.reportType === record.reportType
                            )?.title || 'N/A';
                        return (
                            <TypographyText nowrap={true}>
                                {type}
                            </TypographyText>
                        );
                    },
                },
                {
                    title: 'Cluster',
                    key: 'cluster',
                    filterMultiple: false,
                    filterType: TableFilterType.CLUSTER,
                    render: (record: CcReport) => (
                        <ClusterFormat
                            cluster={clustersMap.get(`${record.clusterId}`)}
                            showPopover={true}
                        />
                    ),
                },
                {
                    title: 'Created by',
                    key: 'owner',
                    render: (record: CcReport) => record.owner,
                },
                {
                    title: 'Data range',
                    key: 'days',
                    render: (record: CcReport) => (
                        <Space>
                            <span>{record.days}</span>
                            <TypographyText muted={true}>days</TypographyText>
                        </Space>
                    ),
                },
                {
                    title: 'Recipients',
                    key: 'recipients',
                    render: (record: CcReport) => {
                        const recipients = record.recipients || '';
                        if (!recipients.trim()) {
                            return '';
                        }

                        return (
                            <div style={{ minWidth: '200px' }}>
                                <TagsList
                                    tags={recipients.split(',')}
                                    className="ClusterList_detail-cell_tags"
                                />
                            </div>
                        );
                    },
                },
                {
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcReport) => (
                        <OperationalReportActionMenu
                            report={record}
                            onActionPerformed={handleActionPerformed}
                        />
                    ),
                },
            ],
            [filterParams, templates]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: filterParams.page,
        total: total || undefined,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };

    const rowKey = useCallback((record: CcReport) => `${record?.getKey()}`, []);

    return (
        <SpaceWide direction="vertical">
            <SpaceWide direction="vertical" align="end">
                <ButtonWithForm
                    button={
                        <Button type="primary" icon={<PlusOutlined />}>
                            Create report
                        </Button>
                    }
                    form={
                        <OperationalReportCreateReportModal
                            templates={templates}
                            onSuccess={handleReportCreatedSuccess}
                        />
                    }
                />
            </SpaceWide>
            <AppTable
                onChange={handleTableChange}
                pagination={pagination}
                responsive={responsive}
                rowKey={rowKey}
                loading={loading}
                dataSource={list || []}
                columns={columns}
                size="middle"
                onRow={(record: CcReport, index: any) => ({
                    'data-testid': `report-table-row-${index}`,
                })}
            />
        </SpaceWide>
    );
}
