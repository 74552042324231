import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../../Jobs/JobCreateActionButton';

export default ClusterToggleReadonlyButton;

export type ClusterEnableReadonlyButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterToggleReadonlyButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterEnableReadonlyButtonProps) {
    const props = useMemo(() => {
        if (cluster.isReadonly()) {
            return {
                command: CmonJobInstanceCommand.DISABLE_CLUSTER_READONLY,
                okText: 'Disable',
                confirmTitle: 'Disable cluster readonly?',
            };
        }
        return {
            command: CmonJobInstanceCommand.ENABLE_CLUSTER_READONLY,
            okText: 'Enable',
            confirmTitle: 'Enable cluster readonly?',
        };
    }, [cluster]);
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            title={`Readonly`}
            onSuccess={onSuccess}
            switchValue={cluster.isReadonly()}
            {...props}
            {...rest}
        />
    );
}
