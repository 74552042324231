import PostgreSqlConfigurator, {
    PostgreSqlFormValues,
} from '../../PostgreSql/PostgreSqlConfigurator';
import merge from 'deepmerge';
import React from 'react';
import PostgreSqlNodeConfiguration from '../../PostgreSql/PostgreSqlNodeConfiguration';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import { Form, Input, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { MysqlFormValues } from '../../Mysql/MysqlConfigurator';
import { ClusterConfigurator } from '../../ClusterConfigurator';
import { FormInstance } from 'antd/es';
import ClusterNodesForm from '../../ClusterNodesForm';

export default class PostgreSqlImportConfigurator extends PostgreSqlConfigurator {
    public static getDefaults(): MysqlFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                serverPort: 5432,
            },
            topologyServerPort: {},
            topologyLogFile: {},
        });
    }
    public static getJobOptions(formValues: PostgreSqlFormValues): any {
        return merge(PostgreSqlConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import PostgreSQL Cluster',
            },
        });
    }

    public static getJobData(formValues: PostgreSqlFormValues): any {
        const {
            topology,
            nodeConfig,
            topologyLogFile,
            topologyServerPort,
            topologyDataIps,
        } = formValues;
        return merge(PostgreSqlConfigurator.getJobData(formValues), {
            nodes: topology.reduce(
                (accumulator: any[], currentNode: TopologyItem) => {
                    accumulator = [
                        ...accumulator,
                        {
                            port:
                                topologyServerPort?.[
                                    currentNode.extraData.hostname
                                ] || nodeConfig.serverPort,
                            hostname: currentNode.extraData.hostname,
                            hostname_data: currentNode.extraData.hostname,
                            hostname_internal:
                                topologyDataIps?.[
                                    currentNode.extraData.hostname
                                ] || currentNode.extraData.hostname,
                            customlogfile:
                                topologyLogFile?.[
                                    currentNode.extraData.hostname
                                ],
                        },
                    ];
                    return accumulator;
                },
                []
            ),
        });
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return (
            <PostgreSqlNodeConfiguration
                hasDataDir={false}
                {...props}
            />
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{
                    onItemAdd: (item) => {
                        const {
                            nodeConfig: { serverPort },
                        } = form.getFieldsValue(true);
                        form.setFieldsValue({
                            topologyServerPort: {
                                [item.extraData.hostname]: serverPort,
                            },
                        });
                    },
                    mutateItem: (item: TopologyItem) => {
                        return {
                            ...item,
                            footer: (
                                <div>
                                    {item.status ===
                                    StatusFormatStatus.success ? (
                                        <div>
                                            <Form.Item
                                                name={[
                                                    'topologyServerPort',
                                                    item.extraData.hostname,
                                                ]}
                                                style={{ marginBottom: 10 }}
                                                label="Port"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter the PostgreSQL service port.',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter server port" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[
                                                    'topologyLogFile',
                                                    item.extraData.hostname,
                                                ]}
                                                style={{ marginBottom: 10 }}
                                                label={
                                                    <Space>
                                                        Logfile path
                                                        <InfoIcon info="Logfile path (Optional, Specify only if logging_collector = OFF in postgresql.conf)" />
                                                    </Space>
                                                }
                                            >
                                                <Input placeholder="Enter logfile path" />
                                            </Form.Item>
                                        </div>
                                    ) : null}
                                </div>
                            ),
                        };
                    },
                }}
            />
        );
    }

    public static getTopologyValidate(form: FormInstance) {
        return form
            .getFieldValue('topology')
            .map((item: TopologyItem) => [
                'topologyServerPort',
                item.extraData.hostname,
            ]);
    }
}
