import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es';
import './AppActionButton.less';
import classNames from 'classnames';
import AppTooltip from '../Feedback/AppTooltip';

export default AppActionButton;

export type AppActionButtonProps = ButtonProps & {
    centerLoading?: boolean;
    tooltip?: React.ReactNode;
};

function AppActionButton({
    className,
    centerLoading,
    tooltip,
    ...rest
}: AppActionButtonProps) {
    const content = (
        <Button
            className={classNames(
                'AppActionButton',
                { 'AppActionButton--center-loading': centerLoading },
                className
            )}
            size="small"
            type="link"
            {...rest}
        />
    );
    return tooltip ? (
        <AppTooltip title={tooltip}>{content}</AppTooltip>
    ) : (
        content
    );
}
