import { Col, Form, Row, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import FormFooter from '../../common/FormFooter';
import { FormInstance } from 'antd/lib/form';
import { ClusterConfigContext } from '../Clusters/Config/ClusterConfigContext';
import AppSpin from '../../common/General/AppSpin';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import CcCluster from '../../services/models/CcCluster';
import FormItemInlineSwitch from '../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import InputNumberWide from '../../common/DataEntry/InputNumberWide';
import FormItem from '../../common/DataEntry/FormItem';
import { ClusterConfigGrouped } from '../Clusters/useClusterConfigGrouped';

export default QueryMonitorSettingsForm;
export type QueryMonitorSettingsFormFields = {
    enableQueryMonitor?: boolean;
    longQueryTime?: number;
    logQueriesNotUsingIndexes?: boolean;
    mysqlLocalQueryOverride?: boolean;
    autoClearQueries?: boolean;
};
export type QueryMonitorSettingsFormProps = {
    cluster: CcCluster;
    form?: FormInstance;
    onSuccess?: () => void;
    onCancel?: () => void;
    onFieldsTouched?: (touched: boolean) => void;
};

function QueryMonitorSettingsForm({
    cluster,
    form: parentForm,
    onSuccess,
    onCancel,
    onFieldsTouched,
    ...rest
}: QueryMonitorSettingsFormProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const { configGroupedLoading, configGrouped } = useContext(
        ClusterConfigContext
    );
    const [localForm] = Form.useForm();
    const form = parentForm || localForm;

    const handleSubmit = async (fields: QueryMonitorSettingsFormFields) => {
        try {
            setLoading(true);
            await CmonClustersService.setConfig({
                cluster_id: cluster.clusterId,
                configuration: getQueryMonitorSettingsConfig(fields),
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Query monitor settings saved successfully!',
                okText: 'Ok',
                cancelText: 'Close',
            });
            setLoading(false);
            onSuccess?.();
            return true;
        } catch (err: any) {
            setLoading(false);
            notifyError({ type: NotifyType.TOAST, content: err.message });
        }
        return false;
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel?.();
    };

    return (
        <AppSpin spinning={configGroupedLoading || !configGrouped}>
            {configGrouped ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={getQueryMonitorSettingsConfigFields(
                        configGrouped
                    )}
                    {...rest}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12} offset={6}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} sm={24} md={24}>
                                    <FormItemInlineSwitch
                                        name="enableQueryMonitor"
                                        justify={true}
                                        noMargin={true}
                                        label={
                                            <Space>
                                                <span>
                                                    Enable query monitor
                                                </span>
                                            </Space>
                                        }
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                    <FormItem
                                        name="longQueryTime"
                                        label={
                                            <Space>
                                                <span>Long query time</span>
                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            Only collect queries
                                                            taking longer that
                                                            Long Query Time
                                                            seconds. 0 - means
                                                            all queries, 0.1
                                                            means only queries
                                                            taking more than 0.1
                                                            seconds will be
                                                            accounted.
                                                        </span>
                                                    }
                                                />
                                            </Space>
                                        }
                                        {...rest}
                                    >
                                        <InputNumberWide />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                    <FormItemInlineSwitch
                                        name="logQueriesNotUsingIndexes"
                                        justify={true}
                                        noMargin={true}
                                        label={
                                            <Space>
                                                <span>
                                                    Log queries not using
                                                    indexes
                                                </span>
                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            <p>
                                                                <b>On</b>: log
                                                                queries not
                                                                using indexes.
                                                            </p>
                                                            <p>
                                                                <b>Off</b>:
                                                                ignore queries
                                                                not using
                                                                indexes (will
                                                                not be ' +
                                                                'accounted for
                                                                in Query
                                                                Monitoring/Query
                                                                Outliers).
                                                            </p>
                                                        </span>
                                                    }
                                                />
                                            </Space>
                                        }
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                    <FormItemInlineSwitch
                                        name="mysqlLocalQueryOverride"
                                        justify={true}
                                        noMargin={true}
                                        label={
                                            <Space>
                                                <span>
                                                    MySQL local query override
                                                </span>
                                                <InfoIcon
                                                    info={
                                                        <span>
                                                            <p>
                                                                <b>On</b>: means
                                                                that the local
                                                                my.cnf settings
                                                                for
                                                                slow_query_log
                                                                will be used.
                                                            </p>
                                                            <p>
                                                                <b>Off</b>:
                                                                ClusterControl
                                                                manages the slow
                                                                query log.
                                                            </p>
                                                        </span>
                                                    }
                                                />
                                            </Space>
                                        }
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24}>
                                    <FormItemInlineSwitch
                                        name="autoClearQueries"
                                        justify={true}
                                        noMargin={true}
                                        label={
                                            <Space>
                                                <span>Auto clear queries</span>
                                            </Space>
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FormFooter
                        showCancelButton
                        showSubmitButton
                        submitButtonText="Save"
                        loading={loading}
                        onCancel={handleCancel}
                    />
                </Form>
            ) : null}
        </AppSpin>
    );
}

export function getQueryMonitorSettingsConfigFields(
    config: ClusterConfigGrouped
): QueryMonitorSettingsFormFields {
    return {
        enableQueryMonitor: !!config?.sampling?.enable_query_monitor
            ?.current_value,
        longQueryTime: Number.parseFloat(
            config?.long_query?.long_query_time?.current_value as string
        ),
        logQueriesNotUsingIndexes: !!config?.sampling
            ?.log_queries_not_using_indexes?.current_value,
        mysqlLocalQueryOverride: !!config?.cluster
            ?.query_monitor_use_local_settings?.current_value,
        autoClearQueries: !!config?.sampling?.enable_query_monitor_auto_purge_ps
            ?.current_value,
    };
}

export function getQueryMonitorSettingsConfig(
    fields: QueryMonitorSettingsFormFields
) {
    return [
        {
            name: 'enable_query_monitor',
            value: fields.enableQueryMonitor ? 1 : 0,
        },
        {
            name: 'long_query_time',
            value: fields.longQueryTime,
        },
        {
            name: 'log_queries_not_using_indexes',
            value: fields.logQueriesNotUsingIndexes,
        },
        {
            name: 'query_monitor_use_local_settings',
            value: fields.mysqlLocalQueryOverride,
        },
        {
            name: 'enable_query_monitor_auto_purge_ps',
            value: fields.autoClearQueries,
        },
    ];
}
