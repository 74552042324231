import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import { DBUserPrivilegesFieldType } from './FormParts/DatabasePrivilegesForm';
import React, { useMemo, useState } from 'react';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import {
    notifyError,
    notifyOperationMessages,
    NotifyType,
} from '../Notifications/uiNotification';
import CcDatabaseAccount from '../../services/models/CcDatabaseAccount';
import { useDebugContext } from '../../common/Debug';

export type DatabaseUserFormFields = {
    username?: string;
    hostname?: string;
    password?: string;
    newPassword?: string;
    requireSsl?: boolean;
    maxQueriesPerHour?: number;
    maxUpdatesPerHour?: number;
    maxConnectionsPerHour?: number;
    maxUserConnections?: number;
    privileges?: DBUserPrivilegesFieldType[];
};
type UseDatabaseUserFormHandlerProps = {
    cluster: CcCluster;
    editAccount?: CcDatabaseAccount;
    onCancel?: () => void;
    onError?: () => void;
    onSuccess?: () => void;
};
export default function useDatabaseUserFormHandler({
    cluster,
    editAccount,
    onError,
    onCancel,
    onSuccess,
}: UseDatabaseUserFormHandlerProps) {
    const { log } = useDebugContext();

    const [loading, setLoading] = useState(false);

    const buildGrants = ({ privileges }: DatabaseUserFormFields) => {
        switch (cluster.getTechnology()) {
            case CcClusterTechnology.TECHNOLOGY_MYSQL:
                return buildPrivilegesStringMysql({ privileges });
            case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
                return buildPrivilegesStringPostgres({ privileges });
        }
        return '';
    };

    const create = async (fields: DatabaseUserFormFields) => {
        try {
            setLoading(true);
            const {
                messages: createMessages,
            } = await CmonClustersService.createAccount({
                cluster_id: cluster.clusterId,
                account: {
                    ...getDatabaseUserAccountDTOObject(fields),
                    grants: buildGrants(fields),
                },
            });
            showMessages(createMessages);

            // "max_" fields hast to be set by update method
            if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
                const {
                    messages: updateMessages,
                } = await CmonClustersService.updateAccount({
                    cluster_id: cluster.clusterId,
                    account: {
                        ...getDatabaseUserAccountDTOObject(fields),
                        grants: undefined,
                    },
                });
                showMessages(updateMessages);
            }

            onSuccess?.();
        } catch (e) {
            showMessages(e?.json?.messages);
            log.error(e);
            notifyError({
                type: NotifyType.TOAST,
                title: e.message,
                duration: 0,
            });
            onError?.();
            onCancel?.();
        } finally {
            setLoading(false);
        }
    };
    const update = async (fields: DatabaseUserFormFields) => {
        try {
            setLoading(true);
            const privilegeToAdd = privilegeDiff(
                editAccount?.privileges || [],
                fields.privileges || []
            );
            const privilegeToDelete = privilegeDiff(
                fields.privileges || [],
                editAccount?.privileges || []
            );

            if (privilegeToDelete.length > 0) {
                const {
                    messages: revokeMessages,
                } = await CmonClustersService.revokePrivileges({
                    cluster_id: cluster.clusterId,
                    account: {
                        class_name: 'CmonAccount',
                        host_allow: editAccount?.hostAllow,
                        user_name: editAccount?.userName,
                    },
                    privileges: buildGrants({
                        privileges: privilegeToDelete || [],
                    }),
                });
                showMessages(revokeMessages);
            }
            if (privilegeToAdd.length > 0) {
                const {
                    messages: revokeMessages,
                } = await CmonClustersService.grantPrivileges({
                    cluster_id: cluster.clusterId,
                    account: {
                        class_name: 'CmonAccount',
                        host_allow: editAccount?.hostAllow,
                        user_name: editAccount?.userName,
                    },
                    privileges: buildGrants({
                        privileges: privilegeToAdd || [],
                    }),
                });
                showMessages(revokeMessages);
            }

            // "max_" fields hast to be set by update method
            if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
                const {
                    messages: updateMessages,
                } = await CmonClustersService.updateAccount({
                    cluster_id: cluster.clusterId,
                    account: {
                        ...getDatabaseUserAccountDTOObject(fields),
                        grants: undefined,
                    },
                });
                showMessages(updateMessages);
            }

            onSuccess?.();
        } catch (e) {
            showMessages(e?.json?.messages);
            log.error(e);
            notifyError({
                type: NotifyType.TOAST,
                title: e.message,
                duration: 0,
            });
            onError?.();
            onCancel?.();
        } finally {
            setLoading(false);
        }
    };

    const initialValues = useMemo(() => {
        let privileges = editAccount?.privileges || [];
        if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
            privileges = privileges.map((privilege) => ({
                ...privilege,
                db:
                    privilege.db.indexOf('.') === -1
                        ? `${privilege.db}.*`
                        : privilege.db,
            }));
        }
        return {
            maxQueriesPerHour: 0,
            maxUpdatesPerHour: 0,
            maxConnectionsPerHour: 0,
            maxUserConnections: 0,
            ...(editAccount
                ? {
                      username: editAccount.userName,
                      hostname: editAccount.hostAllow,
                      password: editAccount.password,
                      requireSsl: `${editAccount.sslType}` === 'ANY',
                      maxQueriesPerHour: editAccount.maxQuestions,
                      maxUpdatesPerHour: editAccount.maxUpdates,
                      maxConnectionsPerHour: editAccount.maxConnections,
                      maxUserConnections: editAccount.maxUserConnections,
                      privileges,
                  }
                : {}),
        };
    }, [editAccount]);

    return {
        initialValues,
        loading,
        create,
        update,
    };
}

function privilegeDiff(
    leftPrivileges: DBUserPrivilegesFieldType[],
    rightPrivileges: DBUserPrivilegesFieldType[]
): DBUserPrivilegesFieldType[] {
    return rightPrivileges
        .filter(
            (item) =>
                !leftPrivileges.find(
                    (leftItem) =>
                        leftItem.db === item.db &&
                        leftItem.privileges.join(',') ===
                            item.privileges.join(',')
                )
        )
        .map((privilege) => {
            const item = leftPrivileges.find(
                (leftItem) => leftItem.db === privilege.db
            );
            if (item) {
                return {
                    ...privilege,
                    privileges: privilege.privileges.filter(
                        (privilege) => !item.privileges.includes(privilege)
                    ),
                };
            }
            return privilege;
        })
        .filter(
            (privilege) => privilege && privilege.privileges.length > 0
        ) as DBUserPrivilegesFieldType[];
}

function buildPrivilegesStringMysql({ privileges }: DatabaseUserFormFields) {
    return (
        privileges
            ?.map((privilege) => {
                const db = privilege.db;
                const privileges = privilege.privileges;
                return `${
                    db.indexOf('.') === -1 ? `${db}.*` : db
                }:${privileges.join(',')}`;
            })
            .join(';') || ''
    );
}

function buildPrivilegesStringPostgres({ privileges }: DatabaseUserFormFields) {
    return (
        privileges
            ?.map((privilege) => {
                const db = privilege.db;
                const privileges = privilege.privileges;
                if (!db || db === '*.*') {
                    return privileges.join(',');
                }
                return `${db}:${privileges.join(',')}`;
            })
            .join(';') || ''
    );
}

function getDatabaseUserAccountDTOObject(fields: DatabaseUserFormFields) {
    return {
        class_name: 'CmonAccount',
        user_name: fields.username,
        password: fields.newPassword || fields.password || undefined,
        host_allow: fields.hostname,
        ssl_type: fields.requireSsl ? 'SSL' : 'NONE',
        max_user_connections: fields.maxUserConnections,
        max_questions: fields.maxQueriesPerHour,
        max_updates: fields.maxUpdatesPerHour,
        max_connections: fields.maxConnectionsPerHour,
    };
}

function showMessages(messages?: string[]) {
    if (messages && Array.isArray(messages)) {
        notifyOperationMessages({
            messages,
            showReadMore: messages.length > 3,
        });
    }
}
