import { Button, Result, Space } from 'antd';
import CmonUnknownErrorError from '../../services/cmon/errors/CmonUnknownErrorError';
import ExternalLink from '../Navigation/ExternalLink';
import CmonError from '../../services/cmon/errors/CmonError';
import ButtonWithForm from '../General/ButtonWithForm';
import DetailsModal from './DetailsModal';
import SpaceDescriptions from '../Layout/SpaceDescriptions';
import ReactJson from 'react-json-view';
import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import CmonClusterNotFoundError from '../../services/cmon/errors/CmonClusterNotFoundError';
import CmonAccessDeniedError from '../../services/cmon/errors/CmonAccessDeniedError';
import CmonAuthRequiredError from '../../services/cmon/errors/CmonAuthRequiredError';
import CmonInvalidRequestError from '../../services/cmon/errors/CmonInvalidRequestError';
import CmonObjectNotFoundError from '../../services/cmon/errors/CmonObjectNotFoundError';
import CmonTryAgainError from '../../services/cmon/errors/CmonTryAgainError';

export default AppErrorState;

type AppErrorStateProps = {
    error?: Error;
    unknownErrorMessage?: string;
};

function AppErrorState({
    error,
    unknownErrorMessage = 'Unknown error',
}: AppErrorStateProps) {
    return (
        <Result
            icon={<CloseCircleOutlined />}
            status="error"
            title={getErrorTypeMessage(error) || unknownErrorMessage}
            subTitle={getErrorSubtitleMessage(error)}
            extra={getErrorExtra(error)}
        />
    );
}

function getErrorTypeMessage(error?: Error) {
    if (error instanceof CmonObjectNotFoundError) {
        return '404 object not found error.';
    } else if (error instanceof CmonClusterNotFoundError) {
        return 'Cluster not found error.';
    } else if (error instanceof CmonAccessDeniedError) {
        return 'Access denied error.';
    } else if (error instanceof CmonAuthRequiredError) {
        return 'Authentication required error.';
    } else if (error instanceof CmonInvalidRequestError) {
        return 'Invalid request error.';
    } else if (error instanceof CmonTryAgainError) {
        return 'Server error, please try again.';
    }
    return undefined;
}

function getErrorSubtitleMessage(error?: Error) {
    if (!error) {
        return undefined;
    }
    if (error instanceof CmonUnknownErrorError) {
        return 'A non-recoverable error has occurred.';
    }
    return error.message;
}

function getErrorExtra(error?: Error) {
    if (error instanceof CmonError && error.json) {
        return (
            <Space>
                <ExternalLink href="https://support.severalnines.com">
                    <Button>Contact support</Button>
                </ExternalLink>
                <ButtonWithForm
                    button={<Button>VIew details</Button>}
                    form={
                        <DetailsModal
                            visible={true}
                            title={getErrorSubtitleMessage(error)}
                        >
                            <SpaceDescriptions
                                style={{ width: '100%', overflow: 'auto' }}
                            >
                                <SpaceDescriptions.Item direction="vertical">
                                    <ReactJson
                                        src={error.json}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        name="response"
                                    />
                                </SpaceDescriptions.Item>
                            </SpaceDescriptions>
                        </DetailsModal>
                    }
                />
            </Space>
        );
    }
}
