import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcCluster from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../Jobs/JobCreateActionButton';

export default ClusterPurgeQueryMonitor;

export type ClusterPurgeQueryMonitorProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterPurgeQueryMonitor({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterPurgeQueryMonitorProps) {
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.PURGE_QUERY_MONITOR}
            confirmTitle={'Purge query monitor data and clear queries?'}
            title={`Clear all queries`}
            onSuccess={onSuccess}
            type="primary"
            critical={true}
            size="middle"
            {...rest}
        />
    );
}
