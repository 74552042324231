import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import './BackupsTable.less';
import CcBackup, { CcBackupMethod } from '../../services/models/CcBackup';
import AppEmpty from '../../common/Feedback/AppEmpty';
import { Space, TableProps, Tag } from 'antd';
import {
    DownOutlined,
    FolderOutlined,
    InfoCircleOutlined,
    UpOutlined,
} from '@ant-design/icons';
import ClusterFormat from '../Clusters/ClusterFormat';
import { CcClusterTechnology } from '../../services/models/CcCluster';
import { getRedisBackupMethod } from './BackupMethodSelect';
import BackupStatusFormat from './BackupStatusFormat';
import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ByteFormat from '@severalnines/bar-frontend-components/build/lib/Format/ByteFormat';
import BackupFormat from './BackupFormat';
import BackupActionsMenu, { BackupAction } from './BackupActionsMenu';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';
import useJobWatcher from '../Jobs/useJobWatcher';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import CcJob from '../../services/models/CcJob';
import { TablePaginationConfig } from 'antd/es';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import BackupSetDetailsButton from './ActionButton/BackupSetDetailsButton';
import BackupStorageFormat from './BackupStorageFormat';
import BackupsList from './BackupsList';
import SpaceWide from '../../common/SpaceWide';
import AppDateFormat from '../../common/AppDateFormat';
import AppTooltip from '../../common/Feedback/AppTooltip';
import AppTable from '../../common/DataDisplay/AppTable';
import UserAclManageCluster from '../User/UserAclManageCluster';
import useTableFilterColumns, {
    TableFilterType,
} from '../../common/hooks/useTableFilterColumns';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default BackupsTable;

// @todo work in responsive table types so we can extend
export type BackupsTableProps = {
    loading?: boolean;
    backups?: CcBackup[];
    pagination?: TablePaginationConfig;
    renderEmpty?: React.ReactNode;
    excludeColumns?: string[];
    excludeActions?: BackupAction[];
    showBackupSetIndicator?: boolean;
    onChange?: TableProps<any>['onChange'];
    filterParams?: any;
    onActionPerformed?: (action: BackupAction) => void;
    onJobFinished?: (commands: CmonJobInstanceCommand[]) => void;
    footer?: (() => React.ReactNode) | boolean;
};

const PROXYSQL_TOOLTIP = (
    <span>
        Backup of ProxySQL node configuration. You can use this file in node
        synchronise instances action. Go to the desired node, select the action
        menu "synchronize instances" and choose "Import configuration file".
    </span>
);

function BackupsTable({
    backups,
    loading,
    pagination,
    renderEmpty,
    excludeColumns,
    excludeActions,
    footer,
    showBackupSetIndicator = true,
    filterParams,
    onActionPerformed,
    onJobFinished,
    ...rest
}: BackupsTableProps) {
    const { responsive } = useContext(ResponsiveContext);
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);

    const { runningJobs, stoppedJobs } = useJobWatcher({
        command: [
            CmonJobInstanceCommand.BACKUP,
            CmonJobInstanceCommand.DELETE_BACKUP,
            CmonJobInstanceCommand.RESTORE_BACKUP,
            CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE,
            CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE,
        ],
    });

    const [backupJobs, setBackupJobs] = useState<{ [key: number]: CcJob }>({});

    const pag: TablePaginationConfig = {
        size: 'default',
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
        ...pagination,
    };

    useEffect(() => {
        if (runningJobs) {
            setBackupJobs(
                runningJobs.reduce(
                    (backupJobs, job) =>
                        job.spec?.job_data?.backupid
                            ? {
                                  ...backupJobs,
                                  [job.spec.job_data.backupid]: job,
                              }
                            : backupJobs,
                    {}
                )
            );
        }
    }, [runningJobs]);

    useEffect(() => {
        if (stoppedJobs?.length > 0) {
            onJobFinished?.(
                stoppedJobs
                    .map((item) => item.spec?.command)
                    .filter((item) => !!item)
            );
        }
    }, [stoppedJobs]);

    const { columns } = useTableFilterColumns({
        columns: useMemo(
            () => [
                {
                    title: 'ID',
                    key: 'id',
                    width: 60,
                    render: (record: CcBackup) => <>{record.getId()}</>,
                    sorter: true,
                    // do not allow disabling sorting
                    sortDirections: ['ascend', 'descend', 'ascend'],
                },

                {
                    title: 'Info',
                    key: 'info',
                    align: 'center',
                    render: (record: CcBackup) =>
                        record.isMethod(CcBackupMethod.PROXYSQL) ? (
                            <InfoIcon info={PROXYSQL_TOOLTIP} />
                        ) : (
                            <Space size={0}>
                                <Space size={5}>
                                    <BackupFormat
                                        backup={record}
                                        cluster={clustersMap.get(
                                            record.getClusterKey()
                                        )}
                                        showPopover={true}
                                        popoverProps={{ placement: 'left' }}
                                    >
                                        <InfoCircleOutlined />
                                    </BackupFormat>
                                </Space>
                                {showBackupSetIndicator &&
                                record.getChildren() &&
                                record.getChildren() > 0 ? (
                                    <BackupSetDetailsButton
                                        backup={record}
                                        type="text"
                                        size="small"
                                    >
                                        <AppTooltip
                                            title={`${record.getChildren()} incremental backups`}
                                        >
                                            <FolderOutlined />
                                        </AppTooltip>
                                    </BackupSetDetailsButton>
                                ) : null}
                            </Space>
                        ),
                },
                {
                    title: 'Cluster',
                    key: 'cluster',
                    render: (record: CcBackup) => (
                        <ClusterFormat
                            clusterLink={true}
                            linkDestination={'backups'}
                            cluster={clustersMap.get(record.getClusterKey())}
                            showPopover={true}
                            fullData={true}
                        />
                    ),
                    filterType: TableFilterType.CLUSTER,
                },
                {
                    title: 'Method',
                    key: 'method',
                    render: (record: CcBackup) => {
                        if (record.isMethod(CcBackupMethod.PROXYSQL)) {
                            return (
                                <Space>
                                    <span>proxysql</span>
                                    <AppTooltip title={PROXYSQL_TOOLTIP}>
                                        <Tag>config data</Tag>
                                    </AppTooltip>
                                </Space>
                            );
                        }
                        return clustersMap
                            .get(record.getClusterKey())
                            ?.isTechnology(CcClusterTechnology.TECHNOLOGY_REDIS)
                            ? getRedisBackupMethod()
                            : record.getMethod();
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    render: (record: CcBackup) => (
                        <BackupStatusFormat
                            backup={record}
                            runningJob={backupJobs[record.getId()]}
                            type={StatusFormatType.dot}
                        />
                    ),
                },
                {
                    title: 'Created',
                    key: 'created',
                    render: (record: CcBackup) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <AppDateFormat fromNow>
                                {record.getCreated()
                                    ? new Date(record.getCreated())
                                    : undefined}
                            </AppDateFormat>
                        </span>
                    ),
                },
                {
                    title: 'Size',
                    key: 'size',
                    render: (record: CcBackup) => (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <ByteFormat
                                val={record.getSize()}
                                className="BackupsList_size-col"
                            />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Backup Host
                        </span>
                    ),
                    key: 'backup-host',
                    render: (record: CcBackup) => record.getBackupHost(),
                },
                {
                    title: (
                        <span style={{ whiteSpace: 'nowrap' }}>Storage</span>
                    ),
                    key: 'storage-host',
                    render: (record: CcBackup) => (
                        <BackupStorageFormat backup={record} />
                    ),
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    align: 'center',
                    render: (record: CcBackup) => {
                        const cluster = clustersMap.get(record.getClusterKey());
                        return (
                            cluster && (
                                <UserAclManageCluster cluster={cluster}>
                                    <BackupActionsMenu
                                        cluster={cluster}
                                        backup={record}
                                        excludeKeys={excludeActions}
                                        onActionPerformed={onActionPerformed}
                                    />
                                </UserAclManageCluster>
                            )
                        );
                    },
                    onCell: () => ({ style: { padding: '2px 10px' } }),
                },
            ],
            [clustersMap, showBackupSetIndicator, backupJobs]
        ),
        filterParams,
        state: {
            clusters: clustersMap,
        },
    });

    const filteredColumns = useMemo(
        () =>
            excludeColumns?.length
                ? columns.filter(
                      ({ key }) => key && !excludeColumns.includes(`${key}`)
                  )
                : columns,
        [columns, excludeColumns]
    );

    const rowKey = useCallback((record: CcBackup) => record.getKey(), []);

    const handleOnRow = useCallback((record: CcBackup, index: number) => {
        return {
            'data-testid': `backups-list-row-${index}`,
            className:
                record.getChildren() === 0
                    ? 'BackupsTable_row--not-expandable'
                    : null,
        };
    }, []);
    const expRowRender = (
        record: CcBackup,
        index: number,
        indent: number,
        expanded: boolean
    ) => {
        if (expanded) {
            return (
                <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                    <BackupsList
                        parentId={record.getId()}
                        defaultPageSize={5}
                        tableProps={
                            {
                                size: 'small',
                                footer: () => (
                                    <SpaceWide justify="center">
                                        <BackupSetDetailsButton backup={record}>
                                            View the complete backup set
                                        </BackupSetDetailsButton>
                                    </SpaceWide>
                                ),
                                pagination: { hideOnSinglePage: true },
                            } as any
                        }
                        excludeColumns={excludeColumns}
                    />
                </div>
            );
        } else {
            return null;
        }
    };
    return (
        <AppTable
            className="BackupsTable"
            loading={loading}
            rowKey={rowKey}
            dataSource={backups}
            columns={filteredColumns}
            pagination={pag}
            size="middle"
            responsive={responsive}
            footer={
                footer !== undefined
                    ? (footer as any)
                    : () => <Space size="large" />
            }
            expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                    <UpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <DownOutlined onClick={(e) => onExpand(record, e)} />
                )
            }
            onRow={handleOnRow}
            childrenColumnName="childrenCol" // to avoid reading CcBackup.children property
            renderEmpty={
                filterParams?.cluster
                    ? false
                    : renderEmpty || (
                          <AppEmpty
                              loading={loading}
                              description="You haven’t created backups yet. When you do, it'll show up here."
                          />
                      )
            }
            {...(showBackupSetIndicator
                ? { expandedRowRender: expRowRender }
                : {})}
            {...rest}
        />
    );
}
