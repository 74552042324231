import React, { useEffect } from 'react';
import CmonJobMessage from '../../services/cmon/models/CmonJobMessage';
import TypographyText from '../../common/TypographyText';
import { Alert, Skeleton, Space } from 'antd';
import SpaceWide from '../../common/SpaceWide';
import useJobMessages from './useJobMessages';
import AppDateFormat from '../../common/AppDateFormat';
import AppSpin from '../../common/General/AppSpin';

export type JobLogProps = {
    jobId: number;
    style?: { [key: string]: any };
    onUpdate?: (messages: CmonJobMessage[]) => void;
};
export default function JobLog({ jobId, style, onUpdate }: JobLogProps) {
    const { messages, loaded, error } = useJobMessages({ jobId });
    useEffect(() => {
        if (messages) {
            onUpdate?.(messages);
        }
    }, [messages, onUpdate]);

    return error ? (
        <TypographyText type="danger"> {error.message} </TypographyText>
    ) : (
        <AppSpin spinning={!loaded} showFallback={true}>
            <SpaceWide direction="vertical" style={style} size={1}>
                {messages?.map((message: CmonJobMessage) => (
                    <TypographyText key={message.messageId}>
                        <Space
                            style={{ wordBreak: 'break-word' }}
                            align="start"
                        >
                            <b style={{ wordBreak: 'initial' }}>
                                [
                                <AppDateFormat
                                    format="HH:mm:ss"
                                    includeTimezone={false}
                                >
                                    {message?.created &&
                                        new Date(message.created)}
                                </AppDateFormat>
                                ]:
                            </b>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: message.messageText || '',
                                }}
                            ></div>
                        </Space>
                    </TypographyText>
                )) || null}
            </SpaceWide>
        </AppSpin>
    );
}
