import merge from 'deepmerge';
import RedisShardedConfigurator, {
    RedisShardedFormValues,
} from '../../RedisSharded/RedisShardedConfigurator';
import React from 'react';
import RedisShardedNodeConfiguration, {
    RedisShardedNodeConfigurationProps,
} from '../../RedisSharded/RedisShardedNodeConfiguration';
import RedisShardedAdvancedNodeConfiguration from './RedisShardedAdvancedNodeConfiguration';
import { FormInstance } from 'antd/lib/form';
import ClusterNodesForm from '../../ClusterNodesForm';
import { Alert } from 'antd';
import TopologySummary from '../../TopologySummary';
import RedisShardedNodeSummary, {
    RedisShardedNodeSummaryProps,
} from '../../RedisSharded/RedisShardedNodeSummary';

export default class RedisShardedImportConfigurator extends RedisShardedConfigurator {
    public static getDefaults(): RedisShardedFormValues {
        return merge(RedisShardedConfigurator.getDefaults(), {
            nodeConfig: {
                clusterAutoRecovery: true,
                nodeAutoRecovery: true,
                redisShardedPort: 6379,
            },
        });
    }

    public static getJobData(formValues: RedisShardedFormValues): any {
        const { nodeConfig, topology } = formValues;

        return merge(RedisShardedConfigurator.getJobData(formValues), {
            enable_cluster_autorecovery: nodeConfig.clusterAutoRecovery,
            enable_node_autorecovery: nodeConfig.nodeAutoRecovery,
            db_password: nodeConfig.password,
            db_user: nodeConfig.userName,
            nodes: [
                {
                    class_name: 'RedisShardedHost',
                    hostname: `${topology?.[0].extraData.hostname}`,
                    port: nodeConfig.redisShardedPort,
                    role: 'primary',
                },
            ],
        });
    }

    public static getJobOptions(formValues: RedisShardedFormValues): any {
        return merge(RedisShardedConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Import Redis Cluster',
            },
        });
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <ClusterNodesForm
                    form={form}
                    nodesInputProps={{
                        formProps: {
                            primaryTitle: 'Cluster node',
                        },
                        singleNode: true,
                    }}
                />
                <Alert
                    message="Specify one node and ClusterControl will discover the rest of members."
                    type="info"
                    showIcon={true}
                />
            </div>
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <TopologySummary singleNode={true} form={form} />;
    }

    public static getNodeConfigurationStep({
        form,
    }: RedisShardedNodeConfigurationProps): React.ReactNode {
        return (
            <>
                <RedisShardedNodeConfiguration
                    form={form}
                    hasTemplate={false}
                    importCluster={true}
                />
                <RedisShardedAdvancedNodeConfiguration />
            </>
        );
    }
    public static getNodeConfigurationSummary(
        props: RedisShardedNodeSummaryProps
    ): React.ReactNode {
        return (
            <RedisShardedNodeSummary
                hasAdvancedNodeConfiguration={true}
                {...props}
            />
        );
    }
}
