import React, { Fragment } from 'react';
import './ClusterPageMenu.less';
import PageMenu, { PageMenuItem } from '../../PageMenu';
import AlarmsBadge from '../Alarms/AlarmsBadge';
import JobsBadge from '../Jobs/JobsBadge';
import ClusterActionsMenu from './Actions/ClusterActionsMenu';
import SpaceWide from '../../common/SpaceWide';
import UserAclManageCluster from '../User/UserAclManageCluster';
import CcCluster, {
    CcClusterBase,
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import { Dropdown, Menu, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import BetaRibbon from '../../common/Feedback/BetaRibbon';
import useClusterList from './useClusterList';
import ClusterTypeFormat from './ClusterTypeFormat';
import TypographyText from '../../common/TypographyText';
import ClusterStateFormat from './ClusterStateFormat';
import NodeListInline from '../Nodes/NodeListInline';
import ClusterAutoRecoveryFormat from './ClusterAutoRecoveryFormat';
import AppDivider from '../../common/AppDivider';
import AppPageHeaderBlock from '../../common/Layout/AppPageHeaderBlock';
import ClusterNodeAutoRecoveryFormat from './ClusterNodeAutoRecoveryFormat';
import useCurrentCluster from './useCurrentCluster';
import { PgVectorIcon } from '../../common/icons/icons';
import { AppIconSize } from '../../common/icons/AppIcon';
import AppTooltip from '../../common/Feedback/AppTooltip';

export default ClusterPageMenu;

export type ClusterPageMenuProps = {
    clusterId?: number;
    activeKey?: string;
};

function ClusterPageMenu({ clusterId, activeKey }: ClusterPageMenuProps) {
    const cluster = useCurrentCluster({ clusterId });
    const { refresh: refreshClusters } = useClusterList();

    const handleActionPerformed = async () => {
        refreshClusters({ useCache: false });
    };

    const extensionIcons =
        cluster?.dbExtensions
            ?.map((extension) => {
                switch (extension.name) {
                    case 'vector':
                        return (
                            <AppTooltip title="pgvector">
                                <span>
                                    <PgVectorIcon size={AppIconSize.xsmall} />
                                </span>
                            </AppTooltip>
                        );
                    default:
                        return null;
                }
            })
            .filter(Boolean) || [];

    return cluster ? (
        <SpaceWide direction="vertical" className="ClusterPageMenu" size={0}>
            <AppPageHeaderBlock>
                <AppDivider invisible={true} />
                <SpaceWide size={10} wrap={true}>
                    <Space>
                        <TypographyText nowrap>
                            <ClusterTypeFormat
                                type={cluster.clusterType}
                                vendor={cluster.vendor}
                                showIcon={true}
                            />{' '}
                            {cluster.version}
                        </TypographyText>
                        &nbsp;
                        <ClusterStateFormat cluster={cluster} showMaintenance />
                    </Space>
                    {extensionIcons.length > 0 ? (
                        <Space>
                            <AppDivider type="vertical" />
                            <Space>
                                <span>Extensions:</span>
                                {extensionIcons}
                            </Space>
                        </Space>
                    ) : null}
                    <AppDivider type="vertical" />
                    <Space>
                        <span>Auto-recovery:</span>
                        <Tooltip title="Cluster auto-recovery">
                            <ClusterAutoRecoveryFormat
                                cluster={cluster}
                                showIcon={true}
                            />
                        </Tooltip>
                        <Tooltip title="Node auto-recovery">
                            <ClusterNodeAutoRecoveryFormat
                                cluster={cluster}
                                showIcon={true}
                            />
                        </Tooltip>
                    </Space>
                    <AppDivider type="vertical" />
                    <Space>
                        <span>Nodes:</span>
                        <NodeListInline cluster={cluster} size={[15, 8]} />
                    </Space>
                </SpaceWide>
            </AppPageHeaderBlock>
            <AppDivider />
            <AppPageHeaderBlock>
                <SpaceWide justify="space-between">
                    <PageMenu
                        activeTab={activeKey}
                        menuItems={getClusterPageMenu(cluster)}
                    />
                    <UserAclManageCluster cluster={cluster}>
                        <ClusterActionsMenu
                            cluster={cluster}
                            onActionPerformed={handleActionPerformed}
                        />
                    </UserAclManageCluster>
                </SpaceWide>
            </AppPageHeaderBlock>
        </SpaceWide>
    ) : null;
}

function getClusterPageMenu(cluster: CcCluster): PageMenuItem[] {
    return [
        {
            key: 'dashboard',
            path: '/clusters/:clusterId/dashboard',
            title: 'Dashboards',
        },
        {
            key: 'nodes',
            path: '/clusters/:clusterId/nodes',
            title: (
                <Dropdown
                    overlayClassName="ClusterSubmenu_overlay"
                    overlay={
                        <Menu
                            onClick={(menuInfo) =>
                                menuInfo.domEvent.stopPropagation()
                            }
                        >
                            <Menu.Item key={'nodes/list'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/nodes/list`}
                                >
                                    Node list
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={'nodes/topolgoy'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/nodes/topology`}
                                >
                                    Topology
                                </Link>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <span>Nodes</span>
                </Dropdown>
            ),
        },
        {
            key: 'performance',
            path: `/clusters/:clusterId/performance/${!cluster?.isBase(CcClusterBase.BASE_REDIS) ? 'db-growth' : 'advisors'}`,
            title: (
                <Dropdown
                    overlayClassName="ClusterSubmenu_overlay"
                    overlay={
                        <Menu
                            onClick={(menuInfo) =>
                                menuInfo.domEvent.stopPropagation()
                            }
                        >
                            {!cluster?.isBase(CcClusterBase.BASE_REDIS) ? (
                                <Menu.Item key={'performance/db-growth'}>
                                    <Link
                                        to={`/clusters/${cluster?.getKey()}/performance/db-growth`}
                                    >
                                        DB Growth
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {[
                                CcClusterTechnology.TECHNOLOGY_MONGODB,
                                CcClusterTechnology.TECHNOLOGY_MYSQL,
                            ].includes(cluster?.getTechnology()) ? (
                                <Fragment>
                                    <Menu.Item key={'performance/db-status'}>
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/db-status`}
                                        >
                                            DB Status
                                        </Link>
                                    </Menu.Item>
                                </Fragment>
                            ) : null}

                            {[
                                CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
                                CcClusterTechnology.TECHNOLOGY_MYSQL,
                            ].includes(cluster?.getTechnology()) ? (
                                <Fragment>
                                    <Menu.Item key={'performance/db-variables'}>
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/db-variables`}
                                        >
                                            DB Variables
                                        </Link>
                                    </Menu.Item>
                                </Fragment>
                            ) : null}

                            {[
                                CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
                                CcClusterTechnology.TECHNOLOGY_MYSQL,
                            ].includes(cluster?.getTechnology()) ? (
                                <Fragment>
                                    <Menu.Item
                                        key={'performance/query-monitor'}
                                    >
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/query-monitor`}
                                        >
                                            Query Monitor
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key={
                                            'performance/agent-based-query-monitor'
                                        }
                                    >
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/agent-based-query-monitor`}
                                        >
                                            <BetaRibbon margin={5}>
                                                Query Monitor/Agents
                                            </BetaRibbon>
                                        </Link>
                                    </Menu.Item>
                                </Fragment>
                            ) : null}
                            <Menu.Item key={'performance/advisors'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/performance/advisors`}
                                >
                                    Advisors
                                </Link>
                            </Menu.Item>
                            {cluster?.getTechnology() ===
                            CcClusterTechnology.TECHNOLOGY_MONGODB ? (
                                <Fragment>
                                    <Menu.Item
                                        key={'performance/running-operations'}
                                    >
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/running-operations`}
                                        >
                                            Running Operations
                                        </Link>
                                    </Menu.Item>
                                </Fragment>
                            ) : null}
                            {[CcClusterTechnology.TECHNOLOGY_MYSQL].includes(
                                cluster?.getTechnology()
                            ) ? (
                                <Fragment>
                                    <Menu.Item
                                        key={'performance/transaction-log'}
                                    >
                                        <Link
                                            to={`/clusters/${cluster?.getKey()}/performance/transaction-log`}
                                        >
                                            Transaction Deadlocks
                                        </Link>
                                    </Menu.Item>
                                </Fragment>
                            ) : null}
                        </Menu>
                    }
                >
                    <span>Performance</span>
                </Dropdown>
            ),
        },
        {
            key: 'backups',
            path: '/clusters/:clusterId/backups',
            title: 'Backups',
        },
        {
            key: 'alarms',
            path: '/clusters/:clusterId/alarms',
            title: (
                <AlarmsBadge
                    clusterId={cluster.clusterId}
                    offsetY={-5}
                    offsetX={5}
                >
                    Alarms
                </AlarmsBadge>
            ),
        },
        {
            key: 'jobs',
            path: '/clusters/:clusterId/jobs',
            title: (
                <JobsBadge
                    clusterId={cluster.clusterId}
                    offsetY={-5}
                    offsetX={5}
                >
                    Jobs
                </JobsBadge>
            ),
        },

        {
            key: 'logs',
            path: '/clusters/:clusterId/logs',
            title: (
                <Dropdown
                    overlayClassName="ClusterSubmenu_overlay"
                    overlay={
                        <Menu
                            onClick={(menuInfo) =>
                                menuInfo.domEvent.stopPropagation()
                            }
                        >
                            <Menu.Item key={'logs/system-log'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/logs/system-log`}
                                >
                                    System log
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={'logs/audit-log'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/logs/audit-log`}
                                >
                                    Audit Log
                                </Link>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <span>Logs</span>
                </Dropdown>
            ),
        },
        {
            key: 'reports',
            path: '/clusters/:clusterId/reports',
            title: 'Reports',
        },
        {
            key: 'manage',
            path: '/clusters/:clusterId/manage/configuration',
            title: (
                <Dropdown
                    overlayClassName="ClusterSubmenu_overlay"
                    overlay={
                        <Menu
                            onClick={(menuInfo) =>
                                menuInfo.domEvent.stopPropagation()
                            }
                        >
                            <Menu.Item key={'manage/configuration'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/manage/configuration`}
                                >
                                    Configuration
                                </Link>
                            </Menu.Item>
                            {[
                                CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
                                CcClusterTechnology.TECHNOLOGY_MYSQL,
                            ].includes(cluster?.getTechnology()) && (
                                <Menu.Item key={'manage/db-users'}>
                                    <Link
                                        to={`/clusters/${cluster?.getKey()}/manage/db-users`}
                                    >
                                        DB Users
                                    </Link>
                                </Menu.Item>
                            )}
                            <Menu.Item key={'manage/advisors'}>
                                <Link
                                    to={`/clusters/${cluster?.getKey()}/manage/advisors`}
                                >
                                    Advisors
                                </Link>
                            </Menu.Item>
                            {!cluster.isBase(CcClusterBase.BASE_REDIS) ? (
                                <Menu.Item key={'manage/scripts'}>
                                    <Link
                                        to={`/clusters/${cluster?.getKey()}/manage/scripts`}
                                    >
                                        Scripts
                                    </Link>
                                </Menu.Item>
                            ) : null}
                        </Menu>
                    }
                >
                    <span>Manage</span>
                </Dropdown>
            ),
        },
        {
            key: 'settings',
            path: '/clusters/:clusterId/settings',
            title: ({ clusterId }) => (
                <Dropdown
                    overlayClassName="ClusterSubmenu_overlay"
                    overlay={
                        <Menu
                            onClick={(menuInfo) =>
                                menuInfo.domEvent.stopPropagation()
                            }
                        >
                            <Menu.Item key={'settings/system-settings'}>
                                <Link
                                    to={`/clusters/${clusterId}/settings/system-settings`}
                                >
                                    System settings
                                </Link>
                            </Menu.Item>

                            <Menu.Item key={'settings/email-notifications'}>
                                <Link
                                    to={`/clusters/${clusterId}/settings/email-notifications`}
                                >
                                    Email Notification
                                </Link>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <span>Settings</span>
                </Dropdown>
            ),
        },
    ];
}
