import { Alert, Col, Form, Row, Space } from 'antd';
import React, { useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import cronParser from 'cron-parser';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import CronInput from '../../../common/DataEntry/CronInput';
import TimezoneSelect from '../../../common/DataEntry/TimezoneSelect';

export type BackupScheduleCronInputFormProps = {
    form: FormInstance;
};
const BackupScheduleCronInputForm = ({
    form,
}: BackupScheduleCronInputFormProps) => {
    const [value, setValue] = useState(form.getFieldValue('scheduleCron'));

    const changeHandle = (newValue: string) => {
        setValue(newValue);
    };

    const validateCron = async (rule: any, value: string) => {
        try {
            cronParser.parseExpression(value);
        } catch (error) {
            throw new Error(`Please enter a valid schedule`);
        }
    };

    return (
        <div className="BackupScheduleCronInputForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Schedule</h3>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="scheduleCron"
                        label={<Space>Set backup schedule</Space>}
                        rules={[
                            {
                                validator: validateCron,
                            },
                        ]}
                    >
                        <CronInput value={value} onChange={changeHandle} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={8}>
                    <Form.Item
                        name="timezone"
                        label={<Space>Select timezone</Space>}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <TimezoneSelect />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Alert
                        message={
                            <>
                                <Space>
                                    Your backup will start:
                                    <b>
                                        <CronFormat>{value}</CronFormat>
                                    </b>
                                    <span>
                                        ({form.getFieldValue('timezone')})
                                    </span>
                                </Space>
                            </>
                        }
                        type="info"
                        showIcon={true}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BackupScheduleCronInputForm;
