import React from 'react';
import { To } from 'react-router-dom';
import AppLink, { AppLinkProps } from '../../common/AppLink';

export default ActivityCenterAlarmsLink;

export type ActivityCenterAlarmsLinkProps = Omit<AppLinkProps, 'to'> & {
    to?: To;
};

function ActivityCenterAlarmsLink({
    to = '/activity-center/alarms',
    children,
    ...rest
}: ActivityCenterAlarmsLinkProps) {
    return (
        <AppLink to={to} {...rest}>
            {children || 'Activity center'}
        </AppLink>
    );
}
