import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
// import CcPrometheusNode from '../../services/models/CcPrometheusNode';
import CcCluster from '../../services/models/CcCluster';
import { Select } from 'antd';
import {
    MonitorDashboardTemplatingItemConfig,
    MonitorDatasource,
} from './Monitor';
import { LabeledValue } from 'antd/es/select';
import {
    exprReplace,
    getVariableAllSeparator,
    INTERPOLATE_PATTERN,
    queryClusterObjects,
    queryNeedsReplacements,
    strInterpolate,
} from './monitorUtils';
import { getSortAlphabeticFn } from '../../common/sorting';
import CcHost from '../../services/models/CcHost';

export default MonitorVariable;

export interface MonitorVariableItem {
    isInstancePicker: boolean;
    key: string;
    loaded: boolean;
    ready: boolean;
    sort: number;
    value: string;
    variable: MonitorDashboardTemplatingItemConfig;
    extraValues: string[];
    instance?: CcHost;
}

export interface MonitorVariableOption extends LabeledValue {
    key: string;
    label: string;
    value: string;
}

export type MonitorVariableProps = {
    cluster: CcCluster;
    selectedValue?: string | null;
    variable: MonitorDashboardTemplatingItemConfig;
    vars: MonitorVariableItem[];
    style?: CSSProperties;
    onVariableLoad?: (
        variable: MonitorDashboardTemplatingItemConfig,
        value: string,
        label: string
    ) => void;
    onVariableChange?: (
        variable: MonitorDashboardTemplatingItemConfig,
        value: string,
        label: string
    ) => void;
};

function MonitorVariable({
    cluster,
    variable,
    selectedValue,
    vars,
    onVariableLoad,
    onVariableChange,
    ...rest
}: MonitorVariableProps) {
    // const [prometheusNode, setPrometheusNode] = useState<CcPrometheusNode>();
    const [options, setOptions] = useState<MonitorVariableOption[]>();
    const [selectedOption, setSelectedOption] = useState<any>({});

    const loadOptions = useCallback(async () => {
        let newOptions: MonitorVariableOption[] = [];
        if (variable.datasource === MonitorDatasource.CLUSTERCONTROL) {
            if (
                variable.query.indexOf('host:') ||
                variable.query.indexOf('node:')
            ) {
                newOptions = getClusterDashboardVariableOptions(
                    variable,
                    cluster,
                    vars
                );
            }
        }
        if (variable.includeAll) {
            newOptions.unshift({
                key: 'all',
                // we sort the items but we want All to be first option so we add a nasty space at the begining
                label: ' All',
                value:
                    variable.allValue === null
                        ? newOptions
                              .map((o: MonitorVariableOption) => o.value)
                              .join(getVariableAllSeparator(variable))
                        : variable.allValue,
            });
        }
        newOptions.sort(getSortAlphabeticFn('ascend', (a) => a.label));
        setOptions(newOptions);
    }, [variable, cluster, vars]);

    // variable param changes
    useEffect(() => {
        loadOptions();
    }, [variable]);

    // vars are possible dependencies of this variable
    // if they change we need to load options again
    useEffect(() => {
        if (vars && queryNeedsReplacements(variable.query)) {
            loadOptions();
        }
    }, [vars]);

    // re-select options and trigger load when options change
    useEffect(() => {
        if (options) {
            const opt = selectedValue
                ? options.find((op) => op.value === selectedValue) || options[0]
                : options[0];
            if (opt) {
                setSelectedOption(opt);
                onVariableLoad?.(variable, opt.value, opt.label);
            }
        }
    }, [options]);

    const handleChange = (val: string, option: MonitorVariableOption) => {
        setSelectedOption(option);
        onVariableChange?.(variable, option.value, option.label);
    };

    return (
        <Select
            className="MonitorVariable"
            data-testid={`monitor-variable-${variable.name}`}
            options={options}
            value={selectedOption?.value}
            onChange={handleChange as any}
            {...rest}
        />
    );
}

/**
 * Get variable options based on dashboard config, cluster and possible
 * variable dependencies
 */
function getClusterDashboardVariableOptions(
    templating: MonitorDashboardTemplatingItemConfig,
    cluster: CcCluster,
    variables: MonitorVariableItem[]
): MonitorVariableOption[] {
    return queryClusterObjects(
        cluster,
        exprReplace(
            templating.query,
            variables,
            MonitorDatasource.CLUSTERCONTROL,
            ''
        )
    ).map((item: any, itemIdx: number) => {
        return {
            key: `${itemIdx}`,
            value: `${
                templating.optionValue.match(INTERPOLATE_PATTERN)
                    ? strInterpolate(templating.optionValue, item)
                    : (item[templating.optionValue] as string)
            }`,
            label: `${
                templating.optionText.match(INTERPOLATE_PATTERN)
                    ? strInterpolate(templating.optionText, item)
                    : (item[templating.optionText] as string)
            }`,
        };
    });
}
