import merge from 'deepmerge';
import React from 'react';
import TopologySummary from '../TopologySummary';
import MysqlNodeConfiguration from '../Mysql/MysqlNodeConfiguration';
import MysqlConfigurator, { MysqlFormValues } from '../Mysql/MysqlConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/es';
import ClusterNodesForm from '../ClusterNodesForm';

export default class MysqlGaleraConfigurator extends MysqlConfigurator {
    public static getJobData(formValues: MysqlFormValues): any {
        const { nodeConfig } = formValues;
        return merge(MysqlConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_GALERA.toLowerCase(),
            enable_ssl: nodeConfig.sslEncryption,
        });
    }

    public static getNodeConfigurationStep(props: any): React.ReactNode {
        return (
            <MysqlNodeConfiguration
                hasSslEncryption
                clusterType={CcClusterType.TYPE_GALERA}
                configurator={this}
                {...props}
            />
        );
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <ClusterNodesForm
                form={form}
                nodesInputProps={{
                    onlyPrimaries: true,
                    mutateItem: (item) => {
                        return { ...item, description: 'Galera node' };
                    },
                    formProps: {
                        primaryTitle: 'Galera node',
                        primaryExtra:
                            ' Please note that an odd number of nodes is recommended, i.e., 3, 5, 7, etc.',
                    },
                }}
            />
        );
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return (
            <TopologySummary
                form={form}
                title="Deploying galera nodes"
                singleNode={true}
            />
        );
    }
}
