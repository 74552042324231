import React from 'react';
import { getAlarmSeverityFormatType } from './AlarmSeverityFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import AlarmActionsMenu from './AlarmActionsMenu';
import TypographyText from '../../common/TypographyText';
import DetailsModal, {
    DetailsModalProps,
} from '../../common/Feedback/DetailsModal';
import { CcAlarm } from '../../services/models';
import { JobActionsMenuProps } from '../Jobs/JobActionsMenu';
import { Space } from 'antd';
import { getStatusFormatIconFilled } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import TextFormat from '@severalnines/bar-frontend-components/build/lib/Format/TextFormat';
import { CopyOutlined } from '@ant-design/icons';

export default AlarmDetailsModal;

export type AlarmDetailsModalProps = Omit<DetailsModalProps, 'children'> & {
    alarm: CcAlarm;
    onActionPerformed?: JobActionsMenuProps['onActionPerformed'];
};

function AlarmDetailsModal({
    alarm,
    onActionPerformed,
    ...rest
}: AlarmDetailsModalProps) {
    return (
        <DetailsModal
            visible={true}
            title="Alarm details"
            headerTitle={
                <TextFormat
                    type={getAlarmSeverityFormatType(alarm.severityName)}
                >
                    {alarm.title}
                </TextFormat>
            }
            icon={getStatusFormatIconFilled(
                getAlarmSeverityFormatType(alarm.severityName)
            )}
            header={
                <SpaceDescriptions>
                    <SpaceDescriptions.Item label="Recommendation">
                        {alarm.recommendation}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            }
            headerExtra={
                alarm ? (
                    <AlarmActionsMenu
                        alarm={alarm}
                        excludeKeys={['details']}
                        onActionPerformed={onActionPerformed}
                    />
                ) : null
            }
            {...rest}
        >
            <SpaceDescriptions
                title="Alarm details"
                extra={
                    <TypographyText
                        copyable={{
                            text: alarm.message,
                            icon: (
                                <Space>
                                    <CopyOutlined />
                                    Copy to clipboard
                                </Space>
                            ),
                        }}
                    ></TypographyText>
                }
            >
                <SpaceDescriptions.Item>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: alarm.message || '',
                        }}
                    />
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
        </DetailsModal>
    );
}
