import React, { useMemo } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import AppPageWrapper from '../../../common/Layout/AppPageWrapper';
import { useParams } from 'react-router-dom';
import SystemLogsPage from '../../SystemLogs/SystemLogsPage';
import { useNavigate } from 'react-router-dom';
import ClusterAuditLog from './ClusterAuditLog';
import AppSectionWrapper from '../../../common/Layout/AppSectionWrapper';

export default ClusterLogs;

export enum ClusterLogsPage {
    SYSTEM_LOGS = 'system-log',
    AUDIT_LOG = 'audit-log',
}

export type ClustersLogsProps = { cluster: CcCluster };

function ClusterLogs({ cluster }: ClustersLogsProps) {
    const navigate = useNavigate();
    const { section = ClusterLogsPage.SYSTEM_LOGS } = useParams<{
        section: ClusterLogsPage;
    }>();
    const submenuItems = useMemo(
        () => [
            {
                label: 'System log',
                key: ClusterLogsPage.SYSTEM_LOGS,
                children: <SystemLogsPage cluster={cluster} />,
            },
            {
                label: 'Audit log',
                key: ClusterLogsPage.AUDIT_LOG,
                children: <ClusterAuditLog cluster={cluster} />,
            },
        ],
        [cluster]
    );

    const tall = [ClusterLogsPage.SYSTEM_LOGS].includes(section);

    return (
        <AppPageWrapper
            className="ClusterLogs"
            transparentBackground={true}
            noPadding={true}
            tall={tall}
        >
            <AppSectionWrapper
                items={submenuItems}
                activeSection={section}
                onNavigate={(key) => {
                    navigate(`/clusters/${cluster.clusterId}/logs/${key}`);
                }}
                tall={tall}
            />
        </AppPageWrapper>
    );
}
