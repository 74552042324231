import React, { useContext, useEffect, useRef, useState } from 'react';
import CcCluster from '../../services/models/CcCluster';
import MonitorToolbar, { MonitorToolbarApi } from '../Monitor/MonitorToolbar';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import AppTable from '../../common/DataDisplay/AppTable';
import SpaceWide from '../../common/SpaceWide';
import useQMProcessesList from './useQMProcessesList';
import TypographyText from '../../common/TypographyText';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import KillQueryButton from './KillQueryButton';
import QueryMonitorEnabledWrapper from './QueryMonitorEnabledWrapper';
import QueryMonitorNodeSelect from './QueryMonitorNodeSelect';
import useTableFilter from '../../common/hooks/useTableFilter';
import { StringParam } from 'use-query-params';
import { isoTimeToMilliseconds } from '../../common/dateTime';
import { formatDuration } from '@severalnines/bar-frontend-components/build/lib/Format/DurationFormat';
import { StopOutlined } from '@ant-design/icons';

export default QueryProcessesPage;

export type QueryProcessesPageProps = {
    cluster: CcCluster;
};

function QueryProcessesPage({ cluster }: QueryProcessesPageProps) {
    const { responsive } = useContext(ResponsiveContext);
    const monitorToolbarRef = useRef<MonitorToolbarApi>(null);

    const {
        filterParams,
        handleTableChange: onTableChange,
        addFilterParams,
    } = useTableFilter({
        params: {
            host: StringParam,
        },
    });

    const [selectedHostname, setSelectedHostname] = useState<
        string | undefined
    >(filterParams.host || 'all');

    const {
        refresh,
        list,
        loading,
        page,
        pageSize,
        total,
    } = useQMProcessesList({
        clusterId: cluster.clusterId!,
    });

    const handleNodeChange = async (value: string) => {
        monitorToolbarRef.current?.resetTimeout();
        setSelectedHostname(value);
        if (value === 'all') {
            addFilterParams({
                host: null,
                page: 1,
            });
        } else {
            addFilterParams({ host: value, page: 1 });
        }
    };

    const handleRefresh = (triggerClick?: boolean) => {
        refresh({ showLoading: !!triggerClick, page });
    };

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [cluster?.clusterId]);

    useEffect(() => {
        const { host, ...restFilter } = filterParams;
        const [hostId, hostname, port] = (host || '')?.split(':');
        (async () => {
            await refresh({
                ...restFilter,
                hostId: Number.parseInt(hostId),
                hostname,
                port: Number.parseInt(port),
            });
        })();
    }, [filterParams]);

    const columns = [
        {
            title: 'Hostname',
            key: 'hostname',
            render: (record: any) => record.hostname,
        },
        {
            title: 'ID',
            key: 'id',
            render: (record: any) => record.pid || record.backendPid, //bc for mysql and pg we get different fieldnames
        },
        {
            title: 'Schema',
            key: 'db',
            render: (record: any) => record.canonical || record.databaseName, //bc for mysql and pg we get different fieldnames
        },
        {
            title: 'User',
            key: 'user',
            render: (record: any) => record.user || record.userName, //bc for mysql and pg we get different fieldnames
        },
        {
            title: 'Time',
            key: 'time',
            render: (record: any) =>
                formatDuration(
                    record.time ||
                        (record.elapsedTime &&
                            isoTimeToMilliseconds(record.elapsedTime)), //bc for mysql and pg we get different fieldnames and format
                    {}
                ),
        },
        {
            title: 'Client',
            key: 'client',
            render: (record: any) => record.client,
        },
        {
            title: 'Info',
            key: 'info',
            render: (record: any) => (
                <TypographyText
                    ellipsis={{ tooltip: record.info || record.query }} //bc for mysql and pg we get different fieldnames
                    style={{ width: '250px' }}
                >
                    {record.info || record.query}
                </TypographyText>
            ),
        },
        {
            title: 'State',
            key: 'state',
            render: (record: any) => record.state,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: any) =>
                showKillProcessButton(record) ? (
                    <ActionsMenu
                        items={[
                            {
                                key: 'kill-query',
                                critical: true,
                                icon: <StopOutlined />,
                                label: (
                                    <KillQueryButton
                                        cluster={cluster}
                                        hostPort={record.node}
                                        pid={record.pid || record.backendPid} //bc for mysql and pg we get different fieldnames
                                    />
                                ),
                            },
                        ]}
                    />
                ) : null,
        },
    ];
    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        onTableChange(pagination, filter, sorters);
        // need to reset timeout in another tick so page is updated
        setTimeout(() => {
            monitorToolbarRef.current?.resetTimeout();
        });
    };
    const rowKey = (record: any) =>
        `${record.hostId}-${record.hostname}-${
            record.pid || record.backendPid
        }`;
    return (
        <QueryMonitorEnabledWrapper loading={loading} cluster={cluster}>
            <SpaceWide direction="vertical" size={24} className="ProcessesPage">
                <MonitorToolbar
                    ref={monitorToolbarRef}
                    enableTimerange={false}
                    onRefresh={handleRefresh}
                    selectors={[
                        <QueryMonitorNodeSelect
                            key="query-mointor-node-select"
                            cluster={cluster}
                            onChange={handleNodeChange}
                            value={selectedHostname}
                        />,
                    ]}
                />

                <AppTable
                    className="ProcessesPageTable"
                    rowKey={rowKey}
                    dataSource={list}
                    columns={columns}
                    pagination={{ current: page, pageSize, total }}
                    size="middle"
                    responsive={responsive}
                    onChange={handleTableChange}
                />
            </SpaceWide>
        </QueryMonitorEnabledWrapper>
    );
}

export function showKillProcessButton(record: any) {
    if (record === undefined) {
        return false;
    }
    return record && !(record.user || record.userName).includes('system');
}
