import React from 'react';
import NodeTypeFormat from '../components/Nodes/NodeTypeFormat';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import { Col, Row } from 'antd';
import { CcClusterVendor } from '../services/models/CcCluster';
import { CcNodeType } from '../services/models/CcNode';
import { CcNode } from '../services/models';

const nodes = [
    { nodetype: CcNodeType.MYSQL, vendor: CcClusterVendor.ORACLE },
    { nodetype: CcNodeType.MYSQL, vendor: CcClusterVendor.MARIADB },
    { nodetype: CcNodeType.MYSQL, vendor: CcClusterVendor.PERCONA },
    { nodetype: CcNodeType.GALERA, vendor: CcClusterVendor.MARIADB },
    { nodetype: CcNodeType.GALERA, vendor: CcClusterVendor.PERCONA },
    { nodetype: CcNodeType.POSTGRESQL, vendor: CcClusterVendor.POSTGRESQL },
    { nodetype: CcNodeType.TIMESCALEDB, vendor: CcClusterVendor.POSTGRESQL },
    { nodetype: CcNodeType.MONGO, vendor: CcClusterVendor.TEN_GEN },
    { nodetype: CcNodeType.MONGO, vendor: CcClusterVendor.MONGODB_ENTERPRISE },
    { nodetype: CcNodeType.MONGO, vendor: CcClusterVendor.PERCONA },
    { nodetype: CcNodeType.REDIS, vendor: CcClusterVendor.REDIS },
    { nodetype: CcNodeType.REDIS_SENTINEL, vendor: CcClusterVendor.REDIS },
    { nodetype: CcNodeType.REDIS_SHARDED, vendor: CcClusterVendor.REDIS },
    { nodetype: CcNodeType.MSSQL, vendor: CcClusterVendor.MICROSOFT },
    { nodetype: CcNodeType.ELASTIC, vendor: CcClusterVendor.ELASTICSEARCH },
].map((n) => new CcNode(n as any));
export default DebugNodeTypeFormat;
export type DebugNodeTypeFormatProps = {};

function DebugNodeTypeFormat({ ...rest }: DebugNodeTypeFormatProps) {
    return (
        <Row className="DebugNodeTypeFormat" gutter={[24, 24]}>
            <Col span={12}>
                <SpaceDescriptions
                    title="NodeTypeFormat component"
                    direction={'vertical'}
                >
                    {nodes.map((n) => {
                        return (
                            <Row>
                                <Col span={6}>Type: {n.nodetype}</Col>
                                <Col span={6}>Vendor: {n.vendor}</Col>
                                <Col span={1}>{'==>'}</Col>
                                <Col span={6}>
                                    <NodeTypeFormat
                                        key={n.getAddress()}
                                        type={n.nodetype}
                                        vendor={n.vendor}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </SpaceDescriptions>
            </Col>
        </Row>
    );
}
