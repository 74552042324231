import ModalDefaultForm from '../../common/ModalDefaultForm';
import React, { useState } from 'react';
import BackupSettingsForm, {
    BackupSettingsFormFields,
    getBackupSettingConfig,
    getBackupSettingsEnableArchivingJobData,
} from './BackupSettingsForm';
import CcCluster, {
    CcClusterTechnology,
    getTechnology,
} from '../../services/models/CcCluster';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { ClusterConfigProvider } from '../Clusters/Config/ClusterConfigContext';
import { useNotificationContext } from '../Notifications/NotificationProvider';

export default BackupSettingsModalForm;
export type BackupSettingsModalFormProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function BackupSettingsModalForm({
    cluster,
    onCancel,
    onSuccess,
}: BackupSettingsModalFormProps) {
    const navigate = useNavigate();
    const [saving, setSaving] = useState<boolean>(false);
    const [form] = useForm();
    const { notifyJobCreationSuccess } = useNotificationContext();

    const handleSubmit = async (fields: BackupSettingsFormFields) => {
        try {
            if (!cluster.clusterId) {
                throw new Error('Cluster is not set');
            }
            setSaving(true);
            await CmonClustersService.setConfig({
                cluster_id: cluster.clusterId,
                configuration: getBackupSettingConfig(fields),
            });

            if (fields.enableWALArchiving && fields.walBackupHost) {
                const nodes = cluster.getDatabaseNodes();
                if (
                    nodes?.findIndex((node: CcNode) => {
                        return node?.isPitrMethodLocalWalArchive();
                    }) === -1
                ) {
                    const {
                        job,
                    } = await CmonJobsService.createEnableLogArchivingJobInstance(
                        cluster.clusterId as number,
                        {
                            job_data: getBackupSettingsEnableArchivingJobData(
                                cluster.clusterId as number,
                                fields
                            ),
                        },
                        {
                            job: {
                                title: 'Enable WAL log archiving',
                            },
                        }
                    );
                    notifyJobCreationSuccess({
                        content: 'Enabling WAL log archiving',
                        job,
                    });
                }
            }
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Backup Settings saved successfully!',
                okText: 'Ok',
                cancelText: 'Close',
            });
            setSaving(false);
            onSuccess?.();
            return true;
        } catch (err: any) {
            setSaving(false);
            notifyError({ type: NotifyType.TOAST, content: err.message });
            console.error(err);
        }
        return false;
    };

    return (
        <ClusterConfigProvider clusterId={cluster.clusterId}>
            <ModalDefaultForm
                title="Backup Settings"
                form={form}
                footer={[]}
                onCancel={onCancel}
                bodyStyle={{}}
                defaultVisible={true}
                showConfirmClose={true}
            >
                <BackupSettingsForm
                    form={form}
                    hosts={cluster.getDatabaseNodes()}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                    buttonLoading={saving}
                    walArchiving={
                        getTechnology(cluster.clusterType) ===
                        CcClusterTechnology.TECHNOLOGY_POSTGRESQL
                    }
                />
            </ModalDefaultForm>
        </ClusterConfigProvider>
    );
}
