import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import CcJob, { CcJobStatus } from '../../services/models/CcJob';
import JobDetailsButton from './JobDetailsButton';
import JobRetryButton from './JobRetryButton';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import useCurrentUser from '../User/useCurrentUser';
import CcCluster from '../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobDeleteButton from './JobDeleteButton';
import {
    DeleteOutlined,
    FileTextOutlined,
    LinkOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { getJobDetailsLinkInActivityViewer } from '../GlobalViewer/GlobalViewer';
import AppActionCopyButton from '../../common/General/AppActionCopyButton';

export default JobActionsMenu;

export type JobActionsMenuProps = ActionsMenuProps & {
    job: CcJob;
    cluster?: CcCluster;
};

function JobActionsMenu({
    job,
    cluster,
    onActionPerformed,
    ...rest
}: JobActionsMenuProps) {
    const { canManageCluster, canExecuteClusters } = useCurrentUser();
    const handleJobRetrySuccess = () => {
        onActionPerformed?.('retry');
    };
    const handleJobDeleteSuccess = () => {
        onActionPerformed?.('delete');
    };
    const handleDetailsActionPerformed = (actionType) => {
        onActionPerformed?.(actionType);
    };

    const canRetryJob = () => {
        if ([CcJobStatus.FAILED, CcJobStatus.ABORTED].includes(job.status)) {
            if (
                [
                    CmonJobInstanceCommand.CREATE_CLUSTER,
                    CmonJobInstanceCommand.ADD_CLUSTER,
                ].includes(job.spec?.command) &&
                canExecuteClusters()
            ) {
                // user can execute clusters
                return true;
            } else if (
                canManageCluster(cluster?.clusterId as number) &&
                job.userName !== 'system'
            ) {
                // user can manage cluster and job triggerer is not system
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    const canDeleteJob = () => {
        if (job.canBeDeleted) {
            if (
                [
                    CmonJobInstanceCommand.CREATE_CLUSTER,
                    CmonJobInstanceCommand.ADD_CLUSTER,
                ].includes(job.spec?.command) &&
                canExecuteClusters()
            ) {
                // user can execute clusters
                return true;
            } else if (canManageCluster(cluster?.clusterId as number)) {
                // user can manage cluster
                return true;
            } else {
                return false;
            }
        }
        return false;
    };
    return (
        <ActionsMenu
            items={
                [
                    {
                        key: 'details',
                        icon: <FileTextOutlined />,
                        label: (
                            <JobDetailsButton
                                job={job}
                                onActionPerformed={handleDetailsActionPerformed}
                            />
                        ),
                    },
                    {
                        key: 'copy-link',
                        icon: <LinkOutlined />,
                        label: (
                            <AppActionCopyButton
                                text={getJobDetailsLinkInActivityViewer(
                                    job.jobId!
                                )}
                            >
                                Copy link
                            </AppActionCopyButton>
                        ),
                    },
                    canRetryJob()
                        ? {
                              key: 'retry',
                              icon: <ReloadOutlined />,

                              waitForConfirm: true,
                              label: (
                                  <JobRetryButton
                                      job={job}
                                      onSuccess={handleJobRetrySuccess}
                                  />
                              ),
                          }
                        : null,

                    canDeleteJob()
                        ? {
                              key: 'delete',
                              icon: <DeleteOutlined />,
                              critical: true,
                              waitForConfirm: true,
                              label: (
                                  <JobDeleteButton
                                      job={job}
                                      onSuccess={handleJobDeleteSuccess}
                                  />
                              ),
                          }
                        : null,
                ] as ActionMenuItem[]
            }
            {...rest}
        />
    );
}
