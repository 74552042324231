import React from 'react';
import ClusterPageMenu from '../components/Clusters/ClusterPageMenu';
import SpaceWide from '../common/SpaceWide';
import getAllClusterInfo from './dataMocks/rpc/clusters/getAllClusterInfo1';
import galera from "./dataMocks/clusters/galera";
import mysqlReplication from "./dataMocks/clusters/mysqlReplication";
import postgresSingleNode from "./dataMocks/clusters/postgresSingleNode";
import postgresStreamingReplication
    from "./dataMocks/clusters/postgresStreamingReplication";
import postgresWithLb from "./dataMocks/clusters/postgresWithLb";
import mongoReplicaSet from "./dataMocks/clusters/mongoReplicaSet";
import mongoShards from "./dataMocks/clusters/mongoShards";
import redis6SingleNodeShutdown
    from "./dataMocks/clusters/redis6SingleNodeShutdown";
import redis6Sentinel3Nodes from "./dataMocks/clusters/redis6Sentinel3Nodes";
import mssqlSingle from "./dataMocks/clusters/mssqlSingle";
import mssqlReplication from "./dataMocks/clusters/mssqlReplication";
import elasticHA3master from "./dataMocks/clusters/elasticHA3master";
import CcCluster from "../services/models/CcCluster";

export default DebugClusterPage;
export type DebugClusterPageProps = {};


const clusters = [
    galera,
    mysqlReplication,
    postgresSingleNode,
    postgresStreamingReplication,
    postgresWithLb,
    mongoReplicaSet,
    mongoShards,
    redis6SingleNodeShutdown,
    redis6Sentinel3Nodes,
    mssqlSingle,
    mssqlReplication,
    elasticHA3master,
].map((c) => new CcCluster(c as any));

function DebugClusterPage({ ...rest }: DebugClusterPageProps) {
    return (
        <SpaceWide direction="vertical">
            {getAllClusterInfo.clusters.map(({ cluster_id }) => (
                <ClusterPageMenu clusterId={cluster_id} />
            ))}
        </SpaceWide>
    );
}
