// put it here to avoid ReferenceError
export enum ServiceClusterWizardStep {
    DETAILS = 'details',
    SSH_CONFIG = 'sshConfig',
    NODE_CONFIG = 'nodeConfig',
    SNAPSHOT_REPOSITORY = 'snapshotRepository',
    TOPOLOGY = 'topology',
    EXTENSION = 'extension',
    PREVIEW = 'preview',
}
