import React from 'react';
import { Col, Form, Input, Row, Select, Space } from 'antd';
import ClusterSelectDropdown from '../../Clusters/ClusterSelectDropdown';
import BackupHostSelectField from './BackupHostSelectField';
import ElasticRepositorySelectField from '../../../common/Form/Fields/ElasticRepositorySelectField';
import BackupMethodSelectField from './BackupMethodSelectField';
import { CcBackupMySQLDumpType } from '../../../services/models/CcBackup';
import BackupFormConfigurator from '../Config/BackupFormConfigurator';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import useCurrentUser from '../../User/useCurrentUser';

export const ALLOWED_BACKUP_CLUSTER_TYPES = [
    CcClusterType.TYPE_REPLICATION,
    CcClusterType.TYPE_GALERA,
    CcClusterType.TYPE_POSTGRESQL,
    CcClusterType.TYPE_TIMESCALEDB,
    CcClusterType.TYPE_MONGODB,
    CcClusterType.TYPE_MONGODB_SHARDS,
    CcClusterType.TYPE_REDIS,
    CcClusterType.TYPE_REDIS_SHARDED,
    CcClusterType.TYPE_MSSQL_SINGLE,
    CcClusterType.TYPE_MSSQL_AO_ASYNC,
    CcClusterType.TYPE_ELASTIC,
];

export default BackupConfigurationFields;
export type BackupConfigurationFieldsProps = {
    configurator: BackupFormConfigurator;
    form: FormInstance;
    onClusterSelect?: (cluster?: CcCluster) => void;
    cluster?: CcCluster;
    size?: 'large' | 'small';
    clusterPreselected?: boolean;
};

function BackupConfigurationFields({
    configurator,
    form,
    onClusterSelect,
    cluster,
    size = 'large',
    clusterPreselected = false,
    ...rest
}: BackupConfigurationFieldsProps) {
    const md = size === 'large' ? 24 : 12;
    const { canManageCluster } = useCurrentUser({
        name: 'curr-user-from-backup-wizard',
    });
    return (
        <Row gutter={[24, 0]}>
            {configurator.available('scheduleName') ? (
                <Col xs={24} sm={24} md={md} key="scheduleName">
                    <Form.Item
                        name="scheduleName"
                        label={<Space>Schedule name</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter schedule name.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            ) : null}
            <Col xs={24} sm={24} md={md} key="cluster">
                <Form.Item
                    name="clusterId"
                    label={<Space>Cluster</Space>}
                    rules={[
                        {
                            required: true,
                            message: 'Please select cluster.',
                        },
                    ]}
                >
                    <ClusterSelectDropdown
                        onClusterSelect={onClusterSelect}
                        disabled={clusterPreselected}
                        useGlobalState
                        showId={true}
                        filterClusters={(cluster) =>
                            filterBackupClusters(cluster, canManageCluster)
                        }
                    />
                </Form.Item>
            </Col>
            <Form.Item shouldUpdate={true} noStyle={true}>
                {() =>
                    configurator.available('host') ? (
                        <Col xs={24} sm={24} md={md}>
                            <BackupHostSelectField
                                form={form}
                                cluster={cluster}
                            />
                        </Col>
                    ) : null
                }
            </Form.Item>
            {configurator.available('elasticRepository') &&
            cluster?.clusterId ? (
                <Col xs={24} sm={24} md={md}>
                    <ElasticRepositorySelectField
                        clusterId={cluster?.clusterId}
                    />
                </Col>
            ) : null}

            <Col xs={24} sm={24} md={md} key="method">
                <BackupMethodSelectField
                    form={form}
                    cluster={cluster}
                    schedule={configurator.available('scheduleName')}
                    disabled={configurator.disabled('method')}
                />
            </Col>

            <Form.Item shouldUpdate={true} noStyle={true}>
                {() =>
                    configurator.available('dumpType') ? (
                        <Col xs={24} sm={24} md={md}>
                            <Form.Item
                                name="dumpType"
                                label={<Space>Dump type</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please dump type.',
                                    },
                                ]}
                            >
                                <Select>
                                    {getBackupConfigurationMysqlDumpTypes().map(
                                        ([key, name]) => (
                                            <Select.Option
                                                key={key}
                                                value={key}
                                            >
                                                {name}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null
                }
            </Form.Item>
        </Row>
    );
}

function filterBackupClusters(
    cluster: CcCluster,
    canManageCluster: (clusterId: number) => boolean
) {
    return (
        ALLOWED_BACKUP_CLUSTER_TYPES.includes(cluster.clusterType) &&
        canManageCluster(cluster?.clusterId as number)
    );
}

export function getBackupConfigurationMysqlDumpTypeName(
    dumpType: CcBackupMySQLDumpType
) {
    return (
        getBackupConfigurationMysqlDumpTypes().find(
            ([type]) => type === dumpType
        )?.[1] || dumpType
    );
}
export function getBackupConfigurationMysqlDumpTypes(): [
    CcBackupMySQLDumpType,
    string,
][] {
    return [
        [CcBackupMySQLDumpType.SCHEMA_ONLY, 'Schema Only'],
        [CcBackupMySQLDumpType.MYSQL_DB_ONLY, 'MySQL Db Only'],
        [CcBackupMySQLDumpType.DATA_ONLY, 'Data Only'],
        [CcBackupMySQLDumpType.SCHEMA_AND_DATA, 'Schema And Data'],
        [CcBackupMySQLDumpType.COMPLETE_PITR, 'Complete PITR-compatible'],
    ];
}
