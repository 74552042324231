import React from 'react';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton, {
    JobCreateActionButtonProps,
} from '../../Jobs/JobCreateActionButton';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { Badge } from 'antd';

export default ClusterToggleAutoRecoveryButton;

export type ClusterToggleAutoRecoveryButtonProps = Omit<
    JobCreateActionButtonProps,
    'command' | 'title'
> & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterToggleAutoRecoveryButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterToggleAutoRecoveryButtonProps) {
    const button = (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={
                cluster.clusterAutoRecovery
                    ? CmonJobInstanceCommand.DISABLE_RECOVERY
                    : CmonJobInstanceCommand.ENABLE_RECOVERY
            }
            confirm={false}
            title="Cluster auto-recovery"
            jobTitle={
                cluster.clusterAutoRecovery
                    ? 'Disable cluster auto-recovery'
                    : 'Enable cluster auto-recovery'
            }
            onSuccess={onSuccess}
            switchValue={cluster.clusterAutoRecovery}
            {...rest}
        >
            {children || 'Cluster auto-recovery'}
        </JobCreateActionButton>
    );
    if (cluster?.clusterType === CcClusterType.TYPE_REDIS_SHARDED) {
        return (
            <Badge
                offset={[0, 3]}
                count={
                    <span>
                        <InfoIcon
                            info={
                                <span>
                                    ClusterControl's cluster auto-recovery
                                    feature does not interfere with the native
                                    Redis cluster auto-recovery feature.
                                    Enabling this feature may impact other
                                    systems, such as the monitoring system. It
                                    may also attempt to perform recovery actions
                                    using the "cluster fix" command when slots
                                    are in an incorrect state.
                                </span>
                            }
                        />
                    </span>
                }
            >
                {button}
            </Badge>
        );
    }
    return button;
}
