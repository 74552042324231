import CcJob, { CcJobProps } from './CcJob';
import { camelCasePropToUnderscore } from '../../common/helpers';

export type CcBackupScheduleProps = CcJobProps & {};
export default class CcBackupSchedule extends CcJob {
    public static createFromJob(job: CcJob) {
        return new CcBackupSchedule(
            camelCasePropToUnderscore(job) as CcJobProps
        );
    }

    constructor(props: CcBackupScheduleProps) {
        super(props);
    }

    public getBackupMethod() {
        return (
            (this.spec &&
                this.spec.job_data &&
                this.spec.job_data.backup_method) ||
            'unknown'
        );
    }

    public getCrontabFormat() {
        return this.recurrence
            ? this.recurrence.split(' ').slice(1).join(' ')
            : null;
    }

    public getTz() {
        if (this.recurrence && this.recurrence.includes('TZ=')) {
            const tz = this.recurrence.split(' ')[0].split('=').slice(1).join('');
            if (tz !== 'UTC') {
                return `GMT ${tz}`
            }
            return tz;
        }
        return null;
    }

    public getLocationDirectory() {
        return this.spec?.job_data?.backupdir || '';
    }
}
