import CcStatsBase, { CcStatsBaseAttentionLevel } from './CcStatsBase';
import { CcNodeStatus } from './CcNode';
import CcStatsOverviewInterface from './CcStatsOverviewInterface';

export type NodeStatesProps = {
    [key in CcNodeStatus]: number;
};

export type CcNodeStatsProps = {
    statsCount: NodeStatesProps;
    maintenanceCount?: number;
    totalNodes: number;
};

export default class CcNodeStats
    extends CcStatsBase
    implements CcStatsOverviewInterface
{
    public readonly statusCount: { [key in CcNodeStatus]: number };
    public readonly total: number;
    public readonly maintenanceCount: number;

    constructor(props: CcNodeStatsProps) {
        super({
            statsCount: props.statsCount,
            attentionErrorStats: [
                CcNodeStatus.CmonHostFailed,
                CcNodeStatus.CmonHostOffLine,
            ],
            attentionWarnStats: [
                CcNodeStatus.CmonHostRecovery,
                CcNodeStatus.CmonHostShutDown,
                CcNodeStatus.CmonHostUnknown,
            ],
        });
        this.statusCount = props.statsCount;
        this.total = props.totalNodes;
        this.maintenanceCount = props.maintenanceCount || 0;
    }

    public getFirstAttentionStat(
        level?: CcStatsBaseAttentionLevel
    ): [CcNodeStatus, number] {
        return super.getFirstAttentionStat(level) as [CcNodeStatus, number];
    }

    public getAttentionStatsValues(
        level?: CcStatsBaseAttentionLevel
    ): CcNodeStatus[] {
        return super.getAttentionStatsValues(level) as CcNodeStatus[];
    }

    public getSatesCountPairs(): [string, number][] {
        return (this.statsCount && Object.entries(this.statsCount)) || [];
    }

    getMaintenance(): number {
        return this.maintenanceCount;
    }
}
