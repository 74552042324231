import React, { useState } from 'react';
import { ModalDefaultProps } from '../../common/ModalDefault';
import ModalDefaultForm from '../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import { Form, Row, Col, Select, InputNumber, Alert, Space } from 'antd';
import ClusterSelectDropdown from '../Clusters/ClusterSelectDropdown';
import FormFooter from '../../common/FormFooter';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { useDebugContext } from '../../common/Debug';
import CcReport from '../../services/models/CcReport';
import CronInput from '@severalnines/bar-frontend-components/build/lib/DataEntry/CronInput';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import CcScheduleReport from '../../services/models/CcScheduleReport';
import { emailValidator } from './OperationalReportCreateReportModal';

export default OperationalReportScheduleModal;

export type OperationalReportScheduleModalProps = {
    schedule?: CcScheduleReport;
    templates?: CcReport[];
    onCancel?: () => void;
    onSuccess?: () => void;
} & Omit<ModalDefaultProps, 'children'>;

type OperationalReportScheduleFormFields = {
    type: string;
    clusterId: number;
    days: number;
    recipients: string[];
    scheduleCron: string;
};

function OperationalReportScheduleModal({
    schedule,
    templates,
    onCancel,
    onSuccess,
}: OperationalReportScheduleModalProps) {
    const [form] = useForm<OperationalReportScheduleFormFields>();
    const [loading, setLoading] = useState<boolean>(false);
    const { log } = useDebugContext();
    const [value, setValue] = useState<string>('* * * * *');

    const handleFormFinish = async ({
        clusterId,
        type,
        recipients,
        days,
        scheduleCron,
    }: OperationalReportScheduleFormFields) => {
        setLoading(true);
        try {
            if (schedule) {
                await CmonReportsService.removeSchedule({
                    id: schedule.id,
                });
            }
            await CmonReportsService.addSchedule({
                cluster_id: clusterId,
                name: type,
                schedule: scheduleCron,
                recipients: recipients?.join(','),
                days,
            });
            setLoading(false);
            onSuccess?.();
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Success!',
                content: 'Schedule successfully saved',
            });
        } catch (err) {
            setLoading(false);
            notifyError({ content: err.message });
            log.error(err);
        }
    };

    const handleClusterSelect = () => {};

    const changeHandle = (newValue: string) => {
        setValue(newValue);
    };

    return (
        <ModalDefaultForm
            title="Schedule report"
            form={form}
            footer={[]}
            width={700}
            defaultVisible={true}
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleFormFinish}
                layout="vertical"
                initialValues={{
                    clusterId: schedule?.clusterId,
                    days: schedule?.days || 7,
                    type: schedule?.command,
                    scheduleCron: schedule?.schedule || '* * * * *',
                    recipients:
                        (schedule?.recipients &&
                            schedule?.recipients.split(',')) ||
                        undefined,
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col xs={12} sm={12} md={12}>
                        <Form.Item
                            name="clusterId"
                            label="Cluster"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select cluster!',
                                },
                            ]}
                        >
                            <ClusterSelectDropdown
                                placeholder="Select cluster"
                                onClusterSelect={handleClusterSelect}
                                showId={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="days"
                            label="Data range"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select data range!',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                placeholder="Enter data range"
                                addonBefore={'Days'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select report type!',
                                },
                            ]}
                        >
                            <Select
                                data-testid="report-type-select"
                                placeholder="Select report type"
                                loading={loading}
                                options={templates?.map((record: CcReport) => ({
                                    value: record.reportType,
                                    label: record.title,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name="recipients"
                            label="Recipients"
                            rules={[
                                {
                                    validator: emailValidator,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Enter email recipients"
                                data-testid="report-recipients-select"
                                loading={loading}
                                mode="tags"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="scheduleCron"
                            label="Set schedule"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select backup method.',
                                },
                            ]}
                        >
                            <CronInput
                                value={value}
                                showThreeLetterDay={true}
                                timePickerInputProps={{ selectOnClick: true }}
                                onChange={changeHandle}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Alert
                            message={
                                <>
                                    <Space>
                                        Report will generate at:
                                        <b>
                                            <CronFormat>{value}</CronFormat>
                                        </b>
                                        (UTC)
                                    </Space>
                                </>
                            }
                            type="info"
                            showIcon={true}
                        />
                    </Col>
                </Row>
                <FormFooter
                    loading={loading}
                    submitButtonText={schedule ? 'Update' : 'Create'}
                    showSubmitButton={true}
                    showCancelButton={true}
                    onCancel={onCancel}
                />
            </Form>
        </ModalDefaultForm>
    );
}
