import React, { Fragment } from 'react';
import { Col, Form, Row, Select } from 'antd';
import FormFooter from '../../common/FormFooter';
import ModalDefaultForm from '../../common/ModalDefaultForm';

import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import CcCluster from '../../services/models/CcCluster';
import { SUPPORTED_EVENTS_POSTGRES } from './enableAuditLogHelper';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import useCreateJob from '../Jobs/useCreateJob';
import { useNotificationContext } from '../Notifications/NotificationProvider';

export default EnableAuditLogPostgreSQL;

export type EnableAuditLogPostgreSQLProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type EnableAuditLogPostgreSQLValues = {
    events: string[];
};

function EnableAuditLogPostgreSQL({
    cluster,
    onSuccess,
    onCancel,
}: EnableAuditLogPostgreSQLProps) {
    const [form] = Form.useForm<EnableAuditLogPostgreSQLValues>();
    const { notifyJobCreationError } = useNotificationContext();

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Setup Audit Logging',
        command: CmonJobInstanceCommand.SETUP_AUDIT_LOGGING.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async (values: EnableAuditLogPostgreSQLValues) => {
        try {
            await send({
                action: 'enable',
                audit_events: values.events.toString(),
                clusterId: cluster?.clusterId,
            });
        } catch (err: any) {
            notifyJobCreationError({
                size: 'large',
                content: 'Job failded.',
            });
        }
    };

    return (
        <ModalDefaultForm
            title="Enable Audit Log"
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    events: ['ROLE', 'DDL', 'MISC'],
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="events"
                            label={'Events'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Events.',
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                placeholder="Events"
                                options={SUPPORTED_EVENTS_POSTGRES}
                            />
                        </Form.Item>
                    </Col>

                    <Fragment>
                        <Col span={24}>
                            <Alert
                                showIcon
                                type="info"
                                message={
                                    'This feature will be enabled on all nodes of this cluster, cluster will be restarted to install the new plugin service.'
                                }
                            />
                        </Col>
                    </Fragment>
                </Row>
                <FormFooter
                    loading={loading}
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText="Save"
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
