import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateClustersMap,
    AppStateGlobalFilters,
    AppStateNodeStatsRecord,
    setNodeStats,
} from '../../appReducer';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import { arrayFilter } from '../../common/filtering';
import useListFetch from '../../common/useListFetch';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import { getFiltersForClient } from '../Clusters/useClusterList';
import CcNodeStats, {
    NodeStatesProps,
} from '../../services/models/CcNodeStats';
import CcNode, { CcNodeType } from '../../services/models/CcNode';

type RefreshFunctionParams = FetchRefreshFunctionParams & { filters?: any };

type UseNodesOverviewProps = {
    name?: string | null;
};
type FilterFunctionParams = { filters?: Function[]; arr?: any[] };

export default function useNodesOverview({ name }: UseNodesOverviewProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: clusters,
        refresh: refreshFetch,
        cancel,
    } = useListFetch({
        name,
        useCache: true,
        fetchFn: async (params, opts) => {
            // await new Promise((resolve) => setTimeout(resolve, 5000));
            const {
                clusters,
                total,
            } = await CmonClustersService.getAllClusterInfo(
                {
                    ...params,
                    with_hosts: true,
                    with_license_check: true,
                },
                opts
            );
            return {
                list: clusters,
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });
    const [currentFilters, setCurrentFilters] = useState<Function[]>([]);
    const [record, setRecord] = useState<CcNodeStats | null>(null);
    const [storedMap, storedRecord, globalFilters]: [
        AppStateClustersMap,
        AppStateNodeStatsRecord,
        AppStateGlobalFilters
    ] = useSelector(({ clusters, nodeStats, globalFilters }: AppState) => [
        clusters,
        nodeStats,
        globalFilters,
    ]);
    const dispatch = useDispatch();
    const gFilters = useRef<any>(getFiltersForClient(globalFilters));

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            filters = currentFilters,
            arr = (storedMap && storedMap.toList().toArray()) || [],
        } = {}) => {
            const filteredArr: CcNode[] = arrayFilter({
                filters: [...(filters || []), ...gFilters.current],
                arr: arr.reduce(
                    (nodes, cluster) => [
                        ...nodes,
                        ...cluster.nodes.filter(
                            (n: CcNode) => !n.isType(CcNodeType.CONTROLLER)
                        ),
                    ],
                    []
                ),
            });
            setRecord(
                new CcNodeStats({
                    statsCount: filteredArr.reduce((accumulator, current) => {
                        if (!current.hoststatus) {
                            return accumulator;
                        }
                        return {
                            ...accumulator,
                            [current.hoststatus]:
                                1 + (accumulator[current.hoststatus] || 0),
                        };
                    }, {} as NodeStatesProps),
                    totalNodes: filteredArr.length,
                    maintenanceCount: filteredArr.reduce(
                        (count, node) =>
                            node.maintenanceModeActive ? ++count : count,
                        0
                    ),
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gFilters.current, currentFilters]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({ filters, ...params } = {}) => {
            setCurrentFilters(filters || []);
            await refreshFetch({
                filters: gFilters.current,
                ...params,
            });
        },
        [gFilters.current]
    );

    useEffect(() => {
        if (clusters) {
            filter({
                arr: clusters,
            });
        }
    }, [clusters]);
    useEffect(() => {
        if (storedMap) {
            filter({
                arr: storedMap.toList().toArray(),
            });
        }
    }, [storedMap]);

    useEffect(() => {
        if (record) {
            dispatch(setNodeStats(record));
        }
    }, [record]);

    useEffect(() => {
        if (storedRecord) {
            setRecord(storedRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedRecord]);

    useEffect(() => {
        gFilters.current = getFiltersForClient(globalFilters);
        if (loaded && globalFilters) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters]);

    return {
        error,
        loading,
        loaded,
        record,
        refresh,
        filter,
        cancel,
    };
}
