import React, { useContext, useEffect, useState, useRef } from 'react';
import './ProxySQLVariables.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { Button, InputRef, Input, Space, Typography } from 'antd';

import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import { TablePaginationConfig } from 'antd/es';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';

import { TableData } from './proxySQLVariablesHelper';

import { SearchIcon } from '../../../../../../common/icons/icons';
import ProxySQLVariablesPopover from './ProxySQLVariablesPopover';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import useProxySQLVariables from './useProxySQLVariables';
import { getSortAlphabeticFn } from '../../../../../../common/sorting';

export default ProxySQLVariables;

export type ProxySQLVariablesProps = {
    cluster: CcCluster;
    node: CcProxySqlNode;
    variablesPageSize?: number;
};

function ProxySQLVariables({
    cluster,
    node,
    variablesPageSize = 15,
}: ProxySQLVariablesProps) {
    const { responsive }: any = useContext(ResponsiveContext);

    const {
        loading,
        list: variablesList,
        refresh: refreshVariables,
        filter: filterVariables,
        page,
        pageSize,
        total,
    } = useProxySQLVariables({
        clusterId: cluster?.clusterId,
        hostName: node?.hostname,
        port: node?.port,
        pageSize: variablesPageSize,
    });

    useEffect(() => {
        refreshVariables({});
    }, []);

    const searchInput = useRef<InputRef>(null);

    const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
    };

    const count = useRef(0);

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => {
        const filterByVariable = (obj: any) => {
            return (
                obj.variableName.indexOf(searchInput.current?.input.value) !==
                -1
            );
        };

        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div className="ProxySQLVariables__Search">
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() => {
                            handleSearch(confirm);
                            filterVariables({
                                filters: [filterByVariable],
                                page: 1,
                            });
                        }}
                        className="ProxySQLVariables--input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm({ closeDropdown: true });

                                filterVariables({
                                    filters: [filterByVariable],
                                    page: 1,
                                });
                                count.current = 0;
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            className="ProxySQLVariables--button"
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters?.();
                                confirm({ closeDropdown: true });
                                filterVariables({
                                    filters: [],
                                });
                                count.current = 0;
                            }}
                            size="small"
                            className="ProxySQLVariables--button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: () => <SearchIcon />,

            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        };
    };

    const columns = [
        {
            title: 'Variable name',
            key: 'variableName',
            dataIndex: '',
            ...getColumnSearchProps('variableName'),
            render: (record: TableData) => (
                <>
                    {record?.variableName}
                    {record?.readOnly && (
                        <Typography.Text disabled>
                            ( read only )
                        </Typography.Text>
                    )}
                </>
            ),
        },
        {
            title: 'Value',
            key: 'variableValue',
            dataIndex: 'variableValue',
            sorter: true,
        },
        {
            title: 'Actions',
            key: '',
            dataIndex: '',
            render: (record: TableData) => (
                <ProxySQLVariablesPopover
                    cluster={cluster}
                    node={node}
                    onSuccess={refreshVariables}
                    variableData={record}
                />
            ),
        },
    ];

    const handleTableChange = (pagination: any, filters: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'variableName':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.variableName
                );
                break;
            case 'variableValue':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.variableValue
                );
                break;
        }

        filterVariables({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div className="ProxySQLVariables">
            <AppTable
                size="small"
                dataSource={variablesList}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={loading}
                renderEmpty={
                    <AppEmpty
                        loading={loading}
                        description="There is no variables yet. "
                    />
                }
            />
        </div>
    );
}
