import React from 'react';
import { Button, Form, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';

export default NodeDisableBinaryLoggingForm;

export type NodeDisableBinaryLoggingFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeDisableBinaryLoggingFormValues = {
    autoRestart?: boolean;
};

function NodeDisableBinaryLoggingForm({
    node,
    onSuccess,
    onCancel,
}: NodeDisableBinaryLoggingFormProps) {
    const [form] = Form.useForm<NodeDisableBinaryLoggingFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Disable binary logging',
        command: CmonJobInstanceCommand.DISABLE_BINARY_LOGGING,
        onSuccess,
    });

    const handleSubmit = async (values: NodeDisableBinaryLoggingFormValues) => {
        await send({
            hostname: node.hostname,
            port: node.port,
            auto_restart: values.autoRestart,
        });
    };
    return (
        <ModalDefaultForm
            form={form}
            title="Disable binary logging"
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeDisableBinaryLoggingForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ autoRestart: true }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <Alert
                        message={
                            <span>
                                This job will update the configuration of
                                {node.getHostWithPort()} to disable the binary
                                logging. Please note that any replica will stop
                                working, and adding replicas is no longer
                                possible. A server restart is needed to finalize
                                the configuration update.
                            </span>
                        }
                        type="info"
                    />
                    <FormItemInlineSwitch
                        data-testid="nodes-actions-nodeDisableBinaryLoggingForm-switch-autoRestart"
                        justify
                        noMargin
                        name="autoRestart"
                        label={<Space>Restart the node</Space>}
                        valuePropName="checked"
                    />
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Disable
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
