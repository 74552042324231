import React, { useState } from 'react';
import './MongoShardsReplicaSetFormWrapper.less';
import MongoShardsReplicaSetForm from './MongoShardsReplicaSetForm';
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es';
import {
    MongoShardsFormShard,
    MongoShardsFormShardsMap,
} from '../../MongoShards/MongoShardsConfigurator';
import TypographyText from '../../../../../common/TypographyText';
import SpaceWide from '../../../../../common/SpaceWide';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import classNames from 'classnames';
import { TopologyItem } from '../../../../Topology/TopologyItem';

export default MongoShardsReplicaSetFormWrapper;

export type MongoShardsReplicaSetFormWrapperProps = {
    form: FormInstance;
    shards: MongoShardsFormShardsMap;
    shardKey?: string;
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem;
    clusterId?: number;
};

function MongoShardsReplicaSetFormWrapper({
    form,
    shards,
    shardKey = '0',
    validateItem,
    clusterId,
}: MongoShardsReplicaSetFormWrapperProps) {
    const [error, setError] = useState<string | null>(null);

    const shardsLength = Object.keys(shards).length;
    // real length, excluding undefined shards
    const shardsRealLength = Object.values(shards).filter(
        (s) => s !== undefined
    ).length;

    const handleAddShardClick = async () => {
        try {
            setError(null);
            await form.validateFields(['shards', shardKey]);
            await getShardValidator(shards[shardKey])();
            const newShards = { ...shards };
            const newShardKey = `${shardsLength}`;

            newShards[newShardKey] = {
                replicaSetName: '',
                serverPort: 27018,
                topology: [],
                perNode: {},
            };

            form.setFieldsValue({
                shards: newShards,
            });
        } catch (err) {
            if (!err.errorFields) {
                setError(err.message);
            }
        }
    };

    const handleRemoveShardClick = async (key: string) => {
        form.setFieldsValue({
            shards: { ...shards, [key]: undefined },
        });
    };
    return (
        <div className="MongoShardsReplicaSetFormWrapper">
            <MongoShardsReplicaSetForm
                form={form}
                shardKey={shardKey}
                validateItem={validateItem}
                clusterId={clusterId}
            />
            <SpaceWide>
                {shardsRealLength > 1 ? (
                    <Button onClick={() => handleRemoveShardClick(shardKey)}>
                        <TypographyText type="danger">
                            <MinusOutlined /> Remove this shard
                        </TypographyText>
                    </Button>
                ) : null}

                <Button
                    className={classNames(
                        'MongoShardsReplicaSetFormWrapper_button',
                        {
                            'MongoShardsReplicaSetFormWrapper_button--error': error,
                        }
                    )}
                    onClick={handleAddShardClick}
                >
                    <PlusOutlined /> Add another shard
                </Button>

                {error ? (
                    <TypographyText type="danger">{error}</TypographyText>
                ) : null}
            </SpaceWide>
        </div>
    );
}

type Shard = {
    topology: TopologyItem[];
};
type ShardMap = {
    [key: string]: Shard;
};

export function getShardValidator(shard: Shard) {
    return () => {
        if (shard.topology.length < 1) {
            throw new Error('At least 1 node is needed');
        }
        if (
            shard.topology.find(
                (item: any) => item.status !== StatusFormatStatus.success
            )
        ) {
            throw new Error('All nodes should be reachable');
        }
    };
}

export function getShardKeys(shards?: ShardMap) {
    const shardKeyList = shards
        ? Object.keys(shards).filter((key) => shards[key] !== undefined)
        : [];
    shardKeyList.sort((a, b) => {
        return Number(a) - Number(b);
    });
    return shardKeyList;
}
