import React from 'react';
import AppActionButton, { AppActionButtonProps } from './AppActionButton';
import TypographyText from '../TypographyText';

export default AppActionCopyButton;

export type AppActionCopyButtonProps = AppActionButtonProps & {
    text: string;
};

function AppActionCopyButton({ text, children }: AppActionCopyButtonProps) {
    return (
        <TypographyText
            copyable={{
                text,
                icon: (
                    <AppActionButton style={{ marginLeft: '-4px' }}>
                        {children || 'Copy'}
                    </AppActionButton>
                ),
                tooltips: null,
            }}
        ></TypographyText>
    );
}
