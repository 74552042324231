import React, {
    useContext,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import ProxySQLRulesWizardModal from './ProxySQLRulesWizardModal';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import {
    prepareDeleteRuleData,
    ProxySqlEndPointRuleData,
} from './proxySQLRulesHelper';
import { notifyError } from '../../../../../Notifications/uiNotification';

import AppConfirmActionButton from '../../../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import useProxySQLRules from './useProxySQLRules';
import { getSortAlphabeticFn } from '../../../../../../common/sorting';

export default forwardRef(ProxySQLRules);

export type RuleIdType = {
    nextCacheId: number;
    nextRuleId: number;
};

export type RuleDataTypes = {
    key: string;
    rule: string;
    status: string;
    apply: string;
    hits: string;
    query: string;
    hostGroup: string;
    pattern: string;
    userName: string;
    schemaName: string;
    actions: any;
};

export interface ProxySQLRulesApi {
    refresh: () => void;
}

export type ProxySQLRulesProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    hostGroupList: { value: string; label: string }[] | undefined;
    onQueryRuleId: (obj: RuleIdType) => void;
};

function ProxySQLRules(
    {
        cluster,
        node,
        hostGroupList,
        onQueryRuleId,
        ...rest
    }: ProxySQLRulesProps,
    forwardedRef: any
) {
    const { responsive }: any = useContext(ResponsiveContext);

    const [queryRuleId, setQueryRuleId] = useState<RuleIdType>();

    const {
        list: rules,
        rulesIds,
        loading: rulesLoading,
        refresh: refreshRules,
        filter: filterRules,
        page: rulesPage,
        pageSize: rulesPageSize,
        total: rulesTotal,
    } = useProxySQLRules({
        pageSize: 15,
        clusterId: cluster?.clusterId,
        hostName: node?.hostname,
        port: node?.port,
    });

    useEffect(() => {
        refreshRules({});
    }, []);

    useEffect(() => {
        if (Array.isArray(rules)) {
            const ruleId = {
                nextCacheId:
                    rules.length !== 0 && rulesIds
                        ? Math.min(...rulesIds) - 1
                        : 99,
                nextRuleId:
                    rules.length !== 0 && rulesIds
                        ? Math.max(...rulesIds) + 100
                        : 100,
            };
            setQueryRuleId(ruleId);
            onQueryRuleId(ruleId);
        }
    }, [rules]);

    useImperativeHandle(
        forwardedRef,
        (): ProxySQLRulesApi => ({
            async refresh() {
                await refreshRules({});
            },
        })
    );

    const deleteRule = async (rule: ProxySqlEndPointRuleData) => {
        try {
            await CmonProxysqlService.deletequeryrule({
                clusterId: cluster?.clusterId,
                hostName: node?.hostname,
                port: node?.port,
                queryRule: prepareDeleteRuleData(rule),
            });
            await refreshRules({});
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const columns = [
        {
            title: 'Rule ID',
            key: 'rule',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.rule}</>,
        },
        {
            title: 'Status',
            key: 'status',
            sorter: true,
            render: (record: RuleDataTypes) => (
                <StatusFormat
                    status={
                        record.status === 'Active'
                            ? StatusFormatStatus.success
                            : StatusFormatStatus.unknown
                    }
                    text={record.status}
                />
            ),
        },
        {
            title: 'Apply',
            key: 'apply',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.apply}</>,
        },
        {
            title: 'Hits',
            key: 'hits',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.hits}</>,
        },
        {
            title: 'Digest/Query',
            key: 'query',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.query}</>,
        },
        {
            title: 'Destination hostgroup',
            key: 'hostGroup',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.hostGroup}</>,
        },
        {
            title: 'Negate match pattern',
            key: 'pattern',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.pattern}</>,
        },
        {
            title: 'Username',
            key: 'userName',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.userName}</>,
        },
        {
            title: 'Schema name',
            key: 'schemaName',
            sorter: true,
            render: (record: RuleDataTypes) => <>{record.schemaName}</>,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: any) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-rule',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <ProxySQLRulesWizardModal
                                            title="Edit rule"
                                            edit={true}
                                            rule={record.actions.queryRule}
                                            onSuccess={refreshRules}
                                            nextQueryRuleId={
                                                queryRuleId?.nextRuleId
                                            }
                                            cluster={cluster}
                                            node={node}
                                            hostGroupList={hostGroupList}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-rule',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete Rule?`}
                                    critical
                                    onConfirm={() =>
                                        deleteRule(record.actions.queryRule)
                                    }
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        let sorterFn;
        switch (sorters.columnKey) {
            case 'rule':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.rule);
                break;
            case 'status':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.status);
                break;
            case 'apply':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.apply);
                break;
            case 'hits':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.hits);
                break;
            case 'hostGroup':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.hostGroup
                );
                break;
            case 'query':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.query);
                break;
            case 'pattern':
                sorterFn = getSortAlphabeticFn(sorters.order, (x) => x.pattern);
                break;
            case 'userName':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.userName
                );
                break;
            case 'schemaName':
                sorterFn = getSortAlphabeticFn(
                    sorters.order,
                    (x) => x.schemaName
                );
                break;
        }

        filterRules({
            page: pagination.current,
            order: sorterFn || undefined,
        });
    };

    const pagination: TablePaginationConfig = {
        size: 'default',
        pageSize: rulesPageSize,
        current: rulesPage,
        total: rulesTotal,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
    };
    return (
        <div style={{ minHeight: '750px' }}>
            <Row justify="end" style={{ paddingBottom: '1rem' }}>
                <ButtonWithForm
                    button={
                        <Button disabled={rulesLoading} icon={<PlusOutlined />}>
                            Create new rule
                        </Button>
                    }
                    form={
                        <ProxySQLRulesWizardModal
                            title="Create new rule"
                            onSuccess={refreshRules}
                            nextQueryRuleId={queryRuleId?.nextRuleId}
                            cluster={cluster}
                            node={node}
                            hostGroupList={hostGroupList}
                        />
                    }
                />
            </Row>

            <AppTable
                className="ProxySQLRulesTable"
                size="small"
                dataSource={rules}
                columns={columns}
                responsive={responsive}
                onChange={handleTableChange}
                pagination={pagination}
                loading={rulesLoading}
                renderEmpty={
                    <AppEmpty
                        loading={rulesLoading}
                        description="You haven’t created Rules yet. When you do, it'll show up here."
                    />
                }
            />
        </div>
    );
}
