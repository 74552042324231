import React, { useEffect, useState } from 'react';
import { NodesIcon } from '../../common/icons/icons';
import { Divider, Space, Typography } from 'antd';
import {
    getNodeStatusFormatText,
    getNodeStatusFormatType,
} from '../Nodes/NodeStatusFormat';
import useNodesOverview from '../Nodes/useNodesOverview';
import { CcNodeStatus } from '../../services/models/CcNode';
import NodesHoneycomb from '../Nodes/NodesHoneycomb';
import CcStatsOverviewInterface from '../../services/types/CcStatsOverviewInterface';
import DashboardOverviewCard from './DashboardOverviewCard';
import CcNodeStats from '../../services/models/CcNodeStats';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { StatusPieDiagramItem } from '../../common/StatusPieDiagram';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import TypographyText from '../../common/TypographyText';
import AppLink from '../../common/AppLink';
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;

export default DashboardNodesCard;

function getOverviewPieData(overview?: CcNodeStats) {
    return (
        overview?.getSatesCountPairs().map(([status, count]) => ({
            name: `${count} ${getNodeStatusFormatText(status as CcNodeStatus)}`,
            value: count,
            status,
            type: getNodeStatusFormatType(status as CcNodeStatus),
        })) || [
            {
                name: 'Online',
                value: 0,
                status: CcNodeStatus.CmonHostOnline,
                type: StatusFormatStatus.success,
            },
            {
                name: 'Down',
                value: 0,
                status: CcNodeStatus.CmonHostFailed,
                type: StatusFormatStatus.error,
            },
        ]
    );
}

export type DashboardNodesCardProps = {};

function DashboardNodesCard({ ...rest }: DashboardNodesCardProps) {
    const navigate = useNavigate();
    const {
        loading: loadingNodesOverview,
        refresh: refreshNodesOverview,
        record: nodesOverview,
    } = useNodesOverview({
        name: 'nodes-overview-dashboard',
    });
    const [pieData, setPieData] = useState<StatusPieDiagramItem[]>(
        getOverviewPieData()
    );
    useEffect(() => {
        if (nodesOverview) {
            setPieData(getOverviewPieData(nodesOverview));
        }
    }, [nodesOverview]);

    useEffect(() => {
        (async () => {
            await refreshNodesOverview({});
        })();
    }, []);

    const handlePieElementClick = (evtData: any) => {
        navigate(`/nodes?status=${evtData.data.status}`);
    };
    return (
        <DashboardOverviewCard
            loading={loadingNodesOverview}
            overview={nodesOverview as CcStatsOverviewInterface}
            pieData={pieData}
            pieSize={200}
            onPieElementClick={handlePieElementClick}
            title={
                <Space>
                    <NodesIcon />
                    <span>Nodes</span>
                </Space>
            }
            extra={<AppLink to="/nodes">View nodes</AppLink>}
            totalSummary={
                <Text type="secondary">
                    {nodesOverview && nodesOverview.total} Nodes
                </Text>
            }
            rightSide={
                <SpaceDescriptions
                    direction="vertical"
                    title="Nodes"
                    wrapperSize={0}
                    footerExtra={
                        <Space>
                            <StatusFormat
                                status={StatusFormatStatus.success}
                                colorText={false}
                            >
                                <TypographyText muted>Online</TypographyText>
                            </StatusFormat>
                            <Divider type="vertical" />
                            <StatusFormat
                                status={StatusFormatStatus.warning}
                                colorText={false}
                            >
                                <TypographyText muted>Warning</TypographyText>
                            </StatusFormat>
                            <Divider type="vertical" />
                            <StatusFormat
                                status={StatusFormatStatus.error}
                                colorText={false}
                            >
                                <TypographyText muted>Failed</TypographyText>
                            </StatusFormat>
                        </Space>
                    }
                >
                    <NodesHoneycomb
                        style={{
                            maxWidth: '450px',
                            minHeight: '250px',
                            margin: 'auto',
                        }}
                    />
                </SpaceDescriptions>
            }
            {...rest}
        />
    );
}
