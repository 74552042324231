import React from 'react';
import NodeListInline from '../components/Nodes/NodeListInline';
import mongodb1 from './dataMocks/clusters/mongodb1';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';
import postgresStreamingReplication from './dataMocks/clusters/postgresStreamingReplication';
import {
    CcNodeRole,
    CcNodeStatus,
    CcNodeType,
} from '../services/models/CcNode';
import postgresWithLb from './dataMocks/clusters/postgresWithLb';
import mssqlSingle from './dataMocks/clusters/mssqlSingle';
import mssqlReplication from './dataMocks/clusters/mssqlReplication';
import galera from './dataMocks/clusters/galera';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import CcCluster from '../services/models/CcCluster';
import SpaceDescriptions from '../common/Layout/SpaceDescriptions';
import ClusterFormat from '../components/Clusters/ClusterFormat';
import AppDivider from '../common/AppDivider';
import { Space } from 'antd';
import redis6sentinel from './dataMocks/clusters/redis6Sentinel3Nodes';
import redisSharded3x3 from './dataMocks/clusters/redisSharded3x3';
import elasticHA3master from './dataMocks/clusters/elasticHA3master';
import mysqlReplicationIntermediate from './dataMocks/clusters/mysqlReplicationIntermediate';
import mysqlReplicationLb from './dataMocks/clusters/mysqlReplicationLb';

const clusters = [
    mongodb1,
    postgresSingleNode,
    postgresStreamingReplication,
    postgresWithLb,
    mssqlSingle,
    mssqlReplication,
    galera,
    mysqlReplication,
    mysqlReplicationIntermediate,
    mysqlReplicationLb,
    redis6sentinel,
    redisSharded3x3,
    elasticHA3master,
].map((c) => new CcCluster(c as any));
export default DebugNodeListInline;
export type DebugNodeListInlineProps = {};

function DebugNodeListInline({ ...rest }: DebugNodeListInlineProps) {
    return (
        <Space className="DebugNodeListInline" direction="vertical" size={20}>
            {clusters.map((c) => (
                <div key={c.getKey()}>
                    <SpaceDescriptions
                        title={<ClusterFormat cluster={c} />}
                        direction={'horizontal'}
                    >
                        <NodeListInline cluster={c} /> - (Nodes:{' '}
                        {
                            c.nodes.filter(
                                (n) => !n.isType(CcNodeType.CONTROLLER)
                            ).length
                        }{' '}
                        -{' '}
                        {c.nodes
                            .filter((n) => !n.isType(CcNodeType.CONTROLLER))
                            .map((n) => `${n.nodetype}-${n.role}`)}
                        )
                    </SpaceDescriptions>
                    <AppDivider />
                </div>
            ))}
        </Space>
    );
}
