import { AutoComplete, Select, Space, Spin } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import React, { useCallback, useEffect } from 'react';
import FormItem, { FormItemProps } from '../../../../common/DataEntry/FormItem';
import {
    CcClusterType,
    CcClusterVendor,
    getCMONClusterType,
} from '../../../../services/models/CcCluster';
import { ClusterConfigurator } from '../ClusterConfigurator';
import { FormInstance } from 'antd/lib/form';
import useFetch from '../../../../common/useFetch';
import CmonDbversionsService from '../../../../services/cmon/CmonDbversionsService';
import { RuleObject } from 'rc-field-form/lib/interface';
import { ReloadOutlined } from '@ant-design/icons';

export default ClusterTypeVersionsField;

type ClusterTypeVersionsFieldProps = FormItemProps & {
    form: FormInstance;
    configurator: typeof ClusterConfigurator;
    clusterType: CcClusterType;
    vendor: CcClusterVendor;
    patchNumber?: boolean;
    autocomplete?: boolean;
    blockOnError?: boolean;
    onVersionsLoaded?: (versions: string[]) => void;
};

function ClusterTypeVersionsField({
    form,
    configurator,
    clusterType,
    vendor,
    patchNumber = false,
    autocomplete = false,
    blockOnError = true,
    name = ['details', 'version'],
    onVersionsLoaded,
    ...rest
}: ClusterTypeVersionsFieldProps) {
    const { loading, data, refresh, error } = useFetch<any>({
        name: 'getDbVersions',
        fetchFn: async (params, opts) => {
            // throw new Error('Test fetching version error');
            const response = await CmonDbversionsService.getDbVersions(
                {
                    ...params,
                },
                opts
            );
            let db_versions = response.db_versions?.sort(
                (a: string, b: string) =>
                    b.localeCompare(a, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    })
            );

            if (patchNumber) {
                db_versions = db_versions?.reduce(
                    (acc: string[], version: string) => {
                        const [major, minor] = version.split('.');
                        const minorVersion = `${major}.${minor}`;
                        if (!acc.includes(minorVersion)) {
                            acc.push(minorVersion);
                        }
                        acc.push(version);
                        return acc;
                    },
                    []
                );
            }

            if (db_versions.length < 1) {
                throw Error('Could not load available versions');
            }

            return { ...response, db_versions };
        },
        cancelFn: async ({ requestId }) => {
            await CmonDbversionsService.cancelRequest(requestId);
        },
    });
    const loadVersions = useCallback(async () => {
        await refresh({
            cluster_type: getCMONClusterType(clusterType),
            vendor: vendor,
            patch_number: patchNumber,
        });
    }, [clusterType, vendor, patchNumber]);

    useEffect(() => {
        (async () => {
            await loadVersions();
        })();
    }, [loadVersions]);

    useEffect(() => {
        if (data?.db_versions) {
            onVersionsLoaded?.(data?.db_versions);
        }
    }, [data]);

    const versionList = error ? [] : data?.db_versions;
    const disableInput = !!error && !blockOnError;

    return (
        <Spin spinning={loading}>
            <FormItem
                label={
                    <Space>
                        Version
                        <InfoIcon
                            info={<span>Select available version</span>}
                        />
                    </Space>
                }
                validateStatus={error && blockOnError ? 'error' : undefined}
                help={
                    error ? (
                        <Space>
                            <span>Fetching versions error</span>
                            <ReloadOutlined
                                onClick={async () => await loadVersions()}
                            />
                        </Space>
                    ) : undefined
                }
                extra={autocomplete ? <small>Type to search</small> : undefined}
                name={name}
                rules={[
                    {
                        required: true,
                        validator: async (rule: RuleObject, value) => {
                            if (!value) {
                                throw new Error('Please select a version');
                            }

                            const checkIfExists = !error || blockOnError;
                            if (checkIfExists && !versionList.includes(value)) {
                                throw new Error(
                                    'Please select version from the list'
                                );
                            }
                        },
                    },
                ]}
                {...rest}
            >
                {autocomplete ? (
                    <AutoComplete
                        disabled={disableInput}
                        filterOption={true}
                        options={versionList?.map((version: string) => ({
                            value: version,
                            label: version,
                        }))}
                    />
                ) : (
                    <Select
                        disabled={disableInput}
                        options={versionList?.map((version: string) => ({
                            value: version,
                            label: version,
                        }))}
                    />
                )}
            </FormItem>
        </Spin>
    );
}
