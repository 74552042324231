import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import React from 'react';
import CcNode from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';

export default NodeSshConsoleButton;

type NodeSshConsoleButtonProps = {
    node: CcNode;
    cluster: CcCluster;
};

function NodeSshConsoleButton({
    cluster,
    node,
    ...rest
}: NodeSshConsoleButtonProps) {
    const handleClick = () => {
        const url = `/cmon-ssh/?cmonAuth=true&clusterId=${cluster.clusterId}&hostId=${node.hostId}`;
        window.open(url, undefined, buildWindowFeatures());
    };
    return (
        <ActionButton onClick={handleClick} {...rest}>
            SSH Console
        </ActionButton>
    );
}

/**
 * Copy-pasted from CC1 UI
 * @see https://github.com/severalnines/dc-ui/blob/develop/src/ccApp/ssh/ssh.service.js#L73
 */
function buildWindowFeatures() {
    const width = Math.floor(window.screen.width / 2);
    const height = Math.floor(window.screen.height / 2);
    const left = Math.floor(window.screen.width / 2 - width / 2);
    const top = Math.floor(window.screen.height / 2 - height / 2);

    return [
        'menubar=no',
        'location=no',
        'scrollbars=yes',
        'status=no',
        'toolbar=no',
        `width=${width}`,
        `height=${height}`,
        `left=${left}`,
        `top=${top}`,
    ].join(',');
}
