const result = {
    authenticated: true,
    cluster_type: 'POSTGRESQL_SINGLE',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: ['11', '12', '13', '14', '15'],
    request_processed: '2024-03-14T09:59:58.197Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    vendor: 'postgresql',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
