import {
    MongoShardsFormShardsMap,
    MongoShardsFormValues,
} from '../../MongoShards/MongoShardsConfigurator';
import merge from 'deepmerge';
import MongoNodeConfiguration, {
    MongoNodeConfigurationProps,
} from '../../Mongo/MongoNodeConfiguration';
import React from 'react';
import MongoDeploymentConfigurator from '../Mongo/MongoDeploymentConfigurator';
import { ServiceClusterWizardStep } from '../../ServiceClusterWizardStep';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { FormInstance } from 'antd/lib/form';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import { WizardFormConfigurationStepProps } from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import MongoShardsReplicaSetFormWrapper, {
    getShardKeys,
    getShardValidator,
} from './MongoShardsReplicaSetFormWrapper';
import NodeConfigurationSummary, {
    NodeConfigurationSummaryProps,
} from '../../NodeConfigurationSummary';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import MongoShardsDeploymentTopologySummary from './MongoShardsDeploymentTopologySummary';
import MongoShardsConfigurationServerClusterNodesForm from '../../MongoShards/MongoShardsConfigurationServerClusterNodesForm';
import MongoShardsRouterClusterNodesForm from '../../MongoShards/MongoShardsRouterClusterNodesForm';

export default class MongoShardsDeploymentConfigurator extends MongoDeploymentConfigurator {
    public static getVendors() {
        return MongoDeploymentConfigurator.getVendors();
    }
    public static getDefaults(): MongoShardsFormValues {
        return merge(MongoDeploymentConfigurator.getDefaults(), {
            nodeConfig: {
                sslEncryption: true,
            },
            configurationServersTopology: [],
            configurationServersPort: 27019,
            routersMongosTopology: [],
            routersMongosPort: 27017,
            shards: {
                0: {
                    replicaSetName: '',
                    serverPort: 27018,
                    topology: [],
                    perNode: {},
                },
            },
        });
    }

    public static getJobData(formValues: MongoShardsFormValues): any {
        const {
            configurationServersTopology,
            routersMongosTopology,
            configurationServersPort,
            routersMongosPort,
            topologyDataIps,
        } = formValues;
        return {
            ...MongoDeploymentConfigurator.getJobData(formValues),
            replica_sets: getShardsJobData(formValues.shards),
            config_servers: getConfigurationServersJobData(
                configurationServersTopology,
                configurationServersPort,
                topologyDataIps
            ),
            mongos_servers: getRoutersMongosJobData(
                routersMongosTopology,
                routersMongosPort,
                topologyDataIps
            ),
        };
    }

    public static getNodeConfigurationStep(
        props: MongoNodeConfigurationProps
    ): React.ReactNode {
        return (
            <MongoNodeConfiguration
                hasReplicaSetName={false}
                hasRouterConfigurationTemplate={true}
                hasSslEncryption={true}
                {...props}
            />
        );
    }

    public static getJobOptions(formValues: MongoShardsFormValues): any {
        return merge(MongoDeploymentConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Create Mongo Shards Cluster',
            },
        });
    }

    public static getTopologyStep(form: FormInstance): React.ReactNode {
        return (
            <div>
                <MongoShardsConfigurationServerClusterNodesForm form={form} />
                <MongoShardsRouterClusterNodesForm form={form} />
            </div>
        );
    }
    public static getTopologyValidate(form: FormInstance) {
        return [
            'configurationServersPort',
            'routersMongosPort',
            () => {
                const configurationServersTopology: TopologyItem[] = form.getFieldValue(
                    'configurationServersTopology'
                );
                const routersMongosTopology: TopologyItem[] = form.getFieldValue(
                    'routersMongosTopology'
                );
                if (
                    configurationServersTopology.find(
                        (item) => item.status !== StatusFormatStatus.success
                    )
                ) {
                    throw new Error('All nodes should be reachable');
                }
                if (!configurationServersTopology.length) {
                    throw new Error(
                        'At least 1 configuration server node is needed'
                    );
                }
                if (
                    routersMongosTopology.find(
                        (item) => item.status !== StatusFormatStatus.success
                    )
                ) {
                    throw new Error('All nodes should be reachable');
                }
                if (!routersMongosTopology.length) {
                    throw new Error('At least 1 router node is needed');
                }
            },
        ];
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <MongoShardsDeploymentTopologySummary form={form} />;
    }

    public static getDeploymentSteps(
        form: FormInstance,
        formValues?: MongoShardsFormValues
    ): (
        | ServiceClusterWizardStep
        | [ServiceClusterWizardStep | string, WizardFormConfigurationStepProps]
    )[] {
        const { shards } = formValues || {};

        return [
            ServiceClusterWizardStep.DETAILS,
            ServiceClusterWizardStep.SSH_CONFIG,
            ServiceClusterWizardStep.NODE_CONFIG,
            [
                ServiceClusterWizardStep.TOPOLOGY,
                {
                    title: 'Configuration Servers and Routers',
                },
            ],
            ...(shards
                ? getShardStepsConfigurations(
                      shards,
                      form,
                      undefined,
                      (item: TopologyItem) => {
                          if (
                              form
                                  .getFieldValue('configurationServersTopology')
                                  .find(
                                      (n: TopologyItem) =>
                                          n.extraData.hostname ===
                                          item.extraData.hostname
                                  )
                          ) {
                              throw new Error(
                                  'This address is used already for the config servers'
                              );
                          }
                          if (
                              form
                                  .getFieldValue('routersMongosTopology')
                                  .find(
                                      (n: TopologyItem) =>
                                          n.extraData.hostname ===
                                          item.extraData.hostname
                                  )
                          ) {
                              throw new Error(
                                  'This address is used already for the router/mongos servers'
                              );
                          }
                          let hostCount = 0;
                          Object.entries(shards).forEach(([key, values]) => {
                              if (Array.isArray(values?.topology)) {
                                  hostCount += values.topology.filter(
                                      (value) =>
                                          value?.extraData?.hostname ===
                                          item?.extraData?.hostname
                                  ).length;
                              }
                          });
                          if (hostCount > 0) {
                              throw new Error(
                                  `${item?.extraData?.hostname} is already in the list of shards. Please enter different node.`
                              );
                          }
                          return item;
                      }
                  )
                : []),
            ServiceClusterWizardStep.PREVIEW,
        ];
    }

    public static getNodeConfigurationSummary(
        props: NodeConfigurationSummaryProps
    ): React.ReactNode {
        const form = props.form;
        const nodeConfig = form.getFieldValue('nodeConfig');
        return (
            <NodeConfigurationSummary
                hasSslEncryption={true}
                extra={
                    <SpaceDescriptions.Item
                        label="Router/Mongos configuration template"
                        labelStrong
                    >
                        {nodeConfig?.routerConfigurationTemplate}
                    </SpaceDescriptions.Item>
                }
                {...props}
            />
        );
    }
}

export function getShardStepsConfigurations(
    shards: MongoShardsFormShardsMap,
    form: FormInstance,
    clusterId?: number,
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem
) {
    const shardKeyList = getShardKeys(shards);
    return shardKeyList.map((key) => [
        key,
        {
            title: shards[key].replicaSetName || 'Shard',
            subTitle: ' ',
            validate: [
                ['shards', key, 'replicaSetName'],
                ['shards', key, 'serverPort'],
                ...(shards[key]?.topology.map((n: TopologyItem) => [
                    [
                        'shards',
                        key,
                        'perNode',
                        n.extraData.hostname,
                        'slaveDelay',
                    ],
                    [
                        'shards',
                        key,
                        'perNode',
                        n.extraData.hostname,
                        'priority',
                    ],
                ]) || []),
                getShardValidator(shards[key]),
            ],
            children: (
                <MongoShardsReplicaSetFormWrapper
                    form={form}
                    shards={shards}
                    shardKey={key}
                    validateItem={validateItem}
                    clusterId={clusterId}
                />
            ),
        },
    ]) as [ServiceClusterWizardStep | string, any][];
}

export function getShardsJobData(shards: MongoShardsFormShardsMap) {
    return Object.values(shards)
        .filter((v) => v !== undefined && v.replicaSetName)
        .map((s) => ({
            rs: s.replicaSetName,
            members: s.topology.map((currentNode) => {
                const slaveDelay =
                    s.perNode[currentNode.extraData.hostname]?.slaveDelay || 0;
                const priority =
                    slaveDelay > 0
                        ? 0
                        : s.perNode[currentNode.extraData.hostname]?.priority;
                return {
                    hostname: currentNode.extraData.hostname,
                    hostname_data: currentNode.extraData.hostname,
                    port: s.serverPort,
                    hostname_internal:
                        s.perNode[currentNode.extraData.hostname]?.dataIp || '',
                    arbiter_only: !!s.perNode[currentNode.extraData.hostname]
                        ?.arbiter,
                    priority: priority,
                    slave_delay: slaveDelay,
                    hidden: slaveDelay > 0,
                };
            }),
        }));
}
export function getConfigurationServersJobData(
    configurationServersTopology: TopologyItem[],
    configurationServersPort: number,
    topologyDataIps?: {
        [key: string]: string;
    }
) {
    return {
        rs: 'replica_set_config',
        members: configurationServersTopology.map(
            (currentNode: TopologyItem) => ({
                port: configurationServersPort,
                hostname: currentNode.extraData.hostname,
                hostname_data: currentNode.extraData.hostname,
                hostname_internal:
                    topologyDataIps?.[currentNode.extraData.hostname] || '',
            })
        ),
    };
}
export function getRoutersMongosJobData(
    routersMongosTopology: TopologyItem[],
    routersMongosPort: number,
    topologyDataIps?: {
        [key: string]: string;
    }
) {
    return routersMongosTopology.map((currentNode: TopologyItem) => ({
        port: routersMongosPort,
        hostname: currentNode.extraData.hostname,
        hostname_data: currentNode.extraData.hostname,
        hostname_internal:
            topologyDataIps?.[currentNode.extraData.hostname] || '',
    }));
}
