import React from 'react';
import { Row, Col, Form, Input, Space } from 'antd';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import NodePortField from '../../../../common/Form/Fields/NodePortField';
import { FormInstance } from 'antd/lib/form';
import InputNumberWide from '../../../../common/DataEntry/InputNumberWide';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import FormItem from '../../../../common/DataEntry/FormItem';
import { TopologyItem } from '../../../Topology/TopologyItem';
import ClusterNodesForm from '../ClusterNodesForm';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default RedisShardedShardForm;

export type RedisShardedShardFormProps = {
    form: FormInstance;
    shardKey: string;
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem;
    clusterId?: number;
};

function RedisShardedShardForm({
    form,
    shardKey,
    validateItem,
    clusterId,
}: RedisShardedShardFormProps) {
    const fieldNamespace = ['shards', shardKey];
    return (
        <div className="RedisShardedShardForm">
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <ClusterNodesForm
                        title={
                            <Space>
                                Nodes
                                <InfoIcon info="Note that the minimal cluster that works as expected must contain at least three primary nodes. For deployment, the recommendation is a six-node cluster, with three primaries and three replicas." />
                            </Space>
                        }
                        form={form}
                        formItemProps={{
                            name: [...fieldNamespace, 'topology'],
                        }}
                        nodesInputProps={{
                            formProps: {
                                primaryTitle: 'Primary node',
                            },
                            clusterId,
                            validateItem,
                        }}
                        topologyNames={[]}
                    />
                </Col>
            </Row>
        </div>
    );
}
