// Global app configuration and meta data
import { TablePaginationConfig } from 'antd/es';
interface IAppConfig {
    CMON_API_URL: string;
    VERSION: string;
    GIT_SHA: string;
    GIT_BRANCH: string;
    BUILD_NUMBER: string;
    USER_REGISTRATION: number;
    COMMUNITY_EDITION: number;
    INITIAL_USER: string;
    INITIAL_PASSWORD: string;
    ENABLE_REDUX_DEVTOOLS: boolean;
    getProductVersion: () => string;
    defaultPaginationConfig: TablePaginationConfig;

    MONITORING_DEFAULT_REFRESH_RATE_SECONDS: number;
    ENABLE_PRIVACY: boolean;
}

export const AppConfig: IAppConfig = {
    CMON_API_URL: window.FEAS_ENV.CMON_API_URL || '/api/v2',
    VERSION: window.FEAS_ENV.VERSION,
    GIT_SHA: window.FEAS_ENV.GIT_SHA,
    GIT_BRANCH: window.FEAS_ENV.GIT_BRANCH,
    BUILD_NUMBER: window.FEAS_ENV.BUILD_NUMBER,
    USER_REGISTRATION: window.FEAS_ENV.USER_REGISTRATION,
    COMMUNITY_EDITION: window.FEAS_ENV.COMMUNITY_EDITION,
    INITIAL_USER: window.FEAS_ENV.INITIAL_USER || 'ccsetup',
    INITIAL_PASSWORD: window.FEAS_ENV.INITIAL_PASSWORD || 'admin',
    ENABLE_REDUX_DEVTOOLS: !!window.FEAS_ENV.ENABLE_REDUX_DEVTOOLS,
    MONITORING_DEFAULT_REFRESH_RATE_SECONDS:
        window.FEAS_ENV.MONITORING_DEFAULT_REFRESH_RATE_SECONDS || 10,
    ENABLE_PRIVACY:
        window.FEAS_ENV.ENABLE_PRIVACY || false,

    getProductVersion: (): string => {
        let prodVersion = 'v:'.concat(AppConfig.VERSION);
        prodVersion = AppConfig.GIT_SHA
            ? prodVersion.concat(' sha:', AppConfig.GIT_SHA)
            : prodVersion;
        prodVersion = AppConfig.BUILD_NUMBER
            ? prodVersion.concat(' b:', AppConfig.BUILD_NUMBER)
            : prodVersion;
        prodVersion = AppConfig.GIT_BRANCH
            ? prodVersion.concat(' n:', AppConfig.GIT_BRANCH)
            : prodVersion;

        return prodVersion;
    },
    defaultPaginationConfig: {
        size: 'default',
        pageSize: 10,
        hideOnSinglePage: true,
        showQuickJumper: false,
        showSizeChanger: false,
        position: ['bottomCenter'],
    },
};
