import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';

export default RedisShardedNodeSummary;

export type RedisShardedNodeSummaryProps = {
    form: FormInstance;
    hasAdvancedNodeConfiguration?: boolean;
};

function RedisShardedNodeSummary({
    form,
    hasAdvancedNodeConfiguration,
}: RedisShardedNodeSummaryProps) {
    const nodeConfig = form.getFieldValue('nodeConfig');
    return (
        <SpaceDescriptions
            direction="vertical"
            title="Node configuration"
            className="RedisShardedDeploymentNodeSummary"
            alignItems="right"
        >
            <SpaceDescriptions.Item label="Redis port" labelStrong>
                {nodeConfig?.redisShardedPort}
            </SpaceDescriptions.Item>
            {nodeConfig?.clusterBusPort !== undefined ? (
                <SpaceDescriptions.Item label="Cluster bus port" labelStrong>
                    {nodeConfig?.clusterBusPort}
                </SpaceDescriptions.Item>
            ) : null}
            {nodeConfig?.nodeTimeout !== undefined ? (
                <SpaceDescriptions.Item label="Node timeout (ms)" labelStrong>
                    {nodeConfig?.nodeTimeout}
                </SpaceDescriptions.Item>
            ) : null}
            {nodeConfig?.replicaValidityFactor !== undefined ? (
                <SpaceDescriptions.Item
                    label="Replica validity factor"
                    labelStrong
                >
                    {nodeConfig?.replicaValidityFactor}
                </SpaceDescriptions.Item>
            ) : null}

            {nodeConfig?.configurationTemplate !== undefined ? (
                <SpaceDescriptions.Item
                    label="Configuration template"
                    labelStrong
                >
                    {nodeConfig?.configurationTemplate}
                </SpaceDescriptions.Item>
            ) : null}
            {nodeConfig?.userName !== undefined ? (
                <SpaceDescriptions.Item label="Username" labelStrong>
                    {nodeConfig?.userName}
                </SpaceDescriptions.Item>
            ) : null}
            {nodeConfig?.password !== undefined ? (
                <SpaceDescriptions.Item label="Password" labelStrong>
                    ******
                </SpaceDescriptions.Item>
            ) : null}
            {hasAdvancedNodeConfiguration ? (
                <>
                    <SpaceDescriptions.Item
                        label="Cluster auto-recovery"
                        labelStrong
                    >
                        <YesNoFormat
                            booleanVar={nodeConfig.clusterAutoRecovery}
                        />
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item
                        label="Node auto-recovery"
                        labelStrong
                    >
                        <YesNoFormat booleanVar={nodeConfig.nodeAutoRecovery} />
                    </SpaceDescriptions.Item>
                </>
            ) : null}
        </SpaceDescriptions>
    );
}
