import React, { useMemo } from 'react';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import WizardConfigurationModal, {
    WizardConfigurationModalOption,
} from '../../../../common/Navigation/WizardConfigurationModal';
import BackupRestoreExternalWizardForm, {
    EXTERNAL_BACKUP_RESTORE_CLUSTER_TYPES,
} from './BackupRestoreExternalWizardForm';
import CcCluster from '../../../../services/models/CcCluster';
import { SyncOutlined, UsbOutlined } from '@ant-design/icons';
import BackupRestoreWizardForm, {
    BackupRestoreType,
    BackupRestoreWizardFormValues,
    getRestoreBackupFormJobData,
    getVerifyOnStandAloneNodeBackupFormJobData,
} from './BackupRestoreWizardForm';
import { useForm } from 'antd/lib/form/Form';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import CcBackup from '../../../../services/models/CcBackup';

export default BackupRestoreWizardModal;

export enum BackupRestoreOptionType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export type BackupRestoreFormProps = {
    cluster: CcCluster;
    backup?: CcBackup;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function BackupRestoreWizardModal({
    cluster,
    backup,
    onSuccess,
    onCancel,
}: BackupRestoreFormProps) {
    const [form] = useForm<BackupRestoreWizardFormValues>();

    const { loading: loadingRestore, send: sendRestore } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Restore backup',
        command: CmonJobInstanceCommand.RESTORE_BACKUP,
        onSuccess,
    });
    const { loading: loadingVerify, send: sendVerify } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Verify backup',
        command: CmonJobInstanceCommand.VERIFY_BACKUP,
        onSuccess,
    });

    const handleBackupRestoreSubmit = async (
        values: BackupRestoreWizardFormValues
    ) => {
        if (values.restoreType === BackupRestoreType.STANDALONE_HOST) {
            await sendVerify(getVerifyOnStandAloneNodeBackupFormJobData(values));
        } else if (values.restoreType === BackupRestoreType.SIMPLE_VERIFICATION) {
            await sendVerify({
                backupid: values.backup?.getId(),
            });
        } else if (values.restoreType === BackupRestoreType.NODE) {
            await sendRestore(getRestoreBackupFormJobData(values));
        }
    };

    const options: WizardConfigurationModalOption<
        BackupRestoreOptionType
    >[] = useMemo(() => {
        const items: WizardConfigurationModalOption<
            BackupRestoreOptionType
        >[] = [
            {
                key: BackupRestoreOptionType.INTERNAL,
                item: (
                    <WizardSelectCardStep.Item
                        key={BackupRestoreOptionType.INTERNAL}
                        icon={<SyncOutlined />}
                        title={getRestoreBackupOptionTypeTitle(
                            BackupRestoreOptionType.INTERNAL
                        )}
                        action={BackupRestoreOptionType.INTERNAL}
                        description={getRestoreBackupOptionTypeDescription(
                            BackupRestoreOptionType.INTERNAL
                        )}
                        buttonTitle="Restore"
                    />
                ),
                step: {
                    step: BackupRestoreOptionType.INTERNAL,
                    title: 'Restore a backup',
                    content: (
                        <BackupRestoreWizardForm
                            form={form}
                            cluster={cluster as CcCluster}
                            backup={backup}
                            onSubmit={handleBackupRestoreSubmit}
                            loading={loadingRestore || loadingVerify}
                        />
                    ),
                },
            },
            {
                key: BackupRestoreOptionType.EXTERNAL,
                item: (
                    <WizardSelectCardStep.Item
                        key={BackupRestoreOptionType.EXTERNAL}
                        icon={<UsbOutlined />}
                        title={getRestoreBackupOptionTypeTitle(
                            BackupRestoreOptionType.EXTERNAL
                        )}
                        action={BackupRestoreOptionType.EXTERNAL}
                        description={getRestoreBackupOptionTypeDescription(
                            BackupRestoreOptionType.EXTERNAL
                        )}
                        buttonTitle="Restore"
                    />
                ),
                step: {
                    step: BackupRestoreOptionType.EXTERNAL,
                    title: getRestoreBackupOptionTypeTitle(
                        BackupRestoreOptionType.EXTERNAL
                    ),
                    content: (
                        <BackupRestoreExternalWizardForm cluster={cluster} />
                    ),
                },
            },
        ];

        return items;
    }, []);

    const defaultActiveKey = useMemo(() => {
        if (backup) {
            return BackupRestoreOptionType.INTERNAL;
        }
        if (
            !EXTERNAL_BACKUP_RESTORE_CLUSTER_TYPES.includes(
                cluster?.clusterType
            )
        ) {
            return BackupRestoreOptionType.INTERNAL;
        }
        return undefined;
    }, [backup, cluster]);

    return (
        <WizardConfigurationModal
            title="Restore a backup"
            description="Restore internal or external backup files"
            options={options}
            onSuccess={onSuccess}
            onCancel={onCancel}
            form={form}
            defaultActiveKey={defaultActiveKey}
            closeOnCancel={defaultActiveKey !== undefined}
        />
    );
}

export function getRestoreBackupOptionTypeTitle(type: BackupRestoreOptionType) {
    switch (type) {
        case BackupRestoreOptionType.INTERNAL:
            return 'Restore an internal backup';
        case BackupRestoreOptionType.EXTERNAL:
            return 'Restore an external backup';
        default:
            return '';
    }
}

export function getRestoreBackupOptionTypeDescription(
    type: BackupRestoreOptionType
) {
    switch (type) {
        case BackupRestoreOptionType.INTERNAL:
            return (
                <ul>
                    <li>Time/Log based PITR</li>
                    <li>Restore on a node</li>
                    <li>Restore and Verify on standalone host</li>
                </ul>
            );
        case BackupRestoreOptionType.EXTERNAL:
            return (
                <ul>
                    <li>
                        Restore external backup for mysqldump, xtrabackup or
                        mariabackup
                    </li>
                </ul>
            );
        default:
            return '';
    }
}
