import React, { useEffect, useState } from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { Col, Space, Select, Checkbox, Row } from 'antd';
import FormItem from '../../../common/DataEntry/FormItem';
import { FormInstance } from 'antd/lib/form';
import {
    ClusterConfigurator,
    ClusterConfiguratorClusterType,
    ClusterConfiguratorVendor,
} from '../../Services/Cluster/ClusterConfigurator';
import { getCoverViewIcon } from '../../../common/DataDisplay/CoverView';
import { getDeploymentConfigurator } from '../../Services/Cluster/Deploy/DeploymentWizard';
import SpaceWide from '../../../common/SpaceWide';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import TypographyText from '../../../common/TypographyText';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default ClusterInPlaceUpgradeConfig;
type ClusterInPlaceUpgradeConfigProps = {
    cluster: CcCluster;
    form: FormInstance;
};

function ClusterInPlaceUpgradeConfig({
    cluster,
    form,
}: ClusterInPlaceUpgradeConfigProps) {
    const [selectedClusterType, setSelectedClusterType] = useState<
        ClusterConfiguratorClusterType | undefined
    >();
    const [selectedVendor, setSelectedVendor] = useState<
        ClusterConfiguratorVendor | undefined
    >();

    useEffect(() => {
        const clusterType = ClusterConfigurator.getDeployClusterTypes().find(
            (clusterType) => clusterType.key === cluster?.clusterType
        );
        setSelectedClusterType(clusterType);
    }, []);

    useEffect(() => {
        const configurator = getDeploymentConfigurator(cluster?.clusterType);
        const vendors = configurator.getVendors();

        setSelectedVendor(
            vendors.find(
                (vendor) =>
                    vendor.value === configurator.getDefaults()?.details?.vendor
            )
        );
    }, []);

    useEffect(() => {
        if (selectedVendor) {
            form.setFieldsValue({ vendor: selectedVendor.value });
        }
        if (!form.getFieldValue('method'))
            form.setFieldsValue({ method: 'copy' });

        form.setFieldsValue({
            currentVersion: `${cluster.version}`,
            upgradeVersion: `${+cluster?.version + 1}`,
        });
    }, [selectedVendor]);

    const [method, setMethod] = useState<string>(form.getFieldValue('method'));

    const onChangeMethod = (value: string) => {
        setMethod(value);

        if (form.getFieldValue('method') === 'link') {
            form.setFieldsValue({
                deleteNode: true,
            });
        } else {
            form.setFieldsValue({
                deleteNode: false,
            });
        }
    };

    async function policyValidator(rule: any, value: string) {
        if (!value) {
            throw new Error('Please read and agree to the policy!');
        }
        return true;
    }

    return (
        <div>
            <Row justify="start">
                <Col span={24}>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <FormItem
                                label={
                                    <span>
                                        Vendor{' '}
                                        <InfoIcon
                                            info={
                                                <span>
                                                    You are not allowed to
                                                    change the vendor for
                                                    in-place-upgrade.
                                                </span>
                                            }
                                        />
                                    </span>
                                }
                                name={'vendor'}
                            >
                                <Select
                                    disabled
                                    data-testid="cluster-database-type-form-select-vendor"
                                    options={
                                        selectedVendor
                                            ? [
                                                  {
                                                      key: selectedVendor.value,
                                                      value:
                                                          selectedVendor.value,
                                                      label: (
                                                          <Space>
                                                              {getCoverViewIcon(
                                                                  selectedClusterType?.icon,
                                                                  20
                                                              )}
                                                              <span>
                                                                  {
                                                                      selectedVendor.name
                                                                  }
                                                              </span>
                                                          </Space>
                                                      ),
                                                  },
                                              ]
                                            : []
                                    }
                                />
                            </FormItem>
                        </Col>

                        <Col span={12}>
                            <FormItem
                                label="Current version"
                                name={'currentVersion'}
                            >
                                <Select disabled />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={
                                    <span>
                                        Upgrade version{' '}
                                        <InfoIcon
                                            info={
                                                <span>
                                                    To upgrade to the latest
                                                    major version, you have to
                                                    upgrade version by version
                                                </span>
                                            }
                                        />
                                    </span>
                                }
                                name={'upgradeVersion'}
                            >
                                <Select disabled />
                            </FormItem>
                        </Col>

                        <Col span={12}>
                            <FormItem
                                label="Method"
                                name={'method'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a method.',
                                    },
                                ]}
                            >
                                <Select
                                    data-testid="cluster-database-type-form-select-method"
                                    onChange={onChangeMethod}
                                    options={[
                                        { value: 'copy', label: 'copy' },
                                        {
                                            value: 'link',
                                            label: 'link',
                                        },
                                        {
                                            value: 'pgdumpall',
                                            label: 'pgdumpall ',
                                        },
                                    ]}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>{getMethodInfo(method)}</Col>
                <Col span={24}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <SpaceWide
                                direction="vertical"
                                style={{ margin: '1rem 0' }}
                            >
                                <Alert
                                    showIcon
                                    type="warning"
                                    message={
                                        'Please note, that during the upgrade process, the cluster will not be operational.'
                                    }
                                />
                            </SpaceWide>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                name="policy"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: policyValidator,
                                    },
                                ]}
                            >
                                <Checkbox data-testid="upgrade-policy-checkbox">
                                    <TypographyText strong>
                                        A major upgrade is performed at your own
                                        risk.{' '}
                                    </TypographyText>
                                    Severalnines will not support any breaking
                                    issues that might occur during this
                                    operation. Please read the version change
                                    log documentation carefully before
                                    attempting this operation.
                                </Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function clusterInPlaceValidation() {
    return [['method'], ['policy']];
}

function getMethodInfo(methodType: string) {
    switch (methodType) {
        case 'copy':
            return (
                <Alert
                    showIcon
                    type="info"
                    message={
                        <TypographyText>
                            This is the default method with pg_upgrade. The
                            --copy option is used which will copy ‘files at a
                            time’ which is faster than doing regular backup and
                            restore. This requires disk storage capacity to hold
                            both the old and the new files.
                            <br />
                            <br />
                            NOTE: Data stored in user-defined tablespaces is not
                            being copied to the new cluster during an upgrade.
                            It remains in its original file system location but
                            is duplicated into a subdirectory indicating the
                            version number of the new cluster. To manually
                            relocate files that are stored in a tablespace after
                            upgrading, move the files to the new location and
                            update the symbolic links to point to the files.
                        </TypographyText>
                    }
                />
            );

        case 'link':
            return (
                <Alert
                    showIcon
                    type="info"
                    message={
                        <TypographyText>
                            This method uses the --link option with pg_ugprade.
                            Hard links will be used instead of copying (--copy)
                            files which is faster and requires no extra disk
                            storage. It is crucial to refrain from starting the
                            old cluster after this step to avert any potential
                            data corruption with the new cluster since they
                            share the data.
                            <br />
                            <br />
                            NOTE: Data stored in user-defined tablespaces is not
                            being copied to the new cluster during an upgrade.
                            It remains in its original file system location but
                            is duplicated into a subdirectory indicating the
                            version number of the new cluster. To manually
                            relocate files that are stored in a tablespace after
                            upgrading, move the files to the new location and
                            update the symbolic links to point to the files.
                        </TypographyText>
                    }
                />
            );

        case 'pgdumpall':
            return (
                <Alert
                    showIcon
                    type="info"
                    message={
                        <TypographyText>
                            This is an alternative method to pg_upgrade and uses
                            pgdumpall to copy files by performing a backup on
                            the old cluster and then restoring it on the new
                            cluster. This requires disk storage capacity to hold
                            the backup. the old and new files.
                        </TypographyText>
                    }
                />
            );

        default:
            return (
                <Alert
                    showIcon
                    type="info"
                    message={
                        <TypographyText>
                            This is the default method with pg_upgrade. The
                            --copy option is used which will copy ‘files at a
                            time’ which is faster than doing regular backup and
                            restore. This requires disk storage capacity to hold
                            both the old and the new files.
                            <br />
                            <br />
                            NOTE: Data stored in user-defined tablespaces is not
                            being copied to the new cluster during an upgrade.
                            It remains in its original file system location but
                            is duplicated into a subdirectory indicating the
                            version number of the new cluster. To manually
                            relocate files that are stored in a tablespace after
                            upgrading, move the files to the new location and
                            update the symbolic links to point to the files.
                        </TypographyText>
                    }
                />
            );
    }
}
