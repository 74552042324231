import React from 'react';
import useFetch, { UseFetchProps } from '../../common/useFetch';
import CcAlarm from '../../services/models/CcAlarm';
import CmonAlarmService from '../../services/cmon/CmonAlarmService';

export default useAlarmRecord;

export type UseAlarmRecordProps = Omit<
    UseFetchProps,
    'fetchFn' | 'cancelFn'
> & {
    alarmId: number;
};

function useAlarmRecord({ alarmId: alarmIdParam }: UseAlarmRecordProps) {
    const { data, ...restFetch } = useFetch<CcAlarm>({
        fetchFn: async ({ alarmId = alarmIdParam, ...params }) => {
            const { alarm } = await CmonAlarmService.getAlarm({
                alarm_id: alarmId,
                ...params,
            });
            return alarm;
        },
        cancelFn: async ({ requestId }) => {
            await CmonAlarmService.cancelRequest(requestId);
        },
    });
    return { record: data, ...restFetch };
}
