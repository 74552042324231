const result = {
    authenticated: true,
    cluster_type: 'GALERA',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: [
        '10.11.6',
        '10.11.5',
        '10.11.4',
        '10.11.3',
        '10.11.2',
        '10.11.1',
        '10.11.0',
        '10.10.7',
        '10.10.6',
        '10.10.5',
        '10.10.4',
        '10.10.3',
        '10.10.2',
        '10.10.1',
        '10.10.0',
        '10.9.8',
        '10.9.7',
        '10.9.6',
        '10.9.5',
        '10.9.4',
        '10.9.3',
        '10.9.2',
        '10.9.1',
        '10.9.0',
        '10.8.8',
        '10.8.7',
        '10.8.6',
        '10.8.5',
        '10.8.4',
        '10.8.3',
        '10.8.2',
        '10.8.0',
        '10.7.8',
        '10.7.7',
        '10.7.6',
        '10.7.5',
        '10.7.4',
        '10.7.3',
        '10.7.1',
        '10.7.0',
        '10.6.16',
        '10.6.15',
        '10.6.14',
        '10.6.13',
        '10.6.12',
        '10.6.11',
        '10.6.10',
        '10.6.9',
        '10.6.8',
        '10.6.7',
        '10.6.5',
        '10.6.4',
        '10.6.3',
        '10.6.2',
        '10.6.1',
        '10.6.0',
        '10.5.23',
        '10.5.22',
        '10.5.21',
        '10.5.20',
        '10.5.19',
        '10.5.18',
        '10.5.17',
        '10.5.16',
        '10.5.15',
        '10.5.13',
        '10.5.12',
        '10.5.11',
        '10.5.10',
        '10.5.9',
        '10.5.8',
        '10.5.7',
        '10.5.6',
        '10.5.5',
        '10.5.4',
        '10.5.3',
        '10.5.2',
        '10.5.1',
        '10.5.0',
        '10.4.32',
        '10.4.31',
        '10.4.30',
        '10.4.29',
        '10.4.28',
        '10.4.27',
        '10.4.26',
        '10.4.25',
        '10.4.24',
        '10.4.22',
        '10.4.21',
        '10.4.20',
        '10.4.19',
        '10.4.18',
        '10.4.17',
        '10.4.16',
        '10.4.15',
        '10.4.14',
        '10.4.13',
        '10.4.12',
        '10.4.11',
        '10.4.10',
        '10.4.8',
        '10.4.7',
        '10.4.6',
        '10.4.5',
        '10.4.4',
        '10.4.3',
        '10.4.2',
        '10.4.1',
        '10.4.0',
    ],
    request_processed: '2024-01-16T09:19:08.024Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    use_vendor_api: false,
    vendor: 'mariadb',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
