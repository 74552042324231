import React, { useMemo, useState } from 'react';
import ModalDefault from '../../../../common/ModalDefault';

import CcCluster from '../../../../services/models/CcCluster';
import MongoShardsFormValueListener from '../../../Services/Cluster/MongoShards/MongoShardsFormValueListener';
import { getShardKeys } from '../../../Services/Cluster/Deploy/MongoShards/MongoShardsReplicaSetFormWrapper';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Form } from 'antd';
import { MongoShardsFormShardsMap } from '../../../Services/Cluster/MongoShards/MongoShardsConfigurator';
import { ServiceClusterWizardStep } from '../../../Services/Cluster/ServiceClusterWizardStep';
import useCreateJob from '../../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import ClusterAddShardConfigurationForm from './ClusterAddShardConfigurationForm';
import MongoShardsDeploymentConfigurator, {
    getShardsJobData,
    getShardStepsConfigurations,
} from '../../../Services/Cluster/Deploy/MongoShards/MongoShardsDeploymentConfigurator';
import { TopologyItem } from '../../../Topology/TopologyItem';
import ClusterAddShardSummary from './ClusterAddShardSummary';
import TypographyText from '../../../../common/TypographyText';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../../services/models/CcNode';

export default ClusterAddShardModal;

export enum ClusterAddShardFormWizardStep {
    CONFIG = 'CONFIG',
    SUMMARY = 'SUMMARY',
}

export interface ClusterAddShardFormValues {
    serverDataDirectory: string;
    disableFirewall: boolean;
    disableSeLinux: boolean;
    installSoftware: boolean;
    configurationTemplate: string;
    shards: MongoShardsFormShardsMap;
}

export type ClusterAddShardModalProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
};

function ClusterAddShardModal({
    cluster,
    onSuccess,
    onCancel,
}: ClusterAddShardModalProps) {
    const [form] = Form.useForm<ClusterAddShardFormValues>();
    const [currentShards, setCurrentShards] = useState<
        MongoShardsFormShardsMap
    >(form.getFieldValue('shards'));
    const [activeStep, setActiveStep] = useState<
        ServiceClusterWizardStep | string | undefined
    >(ClusterAddShardFormWizardStep.CONFIG);
    const { loading, send } = useCreateJob({
        clusterId: cluster.clusterId,
        title: 'Add shards',
        command: CmonJobInstanceCommand.ADD_SHARD,
        onSuccess,
    });

    const handleValuesChange = () => {
        setCurrentShards(form.getFieldValue('shards'));
    };
    const handleSubmit = async () => {
        const values = form.getFieldsValue(true);
        await send({
            dataDir: values.serverDataDirectory,
            disable_firewall: values.disableFirewall,
            disable_selinux: values.disableSeLinux,
            enable_uninstall: false,
            install_software: values.installSoftware,
            mongodb_conf_template: values.configurationTemplate,
            replica_sets: getShardsJobData(values.shards),
        });
    };
    const steps = useMemo(
        () => [
            <WizardFormConfiguration.Step
                key={ClusterAddShardFormWizardStep.CONFIG}
                title={getClusterAddShardWizardStepName(
                    ClusterAddShardFormWizardStep.CONFIG
                )}
                subTitle=" "
                hasRequiredFields={true}
            >
                <ClusterAddShardConfigurationForm cluster={cluster} />
            </WizardFormConfiguration.Step>,
            ...(currentShards
                ? getShardStepsConfigurations(
                      currentShards,
                      form,
                      cluster.clusterId,
                      (item: TopologyItem) => {
                          if (
                              cluster
                                  .getNodesByTypesAndRoles(
                                      [CcNodeType.MONGO],
                                      [CcNodeRole.MONGO_CONFIG_SERVER]
                                  )
                                  .find(
                                      (n: CcNode) =>
                                          n.hostname === item.extraData.hostname
                                  )
                          ) {
                              throw new Error(
                                  'This address is used already for the config servers'
                              );
                          }
                          if (
                              cluster
                                  .getNodesByTypesAndRoles(
                                      [CcNodeType.MONGO],
                                      [CcNodeRole.MONGO_MONGOS]
                                  )
                                  .find(
                                      (n: CcNode) =>
                                          n.hostname === item.extraData.hostname
                                  )
                          ) {
                              throw new Error(
                                  'This address is used already for the router/mongos servers'
                              );
                          }
                      }
                  )
                : []
            ).map(([key, props]) => (
                <WizardFormConfiguration.Step key={key} {...props} />
            )),
            <WizardFormConfiguration.Step
                key={ClusterAddShardFormWizardStep.SUMMARY}
                title={getClusterAddShardWizardStepName(
                    ClusterAddShardFormWizardStep.SUMMARY
                )}
                subTitle=" "
                hasRequiredFields={true}
            >
                <Form.Item noStyle={true} shouldUpdate={true}>
                    {() => {
                        return <ClusterAddShardSummary form={form} />;
                    }}
                </Form.Item>
            </WizardFormConfiguration.Step>,
        ],
        [currentShards]
    );
    const initialValues: ClusterAddShardFormValues = useMemo(() => {
        const {
            nodeConfig,
            sshConfig,
            shards,
        } = MongoShardsDeploymentConfigurator.getDefaults();
        return {
            ...nodeConfig,
            ...sshConfig,
            shards,
        } as ClusterAddShardFormValues;
    }, []);
    return (
        <ModalDefault
            title={<div>Add shards</div>}
            visible={true}
            onCancel={onCancel}
        >
            <WizardFormConfiguration
                activeKey={activeStep}
                initialValues={initialValues}
                onValuesChange={handleValuesChange}
                onActiveKeyChange={(key) => setActiveStep(key)}
                form={form}
                steps={steps}
                onSubmit={handleSubmit}
                loading={loading}
                // using stepsExtra as a hack to set currentValues state with updated values
                stepsExtra={() => (
                    <MongoShardsFormValueListener
                        onShardAdded={(shards) => {
                            const shardsKeys = getShardKeys(shards);
                            setCurrentShards(shards);
                            setActiveStep(
                                `${shardsKeys[shardsKeys.length - 1]}`
                            );
                        }}
                        onShardRemoved={(shards) => {
                            const shardsKeys = getShardKeys(shards);
                            setActiveStep(
                                `${shardsKeys[shardsKeys.length - 1]}`
                            );
                            setTimeout(() => {
                                setCurrentShards(shards);
                            });
                        }}
                    />
                )}
            />
        </ModalDefault>
    );
}

export function getClusterAddShardWizardStepName(
    step: ClusterAddShardFormWizardStep
) {
    switch (step) {
        case ClusterAddShardFormWizardStep.CONFIG:
            return <TypographyText nowrap>Database settings</TypographyText>;
        case ClusterAddShardFormWizardStep.SUMMARY:
            return 'Preview';
        default:
            return '';
    }
}
