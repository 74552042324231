import CcCluster from '../../../../../services/models/CcCluster';
import { FormInstance } from 'antd/lib/form';
import { BackupRestoreWizardFormValues } from '../BackupRestoreWizardForm';
import React from 'react';
import { Alert, Col, Form, Row, Space } from 'antd';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import { isPostgresBackupMethodPITRAvailable } from '../../../Config/BackupFormConfigurator';
import SpaceWide from '../../../../../common/SpaceWide';
import BackupRestorePITRInfoMessage from './BackupRestorePITRInfoMessage';
import BackupRestoreTimePickerField from './BackupRestoreTimePickerField';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import {
    isBackupMethodMariaBackup,
    isBackupMethodPgBackRest,
    isBackupMethodPgBaseBackup,
    isBackupMethodXtraBackup,
} from '../../../../../services/models/CcBackup';

export default BackupRestoreSettingsPosrtgresFormFields;
type BackupRestoreSettingsPosrtgresFormFieldsProps = {
    cluster: CcCluster;
    form: FormInstance<BackupRestoreWizardFormValues>;
};

function BackupRestoreSettingsPosrtgresFormFields({
    cluster,
    form,
}: BackupRestoreSettingsPosrtgresFormFieldsProps) {
    const { backup }: BackupRestoreWizardFormValues = form.getFieldsValue(true);
    const method = backup?.getMethod();

    const showPITR =
        method && isPostgresBackupMethodPITRAvailable(cluster, method);

    const showBootstrapCluster =
        method &&
        (isBackupMethodMariaBackup(method) ||
            isBackupMethodXtraBackup(method) ||
            isBackupMethodPgBaseBackup(method) ||
            isBackupMethodPgBackRest(method));

    const handleChangePITR = (value: boolean) => {
        form.setFieldsValue({
            immediate: !value,
        });
    };

    return (
        <>
            <Row gutter={[24, 0]}>
                {showPITR && (
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            name="pitr"
                            label={<span>Point in time recovery (PITR)</span>}
                            valuePropName="checked"
                            onChange={handleChangePITR}
                        />
                    </Col>
                )}
                {showBootstrapCluster && (
                    <Col xs={24} sm={24} md={12}>
                        <FormItemInlineSwitch
                            justify={true}
                            name="bootstrapCluster"
                            label={
                                <span>
                                    Bootstrap cluster from restored node
                                </span>
                            }
                            valuePropName="checked"
                        />
                    </Col>
                )}
            </Row>
            <Col xs={24} sm={24} md={12}>
                <Form.Item shouldUpdate={true} noStyle={true}>
                    {() => {
                        if (form.getFieldValue('pitr')) {
                            return (
                                <BackupRestorePostgresPITRForm form={form} />
                            );
                        }
                        return null;
                    }}
                </Form.Item>
            </Col>
        </>
    );
}

type BackupRestorePostgresPITRFormProps = {
    form: FormInstance;
};

function BackupRestorePostgresPITRForm({
    form,
}: BackupRestorePostgresPITRFormProps) {
    return (
        <SpaceWide direction="vertical">
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <BackupRestoreTimePickerField
                        label={
                            <Space>
                                Restore time
                                <InfoIcon
                                    info={
                                        <span>
                                            The time until what the PostgreSQL
                                            forward (the oldest possible
                                            timestamp is the backup creation
                                            timestamp, or the current timestamp
                                            minus the "PITR_RETENTION_HOURS"
                                            configuration value if it is greater
                                            than 1.)
                                        </span>
                                    }
                                />
                            </Space>
                        }
                    />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Alert
                        showIcon={true}
                        message={<BackupRestorePITRInfoMessage form={form} />}
                    />
                </Col>
            </Row>
        </SpaceWide>
    );
}
