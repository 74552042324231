import CcCluster from '../../../services/models/CcCluster';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import React from 'react';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import AdvisorInfoModal from './AdvisorInfoModal';
import AdvisorEditScriptModal from './AdvisorEditScriptModal';
import AdvisorEditParamsModal from './AdvisorEditParamsModal';
import CcAdvisorSchedule from '../../../services/models/CcAdvisorSchedule';
import AdvisorActivityToggleActionButton from './AdvisorActivityToggleActionButton';
import {
    CloseCircleOutlined,
    EditOutlined,
    FormOutlined,
    PlayCircleOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';

export default AdvisorActionsMenu;
type AdvisorActionsMenuProps = ActionsMenuProps & {
    cluster: CcCluster;
    advisorSchedule?: CcAdvisorSchedule;
};

function AdvisorActionsMenu({
    cluster,
    advisorSchedule,
    onActionPerformed,
    ...rest
}: AdvisorActionsMenuProps) {
    const toggleAdvisorButton = {
        key: 'enable',
        icon: advisorSchedule?.enabled ? (
            <CloseCircleOutlined />
        ) : (
            <PlayCircleOutlined />
        ),
        critical: !!advisorSchedule?.enabled,
        label: (
            <AdvisorActivityToggleActionButton
                cluster={cluster}
                advisorSchedule={advisorSchedule}
                onSuccess={onActionPerformed}
            />
        ),
    };
    const items = [
        {
            key: 'results',
            icon: <ProfileOutlined />,
            label: (
                <ButtonWithForm
                    button={<ActionButton>Results</ActionButton>}
                    form={
                        <AdvisorInfoModal advisorSchedule={advisorSchedule} />
                    }
                />
            ),
        },
        {
            key: 'Edit script',
            icon: <FormOutlined />,
            label: (
                <ButtonWithForm
                    button={<ActionButton>Edit script</ActionButton>}
                    form={
                        advisorSchedule && (
                            <AdvisorEditScriptModal
                                cluster={cluster}
                                advisorSchedule={advisorSchedule}
                                onUpdate={onActionPerformed}
                            />
                        )
                    }
                />
            ),
        },
        {
            key: 'Edit params',
            icon: <EditOutlined />,
            label: (
                <ButtonWithForm
                    button={<ActionButton>Edit params</ActionButton>}
                    form={
                        advisorSchedule && (
                            <AdvisorEditParamsModal
                                cluster={cluster}
                                advisorSchedule={advisorSchedule}
                                onSuccess={onActionPerformed}
                            />
                        )
                    }
                />
            ),
        },
        toggleAdvisorButton,
    ].filter(Boolean) as ActionMenuItem[];
    return <ActionsMenu items={items} {...rest} />;
}
