import './DatabaseAccounts.less';
import React, { useEffect } from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import CcCluster from '../../services/models/CcCluster';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import DatabaseUsersTable from './DatabaseUsersTable';
import SpaceWide from '../../common/SpaceWide';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CcDatabaseAccount, {
    CcDatabaseAccountStats,
} from '../../services/models/CcDatabaseAccount';
import DatabaseUserFormModal from './DatabaseUserFormModal';
import useDatabaseUsers from './useDatabaseUsers';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import Search from 'antd/lib/input/Search';
import { useDebugContext } from '../../common/Debug';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';

export default DatabaseAccounts;
export type DatabaseAccountsProps = { cluster: CcCluster };

function DatabaseAccounts({ cluster, ...rest }: DatabaseAccountsProps) {
    const { log } = useDebugContext();
    const { filterParams, handleTableChange, addFilterParams } = useTableFilter(
        {
            params: {
                active: ArrayParam,
                system: BooleanParam,
                search: StringParam,
            },
            defaultParams: {
                sort: 'active',
                order: 'ascend',
                system: false,
            },
        }
    );
    const {
        list,
        unusedAccountsReady,
        unusedErrorMsg,
        refresh,
        error,
        loading,
        filter,
    } = useDatabaseUsers({
        clusterId: cluster.clusterId as number,
    });

    const handleUserDelete = async (user: CcDatabaseAccount) => {
        try {
            await CmonClustersService.deleteAccount({
                cluster_id: cluster.clusterId,
                account: {
                    user_name: user.userName,
                    host_allow: user.hostAllow,
                },
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'User deleted successfully!',
            });

            await refreshUsers(cluster.clusterId);
        } catch (error) {
            log.error(error);
            notifyError({
                type: NotifyType.TOAST,
                duration: 0,
                content: error.message,
            });
        }
    };

    const handleUserAddSuccess = async () => {
        await refreshUsers(cluster.clusterId);
    };
    const handleUserAddError = async () => {
        await refreshUsers(cluster.clusterId);
    };
    const handleUserSaved = async () => {
        await refreshUsers(cluster.clusterId);
    };

    const refreshUsers = async (clusterId?: number) => {
        if (clusterId) {
            try {
                await refresh({ cluster_id: clusterId });
            } catch (e: any) {
                log.error(e);
                notifyError({
                    type: NotifyType.TOAST,
                    duration: 0,
                    content: e.message,
                });
            }
        }
    };

    useEffect(() => {
        (async () => {
            await refreshUsers(cluster.clusterId);
        })();
    }, [cluster.clusterId]);

    useEffect(() => {
        if (filterParams) {
            const filters = [];
            if (filterParams.search) {
                const searchString = filterParams.search.toLowerCase();
                filters.push((account: CcDatabaseAccount) => {
                    return (
                        account.userName
                            ?.toLowerCase()
                            .includes(searchString) ||
                        account.hostAllow
                            ?.toLowerCase()
                            .includes(searchString) ||
                        account.privileges?.find((privilege) => {
                            return (
                                privilege.db
                                    ?.toLowerCase()
                                    .includes(searchString) ||
                                privilege.privileges.find((privilege) => {
                                    return privilege
                                        ?.toLowerCase()
                                        .includes(filterParams.search);
                                })
                            );
                        })
                    );
                });
            }
            if (filterParams.active) {
                filters.push((account: CcDatabaseAccount) => {
                    if (
                        account.status === CcDatabaseAccountStats.ACTIVE &&
                        !filterParams.active?.includes('active')
                    ) {
                        return false;
                    }

                    if (
                        account.status === CcDatabaseAccountStats.INACTIVE &&
                        !filterParams.active?.includes('inactive')
                    ) {
                        return false;
                    }
                    return true;
                });
            }
            if (filterParams.system !== true) {
                filters.push((account: CcDatabaseAccount) => {
                    if (account.systemUser === true) {
                        return false;
                    }
                    return true;
                });
            }
            let order;
            if (filterParams.sort === 'active') {
                order = getSortingByActivity(filterParams.order);
            }

            filter({
                filters,
                order,
            });
        }
    }, [filterParams]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;
        addFilterParams({ search: searchString ? searchString : undefined });
    };

    return (
        <SpaceWide className="DatabaseAccounts" direction="vertical">
            <div className="DatabaseAccounts_header">
                <Search
                    placeholder="Search by username, hostname, database, table or privilege"
                    onChange={handleSearchChange}
                    className="DatabaseAccounts_search"
                />
                <ButtonWithForm
                    button={
                        <Button
                            type="primary"
                            size="middle"
                            icon={<PlusOutlined />}
                            {...rest}
                        >
                            Create DB User
                        </Button>
                    }
                    form={
                        <DatabaseUserFormModal
                            cluster={cluster}
                            onSuccess={handleUserAddSuccess}
                            onError={handleUserAddError}
                        />
                    }
                />
            </div>
            <DatabaseUsersTable
                cluster={cluster}
                // @todo come up with some default error message for all tables e.g. "Data loading error"
                unknownErrorMessage="Error loading database users"
                loading={loading}
                dataSource={list}
                statusFilterAvailable={unusedAccountsReady && !unusedErrorMsg}
                statusExtra={
                    !unusedAccountsReady ? (
                        <InfoIcon
                            info={
                                <span>
                                    Server must have been running for 1 hours to
                                    check unused accounts since last server
                                    restart
                                </span>
                            }
                        />
                    ) : !!unusedErrorMsg ? (
                        <InfoIcon info={unusedErrorMsg} />
                    ) : undefined
                }
                filterParams={filterParams}
                onDelete={handleUserDelete}
                onSave={handleUserSaved}
                onChange={handleTableChange}
                error={error || undefined}
            />
        </SpaceWide>
    );
}

function getSortingByActivity(order: 'ascend' | 'descend') {
    return (a: CcDatabaseAccount, b: CcDatabaseAccount) => {
        const activeA = a.status === CcDatabaseAccountStats.ACTIVE;
        const activeB = b.status === CcDatabaseAccountStats.ACTIVE;
        if (order === 'ascend') {
            return (activeB ? 1 : 0) - (activeA ? 1 : 0);
        }
        return (activeA ? 1 : 0) - (activeB ? 1 : 0);
    };
}
