import React, { useContext, ReactNode } from 'react';
import './ProxySQLServers.less';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../../../../common/Navigation/ActionsMenu';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import ButtonWithForm from '../../../../../../common/General/ButtonWithForm';
import ProxySQLServersModal from './ProxySQLServersModal';
import CcCluster from '../../../../../../services/models/CcCluster';
import CcProxySqlNode from '../../../../../../services/models/CcProxySqlNode';
import CmonProxysqlService from '../../../../../../services/cmon/CmonProxysqlService';
import { FetchRefreshFunctionParams } from '../../../../../../common/useFetch';
import { prepareDeleteServerData } from './proxySQLServersHelper';
import { notifyError } from '../../../../../Notifications/uiNotification';
import AppEmpty from '../../../../../../common/Feedback/AppEmpty';
import CcProxySqlServer, {
    CcProxySqlServerStatus,
} from '../../../../../../services/models/CcProxySqlServer';
import AppConfirmActionButton from '../../../../../../common/General/AppConfirmActionButton';
import AppTable from '../../../../../../common/DataDisplay/AppTable';
import AppSpin from '../../../../../../common/General/AppSpin';

export default ProxySQLServers;

export type ProxySQLServersProps = ActionsMenuProps & {
    cluster: CcCluster;
    node: CcProxySqlNode;
    serversPageSize?: number;
    refreshServers: (
        p?: FetchRefreshFunctionParams | undefined
    ) => Promise<void>;
    hostGroupList: { value: string }[] | undefined;
    serversTablesData:
        | {
              [key: string]: CcProxySqlServer[];
          }
        | undefined;
    loading: boolean;
};

function ProxySQLServers({
    cluster,
    node,
    serversPageSize = 5,
    refreshServers,
    hostGroupList,
    serversTablesData,
    loading,
    ...rest
}: ProxySQLServersProps) {
    const { responsive } = useContext(ResponsiveContext);

    const deleteServer = async (serverData: CcProxySqlServer) => {
        try {
            await CmonProxysqlService.deletemysqlserver({
                clusterId: cluster?.clusterId,
                hostName: node?.hostname,
                port: node?.port,
                mysqlServer: prepareDeleteServerData(serverData),
            });
            await refreshServers();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };
    const columns = [
        {
            title: 'Host',
            key: 'hostname',
            dataIndex: 'hostname',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (record: CcProxySqlServerStatus) => getStatus(record),
        },
        {
            title: 'Port',
            key: 'port',
            dataIndex: 'port',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Weight',
            key: 'weight',
            dataIndex: 'weight',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Max connections',
            key: 'maxConnections',
            dataIndex: 'maxConnections',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Max replication lag',
            key: 'maxReplicationLag',
            dataIndex: 'maxReplicationLag',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Max latency',
            key: 'Latency',
            dataIndex: 'Latency',
            render: (record: string) => `${record}`,
        },
        {
            title: 'Actions',
            key: '',
            dataIndex: '',
            render: (record: CcProxySqlServer) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'edit-rule',
                            label: (
                                <ButtonWithForm
                                    button={<Button type="link">Edit</Button>}
                                    form={
                                        <ProxySQLServersModal
                                            cluster={cluster}
                                            node={node}
                                            title="Edit server"
                                            refreshServers={refreshServers}
                                            hostIdList={hostGroupList}
                                            edit={true}
                                            serverData={record}
                                        />
                                    }
                                />
                            ),
                        },
                        {
                            key: 'delete-rule',
                            waitForConfirm: true,
                            label: (
                                <AppConfirmActionButton
                                    confirmTitle={`Delete server?`}
                                    critical
                                    onConfirm={() => deleteServer(record)}
                                >
                                    <Typography.Text type="danger">
                                        Delete
                                    </Typography.Text>
                                </AppConfirmActionButton>
                            ),
                        },
                    ]}
                    {...rest}
                />
            ),
        },
    ];

    return (
        <div className="ProxySQLServers">
            <Row justify="end">
                <ButtonWithForm
                    button={
                        <Button disabled={loading} icon={<PlusOutlined />}>
                            Create new server
                        </Button>
                    }
                    form={
                        <ProxySQLServersModal
                            cluster={cluster}
                            node={node}
                            title="Add new server"
                            refreshServers={refreshServers}
                            hostIdList={hostGroupList}
                        />
                    }
                />
            </Row>

            <AppSpin spinning={loading}>
                {serversTablesData ? (
                    Object.keys(serversTablesData).map((objectKey) => (
                        <div
                            key={objectKey}
                            className="ProxySQLServers__Tables"
                        >
                            <h4>{`Hostgroup ${objectKey}`}</h4>
                            <AppTable
                                size="small"
                                dataSource={serversTablesData[`${objectKey}`]}
                                rowKey={(record: CcProxySqlServer) =>
                                    `${objectKey} ${record.hostname}`
                                }
                                columns={columns}
                                responsive={responsive}
                                pagination={false}
                            />
                        </div>
                    ))
                ) : (
                    <AppEmpty
                        loading={loading}
                        description="There is no servers yet."
                    />
                )}
            </AppSpin>
        </div>
    );
}

function getStatus(type: CcProxySqlServerStatus): ReactNode {
    switch (type) {
        case CcProxySqlServerStatus.ONLINE:
            return (
                <StatusFormat status={StatusFormatStatus.success} text={type} />
            );
        case CcProxySqlServerStatus.SHUNNED:
            return (
                <StatusFormat status={StatusFormatStatus.warning} text={type} />
            );
        case CcProxySqlServerStatus.OFFLINE_SOFT:
            return (
                <StatusFormat status={StatusFormatStatus.error} text={type} />
            );
        case CcProxySqlServerStatus.OFFLINE_HARD:
            return (
                <StatusFormat status={StatusFormatStatus.error} text={type} />
            );

        default:
            return (
                <StatusFormat status={StatusFormatStatus.unknown} text={type} />
            );
    }
}
