import CmonTreeService from '../../services/cmon/CmonTreeService';
import useFetch from '../../common/useFetch';
import CcTreeItem, {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
    CcTreeItemType,
    getMinLevel,
} from '../../services/models/CcTreeItem';
import { useCallback, useEffect, useState } from 'react';
import {
    aclItemsPaths,
    aclItemsPathsDeps,
    AclPermissionItem,
    generateAclItems,
    getAclItemDescription,
    getAclItemText,
    getRealAccessToClusterTypeItem,
    getTreeItem,
    UseTreeProps,
} from './useTree';
import { CMON_ADMINS_GROUP_NAME } from '../../services/models/CcGroup';

export type UseGroupTreeProps = UseTreeProps & {
    groupName?: string;
    userName?: string;
};

export default function useGroupTree({
    groupName: initialGroupName,
    userName,
}: UseGroupTreeProps) {
    const [aclItems, setAclItems] = useState<CcTreeItem[]>([]);
    const [data, setData] = useState<CcTreeItem[]>();

    const { data: treeData, loading, refresh, cancel, loaded } = useFetch({
        name: 'tree',
        useCache: false,
        fetchFn: async (
            { pageSize, page, groupName = initialGroupName, ...rest },
            opts
        ) => {
            /**
             * If user_name is not provided backend is using current logged in user
             * if user_name is not empty then backend somehow returns correct permissions
             * "anonymous" - is not a real user, can be any not empty string instead
             *
             * Expect for group admins in this case adding a workaround with empty string (current user is being user on backend side)
             */
            const groupUserName =
                userName ?? (groupName === 'admins' ? '' : 'anonymous');
            const { cdt } = await CmonTreeService.getTree(
                {
                    filters: [
                        // needed paths
                        ...aclItemsPaths,
                        // needed dependencies
                        ...Object.values(aclItemsPathsDeps).flat(),
                        // items with type = cluster
                        CcTreeItemType.Cluster,
                    ],
                    as_user: {
                        class_name: 'CmonUser',
                        user_name: groupUserName,
                        groups: [
                            { class_name: 'CmonGroup', group_name: groupName },
                        ],
                    },
                    ...rest,
                },
                opts
            );
            return cdt;
        },
        cancelFn: async ({ requestId }) => {
            await CmonTreeService.cancelRequest(requestId);
        },
    });

    /**
     * The `levels` parameter is an optional array of acceptable access levels or permissions.
     * If provided, it's used to filter the items based on their effective privileges. Any item
     * whose effective privileges are not included in the `levels` array will have its `disabled`
     * property set to `true`, indicating that it does not meet the specified access criteria.
     * If the `levels` parameter is not provided, the `disabled` property is not affected by this condition.
     */
    const getAcl = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            aclItems.map((item) => {
                const key = item.getKey() as CcTreeItemHandledAclPath;
                const effectivePrivileges: CcTreeItemAccess = getMinLevel([
                    item.effectivePrivileges!,
                    ...(aclItemsPathsDeps[key]
                        ? // then we get the dependecy items privileges
                          aclItemsPathsDeps[key]!.map(
                              (path) =>
                                  getTreeItem(path, data!)?.effectivePrivileges!
                          )
                        : item.isType(CcTreeItemType.Cluster)
                        ? // then we get the real permission for that cluster
                          [getRealAccessToClusterTypeItem(item, data!)]
                        : []),
                ]);
                return {
                    key: item.getKey(),
                    title: item.isType(CcTreeItemType.Cluster)
                        ? item.itemName
                        : getAclItemText(key),
                    description: getAclItemDescription(key),
                    /**
                     * CcTreeItemHandledAclPath.ACL_USER_MANAGER is not used in
                     * backend for checking permissions in user management module.
                     * Logic is based in checking if executor is form admins group
                     * https://severalnines.atlassian.net/browse/CCV2-946
                     */
                    level:
                        item.itemPath ===
                        CcTreeItemHandledAclPath.ACL_USER_MANAGER
                            ? initialGroupName === CMON_ADMINS_GROUP_NAME
                                ? CcTreeItemAccess.FULL_ACCESS
                                : CcTreeItemAccess.NO_ACCESS
                            : effectivePrivileges,
                    isCluster: item.isType(CcTreeItemType.Cluster),
                    clusterKey: item.getClusterKey(),
                    disabled: levels && !levels.includes(effectivePrivileges),
                    item,
                };
            }),
        [aclItems, initialGroupName]
    );
    const getAclOverActions = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            getAcl(levels).filter((item) => !item.isCluster),
        [getAcl]
    );
    const getAclOverClusters = useCallback(
        (levels?: CcTreeItemAccess[]): AclPermissionItem[] =>
            getAcl(levels).filter((item) => item.isCluster),
        [getAcl]
    );

    useEffect(() => {
        if (treeData) {
            const tree = [treeData];
            setAclItems(generateAclItems(tree));
            setData(tree);
        }
    }, [treeData]);

    return {
        data,
        aclItems,
        getAclOverActions,
        getAclOverClusters,
        getAcl,
        loading,
        refresh,
        cancel,
        loaded,
    };
}
