import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import CcBackupSchedule from '../../../services/models/CcBackupSchedule';
import { ClusterConfig } from '../../Clusters/useClusterConfig';
import merge from 'deepmerge';
import {
    CcBackupMethod,
    CcBackupMySQLDumpType,
    CcBackupRetentionType,
    CcBackupStorageLocationHostType,
} from '../../../services/models/CcBackup';
import { getBackupMethodsByCluster } from '../BackupMethodSelect';
import { BackupFormFieldsType } from './BackupFormConfigurator';
import BackupJobConfig from './BackupJobConfig';

export const BACKUP_CLOUD_DEFAULT_RETENTION = 180;

export default class BackupDefaultFieldsConfig {
    static common(): BackupFormFieldsType {
        return {
            method: '',
            host: '',
            port: 0,
            clusterId: 0,

            backupToolJobId: null,
            toolStatus: null,

            defaultRetention: 31,

            storageLocation: CcBackupStorageLocationHostType.CONTROLLER,
            storageDirectory: '/root/backups',
            storageSubdirectory: 'BACKUP-%I',

            cloudUpload: false,
            cloudCredentials: '',
            cloudEnableRetention: true,
            cloudDefaultRetention: BACKUP_CLOUD_DEFAULT_RETENTION,
            cloudCustomRetention: null,
            cloudLocationPath: '',
            deleteAfterUpload: false,
            cloudBucket: '',
            cloudProvider: '',
            timezone: 'UTC',
        };
    }

    /**
     * Returns form defaults for particular cluster type
     * @param cluster CcCluster
     */
    static clusterType(cluster: CcCluster): BackupFormFieldsType {
        const common = {
            enableRetention: true,
            customRetention: null,
            compression: true,
            compressionLevel: 6,
            encryptBackup: false,
        };

        const backupVerification = {
            verifyBackup: false,
            verificationHost: null,
            verificationInstallSoftware: false,
            verificationDisableFirewall: false,
            verificationDisableSELinux: false,
            verificationShutdownAfter: false,
            verificationStartIn: 0,
        };
        const mysql = merge(common, {
            dumpType: CcBackupMySQLDumpType.SCHEMA_AND_DATA,
            retentionType: CcBackupRetentionType.DEFAULT,
            splitDumpFiles: false,
            enablePartial: false,
            excludeTables: false,
            includeTables: false,
            failoverBackup: false,
            failOverHost: null,

            extendedInsert: true,
            xtrabackupBackupLocks: true,
            xtrabackupLockDdlPerTable: false,
            xtrabackupParallellism: 1,
            throttleRateNet: 0,
            usePIGZ: false,
            useQPress: false,

            partialDatabases: [],
            partialTables: [],
            tableList: [],
            scheduleCron: '* * * * *',
            scheduleName: '',
            ...backupVerification,
        });
        switch (cluster.clusterType) {
            case CcClusterType.TYPE_GALERA:
                return {
                    ...mysql,
                    wsrepDeSync: false,
                };
            case CcClusterType.TYPE_REPLICATION:
                return mysql;
            case CcClusterType.TYPE_REDIS:
                return { ...common, ...backupVerification };
            case CcClusterType.TYPE_REDIS_SHARDED:
                return {
                    ...common,
                    storageHost: cluster.getControllerHost()?.hostname || '',
                    storageLocation: undefined,
                };
            case CcClusterType.TYPE_MSSQL_SINGLE:
            case CcClusterType.TYPE_MSSQL_AO_ASYNC:
                return {
                    ...common,
                    port: undefined,
                    host: undefined,
                    compressionLevel: undefined,
                    backupSystemDatabases: true,
                    encryptBackup: undefined, // not implemented in backend yet
                    storageLocation: CcBackupStorageLocationHostType.NODE,
                };
            case CcClusterType.TYPE_TIMESCALEDB:
            case CcClusterType.TYPE_POSTGRESQL:
                return {
                    ...common,
                    ...backupVerification,
                    enablePartial: false,
                    partialDatabases: [],
                    toolInstallStarted: false,
                    installBackupTool: true,

                    // failover
                    failoverBackup: false,
                    failOverHost: null,
                };
            case CcClusterType.TYPE_MONGODB:
            case CcClusterType.TYPE_MONGODB_SHARDS:
                return {
                    ...common,
                    compression: undefined,
                    compressionLevel: undefined,
                    installBackupTool: true,
                    storageHost: cluster.getControllerHost()?.hostname || '',
                    storageLocation: undefined,
                };
            case CcClusterType.TYPE_ELASTIC:
                return {
                    method: CcBackupMethod.ELASTIC_SNAPSHOT,
                    elasticRepository: null,
                    customRetention: null,
                    enableRetention: true,
                };
            default:
                return {};
        }
    }

    static cluster(cluster: CcCluster): BackupFormFieldsType {
        return {
            method: (cluster && getBackupMethodsByCluster(cluster)[0]) || '',
            host: cluster.getDatabaseNodes()[0]?.hostname || '',
            port: cluster.getDatabaseNodes()[0]?.port || 0,
            clusterId: cluster.clusterId,
            clusterType: cluster.clusterType,
            scheduleCron: '* * * * *',
            scheduleName: '',
            ...BackupDefaultFieldsConfig.clusterType(cluster),
        };
    }

    static clusterConfig(clusterConfig: ClusterConfig): BackupFormFieldsType {
        const fields: BackupFormFieldsType = {};
        if (clusterConfig.backupdir?.current_value) {
            fields.storageDirectory = `${clusterConfig.backupdir.current_value}`;
        }
        if (clusterConfig.backup_subdir?.current_value) {
            fields.storageSubdirectory = `${clusterConfig.backup_subdir.current_value}`;
        }
        if (clusterConfig.backup_retention?.current_value !== undefined) {
            fields.defaultRetention =
                +clusterConfig.backup_retention?.current_value;
        }
        return fields;
    }

    static schedule(schedule: CcBackupSchedule): BackupFormFieldsType {
        let timezone = schedule.recurrence
            ?.match(/([+-]\d{2}:\d{2})|UTC/)?.[0]
            .replace(':00', '')
            .trim();

        if (timezone !== 'UTC' && timezone !== 'LINT') {
            const hour = parseInt(timezone, 10);
            timezone = 'Etc/GMT' + (hour < 0 ? hour : `+${hour}`);
        }

        const values: any = {
            ...BackupJobConfig.jobDataToFields(schedule?.spec?.job_data || {}),
            clusterId: schedule.clusterId,
            scheduleName: schedule.title || '',
            scheduleCron: schedule.recurrence?.replace(/^TZ=[^ ]+ /, '').trim(),
            timezone,
        };
        // fields that are undefined should not override default ones
        // @todo think of a way to make it more consistent with other places in the config
        Object.keys(values).forEach((key) =>
            values[key] === undefined ? delete values[key] : {}
        );
        return values;
    }

    static restore(): BackupFormFieldsType {
        return {
            clusterId: 0,
            method: 'mysqldump',
            serverAddress: '',
            sourceAddress: '',
            backupPath: '/',
            tmpDir: '',
            dumpSetsDatabase: true,
            database: '',
            bootstrap: true,
            pitrCompatible: false,
            backupDataDirBeforeRestore: false,
        };
    }
}
