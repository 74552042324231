import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { useForm } from 'antd/lib/form/Form';
import WizardForm from '@severalnines/bar-frontend-components/build/lib/Navigation/WizardForm';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import useCreateJob from '../../Jobs/useCreateJob';
import ClusterInPlaceUpgradeConfig, {
    clusterInPlaceValidation,
} from './ClusterInPlaceUpgradeConfig';
import ClusterInPlaceUpgradePreview from './ClusterInPlaceUpgradePreview';
import ClusterInPlaceUpgradeAdvanced from './ClusterInPlaceUpgradeAdvanced';

export enum ClusterInPlaceSteps {
    CONFIGURATION = 'Configuration',
    ADVANCED = 'Advanced',
    PREVIEW = 'preview',
}

export default ClusterInPlaceUpgradeFrom;
export type ClusterInPlaceUpgradeFromProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function ClusterInPlaceUpgradeFrom({
    cluster,
    onCancel,
    onSuccess,
    ...rest
}: ClusterInPlaceUpgradeFromProps) {
    const [form] = useForm();

    const { send, loading: upgradeLoading } = useCreateJob({
        title: 'Major upgrade',
        command: CmonJobInstanceCommand.UPGRADE_CLUSTER.toLowerCase() as CmonJobInstanceCommand,
        clusterId: cluster.clusterId,
        onSuccess,
    });

    const handleSubmit = async () => {
        const {
            method,
            temporaryMaster,
            temporaryUtcPort,
            deleteNode,
        } = form.getFieldsValue(true);
        await send(
            {
                upgrade_method: method,
                clusterId: cluster?.clusterId,
                upgrade_to_version: `${+cluster?.version + 1}`,
                upgrade_tmp_port: temporaryMaster,
                ucs_port: temporaryUtcPort,
                delete_old_node: deleteNode,
            },
            {
                job: {
                    title: `Major upgrade to version : ${
                        +cluster?.version + 1
                    }`,
                },
            }
        );
    };

    return (
        <WizardFormConfiguration
            form={form}
            onSubmit={handleSubmit}
            loading={upgradeLoading}
            onCancel={onCancel}
            doneButtonText="Submit"
            initialValues={{
                temporaryMaster: cluster.primaryNode?.port
                    ? +cluster.primaryNode?.port + 100
                    : 5532,
                temporaryUtcPort: cluster.primaryNode?.port
                    ? +cluster.primaryNode?.port + 200
                    : 5632,
            }}
            steps={[
                <WizardForm.Step
                    key={ClusterInPlaceSteps.CONFIGURATION}
                    title="Configuration"
                    subTitle=" "
                    validate={clusterInPlaceValidation()}
                >
                    <ClusterInPlaceUpgradeConfig
                        cluster={cluster}
                        form={form}
                    />
                </WizardForm.Step>,

                <WizardForm.Step
                    key={ClusterInPlaceSteps.ADVANCED}
                    title="Advanced"
                    subTitle=" "
                    validate={['temporaryUtcPort']}
                >
                    <ClusterInPlaceUpgradeAdvanced form={form} />
                </WizardForm.Step>,

                <WizardForm.Step
                    key={ClusterInPlaceSteps.PREVIEW}
                    title="Preview"
                    subTitle=" "
                >
                    <ClusterInPlaceUpgradePreview form={form} />
                </WizardForm.Step>,
            ]}
        />
    );
}
