import { arrayUnique } from '../../common/filtering';
import {
    CcTreeItemAccess,
    CcTreeItemHandledAclPath,
} from '../../services/models/CcTreeItem';
import { AclPermissionItem } from './useTree';

export function getInitialValues(
    noClusterPermissions: AclPermissionItem[],
    clusterPermissions: AclPermissionItem[],
    canManageUsers: boolean
) {
    const unique = arrayUnique(
        clusterPermissions,
        (itemA, itemB) => itemA.level === itemB.level
    );
    return {
        permissions: noClusterPermissions.reduce(
            (acc: { [key: string]: boolean }, curr) => {
                /**
                 * CcTreeItemHandledAclPath.ACL_USER_MANAGER is not used in
                 * backend for checking permissions in user management module.
                 * Logic is based in checking if executor is form admins group
                 * https://severalnines.atlassian.net/browse/CCV2-946
                 */
                if (curr.key === CcTreeItemHandledAclPath.ACL_USER_MANAGER) {
                    acc[curr.key] = canManageUsers;
                } else {
                    acc[curr.key] = [
                        CcTreeItemAccess.FULL_ACCESS,
                        CcTreeItemAccess.EXECUTE,
                    ].includes(curr.level);
                }
                return acc;
            },
            {}
        ),
        permissionLevel: unique.length === 1 ? unique[0].level : 'custom',
        clusterPermissions: clusterPermissions.reduce(
            (acc: { [key: string]: CcTreeItemAccess }, curr) => {
                acc[curr.key] = curr.level;
                return acc;
            },
            {}
        ),
    };
}

export function getClusterPermissions(
    currentPermissions: AclPermissionItem[],
    level: CcTreeItemAccess
) {
    return {
        clusterPermissions: currentPermissions && Object.keys(currentPermissions).reduce(
            (acc: { [key: string]: CcTreeItemAccess }, curr) => {
                acc[curr] = level;
                return acc;
            },
            {}
        ),
    };
}
