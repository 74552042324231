import ActionsMenu from '../../../common/Navigation/ActionsMenu';
import React from 'react';
import CcCluster, {
    CcClusterBase,
    CcClusterType,
} from '../../../services/models/CcCluster';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import { CcNodeType } from '../../../services/models/CcNode';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import BackupToolReconfigureActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolReconfigureActionButton';
import BackupToolUninstallActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolUninstallActionButton';
import BackupSettingsButton from '../Actions/BackupSettingsButton';
import BackupToolPBMSetupActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolPBMSetupActionButton';
import BackupToolPbBackrestSetupActionButton from '../../Backups/BackupTool/ActionButtons/BackupToolPbBackrestSetupActionButton';
import ClusterBackupRestoreActionButton from '../../Backups/Wizard/BackupRestore/ClusterBackupRestoreActionButton';
import ClusterConfigureWalButton from '../Actions/ClusterConfigureWalButton';
import { ReloadIcon, SettingsIcon } from '../../../common/icons/icons';
import {
    DeleteOutlined,
    DeliveredProcedureOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import AppDivider from '../../../common/AppDivider';
import { groupSimilarMenuItemsInSubmenu } from '../../../common/Navigation/actionMenuHelpers';

export default ClusterBackupsMenu;
type ClusterBackupsMenuProps = {
    cluster: CcCluster;
};

function ClusterBackupsMenu({ cluster, ...rest }: ClusterBackupsMenuProps) {
    const restoreBackup = {
        key: 'restore-backup',
        icon: <ReloadIcon />,
        label: <ClusterBackupRestoreActionButton cluster={cluster} />,
    };

    const clusterPBMAgentInstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getDatabaseNodes().length >
            cluster.getNodesByType(CcNodeType.PBM_AGENT).length
            ? {
                  key: 'cluster-pbm-install',
                  icon: <DeliveredProcedureOutlined />,
                  label: <BackupToolPBMSetupActionButton cluster={cluster} />,
              }
            : undefined;

    const clusterPBMAgentReinstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-reinstall',
                  icon: <DeliveredProcedureOutlined />,
                  label: (
                      <BackupToolPBMSetupActionButton
                          cluster={cluster}
                          reinstall={true}
                      />
                  ),
              }
            : undefined;

    const clusterPBMAgentReconfigure =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-reconfigure',
                  icon: <ToolOutlined />,
                  label: (
                      <BackupToolReconfigureActionButton
                          command={CmonJobInstanceCommand.PBMAGENT}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPBMAgentUninstall =
        cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS) &&
        cluster.getNodesByType(CcNodeType.PBM_AGENT).length > 0
            ? {
                  key: 'cluster-pbm-uninstall',
                  icon: <DeleteOutlined />,
                  label: (
                      <BackupToolUninstallActionButton
                          toolType={CcNodeType.PBM_AGENT}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPgBackRestInstall = cluster.isBase(
        CcClusterBase.BASE_POSTGRESQL
    )
        ? {
              key: 'cluster-pgbackrest-install',
              icon: <DeliveredProcedureOutlined />,
              label: (
                  <BackupToolPbBackrestSetupActionButton cluster={cluster} />
              ),
          }
        : undefined;

    const clusterPgBackRestReconfigure =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        cluster.getNodesByType(CcNodeType.PGBACKREST).length > 0
            ? {
                  key: 'cluster-pgbackrest-reconfigure',
                  icon: <ToolOutlined />,
                  label: (
                      <BackupToolReconfigureActionButton
                          command={CmonJobInstanceCommand.PGBACKREST}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterPgBackRestUninstall =
        cluster.isBase(CcClusterBase.BASE_POSTGRESQL) &&
        cluster.getNodesByType(CcNodeType.PGBACKREST).length > 0
            ? {
                  key: 'cluster-pgbackrest-uninstall',
                  icon: <DeleteOutlined />,
                  critical: true,
                  label: (
                      <BackupToolUninstallActionButton
                          toolType={CcNodeType.PGBACKREST}
                          cluster={cluster}
                      />
                  ),
              }
            : undefined;

    const clusterConfigureWalButton = cluster.isBase(
        CcClusterBase.BASE_POSTGRESQL
    )
        ? {
              key: 'cluster-configure-wal',
              icon: <SettingsIcon />,
              label: <ClusterConfigureWalButton cluster={cluster} />,
          }
        : undefined;

    const criticalDivider = (clusterPgBackRestUninstall || clusterPBMAgentUninstall)
        ? {
              type: 'divider',
              label: <AppDivider noMargin={true} />,
          }
        : undefined;

    const pgbackrestGroup = [
        clusterPgBackRestInstall,
        clusterPgBackRestReconfigure,
        // criticalDivider,
        clusterPgBackRestUninstall,
    ].filter(Boolean) as ActionMenuItem[];

    const pgbackrest = groupSimilarMenuItemsInSubmenu(
        {
            key: 'pgbackrest',
            label: 'PgBackRest',
            waitForConfirm: true,
            icon: <SettingsIcon />,
        },
        pgbackrestGroup
    );
    const pbmGroup = [
        clusterPBMAgentInstall,
        clusterPBMAgentReinstall,
        clusterPBMAgentReconfigure,
        // criticalDivider,
        clusterPBMAgentUninstall,
    ].filter(Boolean) as ActionMenuItem[];

    const pbm = groupSimilarMenuItemsInSubmenu(
        {
            key: 'pbm',
            label: 'Percona Backup',
            waitForConfirm: true,
            icon: <SettingsIcon />,
        },
        pbmGroup
    );

    const items: ActionMenuItem[] = [
        restoreBackup,

        pgbackrest,
        pbm,

        // clusterPBMAgentInstall,
        // clusterPBMAgentReinstall,
        // clusterPBMAgentReconfigure,
        // clusterPBMAgentUninstall,



        clusterConfigureWalButton,
        // clusterPgBackRestInstall,
        // clusterPgBackRestReconfigure,
        {
            key: 'backup-settings',
            icon: <SettingsIcon />,
            label: <BackupSettingsButton cluster={cluster} />,
        },
        // criticalDivider,
        // clusterPgBackRestUninstall,
    ].filter(Boolean) as ActionMenuItem[];

    return <ActionsMenu items={items} {...rest} />;
}
