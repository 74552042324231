import React, { useMemo } from 'react';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';

import SSLModalWizard from './SSLModalWizard';
import ButtonWithSwitch from '../../common/General/ButtonWithSwitch';

export default ClusterEnableSSLButton;

export type ClusterEnableSSLButton = {
    cluster?: CcCluster;
    isGalera?: boolean;
};

function ClusterEnableSSLButton({
    cluster,
    isGalera = false,
}: ClusterEnableSSLButton) {
    const message = isGalera ? 'Galera SSL encryption' : 'SSL encryption';

    return (
        <ButtonWithForm
            button={
                <ButtonWithSwitch
                    switchValue={false}
                    button={<ActionButton>{message}</ActionButton>}
                />
            }
            form={<SSLModalWizard galera={isGalera} cluster={cluster} />}
        />
    );
}
