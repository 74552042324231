import React from 'react';
import { CmonJobInstanceCommand } from '../../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../../services/models/CcCluster';
import { ButtonProps } from 'antd/lib/button';
import JobCreateActionButton from '../../../Jobs/JobCreateActionButton';

export default BackupToolReconfigureActionButton;

export type ClusterBackupToolReconfigureButtonProps = ButtonProps & {
    command:
        | CmonJobInstanceCommand.PGBACKREST
        | CmonJobInstanceCommand.PBMAGENT;
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function BackupToolReconfigureActionButton({
    command,
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterBackupToolReconfigureButtonProps) {
    const toolName =
        command === CmonJobInstanceCommand.PGBACKREST
            ? 'PgBackRest'
            : 'Percona Backup';
    const toolClassName = CmonJobInstanceCommand.PGBACKREST
        ? 'CmonPgBackRestHost'
        : 'CmonPBMAgentHost';

    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={command.toLowerCase() as CmonJobInstanceCommand}
            confirmTitle={`Reconfigure ${toolName}?`}
            title={`Reconfigure ${toolName}`}
            onSuccess={onSuccess}
            jobData={{
                action: 'reconfigure',
                nodes: [
                    {
                        class_name: toolClassName,
                        hostname: '*',
                    },
                ],
                clusterId: cluster?.clusterId,
            }}
            {...rest}
        />
    );
}
