import RedisShardedHost, {
    RedisShardedHostProps,
} from '../cmon/models/RedisShardedHost';

export interface CcRedisShardedNodeProps extends RedisShardedHostProps {}

export default class CcRedisShardedNode extends RedisShardedHost {
    constructor(props: CcRedisShardedNodeProps) {
        super(props);
    }

    isReplicationRunning() {
        return this.redis?.sharded?.busLink === 'connected';
    }
}
