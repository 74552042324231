import React from 'react';
import CcBackupSchedule from '../../services/models/CcBackupSchedule';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import BackupScheduleEnableActionButton from './BackupScheduleEnableActionButton';
import BackupScheduleDisableActionButton from './BackupScheduleDisableActionButton';
import BackupScheduleDeleteActionButton from './BackupScheduleDeleteActionButton';
import BackupScheduleEditActionButton from './BackupScheduleEditActionButton';
import { CcJobStatus } from '../../services/models/CcJob';
import {
    DeleteOutlined,
    EditOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons';

export enum BackupScheduleAction {
    ENABLE = 'ENABLE',
    DISABLE = 'DISABLE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

export function getBackupScheduleActionFormatText(action?: string) {
    switch (action) {
        case BackupScheduleAction.ENABLE:
            return 'Enable';
        case BackupScheduleAction.DISABLE:
            return 'Pause';
        case BackupScheduleAction.EDIT:
            return 'Edit';
        case BackupScheduleAction.DELETE:
            return 'Delete';
        default:
            return action || '';
    }
}

export type BackupScheduleActionsMenuProps = ActionsMenuProps & {
    schedule: CcBackupSchedule;
    onActionPerformed?: () => void;
};
export default function BackupScheduleActionsMenu({
    schedule,
    onActionPerformed,
    ...rest
}: BackupScheduleActionsMenuProps) {
    const handleActionSuccess = () => {
        if (onActionPerformed) {
            onActionPerformed();
        }
    };
    return (
        <ActionsMenu
            items={[
                schedule.status === CcJobStatus.PAUSED
                    ? {
                          key: BackupScheduleAction.ENABLE,
                          icon: <PlayCircleOutlined />,
                          label: (
                              <BackupScheduleEnableActionButton
                                  schedule={schedule}
                                  onSuccess={handleActionSuccess}
                              >
                                  {getBackupScheduleActionFormatText(
                                      BackupScheduleAction.ENABLE
                                  )}
                              </BackupScheduleEnableActionButton>
                          ),
                      }
                    : {
                          key: BackupScheduleAction.DISABLE,
                          icon: <PauseCircleOutlined />,
                          label: (
                              <BackupScheduleDisableActionButton
                                  schedule={schedule}
                                  onSuccess={handleActionSuccess}
                              >
                                  {getBackupScheduleActionFormatText(
                                      BackupScheduleAction.DISABLE
                                  )}
                              </BackupScheduleDisableActionButton>
                          ),
                      },
                {
                    key: BackupScheduleAction.EDIT,
                    icon: <EditOutlined />,
                    label: (
                        <BackupScheduleEditActionButton
                            schedule={schedule}
                            onSuccess={handleActionSuccess}
                        >
                            {getBackupScheduleActionFormatText(
                                BackupScheduleAction.EDIT
                            )}
                        </BackupScheduleEditActionButton>
                    ),
                },
                {
                    key: BackupScheduleAction.DELETE,
                    waitForConfirm: true,
                    critical: true,
                    icon: <DeleteOutlined />,
                    label: (
                        <BackupScheduleDeleteActionButton
                            schedule={schedule}
                            onSuccess={handleActionSuccess}
                        >
                            {getBackupScheduleActionFormatText(
                                BackupScheduleAction.DELETE
                            )}
                        </BackupScheduleDeleteActionButton>
                    ),
                },
            ]}
            {...rest}
        />
    );
}
