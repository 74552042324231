const result = {
    authenticated: true,
    cluster_type: 'GALERA',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: [
        '10.4',
        '10.5',
        '10.6',
        '10.7',
        '10.8',
        '10.9',
        '10.10',
        '10.11',
    ],
    request_processed: '2024-01-16T09:12:58.559Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    vendor: 'mariadb',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
