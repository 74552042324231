import React from 'react';
import ClusterFormat from '../Clusters/ClusterFormat';
import { AclPermissionItem } from './useTree';
import { Col, Table } from 'antd';
import PermissionLevelSelect from './PermissionLevelSelect';
import FormItem from '../../common/DataEntry/FormItem';
import { InternalNamePath } from 'rc-field-form/lib/interface';
import TypographyText from '../../common/TypographyText';
import AppRow from '../../common/AppRow';
import { FormInstance } from 'antd/es';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';

export default ClustersPermissionsForm;

export type ClustersPermissionsFormProps = {
    permissions: AclPermissionItem[];
    fieldNamespace?: InternalNamePath;
    form: FormInstance;
};

function ClustersPermissionsForm({
    permissions,
    fieldNamespace = ['clusterPermissions'],
    form,
}: ClustersPermissionsFormProps) {
    const [clustersMap]: [
        AppStateClustersMap
    ] = useSelector(({ clusters }: AppState) => [clusters]);
    const columns = [
        {
            key: 'name',
            title: 'Cluster',
            render: (item: AclPermissionItem) => item.title,
        },
        {
            key: 'more-info',
            title: 'More info',
            render: (item: AclPermissionItem) => (
                <ClusterFormat
                    cluster={
                        item.clusterKey
                            ? clustersMap.get(item.clusterKey)
                            : undefined
                    }
                />
            ),
            width: '40%',
        },
        {
            key: 'access-level',
            title: 'Access level',
            render: (item: AclPermissionItem) => (
                <FormItem
                    name={[...fieldNamespace, item.key]}
                    // initialValue={item.level}
                    noStyle={true}
                >
                    <PermissionLevelSelect
                        noCustom={true}
                        maxLevel={item.level}
                    />
                </FormItem>
            ),
            width: '20%',
        },
    ];
    return (
        <AppRow gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12}>
                <FormItem
                    withLessMarginBottom={true}
                    name="permissionLevel"
                    label="Clusters permission level"
                    extra={
                        <TypographyText muted={true}>
                            {clustersMap.size > 0
                                ? 'Applicable for all clusters'
                                : 'No clusters are available to apply permissions'}
                        </TypographyText>
                    }
                >
                    <PermissionLevelSelect showDescription={true} />
                </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24}>
                <FormItem noStyle={true} shouldUpdate={true}>
                    {() => (
                        <div
                            style={
                                form.getFieldValue('permissionLevel') ===
                                'custom'
                                    ? {}
                                    : { display: 'none' }
                            }
                        >
                            <Table
                                columns={columns}
                                rowKey={(item) => item.key}
                                dataSource={permissions}
                                size="small"
                                pagination={false}
                            ></Table>
                        </div>
                    )}
                </FormItem>
            </Col>
        </AppRow>
    );
}
