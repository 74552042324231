import React, { useMemo } from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import { LdapConfigGroupMappingsItem } from './LdapTypes';
import LdapMapGroupButton from './LdapMapGroupButton';
import LdapGroupMapDeleteButton from './LdapGroupMapDeleteButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default LdapGroupMapActionMenu;

export type LdapGroupMapActionMenuProps = ActionsMenuProps & {
    item: LdapConfigGroupMappingsItem;
};

function LdapGroupMapActionMenu({
    item,
    onActionPerformed,
    ...rest
}: LdapGroupMapActionMenuProps) {
    const handleLdapMapGroupDeleteSuccess = () => {
        onActionPerformed?.();
    };
    const handleLdapMapGroupEditSuccess = () => {
        onActionPerformed?.();
    };
    const items = useMemo(() => {
        return [
            {
                key: 'edit',
                icon: <EditOutlined />,
                label: (
                    <LdapMapGroupButton
                        item={item}
                        onSuccess={handleLdapMapGroupEditSuccess}
                    >
                        Edit
                    </LdapMapGroupButton>
                ),
            },
            {
                key: 'delete',
                waitForConfirm: true,
                critical: true,
                icon: <DeleteOutlined />,
                label: (
                    <LdapGroupMapDeleteButton
                        item={item}
                        onSuccess={handleLdapMapGroupDeleteSuccess}
                    />
                ),
            },
        ];
    }, [item]);
    return <ActionsMenu items={items as ActionMenuItem[]} {...rest} />;
}
