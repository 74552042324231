import React, { useMemo } from 'react';
import { Alert, Col, Form, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster, {
    CcClusterBase,
    CcClusterTechnology,
    CcClusterType,
} from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';
import PrimaryNodeSelectField from '../../../../common/Form/Fields/PrimaryNodeSelectField';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import TopologyNodeDataIpField from '../../../../common/Form/Fields/TopologyNodeDataIpField';
import TopologyNodeRoleSelectField from '../../../../common/Form/Fields/TopologyNodeRoleSelectField';
import SpaceWide from '../../../../common/SpaceWide';
import SshCheckableNodesInput from '../../../../common/DataEntry/SshCheckeableNodesInput';

export default AddNodeHostForm;

export type AddNodeHostFormFields = {
    nodePrimary?: string;
    topology?: TopologyItem[];
    nodeDataIp?: string;
    nodeRole?: string;
};

export type AddNodeHostFormProps = {
    form: FormInstance;
    cluster: CcCluster;
    hasPrimary?: boolean;
    hasDataIp?: boolean;
    isImport?: boolean;
    // @todo get rid of these "has", "is", etc flag props
    isReplication?: boolean;
    validateItem?: (item: TopologyItem) => Promise<TopologyItem> | TopologyItem;
};

function AddNodeHostForm({
    form,
    cluster,
    hasPrimary = true,
    hasDataIp = false,
    isImport = false,
    isReplication = false,
    validateItem,
}: AddNodeHostFormProps) {
    const infoSecondaryMessage = useMemo(() => {
        if (
            !isImport &&
            cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) &&
            isReplication &&
            !cluster.isType([
                CcClusterType.TYPE_REPLICATION,
                CcClusterType.TYPE_MYSQL_CLUSTER,
            ])
        ) {
            return (
                <span>
                    The slave will be setup from a streamed XtraBackup from the
                    master to the slave.{' '}
                    <a
                        href="https://severalnines.com/database-blog/deploying-asynchronous-slave-mysql-galera-cluster"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Please see prerequisites
                    </a>
                </span>
            );
        }

        if (!isImport && cluster.isBase(CcClusterBase.BASE_POSTGRESQL)) {
            return (
                <Space direction="vertical">
                    <span>
                        The replica will be staged with data from the primary.
                        The primary's configuration will be altered to allow the
                        replica to join the primary.
                    </span>
                    <span>
                        The replica server must be reachable by SSH (key-based
                        auth.) from the controller.
                    </span>
                </Space>
            );
        }

        if (isImport && cluster.isType(CcClusterType.TYPE_REPLICATION)) {
            return (
                <span>
                    The node must be up and running and allow the "cmon" user to
                    connect from the controller, with at least SELECT, PROCESS,
                    SUPER, REPLICATION CLIENT, SHOW DATABASES, RELOAD privileges
                    on all databases. For complete management functionality ALL
                    PRIVILEGES WITH GRANT OPTION is needed.
                </span>
            );
        }

        return null;
    }, [cluster.clusterType]);

    return (
        <div className="AddNodeHostForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Add node</h3>
                </Col>
                {hasPrimary && (
                    <Col xs={24} sm={24} md={12}>
                        <PrimaryNodeSelectField
                            selectProps={{ cluster, showOnlineNodes: true }}
                        />
                    </Col>
                )}
                <Col xs={24} sm={24} md={hasPrimary ? 12 : 24}>
                    <Form.Item name="topology" validateTrigger={false}>
                        <SshCheckableNodesInput
                            clusterId={cluster.clusterId}
                            direction={hasPrimary ? 'vertical' : 'horizontal'}
                            validateItem={validateItem}
                            singleNode={true}
                            mutateItem={(item) => {
                                if (
                                    hasDataIp &&
                                    item?.status === StatusFormatStatus.success
                                ) {
                                    const footer = cluster.isType(
                                        CcClusterType.TYPE_ELASTIC
                                    ) ? (
                                        <SpaceWide direction="vertical">
                                            <TopologyNodeDataIpField
                                                item={item}
                                            />
                                            <TopologyNodeRoleSelectField
                                                clusterType={
                                                    cluster.clusterType
                                                }
                                            />
                                        </SpaceWide>
                                    ) : (
                                        <TopologyNodeDataIpField
                                            item={item}
                                            name="nodeDataIp"
                                        />
                                    );
                                    return {
                                        ...item,
                                        footer,
                                        footerExpandable: true,
                                        footerExpanded: true,
                                    };
                                }
                                return item;
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {infoSecondaryMessage && (
                <Row>
                    <Alert
                        message={infoSecondaryMessage}
                        showIcon
                        type="info"
                    />
                </Row>
            )}
        </div>
    );
}

export function getNodeHostValidatingFields(topology: TopologyItem[]) {
    return [
        'nodePrimary',
        () => {
            if (topology?.length < 1 || !topology?.[0].extraData.hostname) {
                throw new Error('Please enter node host');
            }
        },
    ];
}
