import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CcLicenseFeatureType } from '../../services/models/CcLicense';
import useFeatureCheck from '../../components/License/useFeatureCheck';
import { Button } from 'antd';
import AppTooltip from '../Feedback/AppTooltip';

export default ButtonBarrier;
export type ButtonBarrierProps = ButtonProps & {
    children: React.ReactElement<ButtonProps>;
    licenseFeature?: CcLicenseFeatureType;
    buttonProps?: ButtonProps;
    actionButton?: boolean;
    actualButton?: boolean;
    button?: React.ReactElement<ButtonProps>;
};

function ButtonBarrier({
    children,
    licenseFeature,
    buttonProps = {},
    actionButton = false,
    actualButton = false,
    button,
    ...rest
}: ButtonBarrierProps) {
    const { isFeatureEnabled, licenseInfo } = useFeatureCheck();

    const restrictionNote = useMemo(() => {
        if (licenseFeature && !isFeatureEnabled(licenseFeature)) {
            return (
                <span>
                    This feature is disabled due your active subscription limit
                </span>
            );
        } else {
            return null;
        }
    }, [licenseFeature, licenseInfo]);

    const disabledButton = useMemo(() => {
        if (!restrictionNote) {
            return null;
        }
        if (button) {
            return React.cloneElement(button, {
                ...button.props,
                disabled: true,
            });
        }
        if (actualButton) {
            return React.cloneElement(children, {
                ...children.props,
                disabled: true,
            });
        }
        const buttonChildren = findButtonChildren(children);

        return actionButton ? (
            <Button
                disabled={true}
                type="text"
                {...buttonProps}
            >
                {buttonChildren}
            </Button>
        ) : (
            <Button disabled={true} {...buttonProps}>
                {buttonChildren}
            </Button>
        );
    }, [restrictionNote, licenseFeature, actionButton, actionButton, children]);

    return disabledButton ? (
        <AppTooltip title={restrictionNote}>{disabledButton}</AppTooltip>
    ) : (
        children
    );
}

function findButtonChildren(element: React.ReactElement<any>): any {
    if (element.props.children) {
        return element.props.children;
    } else if (element.props?.button) {
        return findButtonChildren(element.props.button);
    }
    return undefined;
}
