import React from 'react';
import { Col, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import SshCredentials from '../FormParts/SshCredentials';
import InstallSoftwareSwitch from '../../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableFirewallField from '../../../../common/Form/Fields/DisableFirewallField';
import DisableSelinuxField from '../../../../common/Form/Fields/DisableSelinuxField';

export default DeploymentSshConfiguration;

export type DeploymentSshConfigurationProps = {
    form: FormInstance;
};

function DeploymentSshConfiguration({ form }: DeploymentSshConfigurationProps) {
    return (
        <div className="DeploymentSshConfiguration">
            <Row gutter={[24, 0]}>
                <SshCredentials form={form} />
                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch
                        name={['sshConfig', 'installSoftware']}
                    />
                </Col>
                <Col span={24}>
                    <h3>Security configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableFirewallField
                        name={['sshConfig', 'disableFirewall']}
                    />
                    <DisableSelinuxField
                        name={['sshConfig', 'disableAppArmor']}
                    />
                </Col>
            </Row>
        </div>
    );
}
