import CmonClustersService from '../../services/cmon/CmonClustersService';
import CcImperativeService from '../../services/CcImperativeService';
import CcDatabaseAccount, {
    CcDatabaseAccountStats,
} from '../../services/models/CcDatabaseAccount';
import { camelCasePropToUnderscore } from '../../common/helpers';
import { useMemo } from 'react';
import useClientListFilter from '../../common/hooks/useClientListFilter';
import useFetch from '../../common/useFetch';

type UseDatabaseUsersProps = {
    clusterId: number;
};
export default function useDatabaseUsers({ clusterId }: UseDatabaseUsersProps) {
    const { data, loading, refresh, error } = useFetch<any>({
        name: 'database-users-list',
        throwError: true,
        fetchFn: async ({ ...rest }, opts) => {
            const { accounts } = await CmonClustersService.getAccounts(
                {
                    ...rest,
                },
                opts
            );
            const { results } = await CcImperativeService.executeScript(
                {
                    filename:
                        '/s9s/mysql/widgets/usermgmt/show_unused_accounts.js',
                    ...rest,
                },
                opts
            );
            const {
                error_msg: errorMsg,
                unused_accounts: unusedAccounts,
            } = results?.exitStatus;

            let unusedAccountsReady = true;
            if (errorMsg) {
                if (errorMsg.startsWith('Server must have been running for')) {
                    unusedAccountsReady = false;
                }
            }

            // create object with key ${user}'@'${host}
            const inactiveMap = unusedAccounts.reduce((acc: any, item: any) => {
                acc[`${item.user}'@'${item.host}`] = item;
                return acc;
            }, {});
            const list = accounts.map((account: CcDatabaseAccount) => {
                let status;
                if (!errorMsg && unusedAccountsReady) {
                    if (
                        inactiveMap[
                            `${account.userName}'@'${account.hostAllow}`
                        ]
                    ) {
                        status = CcDatabaseAccountStats.INACTIVE;
                    } else {
                        status = CcDatabaseAccountStats.ACTIVE;
                    }
                } else {
                    status = CcDatabaseAccountStats.UNKNOWN;
                }
                return new CcDatabaseAccount({
                    ...camelCasePropToUnderscore(account),
                    status,
                });
            });

            return {
                list,
                unusedAccountsReady,
                unusedErrorMsg: errorMsg,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
            await CcImperativeService.cancelRequest(requestId);
        },
    });

    const {
        list: accountsList,
        unusedAccountsReady,
        unusedErrorMsg,
    } = useMemo(() => {
        if (!data) {
            return {
                unusedAccountsReady: false,
            };
        }
        const { list, unusedAccountsReady, unusedErrorMsg } = data;
        return {
            list,
            unusedAccountsReady,
            unusedErrorMsg,
        };
    }, [data]);

    const { list, filter, total } = useClientListFilter({
        dataList: accountsList,
    });

    return {
        list,
        filter,
        total,
        loading,
        unusedAccountsReady,
        unusedErrorMsg,
        refresh,
        error,
    };
}
