import ClusterTopologyConfigurator, {
    ClusterTopologyCard,
} from './ClusterTopologyConfigurator';
import CcNode, {
    CcNodeRole,
    CcNodeType,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import CcRedisShardedNode from '../../../services/models/CcRedisShardedNode';
import TruncateText from '@severalnines/bar-frontend-components/build/lib/Format/TruncateText';
import { Tag, Tooltip } from 'antd';

const flagMap = {
    myself: 'The node you are contacting.',
    master: 'Node is a primary.',
    slave: 'Node is a replica.',
    'fail?':
        'Node is in PFAIL state. Not reachable for the node you are contacting, but still logically reachable (not in FAIL state).',
    fail: 'Node is in FAIL state. It was not reachable for multiple nodes that promoted the PFAIL state to FAIL.',
    handshake: 'Untrusted node, we are handshaking.',
    noaddr: 'No address known for this node.',
    nofailover: 'Replica will not try to failover.',
    noflags: 'No flags at all.',
};
export default class ClusterTopologyRedisShardedConfigurator extends ClusterTopologyConfigurator {
    constructor(clusters: CcCluster[]) {
        super(clusters);
    }

    nodeToItem(node: CcNode, cluster: CcCluster, item: IDiagramItem): any {
        let result = item;
        this.hasDbNodes = true;
        if (
            node.isType(CcNodeType.REDIS_SHARDED) &&
            !node.isRole(CcNodeRole.BVS)
        ) {
            if (node.isRole(CcNodeRole.PRIMARY)) {
                result.from = 'db';
                result.arrow = 'both';
            }

            if (node.isRole(CcNodeRole.REPLICA)) {
                const master = cluster.getPrimaryOf(node);

                if (master) {
                    result.from = master.getKey();
                    result.arrow = 'right';
                    if (!(node as CcRedisShardedNode).isReplicationRunning()) {
                        result.connectionStatus = 'error';
                    }
                }
            }
        } else {
            result = super.nodeToItem(node, cluster, item);
        }
        return result;
    }
    getNodeCoverCardConfig(node: CcNode): ClusterTopologyCard {
        if (node.isType(CcNodeType.REDIS_SHARDED)) {
            const redisNode = node as CcRedisShardedNode;
            return [
                [
                    {
                        label: 'Bus link',
                        value: redisNode.redis?.sharded?.busLink,
                    },
                ],
                [
                    {
                        label: 'Bus port',
                        value: redisNode.auxPort,
                    },
                ],
                [
                    {
                        label: 'Flags',
                        value: redisNode.redis?.sharded?.flags
                            ? redisNode.redis.sharded.flags
                                  .split(',')
                                  .map((f) => (
                                      <Tooltip title={flagMap[f]}>
                                          <Tag>{f}</Tag>
                                      </Tooltip>
                                  ))
                            : undefined,
                    },
                ],
                [
                    {
                        label: 'Node id',
                        value: (
                            <TruncateText
                                text={redisNode.redis?.sharded?.nodeId || '-'}
                                maxLength={30}
                            />
                        ),
                    },
                ],
                ...([CcNodeRole.PRIMARY].includes(redisNode.role as CcNodeRole)
                    ? [
                          [
                              {
                                  label: 'Hash slot info',
                                  value: (
                                      <TruncateText
                                          text={
                                              redisNode.redis?.sharded
                                                  ?.hashSlotInfo
                                          }
                                          maxLength={30}
                                      />
                                  ),
                              },
                          ],
                      ]
                    : []),
                [],
            ];
        }

        return super.getNodeCoverCardConfig(node);
    }
}
