import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import { Select, SelectProps } from 'antd';
import React from 'react';
import { CcBackupMethod } from '../../services/models/CcBackup';
import { versionCompare } from '../../common/helpers';
import { CcNodeRole } from '../../services/models/CcNode';
import SpaceWide from '../../common/SpaceWide';

export const getRedisBackupMethod = () =>
    `${CcBackupMethod.RDB}, ${CcBackupMethod.AOF}`;

export const getBackupMethodsByCluster = (cluster: CcCluster) => {
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_MYSQL_SINGLE:
            if (
                cluster.isVendorMariaDb() &&
                versionCompare(cluster.version || '', '>=10.3')
            ) {
                return [
                    CcBackupMethod.MYSQLDUMP,
                    CcBackupMethod.MARIABACKUP_FULL,
                    CcBackupMethod.MARIABACKUP_INCR,
                    CcBackupMethod.MYSQL_BINLOG,
                ];
            }
            if (
                cluster.isVendorMariaDb() &&
                versionCompare(cluster.version || '', '>=10.1')
            ) {
                return [
                    CcBackupMethod.MYSQLDUMP,
                    CcBackupMethod.XTRABACKUP_FULL,
                    CcBackupMethod.XTRABACKUP_INCR,
                    CcBackupMethod.MARIABACKUP_FULL,
                    CcBackupMethod.MARIABACKUP_INCR,
                    CcBackupMethod.MYSQL_BINLOG,
                ];
            }
            return [
                CcBackupMethod.MYSQLDUMP,
                CcBackupMethod.XTRABACKUP_FULL,
                CcBackupMethod.XTRABACKUP_INCR,
                CcBackupMethod.MYSQL_BINLOG,
            ];
        case CcClusterType.TYPE_MONGODB:
            return [CcBackupMethod.MONGODUMP];
        case CcClusterType.TYPE_MONGODB_SHARDS:
            // CCv1 - src/ccApp/backup/backup.service.js:622
            if (
                cluster.containsNodeRole(CcNodeRole.MONGO_CONFIG_SERVER) &&
                cluster.containsNodeRole(CcNodeRole.MONGO_MONGOS) &&
                versionCompare(cluster.version || '', '>=4.2')
            ) {
                return [CcBackupMethod.MONGODB_PERCONA];
            }
            return [
                CcBackupMethod.MONGODUMP,
                // CcBackupMethod.MONGODB_CONSISTENT, // isn't showing in CCv1
                CcBackupMethod.MONGODB_PERCONA,
            ];
        default:
            return getBackupMethodsByClusterType(cluster.clusterType);
    }
};

export const getBackupMethodsByClusterType = (clusterType: CcClusterType) => {
    switch (clusterType) {
        case CcClusterType.TYPE_MSSQL_SINGLE:
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return [
                CcBackupMethod.MSSQL_FULL,
                CcBackupMethod.MSSQL_DIFF,
                CcBackupMethod.MSSQL_LOG,
            ];
        case CcClusterType.TYPE_REDIS:
        case CcClusterType.TYPE_REDIS_SHARDED:
            return [getRedisBackupMethod()];
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_MYSQL_SINGLE:
            return [
                CcBackupMethod.MYSQLDUMP,
                CcBackupMethod.XTRABACKUP_FULL,
                CcBackupMethod.XTRABACKUP_INCR,
                CcBackupMethod.MARIABACKUP_FULL,
                CcBackupMethod.MARIABACKUP_INCR,
                CcBackupMethod.MYSQL_BINLOG,
            ];
        case CcClusterType.TYPE_GROUP_REPLICATION:
            return [
                CcBackupMethod.MYSQLDUMP,
                CcBackupMethod.XTRABACKUP_FULL,
                CcBackupMethod.XTRABACKUP_INCR,
                CcBackupMethod.MYSQL_BINLOG,
            ];
        case CcClusterType.TYPE_MYSQL_CLUSTER:
            return [CcBackupMethod.NDB];
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return [
                CcBackupMethod.MONGODUMP,
                CcBackupMethod.MONGODB_CONSISTENT,
                CcBackupMethod.MONGODB_PERCONA,
            ];
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return [
                CcBackupMethod.PGDUMP,
                CcBackupMethod.PGBASEBACKUP,
                CcBackupMethod.PGBACKREST_FULL,
                CcBackupMethod.PGBACKREST_DIFF,
                CcBackupMethod.PGBACKREST_INC,
            ];
        case CcClusterType.TYPE_ELASTIC:
            return [CcBackupMethod.ELASTIC_SNAPSHOT];
        default:
            return [];
    }
};

// @todo sync with getBackupMethodText from BackupFormat
export function getBackupMethodName(method: CcBackupMethod | string) {
    switch (method) {
        case CcBackupMethod.MARIABACKUP_FULL:
            return 'mariabackup (full)';
        case CcBackupMethod.MARIABACKUP_INCR:
            return 'mariabackup (incr)';
        case CcBackupMethod.MONGODB_CONSISTENT:
            return 'mongodb consistent';
        case CcBackupMethod.PGDUMP:
            return 'pgdumpall';
        case CcBackupMethod.XTRABACKUP_FULL:
            return 'xtrabackup (full)';
        case CcBackupMethod.XTRABACKUP_INCR:
            return 'xtrabackup (incr)';
        case CcBackupMethod.MSSQL_FULL:
            return 'Full';
        case CcBackupMethod.MSSQL_DIFF:
            return 'Differential';
        case CcBackupMethod.MSSQL_LOG:
            return 'Transaction Log';
        case CcBackupMethod.MYSQL_BINLOG:
            return 'binlog';
        default:
            return method;
    }
}

export type BackupMethodSelectProps = SelectProps<string> & {
    cluster: CcCluster;
    extra?: React.ReactNode;
    filterMethods?: Function;
};
const BackupMethodSelect = ({
    cluster,
    extra,
    filterMethods = () => true,
    ...rest
}: BackupMethodSelectProps) => {
    const methods = getBackupMethodsByCluster(cluster).filter(filterMethods);
    return (
        <SpaceWide direction="vertical" className="BackupMethodSelect">
            <Select
                {...rest}
                disabled={methods?.length === 1}
                allowClear={false}
            >
                {methods.map((o) => (
                    <Select.Option key={o} value={o}>
                        {getBackupMethodName(o)}
                    </Select.Option>
                ))}
            </Select>
            {extra}
        </SpaceWide>
    );
};
export default BackupMethodSelect;
