import React, { useState } from 'react';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import { ButtonProps } from 'antd/lib/button';
import CcSnapshotRepository from '../../services/models/CcSnapshotRepository';
import CmonBackupService from '../../services/cmon/CmonBackupService';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';

export default SnapshotRepositoryDeleteActionButton;
export type SnapshotRepositoryDeleteActionButtonProps = ButtonProps & {
    repository: CcSnapshotRepository;
    clusterId: number;
    onSuccess?: () => void;
};

function SnapshotRepositoryDeleteActionButton({
    children,
    repository,
    clusterId,
    onSuccess,
    ...rest
}: SnapshotRepositoryDeleteActionButtonProps) {
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        try {
            setLoading(true);
            await CmonBackupService.deleteSnapshotRepository({
                cluster_id: clusterId ,
                snapshot_repository: repository.name,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Snapshot repository ${repository.name} removed successfully!`,
                content: '',
            });
            setLoading(false);
            onSuccess?.();
        } catch (e) {
            setLoading(false);
            notifyError({
                type: NotifyType.TOAST,
                content: e.message,
            });
        }
    };
    return (
        <AppConfirmActionButton
            critical={true}
            confirmTitle={
                <span>
                    Are you sure, you want to delete repository "
                    {repository?.name || ''}"?
                </span>
            }
            onConfirm={handleClick}
            loading={loading}
            {...rest}
        >
            {children || 'Delete'}
        </AppConfirmActionButton>
    );
}
