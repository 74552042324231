import React from 'react';
import { Button, Col, Divider, Modal, Row } from 'antd';
import {
    notify,
    notifyAlert,
    notifyConfirm,
    notifyError,
    NotifyModalProps,
    NotifyProps,
    NotifyType,
} from '../components/Notifications/uiNotification';
import {
    NotificationProvider,
    useNotificationContext,
} from '../components/Notifications/NotificationProvider';

export default DebugNotifications;

export type DebugNotificationsProps = {};

const SubComponent = () => {
    const {
        notifyJobCreationSuccess,
        notifyJobCreationError,
    } = useNotificationContext();

    const handleCancel = () => {
        Modal.success({ content: 'Cancel clicked' });
    };
    const handleOk = () => {
        Modal.success({ content: 'Ok clicked' });
    };
    const samples: {
        message: string;
        props: NotifyProps<any>;
        fn: Function;
    }[] = [
        {
            message: 'notify type=modal default',
            props: {},
            fn: notify,
        },
        {
            message: 'notify type=modal large',
            props: { size: 'large' } as NotifyModalProps,
            fn: notify,
        },
        {
            message: 'notify type=toast default',
            props: { type: NotifyType.TOAST },
            fn: notify,
        },
        {
            message: 'notifyAlert default',
            props: {
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyAlert large',
            props: {
                size: 'large',
                title: 'something has happened',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyAlert toast',
            props: {
                type: NotifyType.TOAST,
                title: 'something has happened',
                content: 'All the information will be lost.',
            },
            fn: notifyAlert,
        },
        {
            message: 'notifyError default',
            props: {
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyError large',
            props: {
                size: 'large',
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyError toast',
            props: {
                type: NotifyType.TOAST,
                content: 'All the information will be lost.',
            },
            fn: notifyError,
        },
        {
            message: 'notifyConfirm default',
            props: {
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyConfirm large',
            props: {
                size: 'large',
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyConfirm toast',
            props: {
                size: 'large',
                type: NotifyType.TOAST,
                title: 'Do you really want to close the wizard?',
                content: 'All the information will be lost.',
            },
            fn: notifyConfirm,
        },
        {
            message: 'notifyJobCreationSuccess modal default',
            props: { type: NotifyType.MODAL },
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationSuccess large',
            props: { size: 'large', type: NotifyType.MODAL },
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationSuccess toast',
            props: { type: NotifyType.TOAST },
            fn: notifyJobCreationSuccess,
        },
        {
            message: 'notifyJobCreationError modal default',
            props: { type: NotifyType.MODAL },
            fn: notifyJobCreationError,
        },
        {
            message: 'notifyJobCreationError large',
            props: { size: 'large', type: NotifyType.MODAL },
            fn: notifyJobCreationError,
        },
        {
            message: 'notifyJobCreationError toast',
            props: { type: NotifyType.TOAST },
            fn: notifyJobCreationError,
        },
    ];
    return (
        <div className="DebugNotifications">
            <h1>Job notifications</h1>
            <Row gutter={16}>
                {samples.map((sample, index) => (
                    <Col span={8} key={index}>
                        <Divider
                            plain={true}
                            orientation="left"
                            style={{ marginBottom: '0px', marginTop: '15px' }}
                        >
                            {sample.message}
                        </Divider>
                        <Button
                            onClick={() => {
                                sample.fn({
                                    ...sample.props,
                                    onOk: handleOk,
                                    onCancel: handleCancel,
                                });
                            }}
                            type="primary"
                        >
                            {sample.message}
                        </Button>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

function DebugNotifications({}: DebugNotificationsProps) {
    return (
        <NotificationProvider>
            <SubComponent />
        </NotificationProvider>
    );
}
