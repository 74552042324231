import React from 'react';
import useFetch, { UseFetchProps } from '../../common/useFetch';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CcJob from '../../services/models/CcJob';

export default UseJobRecord;

export type UseJobRecordProps = Omit<UseFetchProps, 'fetchFn' | 'cancelFn'> & {
    jobId?: number;
};

function UseJobRecord({ jobId: jobIdParam }: UseJobRecordProps) {
    const { data, ...restFetch } = useFetch<CcJob>({
        fetchFn: async ({ jobId = jobIdParam, ...params }) => {
            const { job } = await CmonJobsService.getJobInstance({
                job_id: jobId,
                ...params,
            });
            return job;
        },
        cancelFn: async ({ requestId }) => {
            await CmonJobsService.cancelRequest(requestId);
        },
    });
    return { record: data, ...restFetch };
}
