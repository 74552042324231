import { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';

export type CheckBoxItem = {
    isGroup: boolean;
    children?: number[]; // indices of the children in the list
};

/**
 * 0 - intermediate state
 */
type ItemCheckStatus = boolean | 0;

type UseCheckboxGroupProps = {
    items: CheckBoxItem[];
    form: FormInstance;
    // root name of items fields, e.g. 'checks' item names will be ['checks',0], ['checks',1], etc.
    itemName: string;
};
export default function useCheckboxGroup({
    items,
    form,
    itemName,
}: UseCheckboxGroupProps) {
    // console.log('items', items);
    useEffect(() => {
        const checks = form.getFieldValue(itemName);
        const newChecks = items.map((_, i) => checks?.[i] || false);
        form.setFieldsValue({ [itemName]: newChecks });
    }, [items]);

    const toggleGroup = (
        groupIndex: number,
        checkedStatus: ItemCheckStatus,
        checkedList: ItemCheckStatus[]
    ): ItemCheckStatus[] => {
        checkedList[groupIndex] = checkedStatus;
        items[groupIndex].children?.forEach((childIndex) => {
            if (items[childIndex].isGroup) {
                checkedList = toggleGroup(
                    childIndex,
                    checkedStatus,
                    checkedList
                );
            } else {
                checkedList[childIndex] = checkedStatus;
            }
        });
        return checkedList;
    };

    const updateParentGroups = (
        itemIndex: number,
        checkedStatus: ItemCheckStatus,
        checkedList: ItemCheckStatus[]
    ): ItemCheckStatus[] => {
        items.forEach((item, i) => {
            if (item.isGroup && item.children?.includes(itemIndex)) {
                const siblings = item.children;
                const areAllSiblingsSame = siblings.every(
                    (siblingIndex) =>
                        checkedList[siblingIndex] === checkedStatus
                );
                if (areAllSiblingsSame) {
                    checkedList[i] = checkedStatus;
                } else {
                    checkedList[i] = 0; // set to indeterminate
                }
                checkedList = updateParentGroups(
                    i,
                    checkedList[i],
                    checkedList
                );
            }
        });
        return checkedList;
    };

    const toggle = (index: number, checkedStatus: boolean) => {
        const checks = form.getFieldValue(itemName);
        if (index === undefined) {
            return checks;
        }
        let newChecked = (checks && [...checks]) || [];
        if (items[index].isGroup) {
            newChecked = toggleGroup(index, checkedStatus, newChecked);
        } else {
            newChecked[index] = checkedStatus;
            newChecked = updateParentGroups(index, checkedStatus, newChecked);
        }

        // console.log('newChecked', newChecked, index, checkedStatus);
        form.setFieldsValue({ [itemName]: newChecked });

        return newChecked;
    };

    const toggleAll = (checked: boolean) => {
        const newChecks = items.map(() => checked);
        form.setFieldsValue({ [itemName]: newChecks });
    };

    const calcAllStatus: () => ItemCheckStatus = () => {
        const checks = form.getFieldValue(itemName);
        const allChecked = checks?.every((check: boolean) => check);
        const allUnchecked = checks?.every((check: boolean) => !check);
        if (allChecked) {
            return true;
        }
        if (allUnchecked) {
            return false;
        }
        return 0;
    };

    return {
        toggle,
        toggleAll,
        calcCommonState: calcAllStatus,
    };
}
