import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcNode from '../../../services/models/CcNode';
import JobCreateActionButton from '../../Jobs/JobCreateActionButton';

export default NodeStartSlaveButton;

export type NodeStartSlaveButtonProps = ButtonProps & {
    node: CcNode;
    title?: string;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function NodeStartSlaveButton({
    node,
    title,
    children,
    onSuccess,
    ...rest
}: NodeStartSlaveButtonProps) {
    const jobData = useMemo(
        () => ({
            node: { hostname: node.hostname, port: node.port },
        }),
        []
    );
    return (
        <JobCreateActionButton
            clusterId={node.clusterid}
            command={CmonJobInstanceCommand.START_REPLICATION_SLAVE}
            confirmTitle={`${title || 'Start replica'}: ${node.getName()}?`}
            title={title || 'Start replica'}
            jobData={jobData}
            onSuccess={onSuccess}
            extraKey={[node.hostname, node.port]}
            {...rest}
        />
    );
}
