import galera from './galera';
import mongodb1 from './mongodb1';
import mysqlReplication from './mysqlReplication';
import postgesWithLb from './postgresWithLb';
import redis6sentinel from './redis6Sentinel3Nodes';
import mssqlReplication from './mssqlReplication';
import elasticHA3master from './elasticHA3master';
import redisSharded3x3 from './redisSharded3x3';

export const baseClustersList = [
    redisSharded3x3,
    galera,
    mongodb1,
    mysqlReplication,
    postgesWithLb,
    redis6sentinel,
    mssqlReplication,
    elasticHA3master,
];
