import React from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../../common/Navigation/ActionsMenu';
import ActionButton from '@severalnines/bar-frontend-components/build/lib/General/ActionButton';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import NotificationServicesDeleteButton from './NotificationServicesDeleteButton';
import NotificationServicesModalEdit from './NotificationServicesModalEdit';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default NotificationServicesActionMenu;

export type NotificationServicesActionMenuProps = ActionsMenuProps & {
    onSuccess?: () => void;
    record: any;
};

function NotificationServicesActionMenu({
    onSuccess,
    record,
}: NotificationServicesActionMenuProps) {
    return (
        <ActionsMenu
            items={[
                {
                    key: 'edit',
                    icon: <EditOutlined />,
                    label: (
                        <ButtonWithForm
                            button={<ActionButton>Edit</ActionButton>}
                            form={
                                <NotificationServicesModalEdit
                                    onSuccess={onSuccess}
                                    record={record}
                                />
                            }
                        />
                    ),
                },
                {
                    key: 'delete',
                    icon: <DeleteOutlined />,
                    critical: true,
                    waitForConfirm: true,
                    label: (
                        <NotificationServicesDeleteButton
                            onSuccess={onSuccess}
                            record={record}
                        />
                    ),
                },
            ]}
        />
    );
}
