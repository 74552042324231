import {
    CcBackupMethod,
    CcBackupRetentionType,
} from '../../../services/models/CcBackup';
import { getRedisBackupMethod } from '../BackupMethodSelect';
import { MULTI_SELECT_ALL_OPTION_VALUE } from '../../../common/DataEntry/MultiSelect';
import { CcCloudProviderType } from '../../../services/models/CcCloudCredentials';
import { BackupFormFieldsType } from './BackupFormConfigurator';
import { TopologyItem } from '../../../components/Topology/TopologyItem';
import CmonHost from '../../../services/cmon/models/CmonHost';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { CcClusterType } from '../../../services/models/CcCluster';

export default class BackupJobConfig {
    static fieldsToJobData(fields: BackupFormFieldsType) {
        const base = getBackupFormBaseJobData(fields);
        const hostStorage = getBackupFormStorageJobData(fields);
        const compression = getBackupFormCompressionJobData(fields);
        const retention = getBackupFormRetentionJobData(fields);
        const failOver = getBackupFormFailOverJobData(fields);
        const partial = getBackupFormPartialJobData(fields);
        const cloud = getBackupFromCloudJobDataPart(fields);

        switch (fields.method) {
            case CcBackupMethod.MYSQLDUMP:
                return {
                    ...base,
                    ...hostStorage,
                    ...compression,
                    ...partial,
                    ...failOver,
                    ...cloud,
                    wsrep_desync: fields.wsrepDeSync,
                    backup_individual_schemas: fields.splitDumpFiles || false,
                    backup_mysqldump_type: fields.dumpType || undefined,
                    extended_insert: fields.extendedInsert,
                    backup_retention: retention,
                };
            case CcBackupMethod.MARIABACKUP_FULL:
            case CcBackupMethod.MARIABACKUP_INCR:
            case CcBackupMethod.XTRABACKUP_FULL:
            case CcBackupMethod.XTRABACKUP_INCR:
                return {
                    ...base,
                    ...hostStorage,
                    ...compression,
                    ...partial,
                    ...failOver,
                    ...cloud,
                    wsrep_desync: fields.wsrepDeSync,
                    use_qpress: fields.useQPress || false,
                    use_pigz: fields.usePIGZ,
                    xtrabackup_parallellism: fields.xtrabackupParallellism,
                    xtrabackup_backup_locks: fields.xtrabackupBackupLocks,
                    throttle_rate_netbw: fields.throttleRateNet,
                    xtrabackup_lock_ddl_per_table:
                        fields.xtrabackupLockDdlPerTable,
                    backup_retention: retention,
                };
            case CcBackupMethod.NDB:
                return {
                    ...base,
                    ...failOver,
                    ...cloud,
                    cc_storage: !!fields.storageLocation,
                    wsrep_desync: false,
                    port: 3306,
                    include_databases:
                        getPartialBackupItems(fields.partialDatabases).join(
                            ','
                        ) || undefined,
                    backup_retention: retention,
                };
            case CcBackupMethod.MYSQL_BINLOG:
                return { backup_method: fields.method, ...cloud };
            case CcBackupMethod.MONGODB_CONSISTENT:
            case CcBackupMethod.MONGODUMP:
                return {
                    ...base,
                    ...failOver,
                    ...cloud,
                    hostname: '',
                    cc_storage: !!fields.storageLocation,
                    storage_host: fields.storageHost,
                    wsrep_desync: false,
                    backup_retention: retention,
                };
            case CcBackupMethod.PGDUMP:
                return {
                    ...base,
                    ...hostStorage,
                    ...compression,
                    ...failOver,
                    ...cloud,
                    include_databases:
                        getPartialBackupItems(fields.partialDatabases).join(
                            ','
                        ) || undefined,
                    backup_retention: retention,
                };
            case CcBackupMethod.PGBASEBACKUP:
                return {
                    ...base,
                    ...hostStorage,
                    ...compression,
                    ...failOver,
                    ...cloud,
                    backup_retention: retention,
                };
            case CcBackupMethod.PGBACKREST_FULL:
            case CcBackupMethod.PGBACKREST_INC:
            case CcBackupMethod.PGBACKREST_DIFF:
                return {
                    backup_method: fields.method,
                    hostname: fields.host || 'auto',
                    port: fields.port || undefined,
                    encrypt_backup: fields.encryptBackup || undefined,
                    ...compression,
                };
            case CcBackupMethod.MONGODB_PERCONA:
                return {
                    backup_method: fields.method,
                    encrypt_backup: fields.encryptBackup || undefined,
                    backup_retention: retention,
                };
            case getRedisBackupMethod():
                let props = {};
                if (fields.clusterType === CcClusterType.TYPE_REDIS) {
                    props = {
                        ...hostStorage,
                        ...cloud,
                    };
                } else if (fields.clusterType === CcClusterType.TYPE_REDIS_SHARDED) {
                    props = {
                        storage_host: fields.storageHost,
                    };
                }

                return {
                    ...base,
                    backup_method: undefined,
                    ...compression,
                    ...props,
                    backup_retention: retention,
                };
            case CcBackupMethod.MSSQL_FULL:
            case CcBackupMethod.MSSQL_DIFF:
            case CcBackupMethod.MSSQL_LOG:
                return {
                    ...base,
                    ...hostStorage,
                    ...compression,
                    ...partial,
                    ...cloud,
                    backup_retention: retention,
                    backup_system_db: fields.backupSystemDatabases,
                };
            case CcBackupMethod.ELASTIC_SNAPSHOT:
                return {
                    snapshot_repository: fields.elasticRepository,
                    backup_retention: retention,
                };
            default:
                return {};
        }
    }

    static jobDataToFields(jobData: any): BackupFormFieldsType {
        const bucket = jobData.upload_backup_data_to_cloud_storage?.bucket;

        return {
            // configuration
            method: jobData.backup_method,
            host: jobData.hostname === 'auto' ? undefined : jobData.hostname,
            port: jobData.port,
            compression: jobData.compression,
            compressionLevel: jobData.compression_level
                ? jobData.compression_level
                : undefined,
            customRetention: jobData.backup_retention
                ? jobData.backup_retention
                : undefined,
            enableRetention:
                jobData.backup_retention !== CcBackupRetentionType.FOREVER,
            dumpType: jobData.backup_mysqldump_type,
            elasticRepository: jobData.snapshot_repository,
            cloudUpload: !!jobData.upload_backup_data_to_cloud_storage,

            // storage
            storageLocation: jobData.storageHost
                ? undefined
                : jobData.cc_storage
                  ? 1
                  : 0,
            storageDirectory: jobData.backupdir,
            storageSubdirectory: jobData.backupsubdir,
            storageHost: jobData.storageHost || undefined,

            // advanced
            encryptBackup: jobData.encrypt_backup,
            wsrepDeSync: jobData.wsrep_desync,
            splitDumpFiles: jobData.backup_individual_schemas,
            extendedInsert: jobData.extended_insert,
            useQPress: jobData.use_qpress,
            usePIGZ: jobData.use_pigz,
            xtrabackupParallellism: jobData.xtrabackup_parallellism,
            xtrabackupBackupLocks: jobData.xtrabackup_backup_locks,
            throttleRateNet: jobData.throttle_rate_netbw,
            xtrabackupLockDdlPerTable: jobData.xtrabackup_lock_ddl_per_table,
            failoverBackup: jobData.backup_failover,
            failOverHost: jobData.backup_failover_host,
            verifyBackup: !!jobData.verify_backup?.server_address,
            enablePartial: !!jobData.include_databases,
            partialDatabases: jobData.include_databases?.split(',') || [],
            partialTables:
                jobData.include_tables?.split(',') ||
                jobData.exclude_tables?.split(',') ||
                [],
            includeTables: !!jobData.include_tables,
            excludeTables: !!jobData.exclude_tables,

            // verification
            verificationStartIn: +(jobData.verify_backup_delay || 0) / 3600,
            verificationDisableFirewall:
                jobData.verify_backup?.disable_firewall,
            verificationDisableSELinux: jobData.verify_backup?.disable_selinux,
            verificationInstallSoftware:
                jobData.verify_backup?.install_software,
            verificationHost: jobData.verify_backup?.server_address && [
                new TopologyItem({
                    key: 'primary0',
                    title: jobData.verify_backup?.server_address,
                    description: 'Node',
                    extraData: new CmonHost({
                        hostname: jobData.verify_backup?.server_address,
                    }),
                    status: StatusFormatStatus.success,
                }),
            ],
            verificationShutdownAfter:
                jobData.verify_backup?.terminate_db_server,

            // cloud
            cloudProvider:
                jobData.upload_backup_data_to_cloud_storage
                    ?.cloud_storage_provider,
            cloudCredentials:
                jobData.upload_backup_data_to_cloud_storage
                    ?.cloud_storage_credentials_id,
            cloudCustomRetention:
                jobData.upload_backup_data_to_cloud_storage?.backup_retention,
            deleteAfterUpload:
                jobData.upload_backup_data_to_cloud_storage
                    ?.delete_after_upload,
            cloudBucket: bucket?.split('/')[0],
            cloudLocationPath:
                bucket?.indexOf('/') === -1
                    ? ''
                    : bucket?.substr(bucket?.indexOf('/') + 1),
        };
    }
}

export function getBackupFormBaseJobData(fields: BackupFormFieldsType) {
    const base: any = {
        backup_method: fields.method,
        backupdir: fields.storageDirectory,
        backupsubdir: fields?.storageSubdirectory,
        encrypt_backup: fields.encryptBackup || undefined,
    };
    if (fields.verifyBackup) {
        return {
            ...base,
            verify_backup_delay: +(fields.verificationStartIn || 0) * 3600,
            verify_backup: {
                disable_firewall: fields.verificationDisableFirewall,
                disable_selinux: fields.verificationDisableSELinux,
                install_software: fields.verificationInstallSoftware,
                server_address: fields.verificationHost?.[0].extraData.hostname,
                terminate_db_server: fields.verificationShutdownAfter,
            },
        };
    }
    return base;
}

export function getBackupFormStorageJobData(fields: BackupFormFieldsType) {
    return {
        hostname: fields.host || 'auto',
        port: fields.port || undefined,
        cc_storage: !!fields.storageLocation,
    };
}

export function getBackupFormCompressionJobData(fields: BackupFormFieldsType) {
    return {
        compression: fields.compression,
        compression_level:
            (fields.compression && fields.compressionLevel) || -1,
    };
}

export function getBackupFormRetentionJobData(fields: BackupFormFieldsType) {
    return fields.enableRetention
        ? fields.customRetention || CcBackupRetentionType.DEFAULT
        : CcBackupRetentionType.FOREVER;
}

export function getBackupFormFailOverJobData(fields: BackupFormFieldsType) {
    return fields.failoverBackup !== undefined
        ? {
              backup_failover: fields.failoverBackup,
              ...(fields.failOverHost
                  ? { backup_failover_host: fields.failOverHost }
                  : {}),
          }
        : {};
}

export function getBackupFormPartialJobData(fields: BackupFormFieldsType) {
    if (fields.enablePartial) {
        const databases = getPartialBackupItems(fields.partialDatabases);
        let tables;
        if (databases.length > 0 && fields.partialTables?.length) {
            tables = getPartialBackupItems(fields.partialTables);
        }

        let includeTables;
        if (fields.includeTables) {
            includeTables = tables
                ?.map((table) => table.match(/[^.]+$/)[0])
                .join(',');
        } else if (fields.excludeTables && tables?.length) {
            const selectedTables = tables?.map(
                (table) => table.match(/[^.]+$/)[0]
            );
            includeTables = fields.tableList
                .filter((table) => !selectedTables.includes(table))
                .join(',');
        }

        return {
            include_databases: databases.join(',') || undefined,
            include_tables: includeTables,
        };
    }
    return {};
}

function getPartialBackupItems(items?: string[]) {
    return (
        items?.filter((item) => item !== MULTI_SELECT_ALL_OPTION_VALUE) || []
    );
}

export function getBackupFromCloudJobDataPart(fields: BackupFormFieldsType) {
    if (fields.cloudUpload) {
        return {
            upload_backup_data_to_cloud_storage:
                getBackupFormCloudJobData(fields),
        };
    }
    return {};
}

export function getBackupFormCloudJobData(fields: BackupFormFieldsType) {
    return {
        cloud_storage_provider: fields.cloudProvider,
        cloud_storage_service: getCloudProviderService(
            fields.cloudProvider as CcCloudProviderType
        ),
        cloud_storage_credentials_id: fields.cloudCredentials,
        bucket: getCloudProviderBucketLocation(fields),
        backup_retention: fields.cloudEnableRetention
            ? fields.cloudCustomRetention || CcBackupRetentionType.DEFAULT
            : CcBackupRetentionType.FOREVER,
        delete_after_upload: fields.deleteAfterUpload,
    };
}

function getCloudProviderBucketLocation({
    cloudBucket,
    cloudLocationPath,
}: BackupFormFieldsType) {
    return `${cloudBucket}${
        !cloudLocationPath || cloudLocationPath?.substr(0, 1) === '/' ? '' : '/'
    }${cloudLocationPath || ''}`;
}

function getCloudProviderService(provider?: CcCloudProviderType) {
    switch (provider) {
        case CcCloudProviderType.AWS:
            return 's3';
        case CcCloudProviderType.GOOGLE:
            return 'gcs';
        default:
            return 'unknown';
    }
}

export function getBackupFormRestoreJobData(fields: BackupFormFieldsType) {
    return {
        backup_method: fields.method,
        backup_path: fields.backupPath,
        pitr_compatible: fields.pitrCompatible,
        restore_tmpdir: fields.tmpDir,
        server_address: fields.serverAddress,
        source_address: fields.sourceAddress,
        database: fields.database,
        backup_datadir_before_restore: fields.backupDataDirBeforeRestore,
        bootstrap: fields.bootstrap,
    };
}
