import { Button, Divider } from 'antd';
import React from 'react';
import CcCluster, { CcClusterType } from '../services/models/CcCluster';
import ButtonWithForm from '../common/General/ButtonWithForm';
import BackupSettingsModalForm from '../components/Backups/BackupSettingsModalForm';
import getAllClusterInfo from './dataMocks/rpc/clusters/getAllClusterInfo1';
import mysqlReplication from './dataMocks/clusters/mysqlReplication';
import postgresSingleNode from './dataMocks/clusters/postgresSingleNode';

export default DebugBackupSettingsButton;

function DebugBackupSettingsButton() {
    const clusters = [mysqlReplication, postgresSingleNode].map(
        (props) => new CcCluster(props as any)
    );
    return (
        <div className="DebugBackupSettingsButton">
            <h1>BackupSettingsButton</h1>
            {clusters.map((cluster, index) => (
                <div key={index}>
                    <Divider
                        plain={true}
                        orientation="left"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        clusterType={cluster.clusterType}
                    </Divider>
                    <ButtonWithForm
                        button={<Button>Backup Settings</Button>}
                        form={
                            <BackupSettingsModalForm
                                cluster={cluster as CcCluster}
                            />
                        }
                    />
                </div>
            ))}
        </div>
    );
}
