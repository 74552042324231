import React from 'react';
import FormCheckbox, { FormCheckboxProps } from '../../DataEntry/FormCheckbox';

type DisableFirewallFieldProps = FormCheckboxProps & {};

function DisableFirewallField({ name, ...rest }: DisableFirewallFieldProps) {
    return (
        <FormCheckbox
            name={name ? name : 'disableFirewall'}
            label="Disable firewall"
            {...rest}
        />
    );
}

export default DisableFirewallField;
