import React, { Fragment, useCallback, useEffect } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import ContentWithLayout, { ContentWithLayoutProps } from './ContentWithLayout';
import { BreadcrumbProvider } from './common/BreadcrumbContext';
import { BreadcrumbConfig } from './common/useBreadcrumb';
import useCurrentUser from './components/User/useCurrentUser';
import LoadingPage from './common/LoadingPage';
import Error403 from './common/Error403';
import { AppConfig } from './AppConfig';

export default AppRoute;

export const AUTH_PATHNAMES = [
    '/dashboard',
    '/clusters',
    '/clusters/:clusterId',
    '/clusters/:clusterId/:page',
    '/clusters/:clusterId/nodes/:section?',
    '/clusters/:clusterId/backups/:listType',
    '/clusters/:clusterId/settings/:settingsPage/:configPage?',
    '/clusters/:clusterId/manage/:managePage',
    '/clusters/:clusterId/performance/:section/:subSection?',
    '/clusters/:clusterId/logs/:section',
    '/nodes',
    '/backup/:listType',
    '/activity-center/:activityType?',
    '/settings/profile',
    '/settings/:page',
    '/user-management/ldap',
    '/user-management/:section',
    '/debugComponents/',
    '/debugComponents/:componentName',
    '/logout',
    '/operational-reports/:page',
];
export const NO_AUTH_PATHNAMES = [
    '/login',
    '/registration',
    '/agreement',
    '/reset-password',
];

type AppRouteProps = {
    withoutLayout?: boolean;
    transparentContent?: boolean;
    contentSize?: any[];
    breadcrumb?: BreadcrumbConfig;
    layoutTopExtra?: React.ReactNode;
    children?: React.ReactNode;
    layout?: ContentWithLayoutProps['layout'];
};

function AppRoute({
    withoutLayout,
    transparentContent,
    contentSize,
    breadcrumb,
    layoutTopExtra,
    layout,
    children,
    ...rest
}: AppRouteProps) {
    const {
        record: loggedInUser,
        canManageConfiguration,
        canManageUsers,
        canManageLdapSettings,
        refresh: loadUser,
    } = useCurrentUser();

    const location = useLocation();
    const [match] =
        matchRoutes(
            [...AUTH_PATHNAMES, ...NO_AUTH_PATHNAMES].map((p) => ({ path: p })),
            location
        ) || [];
    const path = match?.route?.path;

    useEffect(() => {
        if (!loggedInUser && AUTH_PATHNAMES.includes(path as string)) {
            (async () => {
                try {
                    const user = await loadUser();
                    if (user.userName === AppConfig.INITIAL_USER) {
                        window.location.assign('/registration');
                    }
                } catch (err) {
                    window.location.assign(
                        `/login?redirectTo=${encodeURI(window.location.href)}`
                    );
                }
            })();
        }
    }, [loggedInUser, path]);

    const canAccessPath = useCallback(
        (path) => {
            //@todo: think about having a configuration item for the routes
            switch (path) {
                case '/settings/:page':
                    return canManageConfiguration();
                case '/user-management/ldap':
                    return canManageLdapSettings();
                case '/user-management/:section':
                    return canManageUsers();
                default:
                    return true;
            }
        },
        [canManageConfiguration, canManageUsers, canManageLdapSettings]
    );

    const checkAuthorizedContent = (content: React.ReactNode) => {
        if (NO_AUTH_PATHNAMES.includes(path as string)) {
            // pages with no authorization needed
            return content;
        }
        if (loggedInUser) {
            if (!canAccessPath(path)) {
                return <Error403 />;
            }
            return content;
        } else {
            // Using spinning = false, otherwise this loading page, which is
            // replaced by the one in ContentWithLayout, will restart the animation
            // and looks weird.
            return <LoadingPage spinning={false} />;
        }
    };

    /**
     * Renders the route content wrapping with default layout
     * @returns {*}
     */
    const renderWithLayout = () => {
        const content = (
            <ContentWithLayout
                transparentContent={transparentContent}
                contentSize={contentSize}
                breadcrumb={breadcrumb}
                layoutTopExtra={layoutTopExtra}
                layout={layout}
            >
                {children}
            </ContentWithLayout>
        );
        return checkAuthorizedContent(
            <BreadcrumbProvider>{content}</BreadcrumbProvider>
        );
    };

    function renderWithoutLayout() {
        return checkAuthorizedContent(children);
    }

    return (
        <Fragment>
            {withoutLayout ? renderWithoutLayout() : renderWithLayout()}
        </Fragment>
    );
}
