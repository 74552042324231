import React, { useMemo } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton from '../Jobs/JobCreateActionButton';
import CcCluster from '../../services/models/CcCluster';

export default UninstallAllCmonAgentsButton;

export type UninstallAllCmonAgentsButtonProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function UninstallAllCmonAgentsButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: UninstallAllCmonAgentsButtonProps) {
    const jobData = useMemo(() => ({}), []);
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={CmonJobInstanceCommand.UNINSTALL_CMONAGENT}
            confirmTitle={`Uninstall all monitoring agents?`}
            title={`Uninstall all monitoring agents`}
            jobData={jobData}
            onSuccess={onSuccess}
            {...rest}
        />
    );
}
