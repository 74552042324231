import React from 'react';
import AppPageWrapper from '../common/Layout/AppPageWrapper';
import BackupsList from '../components/Backups/BackupsList';
import useServiceMock from './serviceMock/useServiceMock';
import { ServiceMethod } from './serviceMock/ServiceMockContext';

export default DebugBackupList;
export type DebugBackupListProps = {};

function DebugBackupList({ ...rest }: DebugBackupListProps) {
    useServiceMock({
        mockId: 'backup-list',
        methods: [ServiceMethod.GET_BACKUPS],
    });
    return (
        <AppPageWrapper>
            <BackupsList />
        </AppPageWrapper>
    );
}
