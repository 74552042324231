import { CcClusterState } from './CcCluster';
import CcStatsBase, { CcStatsBaseAttentionLevel } from './CcStatsBase';
import CcAlarmStats from './CcAlarmStats';
import CcStatsOverviewInterface from './CcStatsOverviewInterface';

export type ClusterStatesProps = {
    [key in CcClusterState]: number;
};

export type CcClusterStatsProps = {
    statsCount: ClusterStatesProps;
    maintenanceCount?: number;
    totalClusters: number;
    alarmStatistics: CcAlarmStats;
};

export default class CcClusterStats
    extends CcStatsBase
    implements CcStatsOverviewInterface
{
    public readonly statusCount: { [key in CcClusterState]: number };
    public readonly total: number;
    public readonly alarmStatistics: CcAlarmStats;
    public readonly maintenanceCount: number;

    constructor(props: CcClusterStatsProps) {
        super({
            statsCount: props.statsCount,
            attentionErrorStats: [CcClusterState.CLUSTER_FAILURE],
            attentionWarnStats: [
                CcClusterState.CLUSTER_UNKNOWN,
                CcClusterState.CLUSTER_DEGRADED,
                CcClusterState.CLUSTER_MGMD_NO_CONTACT,
                CcClusterState.CLUSTER_NOT_STARTED,
                CcClusterState.CLUSTER_SHUTTING_DOWN,
                CcClusterState.CLUSTER_RECOVERING,
                CcClusterState.CLUSTER_STOPPED,
            ],
        });
        this.statusCount = props.statsCount;
        this.total = props.totalClusters;
        this.alarmStatistics = props.alarmStatistics;
        this.maintenanceCount = props.maintenanceCount || 0;
    }

    public getFirstAttentionStat(
        level?: CcStatsBaseAttentionLevel
    ): [CcClusterState, number] {
        return super.getFirstAttentionStat(level) as [CcClusterState, number];
    }

    public getAttentionStatsValues(
        level?: CcStatsBaseAttentionLevel
    ): CcClusterState[] {
        return super.getAttentionStatsValues(level) as CcClusterState[];
    }

    public getSatesCountPairs(): [string, number][] {
        return (this.statsCount && Object.entries(this.statsCount)) || [];
    }

    getMaintenance(): number {
        return this.maintenanceCount;
    }
}
