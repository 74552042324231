import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Form, Input, Row, Space } from 'antd';
import FormFooter from '../../../../common/FormFooter';
import ModalDefaultForm from '../../../../common/ModalDefaultForm';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../../../Notifications/uiNotification';
import AppSelect from '../../../../common/DataEntry/AppSelect';
import InputNumberWide from '../../../../common/DataEntry/InputNumberWide';
import CcCluster from '../../../../services/models/CcCluster';
import CcPostgreSqlNode from '../../../../services/models/CcPostgreSqlNode';
import { CcNodeType } from '../../../../services/models/CcNode';
import { getNodeHostWithDesc } from '../../NodeFormat';
import useFetch from '../../../../common/useFetch';
import CmonClustersService from '../../../../services/cmon/CmonClustersService';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import CmonConfigService from '../../../../services/cmon/CmonConfigService';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
    createPool,
    initialValue,
    parseConfigPools,
} from './pgBouncerPoolHelper';

export default PgBouncerPoolModal;

export type PgBouncerPoolModalProps = {
    cluster: CcCluster;
    node: CcPostgreSqlNode;
    onSuccess?: () => void;
    onCancel?: () => void;
    onError?: (err: Error) => void;
    pool?: any;
    edit?: boolean;
    title: string;
};
export type PgBouncerPoolFormValues = {
    hostname: string[];
    poolName: string;
    username: string;
    poolMode: string;
    poolSize: string;
    maxConnections: string;
};

function PgBouncerPoolModal({
    onSuccess,
    onCancel,
    onError,
    cluster,
    node,
    edit,
    pool,
    title,
}: PgBouncerPoolModalProps) {
    const [form] = Form.useForm<PgBouncerPoolFormValues>();
    const [loading, setLoading] = useState<boolean>(false);
    const [editAll, setEditAll] = useState<boolean>(true);
    const [accounts, setAccounts] = useState<
        {
            label: string;
            value: string;
        }[]
    >([]);

    const getHostNames = useCallback(
        (): DefaultOptionType[] =>
            cluster?.nodes
                .filter((node) => node.getType() === CcNodeType.PGBOUNCER)
                .map((node) => ({
                    value: `${node.hostname} + ${node.port}`,
                    label: getNodeHostWithDesc(node),
                })),
        []
    );

    const hostNames: DefaultOptionType[] = useMemo(() => getHostNames(), []);

    const { data, refresh: refreshList } = useFetch({
        name: 'getAccounts',
        fetchFn: async (params, opts) => {
            const response = await CmonClustersService.listAccounts(
                {
                    ...params,
                    cluster_id: cluster?.clusterId,
                },
                opts
            );
            return response;
        },
        cancelFn: async ({ requestId }) => {
            await CmonClustersService.cancelRequest(requestId);
        },
    });

    useEffect(() => {
        (async () => {
            await refreshList({});
        })();
    }, []);

    useEffect(() => {
        setAccounts(
            data?.queryResults[0]?.accounts
                ?.filter((user: any) => !user.systemUser)
                ?.map((acc: any) => {
                    return {
                        label: acc.userName,
                        value: acc.userName,
                    };
                })
        );
    }, [data]);
    const handleSubmit = async (values: PgBouncerPoolFormValues) => {
        try {
            setLoading(true);
            if (edit) {
                if (editAll) {
                    // edit all pools
                    await Promise.all(
                        hostNames.map(async (host) => {
                            const [hostName, port] = host.value.split('+');
                            const data = await CmonConfigService.getConfig({
                                cluster_id: cluster?.clusterId,
                                hostName: hostName.trim(),
                                port: +port.trim(),
                            });
                            const response = parseConfigPools(data.config);
                            const foundedPool = response.find(
                                (res: any) => res.database === pool.database
                            );
                            if (foundedPool) {
                                await CmonConfigService.setConfig({
                                    clusterid: cluster?.clusterId,
                                    hostName: hostName.trim(),
                                    port: port,
                                    configuration: [
                                        {
                                            group: 'databases',
                                            name: foundedPool.database,
                                            value: `${
                                                values.poolMode !== 'default'
                                                    ? 'pool_mode=' +
                                                      values.poolMode
                                                    : ''
                                            } ${
                                                values.poolSize !== null
                                                    ? 'pool_size=' +
                                                      values.poolSize
                                                    : ''
                                            } ${
                                                values.maxConnections
                                                    ? 'max_db_connections=' +
                                                      values.maxConnections
                                                    : 'max_db_connections=0'
                                            } user=${values.username}`,
                                        },
                                    ],
                                });
                            }
                        })
                    );
                } else {
                    // edit 1 pool
                    await CmonConfigService.setConfig({
                        clusterid: cluster?.clusterId,
                        hostName: node?.hostname,
                        port: node?.port,
                        configuration: [
                            {
                                group: 'databases',
                                name: pool.database,
                                value: `${
                                    values.poolMode !== 'default'
                                        ? 'pool_mode=' + values.poolMode
                                        : ''
                                } ${
                                    values.poolSize !== null
                                        ? 'pool_size=' + values.poolSize
                                        : ''
                                } ${
                                    values.maxConnections
                                        ? 'max_db_connections=' +
                                          values.maxConnections
                                        : 'max_db_connections=0'
                                } user=${values.username}`,
                            },
                        ],
                    });
                }
            } else {
                // create pools
                await Promise.all(createPool(values, cluster));
            }
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: edit ? (
                    <span>Pool edited successfully</span>
                ) : (
                    <span>Pool created successfully</span>
                ),
            });
            setLoading(false);
            await onSuccess?.();
        } catch (error: any) {
            setLoading(false);
            notifyError({
                content: error.message,
            });
            onError?.(error);
        }
    };
    const editAllHandler = (e: CheckboxChangeEvent) => {
        setEditAll(e.target.checked);
    };
    return (
        <ModalDefaultForm
            title={title}
            form={form}
            footer={[]}
            onCancel={onCancel}
            width={900}
            defaultVisible={true}
        >
            <Form
                className="PgBouncerPoolModal"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={initialValue(edit, pool)}
            >
                <Row gutter={[24, 0]}>
                    {!edit && (
                        <Col span={12}>
                            <Form.Item
                                name="hostname"
                                label={
                                    <Space>
                                        PgBouncer host name
                                        <InfoIcon
                                            info={
                                                'Select node hosts to create connection pools on.'
                                            }
                                        />
                                    </Space>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select PgBouncer host name',
                                    },
                                ]}
                            >
                                <AppSelect
                                    mode="multiple"
                                    allowClear
                                    placeholder="Please PgBouncer host name."
                                    options={hostNames}
                                    placement="topLeft"
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {!edit && (
                        <Col span={12}>
                            <Form.Item
                                name="poolName"
                                label={
                                    <Space>
                                        Pool Name
                                        <InfoIcon
                                            info={
                                                'Pool and Database name must the same.'
                                            }
                                        />
                                    </Space>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter pool name.',
                                    },
                                ]}
                            >
                                <Input placeholder="Click here to enter pool name."></Input>
                            </Form.Item>
                        </Col>
                    )}

                    <Col span={12}>
                        <Form.Item shouldUpdate={() => (data ? true : false)}>
                            {() => (
                                <Form.Item
                                    name="username"
                                    label={
                                        <Space>
                                            Username
                                            <InfoIcon
                                                info={
                                                    'Enter an existing DB User or create a new user to use at Manage->DB Users'
                                                }
                                            />
                                        </Space>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter Username.',
                                        },
                                    ]}
                                >
                                    <AppSelect
                                        placeholder={'Enter Username.'}
                                        options={accounts}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="poolMode"
                            label={
                                <Space>
                                    Pool Mode
                                    <InfoIcon
                                        info={
                                            <dl>
                                                <dt>session</dt>{' '}
                                                <dd>
                                                    Server is released back to
                                                    pool after client
                                                    disconnects.
                                                </dd>
                                                <dt>transaction</dt>{' '}
                                                <dd>
                                                    Server is released back to
                                                    pool after transaction
                                                    finishes.
                                                </dd>
                                                <dt>statement</dt>{' '}
                                                <dd>
                                                    Server is released back to
                                                    pool after query finishes.
                                                    Transactions spanning
                                                    multiple statements are
                                                    disallowed in this mode.
                                                </dd>
                                            </dl>
                                        }
                                    />
                                </Space>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Pool Mode.',
                                },
                            ]}
                        >
                            <AppSelect
                                placeholder={'Enter Pool Mode.'}
                                options={[
                                    {
                                        label: 'default',
                                        value: 'default',
                                    },
                                    {
                                        label: 'session',
                                        value: 'session',
                                    },
                                    {
                                        label: 'transaction',
                                        value: 'transaction',
                                    },
                                    {
                                        label: 'statement',
                                        value: 'statement',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="poolSize"
                            label={
                                <Space>
                                    Pool Size
                                    <InfoIcon
                                        info={
                                            'Set the maximum size of pools for this database. Leave this field empty to use the default pool size.'
                                        }
                                    />
                                </Space>
                            }
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Enter pool size"
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'maxConnections'}
                            label={
                                <Space>
                                    Max connection
                                    <InfoIcon
                                        info={
                                            'Configure a database-wide maximum (i.e. all pools within the database will not have more than this many server connections).'
                                        }
                                    />
                                </Space>
                            }
                        >
                            <InputNumberWide
                                min={0}
                                placeholder="Enter max connections  | default: 0 (unlimited)"
                            ></InputNumberWide>
                        </Form.Item>
                    </Col>
                </Row>

                <FormFooter
                    loading={loading}
                    children={
                        edit && (
                            <Checkbox
                                defaultChecked
                                onChange={editAllHandler}
                                value={editAll}
                            >
                                Apply changes to all nodes
                            </Checkbox>
                        )
                    }
                    extraLeft
                    showCancelButton
                    onCancel={onCancel}
                    submitButtonText={edit ? 'Edit' : 'Add'}
                    showSubmitButton
                />
            </Form>
        </ModalDefaultForm>
    );
}
