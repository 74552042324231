import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react';
import useBackups from './useBackups';
import BackupsTable, { BackupsTableProps } from './BackupsTable';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';

export default forwardRef(BackupsList);

export interface BackupListApi {
    refresh: () => Promise<void>;
}

export type BackupsListProps = {
    clusterId?: number;
    parentId?: number;
    tableProps?: BackupsTableProps;
    defaultPageSize?: number;
    excludeColumns?: string[];
};

function BackupsList(
    {
        clusterId,
        parentId,
        tableProps,
        defaultPageSize = 20,
        excludeColumns = [],
    }: BackupsListProps,
    ref: any
) {
    const { filterParams, handleTableChange, resetFilters } = useTableFilter({
        params: {
            cluster: ArrayParam,
        },
        defaultParams: {
            sort: 'id',
            order: 'descend',
        },
        useUrl: false,
    });
    const clusterFilter = useMemo(() => {
        return clusterId
            ? {
                  cluster_id: clusterId,
              }
            : {};
    }, [clusterId]);
    const parentFilter = useMemo(() => {
        return parentId
            ? {
                  parent_id: parentId,
              }
            : { parent_id: null };
    }, [parentId]);

    useImperativeHandle(
        ref,
        (): BackupListApi => ({
            async refresh() {
                // @todo test resetting filters
                resetFilters();
                await refreshBackups({});
            },
        })
    );
    const {
        list: backups,
        loading: loadingBackups,
        refresh: refreshBackups,
        page,
        pageSize,
        total,
    } = useBackups({ pageSize: defaultPageSize });

    const refresh = async (refreshParams: any = {}) => {
        let localFilters: any = {
            ...parentFilter,
            cluster_ids: undefined,
        };
        if (filterParams.cluster) {
            localFilters = {
                ...localFilters,
                cluster_ids: filterParams.cluster,
            };
        } else {
            localFilters = { ...localFilters, ...clusterFilter };
        }

        const currentPage = filterParams.page || page;
        await refreshBackups({
            ...localFilters,
            page: currentPage,
            pageSize,
            ascending:
                filterParams.sort === 'id' && filterParams.order === 'ascend',
            ...refreshParams,
        });
    };

    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [filterParams, clusterFilter, parentFilter]);

    const handleJobFinished = async (commands: CmonJobInstanceCommand[]) => {
        await refresh({
            showLoading: commands.includes(
                CmonJobInstanceCommand.DELETE_BACKUP
            ),
        });
    };

    return (
        <BackupsTable
            loading={loadingBackups}
            backups={backups}
            pagination={{ current: page, pageSize, total: total || undefined }}
            onChange={handleTableChange}
            onJobFinished={handleJobFinished}
            filterParams={filterParams}
            excludeColumns={excludeColumns}
            {...tableProps}
        />
    );
}
