import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, Form, Input, Popconfirm, Space } from 'antd';
import useCloudBuckets from '../useCloudBuckets';
import CcCloudCredentials from '../../../../services/models/CcCloudCredentials';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { createCloudCredentialsBucket } from '../useCloudCredentials';
import AppSpin from '../../../../common/General/AppSpin';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { notifyError } from '../../../Notifications/uiNotification';
import TypographyText from '../../../../common/TypographyText';

export default CloudCredentialsBucketSelect;

export interface CloudCredentialsBucketSelectApi {
    hasBucket: (name: string) => boolean;
}

export type CloudCredentialsBucketSelectProps = {
    credentials: CcCloudCredentials;
    onLoadingChange?: (loading: boolean) => void;
};

function CloudCredentialsBucketSelect({
    credentials,
    onLoadingChange,
    ...rest
}: CloudCredentialsBucketSelectProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [newBucketName, setNewBucketName] = useState<string>();
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    const createError = useRef<string>();

    const { list, refresh, loading: bucketsLoading, error } = useCloudBuckets({
        credentialsId: credentials.id,
        provider: credentials.provider,
    });

    useEffect(() => {
        (async () => {
            await refresh({});
        })();
    }, [credentials]);

    const hasBucket = (bucketName: string) => {
        return !!list?.find(({ name }) => name === bucketName);
    };

    const handleBucketNameChange = (value: string) => {
        createError.current = undefined;
        const matchBucket = list?.find(({ name }) =>
            matchBucketName(name as string, value)
        );
        setNewBucketName(matchBucket ? undefined : value);
    };

    const handleAddNewBucketConfirm = async () => {
        if (credentials && newBucketName && !hasBucket(newBucketName)) {
            setLoading(true);
            onLoadingChange?.(true);
            try {
                const result = await createCloudCredentialsBucket(
                    newBucketName,
                    credentials.id,
                    credentials.provider
                );
                if (!result?.created) {
                    throw new Error(
                        result?.error ||
                            `Could not create a bucket '${newBucketName}'`
                    );
                }
                setNewBucketName(undefined);
                await refresh({});
            } catch (e: any) {
                notifyError({ content: e.message });
                createError.current = e.message;
            } finally {
                setLoading(false);
                onLoadingChange?.(false);
            }
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleKeyUp = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (confirmVisible) {
                await handleAddNewBucketConfirm();
            } else {
                setConfirmVisible(true);
            }
        }
    };

    return (
        <AppSpin spinning={bucketsLoading}>
            <Form.Item
                name="cloudBucket"
                validateTrigger={false}
                label={
                    <Space>
                        Bucket
                        <InfoIcon
                            info={
                                <span>
                                    Select your existing bucket or type to
                                    create new
                                </span>
                            }
                        />
                    </Space>
                }
                rules={[
                    {
                        required: true,
                        validator: bucketValidator,
                    },
                ]}
            >
                <AutoComplete
                    options={list?.map((o) => ({
                        value: o.name,
                        label: o.name,
                    }))}
                    placeholder="Type bucket name"
                    filterOption={(inputValue, option) =>
                        matchBucketName(option?.value as string, inputValue)
                    }
                    onChange={handleBucketNameChange}
                >
                    <Input
                        addonAfter={
                            newBucketName ? (
                                <Popconfirm
                                    title={'Add new bucket?'}
                                    onConfirm={handleAddNewBucketConfirm}
                                    showCancel={true}
                                    visible={confirmVisible}
                                    onVisibleChange={(visible) => {
                                        setConfirmVisible(visible);
                                    }}
                                >
                                    {loading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <PlusOutlined data-testid="add-bucket-button-icon" />
                                    )}
                                </Popconfirm>
                            ) : (
                                <TypographyText
                                    muted={true}
                                    style={{ cursor: 'not-allowed' }}
                                >
                                    <PlusOutlined />
                                </TypographyText>
                            )
                        }
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                    />
                </AutoComplete>
            </Form.Item>
        </AppSpin>
    );

    async function bucketValidator(rule: any, value: string) {
        if (error?.message) {
            await refresh({});
            throw new Error(error.message);
        }
        if (createError.current) {
            throw new Error(createError.current);
        }
        if (!value) {
            throw new Error('Please enter bucket name');
        }
        if (value && !hasBucket(value)) {
            throw new Error(`Bucket "${value}" does not exists`);
        }
        // if (!/^[a-z0-9-]+$/.test(value)) {
        //     throw new Error('Allowed only lowercase letters, numbers and dash');
        // }

        return true;
    }

    function matchBucketName(value: string, name: string) {
        return value?.toUpperCase().indexOf(name.toUpperCase()) !== -1;
    }
}
