import React from 'react';
import { Form, Space } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { FormItemProps } from 'antd/lib/form';
import ConfigTemplateInput from '../../DataEntry/ConfigTemplateInput';
import { CcClusterType } from '../../../services/models/CcCluster';

export default NodeConfigurationTemplateField;

export type ConfigurationTemplateProps = FormItemProps & {
    vendor: string;
    version: string;
    required?: boolean;
    clusterType: CcClusterType;
};

function NodeConfigurationTemplateField({
    name = 'configurationTemplate',
    vendor,
    version,
    required = false,
    clusterType,
    ...rest
}: ConfigurationTemplateProps) {
    return (
        <div className="NodeConfigurationTemplateField">
            <Form.Item
                name={name}
                label={
                    <Space>
                        Configuration template
                        <InfoIcon
                            info={
                                <span>
                                    The configuration template is located in
                                    "/usr/share/cmon/templates" and custom
                                    templates in "/etc/cmon/templates".
                                </span>
                            }
                        />
                    </Space>
                }
                {...rest}
                rules={[
                    {
                        required,
                        message: 'Please choose configuration template',
                    },
                ]}
            >
                <ConfigTemplateInput
                    clusterType={clusterType}
                    vendor={vendor}
                    version={version}
                />
            </Form.Item>
        </div>
    );
}
