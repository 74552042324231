import React from 'react';
import CcCluster from '../../../services/models/CcCluster';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import JobCreateActionButton, {
    JobCreateActionButtonProps,
} from '../../Jobs/JobCreateActionButton';

export default ClusterToggleNodeAutoRecoveryButton;

export type ClusterToggleNodeAutoRecoveryButtonProps = Omit<
    JobCreateActionButtonProps,
    'command' | 'title'
> & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
};

function ClusterToggleNodeAutoRecoveryButton({
    cluster,
    children,
    onSuccess,
    ...rest
}: ClusterToggleNodeAutoRecoveryButtonProps) {
    return (
        <JobCreateActionButton
            clusterId={cluster.clusterId}
            command={
                cluster.nodeAutoRecovery
                    ? CmonJobInstanceCommand.DISABLE_NODE_RECOVERY
                    : CmonJobInstanceCommand.ENABLE_NODE_RECOVERY
            }
            confirm={false}
            title="Node auto-recovery"
            jobTitle={
                cluster.nodeAutoRecovery
                    ? 'Disable node auto-recovery'
                    : 'Enable node auto-recovery'
            }
            onSuccess={onSuccess}
            switchValue={cluster.nodeAutoRecovery}
            {...rest}
        />
    );
}
