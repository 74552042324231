import React, { useMemo } from 'react';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CcCluster from '../../../services/models/CcCluster';
import JobCreateButton, {
    JobCreateButtonProps,
} from '../../Jobs/JobCreateButton';
import { CLUSTER_UPGRADABLE_NODE_TYPES } from './useClusterAvailableUpgrades';

export default ClusterNodesCheckAvailablePackages;
export type ClusterNodesCheckAvailablePackagesProps = Omit<
    JobCreateButtonProps,
    'command' | 'title'
> & {
    cluster: CcCluster;
};

function ClusterNodesCheckAvailablePackages({
    cluster,
    ...rest
}: ClusterNodesCheckAvailablePackagesProps) {
    const jobData = useMemo(
        () => ({
            clusterId: cluster.clusterId,
            nodes: cluster.nodes
                .filter((node) => node.isType(CLUSTER_UPGRADABLE_NODE_TYPES))
                .map((node) => ({ hostname: node.hostname, port: node.port })),
        }),
        [cluster]
    );
    return (
        <JobCreateButton
            {...rest}
            clusterId={cluster.clusterId}
            jobData={jobData}
            title="Check available upgrades"
            loadingContent="Checking for upgrades"
            command={CmonJobInstanceCommand.CHECK_PKG_UPGRADES}
        >
            Check for upgrades
        </JobCreateButton>
    );
}
