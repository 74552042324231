import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Col, Form, Row } from 'antd';
import { TopologyItem } from '../../Topology/TopologyItem';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import TopologyNodeDataIpField from '../../../common/Form/Fields/TopologyNodeDataIpField';
import { FormItemProps } from 'antd/es';
import SshCheckableNodesInput, {
    SshCheckableNodesInputProps,
} from '../../../common/DataEntry/SshCheckeableNodesInput';

export default ClusterNodesForm;

export type ClusterNodesFormProps = {
    form: FormInstance;
    title?: React.ReactNode;
    formItemProps?: FormItemProps;
    nodesInputProps?: SshCheckableNodesInputProps;
    topologyNames?: string[];
};

function ClusterNodesForm({
    form,
    title,
    formItemProps,
    nodesInputProps = {},
    topologyNames = ['topology'],
}: ClusterNodesFormProps) {
    const { sshConfig } = form.getFieldsValue(true);

    const {
        mutateItem,
        onItemDelete,
        ...restNodesInputProps
    } = nodesInputProps;

    const topologyItemValidator = async (item: TopologyItem) => {
        await restNodesInputProps.validateItem?.(item);
        if (topologyNames.length > 0) {
            const topologies = form.getFieldsValue(topologyNames);
            let hostCount = 0;
            Object.entries(topologies).forEach(([key, values]) => {
                if (Array.isArray(values)) {
                    hostCount += values.filter(
                        (value) =>
                            value?.extraData?.hostname ===
                            item?.extraData?.hostname
                    ).length;
                }
            });
            if (hostCount > 1) {
                throw new Error(
                    `${item?.extraData?.hostname} is already in the list. Please enter different node.`
                );
            }
        }
        return item;
    };

    return (
        <div className="ClusterNodesForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>{title}</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item name="topology" noStyle {...formItemProps}>
                        <SshCheckableNodesInput
                            {...sshConfig}
                            mutateItem={(item) => {
                                const mutatedItem = mutateItem?.(item) || item;
                                const {
                                    footer: mutatedFooter,
                                    ...restMutatedItem
                                } = mutatedItem;
                                return {
                                    ...restMutatedItem,
                                    footer: (
                                        <div>
                                            {renderCommonItemFooter(item)}
                                            {mutatedFooter}
                                        </div>
                                    ),
                                    footerExpanded:
                                        item.status ===
                                        StatusFormatStatus.success,
                                    footerExpandable:
                                        item.status ===
                                        StatusFormatStatus.success,
                                };
                            }}
                            onItemDelete={(item) => {
                                form.resetFields(getCommonItemNames(item));
                                onItemDelete?.(item);
                            }}
                            {...restNodesInputProps}
                            validateItem={topologyItemValidator}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

function renderCommonItemFooter(item: TopologyItem) {
    return item.status === StatusFormatStatus.success ? (
        <TopologyNodeDataIpField
            item={item}
            name={['topologyDataIps', item.extraData.hostname]}
        />
    ) : null;
}
function getCommonItemNames(item: TopologyItem) {
    return [['topologyDataIps', item.extraData.hostname]];
}
