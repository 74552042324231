import merge from 'deepmerge';
import RedisShardedConfigurator, {
    RedisShardedFormValues,
} from '../../RedisSharded/RedisShardedConfigurator';
import React from 'react';
import { FormInstance } from 'antd/lib/form';
import RedisShardedDeploymentTopologySummary from './RedisShardedDeploymentTopologySummary';
import RedisShardedNodeSummary, {
    RedisShardedNodeSummaryProps,
} from '../../RedisSharded/RedisShardedNodeSummary';

export default class RedisShardedDeploymentConfigurator extends RedisShardedConfigurator {
    public static getDefaults(): RedisShardedFormValues {
        return merge(RedisShardedConfigurator.getDefaults(), {
            details: {
                vendor: 'redis',
                version: '7',
            },
            nodeConfig: {
                clusterBusPort: 16379,
                nodeTimeout: 15000,
                replicaValidityFactor: 10,
            },
            shards: {
                0: {
                    shardName: 'Shard 1',
                    topology: [],
                    perNode: {},
                },
            },
        });
    }

    public static getJobOptions(formValues: RedisShardedFormValues): any {
        return merge(RedisShardedConfigurator.getJobOptions(formValues), {
            job: {
                title: 'Create Redis Cluster',
            },
        });
    }

    public static getTopologySummary(form: FormInstance): React.ReactNode {
        return <RedisShardedDeploymentTopologySummary form={form} />;
    }
    public static getNodeConfigurationSummary(
        props: RedisShardedNodeSummaryProps
    ): React.ReactNode {
        return <RedisShardedNodeSummary {...props} />;
    }
}
