import { CmonFeature } from './CmonFeature';
import { CmonJobInstanceCommand } from "./models/CmonJobInstance";

export function getCmonLicenseFeatures() {
    return [
        CmonFeature.GALERA_AUTO_RECOVER_CLUSTER,
        CmonFeature.GALERA_AUTO_RECOVER_NODE,
        CmonFeature.REPLICATION_PROMOTE_SLAVE,
        CmonFeature.REPLICATION_DEMOTE_NODE,
        CmonFeature.REPLICATION_START_FAILED_NODE,
        CmonFeature.MYSQL_FAILOVER_REPLICATION_SLAVE,
        CmonFeature.POSTGRESQL_FAILOVER_REPLICATION_SLAVE,
        CmonFeature.POSTGRESQL_RESLAVE_OLD_MASTER_JOB,
        CmonFeature.POSTGRESQL_FAILOVER_JOB,
        CmonFeature.POSTGRESQL_RECOVER_SINGLE_NODE_JOB,
        CmonFeature.POSTGRESQL_RECOVER_SLAVES_JOB,
        CmonFeature.POSTGRESQL_PROMOTE_SLAVE,
        CmonFeature.POSTGRESQL_RECOVER_MASTER_JOB,
        CmonFeature.POSTGRESQL_ALARM_INIT_SNMP_TRAP_EXECUTOR,
        CmonFeature.ELASTIC_ADD_NODE,
        CmonFeature.MONGODB_CONVERT_TO_SHARDS,
        CmonFeature.MONGODB_ADD_SHARD,
        CmonFeature.MONGODB_ADD_NODE,
        CmonFeature.MONGODB_REMOVE_SHARD,
        CmonFeature.MONGODB_REMOVE_NODE,
        CmonFeature.MONGODB_RESTORE_BACKUP,
        CmonFeature.MONGODB_START_BACKUP,
        CmonFeature.MSSQL_AO_ASYNC_FAILOVER_JOB,
        CmonFeature.MSSQL_AO_ASYNC_PROMOTE_SLAVE,
        CmonFeature.PROXY_SQL_START_BACKUP,
        CmonFeature.PROXY_SQL_RESTORE_BACKUP,
        CmonFeature.GENERATE_REPORT,
        CmonFeature.LDAP,
        CmonFeature.GALERA_ADD_REPLICATION_SLAVE,
        CmonFeature.START_BACKUP,
        CmonFeature.RESTORE_BACKUP,
        CmonFeature.VERIFY_BACKUP,
        CmonFeature.SEND_EMAIL_ON_BACKUP_CREATED,
        CmonFeature.SEND_EMAIL_ON_BACKUP_RESTORED,
        CmonFeature.ENCRYPT_REPLICATION,
        CmonFeature.SETUP_SSL,
        CmonFeature.PURGE_QUERY_MONITOR,
        CmonFeature.SETUP_AUDIT_LOGGING,
        CmonFeature.ENABLE_DISABLE_CLUSTER_READONLY,
        CmonFeature.CLUSTER_RECOVERY,
        CmonFeature.CHECK_PKG_UPGRADES,
    ]
}

export function getCmonLicenseJobFeature(jobCommand: CmonJobInstanceCommand) {
    switch (jobCommand) {
        case CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE:
            return CmonFeature.PROMOTE_SLAVE;
        case CmonJobInstanceCommand.DEMOTE_NODE:
            return CmonFeature.DEMOTE_NODE;
        case CmonJobInstanceCommand.ADDNODE:
            return CmonFeature.ADD_NODE;
        case CmonJobInstanceCommand.CONVERT_TO_SHARDED_CLUSTER:
            return CmonFeature.CONVERT_TO_SHARDS;
        case CmonJobInstanceCommand.ADD_SHARD:
            return CmonFeature.ADD_SHARD;
        case CmonJobInstanceCommand.REMOVE_SHARD:
            return CmonFeature.REMOVE_SHARD;
        case CmonJobInstanceCommand.REMOVENODE:
            return CmonFeature.REMOVE_NODE;
        case CmonJobInstanceCommand.RESTORE_BACKUP:
            return CmonFeature.RESTORE_BACKUP;
        case CmonJobInstanceCommand.BACKUP:
            return CmonFeature.START_BACKUP;
        case CmonJobInstanceCommand.REPORT:
            return CmonFeature.GENERATE_REPORT;
        case CmonJobInstanceCommand.ADD_REPLICATION_SLAVE:
            return CmonFeature.ADD_REPLICATION_SLAVE;
        case CmonJobInstanceCommand.VERIFY_BACKUP:
            return CmonFeature.VERIFY_BACKUP;
        case CmonJobInstanceCommand.ENCRYPT_REPLICATION:
            return CmonFeature.ENCRYPT_REPLICATION;
        case CmonJobInstanceCommand.SETUP_SSL:
            return CmonFeature.SETUP_SSL;
        case CmonJobInstanceCommand.PURGE_QUERY_MONITOR:
            return CmonFeature.PURGE_QUERY_MONITOR;
        case CmonJobInstanceCommand.SETUP_AUDIT_LOGGING:
            return CmonFeature.SETUP_AUDIT_LOGGING;
        case CmonJobInstanceCommand.ENABLE_CLUSTER_READONLY:
        case CmonJobInstanceCommand.DISABLE_CLUSTER_READONLY:
            return CmonFeature.ENABLE_DISABLE_CLUSTER_READONLY;
        case CmonJobInstanceCommand.CLUSTER_RECOVERY:
            return CmonFeature.CLUSTER_RECOVERY;
        case CmonJobInstanceCommand.CHECK_PKG_UPGRADES:
            return CmonFeature.CHECK_PKG_UPGRADES;
    }
}

/*

Here lines of backend code extracted by following rules:
const checks = [
    (code) => code.indexOf('hasLicense') > -1,
    (code) => code.indexOf('.isEnterprise(') > -1,
];


========================================Galera.cpp

    {
        if (hasLicense())
            checkErrorLog();
------
    if (! S9sClusterFacade::instance()->isCreateClusterRunning(clusterId())
        && ! hasLicense())
    {
------
    if (!S9sClusterFacade::instance()->isCreateClusterRunning(clusterId())
        && !hasLicense())
    {
------

==============================MySQLReplication.cpp

     *\/
    if (!hasLicense())
    {
------

    if (!hasLicense())
    {
------

======================cmonabstractmysqlcluster.cpp

     *\/
    if (!hasLicense())
    {
------
    {
        if (hasLicense())
            if(!analyzeTables())
------

=================cmonabstractpostgresqlcluster.cpp

     *\/
    if (!hasLicense())
    {
------
    // license barrier
    if (!hasLicense())
        return false;
------
     *\/
    if (!hasLicense())
    {
------
        if (! S9sClusterFacade::instance()->isCreateClusterRunning(clusterId())
            && ! hasLicense())
        {
------

    if (!hasLicense())
        return false;
------

    if (!hasLicense())
        return false;
------
     *\/
    if (!hasLicense())
    {
------

    if (!hasLicense())
        return false;
------

===================================cmonalarmdb.cpp

    CmonLicense licence = CmonDb::getLicense();
    if (!licence.isEnterprise())
        return false;
------
    bool                 success;
    bool                 hasLicense = checkLicense(NULL);

------
        if (CmonCluster::sm_checkLicense &&
            !hasLicense &&
            component != "SoftwareInstallation")
------
    CmonLicense licence = CmonDb::getLicense();
    bool hasLicense = licence.isValid() && !licence.isExpired();
    CMON_UNUSED(errorString)
------
    if (config == NULL)
        return hasLicense;

------
    // update sheetmanager
    config->pushInfo(licenseStatusKey, hasLicense);
    // and the configuration
------
    // and the configuration
    config->set(PropHasLicense, hasLicense, errorString);

------

    return hasLicense;
}
------

===================================cmoncluster.cpp

            // is set to 60 (kinda disabling the real-time-sampling).
            if (!hasLicense() && m_memCache->sampleCounter() > 5ull)
            {
------
bool
CmonCluster::hasLicense()
{
------

======================cmonelasticsearchcluster.cpp

{
    if(!CmonCluster::hasLicense())
    {
------

==============================cmonmongocluster.cpp


    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------

    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------

    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------
{
    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------
{
    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------

    if (!hasLicense())
    {
------

    if(!CmonCluster::hasLicense()){
        FAILURE("%s", STR(sm_invalidLicenseTextHtml));
------

    if (!CmonCluster::hasLicense())
    {
------

=======================cmonmssqlaoasynccluster.cpp

    if (! S9sClusterFacade::instance()->isCreateClusterRunning(clusterId())
        && ! hasLicense())
    {
------
     *\/
    if (!hasLicense())
    {
------

==================================cmonproxysql.cpp

    // license check that requires cluster object
    if (!cluster->hasLicense())
    {
------
    // license check that requires cluster object
    if (!cluster->hasLicense())
    {
------

==================================cmonreporter.cpp

     *\/
    if (!hasLicense(clusterIds))
    {
------
bool
CmonReporter::hasLicense(
        CmonVector<CmonClusterId> clusterIds)
------

========================cmonrpcclustershandler.cpp

    {
        "hasLicense": false,
        "licenseExpires": -1,
------
    cmonInfo["version"]             = CmonString (PACKAGE_VERSION);
    cmonInfo["hasLicense"]          = hasValidLicense;

------

========================cmonrpcsettingshandler.cpp

    config->getInfo("license.status", variant);
    reply.setValueForPath("/data/hasLicense", variant.toBoolean ());

------
        {
            reply.setValueForPath("/data/hasLicense", false);
            reply.setValueForPath("/data/licenseStatus", "The license has expired.");
------
        {
            reply.setValueForPath("/data/hasLicense", true);
            reply.setValueForPath("/data/licenseStatus", "The license key is valid.");
------
    {
        reply.setValueForPath("/data/hasLicense", false);
        reply.setValueForPath("/data/licenseStatus", "Invalid license key.");
------

===============================cmonrpcv2config.cpp

     *   {
     *      "hasLicense":true,
     *      "licenseExpires":387,
------
     *   {
     *      "hasLicense":true,
     *      "licenseExpires":387,
------
    config->getInfo("license.status", variant);
    reply.setValueForPath("/data/hasLicense", variant.toBoolean ());

------

===============================cmonusermanager.cpp

            CmonLicense license = CmonDb::getLicense();
            ldapLicensed = license.isEnterprise();
        }
------
        CmonLicense license = CmonDb::getLicense();
        if (!license.isEnterprise())
        {
------

==================================cmonworkflow.cpp

     *\/
    if (m_configuration->getTypeId() == GALERA && !m_cluster->hasLicense())
    {
------
     *\/
    if (!m_cluster->hasLicense())
    {
------

    if (!m_cluster->hasLicense())
    {
------
     *\/
    if (!m_cluster->hasLicense())
        throw CMON_EXCEPTION("%s", STR(CmonCluster::sm_invalidLicenseTextHtml));
------
     *\/
    if (!m_cluster->hasLicense())
    {
------

    bool hasLicense = m_cluster->hasLicense();
    bool success = (status == 0);
------

    if (hasLicense)
    {
------
    // not sending anything unless user has a valid license
    bool hasLicense = m_cluster->hasLicense();
    if (!hasLicense)
------
    bool hasLicense = m_cluster->hasLicense();
    if (!hasLicense)
        return;
------
     *\/
    if (!m_cluster->hasLicense())
    {
------

        if (!m_cluster->hasLicense())
        {
------

    if (!m_cluster->hasLicense())
        throw CMON_EXCEPTION("%s", STR(CmonCluster::sm_invalidLicenseTextHtml));
------

    if (!m_cluster->hasLicense())
        throw CMON_EXCEPTION("%s", STR(CmonCluster::sm_invalidLicenseTextHtml));
------

    if (!m_cluster->hasLicense())
    {
------

    if (!m_cluster->hasLicense())
    {
------
    // license check for load balancers
    if (!m_cluster->hasLicense())
    {
------
{
    if (!m_cluster->hasLicense())
    {
------
     *\/
    if (!m_cluster->hasLicense())
    {
------
     *\/
    if (!m_cluster->hasLicense())
    {
------

 */
