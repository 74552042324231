import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import CcAlarm from '../../services/models/CcAlarm';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import AppActionButton from '../../common/General/AppActionButton';
import AlarmDetailsModal, { AlarmDetailsModalProps } from './AlarmDetailsModal';

export default AlarmDetailsButton;

export type AlarmDetailsButtonProps = ButtonProps & {
    alarm: CcAlarm;
    children?: React.ReactNode;
    onActionPerformed?: AlarmDetailsModalProps['onActionPerformed'];
};

function AlarmDetailsButton({
    alarm,
    children,
    onActionPerformed,
    ...rest
}: AlarmDetailsButtonProps) {
    return (
        <ButtonWithForm
            button={
                <AppActionButton {...rest}>
                    {children || 'Details'}
                </AppActionButton>
            }
            form={
                <AlarmDetailsModal
                    alarm={alarm}
                    onActionPerformed={onActionPerformed}
                />
            }
        ></ButtonWithForm>
    );
}
