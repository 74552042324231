import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import FormFooter from '../../../common/FormFooter';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import CcNode from '../../../services/models/CcNode';
import NodeFormat from '../NodeFormat';
import SpaceWide from '../../../common/SpaceWide';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ExternalLink from '../../../common/Navigation/ExternalLink';
import FormItem from '../../../common/DataEntry/FormItem';

export default NodeEnableBinaryLoggingForm;

export type NodeEnableBinaryLoggingFormProps = {
    node: CcNode;
    onSuccess?: () => void;
    onCancel?: () => void;
};
export type NodeEnableBinaryLoggingFormValues = {
    autoRestart?: boolean;
    binlogPath?: string;
    expireLogsDays?: number;
};

function NodeEnableBinaryLoggingForm({
    node,
    onSuccess,
    onCancel,
}: NodeEnableBinaryLoggingFormProps) {
    const [form] = Form.useForm<NodeEnableBinaryLoggingFormValues>();
    const { loading, send } = useCreateJob({
        clusterId: node.clusterid,
        title: 'Enable binary logging',
        command: CmonJobInstanceCommand.ENABLE_BINARY_LOGGING,
        onSuccess,
    });

    const handleSubmit = async (values: NodeEnableBinaryLoggingFormValues) => {
        await send({
            binlog_path: values.binlogPath,
            master_address: node.getHostWithPort(),
            auto_restart: values.autoRestart,
            expire_logs_days: values.expireLogsDays,
        });
    };
    return (
        <ModalDefaultForm
            form={form}
            title="Enable binary logging"
            footer={[]}
            onCancel={onCancel}
            width={450}
            defaultVisible={true}
        >
            <Form
                className="NodeEnableBinaryLoggingForm"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    expireLogsDays: 7,
                    autoRestart: true,
                    binlogPath: '/var/lib/mysql-binlog/binlog',
                }}
            >
                <SpaceWide direction="vertical">
                    <NodeFormat
                        node={node}
                        size="large"
                        contentProps={{ style: { width: '100%' } }}
                    />
                    <FormItem
                        name="binlogPath"
                        withLessMarginBottom={true}
                        label={
                            <Space>
                                <span>Binlog Path (log_bin) </span>
                            </Space>
                        }
                        extra={
                            <span>
                                See mysql documentation:{' '}
                                <ExternalLink href="http://dev.mysql.com/doc/refman/en/replication-options-binary-log.html#option_mysqld_log-bin">
                                    documentation
                                </ExternalLink>
                            </span>
                        }
                    >
                        <Input></Input>
                    </FormItem>

                    <Alert
                        message={
                            <span>
                                This job will update the configuration of
                                {node.getHostWithPort()} to enable binary
                                logging. A replica can then be added
                                to the node, or it may be possible to use the
                                binary log for Point In Time Recovery. A server
                                restart is needed to finalize the configuration
                                update.
                            </span>
                        }
                        type="info"
                    />
                    <FormItemInlineSwitch
                        data-testid="nodes-actions-nodeEnableBinaryLoggingForm-switch-autoRestart"
                        justify
                        noMargin
                        name="autoRestart"
                        label={<Space>Restart the node</Space>}
                        valuePropName="checked"
                    />
                    <FormItem
                        name="expireLogsDays"
                        withLessMarginBottom={true}
                        label={<span>Expire binary logs after (days)</span>}
                    >
                        <Input type="number"></Input>
                    </FormItem>
                </SpaceWide>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Enable
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
