import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Col, Form, Row } from 'antd';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import BackupRestoreTimePickerField from './BackupRestoreTimePickerField';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import SpaceWide from '../../../../../common/SpaceWide';

export default BackupRestoreSQLPITRForm;
type BackupRestoreSQLPITRFormProps = {
    form: FormInstance;
};

function BackupRestoreSQLPITRForm({ form }: BackupRestoreSQLPITRFormProps) {
    return (
        <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12}>
                <FormItemInlineSwitch
                    justify={true}
                    name="pitr"
                    label={<span>Point in time recovery (PITR)</span>}
                    valuePropName="checked"
                />
                <Form.Item shouldUpdate={true} noStyle={true}>
                    {() => {
                        if (form.getFieldValue('pitr')) {
                            return (
                                <BackupRestoreTimePickerField
                                    label={
                                        <span>
                                            Restore time{' '}
                                            <InfoIcon
                                                info={
                                                    <span>
                                                        Recover the data up
                                                        until the date and time
                                                        given by Restore Time
                                                        (Event time - stop date
                                                        &amp; time).
                                                    </span>
                                                }
                                            />
                                        </span>
                                    }
                                />
                            );
                        }
                        return null;
                    }}
                </Form.Item>
            </Col>
        </Row>
    );
}
