import CcProxySqlVariable from '../../../../../../services/models/CcProxySqlVariable';

export type TableData = CcProxySqlVariable & {
    key: string;
    showPopover: boolean;
};

export function handleCcProxyVariables(
    data: CcProxySqlVariable[]
): TableData[] {
    return data.map((data, index) => {
        return {
            ...data,
            key: `${data.variableName} ${index}`,
            showPopover: false,
        };
    });
}
