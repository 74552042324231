export enum CcControllerStatus {
    Ok = 'ok',
    Failed = 'failed',
    AuthenticationError = 'authentication-error',
}

export type CcControllerProps = {
    controller_id: string;
    controller_name: string;
    url: string;
    frontend_url: string;
    version?: string;
    status_message?: string;
    status: CcControllerStatus;
    last_seen: string;
    last_updated: string;
};
export default class CcController {
    public readonly controllerId: string;
    public readonly controllerName: string;
    public readonly url: string;
    public readonly frontendUrl: string;
    public readonly version?: string;
    public readonly statusMessage?: string;
    public readonly status: CcControllerStatus;
    public readonly lastSeen?: string;
    public readonly lastUpdated?: string;

    constructor(props: CcControllerProps) {
        this.controllerId = props.controller_id;
        this.controllerName = props.controller_name;
        this.url = props.url;
        this.frontendUrl = props.frontend_url;
        this.version = props.version;
        this.statusMessage = props.status_message;
        this.status = props.status;
        this.lastSeen = props.last_seen;
        this.lastUpdated = props.last_updated;
    }

    public getStatusMessage() {
        return this.status === CcControllerStatus.Ok
            ? 'Up and Running'
            : this.statusMessage;
    }

    public getKey(): string {
        return this.url;
    }
}
