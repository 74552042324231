import React, { useContext } from 'react';
import './DashboardOverviewCard.less';
import AppRow from '../../common/AppRow';
import { Col } from 'antd';
import DashboardCard, { DashboardCardProps } from './DashboardCard';
import StatusPieDiagram, {
    StatusPieDiagramItem,
} from '../../common/StatusPieDiagram';

import CcStatsOverviewInterface from '../../services/types/CcStatsOverviewInterface';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import classNames from 'classnames';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import AppSpin from '../../common/General/AppSpin';

export default DashboardOverviewCard;

export type DashboardOverviewCardProps = DashboardCardProps & {
    overview?: CcStatsOverviewInterface;
    pieData?: StatusPieDiagramItem[];
    title: string | React.ReactElement;
    totalSummary: React.ReactElement;
    rightSide: React.ReactElement;
    loading?: boolean;
    pieSize?: number;
    leftSideSize?: number;
    onPieElementClick?: (evtData: any) => void;
};

function DashboardOverviewCard({
    overview,
    pieData,
    loading = false,
    title,
    totalSummary,
    rightSide,
    pieSize,
    leftSideSize = 8,
    onPieElementClick,
    ...rest
}: DashboardOverviewCardProps) {
    const { responsive } = useContext(ResponsiveContext);

    return (
        <DashboardCard
            className="DashboardOverviewCard"
            title={title}
            verticalPadding={0}
            horizontalPadding={10}
            {...rest}
        >
            <AppRow gutter={[24, 0]} justify="space-between">
                <Col
                    xs={24}
                    sm={24}
                    md={leftSideSize}
                    className="DashboardOverviewCard_activity"
                >
                    <AppRow gutter={24}>
                        <Col span={24} style={{ padding: '20px' }}>
                            <SpaceDescriptions
                                direction="vertical"
                                title="Status overview"
                                extra={loading ? null : totalSummary}
                            >
                                <AppSpin spinning={loading}>
                                    <StatusPieDiagram
                                        pieSize={pieSize || 230}
                                        pieData={pieData || []}
                                        total={overview?.total || 0}
                                        onElementClick={onPieElementClick}
                                    />
                                </AppSpin>
                            </SpaceDescriptions>
                        </Col>
                    </AppRow>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={24 - leftSideSize}
                    style={{ padding: '20px' }}
                >
                    <div
                        className={classNames({
                            'DashboardOverviewCard_responsive-right-side':
                                responsive,
                        })}
                    >
                        {rightSide}
                    </div>
                </Col>
            </AppRow>
        </DashboardCard>
    );
}
