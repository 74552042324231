import MysqlConfigurator, { MysqlFormValues } from '../Mysql/MysqlConfigurator';
import { TopologyItem } from '../../../Topology/TopologyItem';
import { ClusterConfiguratorVendor } from '../ClusterConfigurator';

export interface MysqlReplicationFormValues extends MysqlFormValues {
    useMultiPrimary?: boolean;
    topologyMultiPrimary?: TopologyItem[];
}
export default class MysqlReplicationConfigurator extends MysqlConfigurator {
    public static getVendors(): ClusterConfiguratorVendor[] {
        return [
            ...MysqlConfigurator.getVendors(),
            {
                name: 'Oracle',
                value: 'oracle',
            },
        ];
    }
}
