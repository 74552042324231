import React, { useState } from 'react';
import './JobDetailsModal.less';
import ReactJson from 'react-json-view';
import { Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { getJobStatusFormatType } from './JobStatusFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import JobActionsMenu, { JobActionsMenuProps } from './JobActionsMenu';
import AppSpin from '../../common/General/AppSpin';
import TypographyText from '../../common/TypographyText';
import SpaceWide from '../../common/SpaceWide';
import JobLog from './JobLog';
import DetailsModal, {
    DetailsModalProps,
} from '../../common/Feedback/DetailsModal';
import CcJob from '../../services/models/CcJob';
import TextFormat from '@severalnines/bar-frontend-components/build/lib/Format/TextFormat';
import { getStatusFormatIconFilled } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import CmonJobMessage from '../../services/cmon/models/CmonJobMessage';
import { AppState, AppStateClustersMap } from '../../appReducer';
import { useSelector } from 'react-redux';

export default JobDetailsModal;

export type JobDetailsModalProps = Omit<DetailsModalProps, 'children'> & {
    job: CcJob;
    onActionPerformed?: JobActionsMenuProps['onActionPerformed'];
};

function JobDetailsModal({
    job,
    onActionPerformed,
    ...rest
}: JobDetailsModalProps) {
    const clustersMap: AppStateClustersMap = useSelector(
        ({ clusters }: AppState) => clusters
    );
    const [messagesStr, setMessagesStr] = useState<string>('');

    const handleMessagesUpdate = (messages: CmonJobMessage[]) => {
        // using a div to remove html tags
        const div = document.createElement('div');
        div.innerHTML = messages
            .map(
                (m) =>
                    `${
                        m.created
                            ? new Date(m.created).toISOString()
                            : '                   '
                    }: ${m.messageText}`
            )
            .join('\n');
        setMessagesStr(
            `${JSON.stringify(job?.spec, null, 2)}\n\n` +
                (div.textContent || div.innerText || '')
        );
    };
    return (
        <DetailsModal
            title="Job details"
            headerTitle={
                <TextFormat type={getJobStatusFormatType(job?.status)}>
                    {job?.title}
                </TextFormat>
            }
            getContainer={() => document.body}
            icon={getStatusFormatIconFilled(
                getJobStatusFormatType(job?.status)
            )}
            header={
                <SpaceDescriptions>
                    <SpaceDescriptions.Item direction="vertical">
                        <div className="JobDetailsModal_json_viewer">
                            <ReactJson
                                src={job?.spec}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                name="jobSpec"
                                shouldCollapse={(field) =>
                                    field.name === 'jobSpec'
                                }
                            />
                        </div>
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            }
            headerExtra={
                job ? (
                    <JobActionsMenu
                        job={job}
                        cluster={clustersMap.get(job.getClusterKey())}
                        excludeKeys={['details']}
                        onActionPerformed={onActionPerformed}
                    />
                ) : null
            }
            visible={true}
            {...rest}
        >
            <SpaceDescriptions
                direction="vertical"
                title={
                    <Space>
                        <span>Job messages</span>
                        {job?.isRunning() ? (
                            <AppSpin spinning={true} size="small" />
                        ) : null}
                    </Space>
                }
                extra={
                    <TypographyText
                        copyable={{
                            text: messagesStr,
                            icon: (
                                <Space>
                                    <CopyOutlined />
                                    Copy to clipboard
                                </Space>
                            ),
                        }}
                    ></TypographyText>
                }
            >
                <SpaceWide direction="vertical">
                    {job?.jobId ? (
                        <JobLog
                            jobId={job.jobId}
                            onUpdate={handleMessagesUpdate}
                        />
                    ) : null}
                </SpaceWide>
            </SpaceDescriptions>
        </DetailsModal>
    );
}
