import { defaultSettings, UserEmail } from './emailNotificationsHelper';
import AppConfirmActionButton from '../../common/General/AppConfirmActionButton';
import React from 'react';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import {
    notifyError,
    notifyOperationSuccess,
    NotifyType,
} from '../Notifications/uiNotification';
import CcCluster from '../../services/models/CcCluster';
import { Button } from 'antd';

export default EmailNotificationsAddRecipientButton;
type EmailNotificationsToggleUserButtonProps = {
    userEmail: UserEmail;
    cluster: CcCluster;
    onAdd?: () => void;
};

function EmailNotificationsAddRecipientButton({
    userEmail,
    cluster,
    onAdd,
}: EmailNotificationsToggleUserButtonProps) {
    const setRecipient = async () => {
        try {
            await CmonConfigService.setnotificationsettings({
                cluster_id: cluster.clusterId,
                email: userEmail.emailAddress,
                settings: defaultSettings,
            });
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: `Email ${userEmail.emailAddress} was added to recipient list!`,
                cancelText: 'Close',
            });
            await onAdd?.();
        } catch (error) {
            notifyError({ content: error.message });
        }
    };

    const handleClick = async () => {
        await setRecipient();
    };

    return (
        <Button
            danger={userEmail?.active}
            onClick={handleClick}
            type="primary"
            size="middle"
        >
            Enable
        </Button>
    );
}
