import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import CcCluster from '../../../../services/models/CcCluster';
import useClusterConfigGrouped, {
    ClusterConfigCategory,
    ClusterConfigGrouped,
} from '../../useClusterConfigGrouped';
import { ClusterConfigValue } from '../../useClusterConfig';
import CmonClustersService from '../../../../services/cmon/CmonClustersService';
import { notifyError, NotifyType } from '../../../Notifications/uiNotification';
import {
    ConfigClusterContext,
    ConfigClusterThresholdsContext,
    ConfigContext,
    ConfigGroupedContext,
    ConfigGroupedProvider,
    filterConfigItem,
} from '../ConfigContext';
import { ThresholdItem } from '../ClusterConfigContext';

type ConfigClusterGroupedProviderProps = {
    children: React.ReactNode;
    cluster?: CcCluster;
    loadConfigGrouped?: boolean;
    presetGrouped?: ClusterConfigGrouped;
};

export function ConfigClusterGroupedProvider({
    cluster,
    children,
    loadConfigGrouped = true,
    presetGrouped,
}: ConfigClusterGroupedProviderProps) {
    const [clusterId, setClusterId] = useState<number | undefined>(
        cluster?.clusterId
    );
    const [changeLoading, setChangeLoading] = useState(false);

    const {
        refresh: refreshConfigGrouped,
        config: configGroupedResult,
        loading: configGroupedLoading,
    } = useClusterConfigGrouped({
        clusterId,
    });

    useEffect(() => {
        (async () => {
            if (clusterId) {
                if (loadConfigGrouped) {
                    await refreshConfigGrouped({});
                }
            }
        })();
    }, [clusterId]);

    useEffect(() => {
        setClusterId(cluster?.clusterId);
    }, [cluster?.clusterId]);

    const changeValue = useCallback(
        async (item: ClusterConfigValue, value: any) => {
            try {
                setChangeLoading(true);
                await CmonClustersService.setConfig({
                    cluster_id: clusterId,
                    configuration: [
                        {
                            name: item.name,
                            value: value,
                        },
                    ],
                });
            } catch (err: any) {
                notifyError({ type: NotifyType.TOAST, content: err.message });
                throw err; // rethrow error to restore previous value in input
            } finally {
                setChangeLoading(false);
            }
        },
        [clusterId]
    );

    const clusterConfigContext = useMemo(() => {
        return {
            setClusterId,
            clusterId,
        };
    }, [clusterId]);

    const configGrouped = configGroupedResult || presetGrouped;

    return (
        <ConfigClusterContext.Provider value={clusterConfigContext}>
            <ConfigGroupedProvider
                changeValue={changeValue}
                refresh={refreshConfigGrouped}
                configGrouped={configGrouped}
                configGroupedLoading={configGroupedLoading}
                changeLoading={changeLoading}
            >
                <ConfigClusterThresholdsProvider configGrouped={configGrouped}>
                    {children}
                </ConfigClusterThresholdsProvider>
            </ConfigGroupedProvider>
        </ConfigClusterContext.Provider>
    );
}

type ConfigClusterThresholdsProviderProps = {
    children: React.ReactNode;
    configGrouped?: ClusterConfigGrouped;
};

/**
 * Depends on ConfigGroupedContext and ConfigContext
 * Modifies ConfigGroupedContext
 *
 * @param children
 * @param configGrouped
 * @param searchString
 * @constructor
 */
function ConfigClusterThresholdsProvider({
    children,
    configGrouped,
}: ConfigClusterThresholdsProviderProps) {
    const {
        configGroupedLoading,
        availableCategories,
        configGrouped: searchResultGrouped,
        categoryTotal,
    } = useContext(ConfigGroupedContext);
    const { searchString } = useContext(ConfigContext);

    // all threshold items
    const thresholdItems = useMemo(() => {
        const listItems =
            [ClusterConfigCategory.THRESHOLD, ClusterConfigCategory.SWAPPING]
                ?.map((category) =>
                    Object.values(configGrouped?.[category] || {})
                )
                .flat() || [];
        const items: ThresholdItem[] = Object.values(
            listItems.reduce((a, c) => {
                let name: string[] | string = c.name?.split('_') || [];
                const type = name.pop();
                if (!type || !['warning', 'critical'].includes(type)) {
                    return a;
                }
                name = name.join('_');
                if (!a[name]) {
                    a[name] = {
                        name: name,
                        waning: 0,
                        critical: 0,
                    };
                }
                a[name][type] = c;
                a[name][`${type}Value`] = c;
                return a;
            }, {} as any)
        );

        return items;
    }, [configGrouped]);

    // filtered by searchString thresholds
    const thresholds: ThresholdItem[] = useMemo(() => {
        return thresholdItems.filter(
            ({ warning, critical }) =>
                (warning && filterConfigItem(searchString, warning)) ||
                (critical && filterConfigItem(searchString, critical))
        );
    }, [searchString, thresholdItems]);

    const categoriesWithThresholds = useMemo(() => {
        const categories = [...availableCategories];
        // thresholds must be handled separately
        if (
            thresholds.length > 0 &&
            !categories.includes(ClusterConfigCategory.THRESHOLD)
        ) {
            categories.push(ClusterConfigCategory.THRESHOLD);
        }

        return categories;
    }, [availableCategories, thresholds]);

    const configGroupedContext = useMemo(() => {
        return {
            configGroupedLoading,
            availableCategories: categoriesWithThresholds,
            configGrouped: searchResultGrouped,
            categoryTotal,
        };
    }, [configGroupedLoading, availableCategories, searchResultGrouped]);

    const thresholdsContext = useMemo(() => {
        return {
            thresholds,
        };
    }, [thresholds]);

    return (
        <ConfigGroupedContext.Provider value={configGroupedContext}>
            <ConfigClusterThresholdsContext.Provider value={thresholdsContext}>
                {children}
            </ConfigClusterThresholdsContext.Provider>
        </ConfigGroupedContext.Provider>
    );
}
