
import {CmonRequestServiceResponseData} from '../CmonRequestService';
import CmonError from './CmonError';

export default class CmonInvalidRequestError
    extends CmonError {

    constructor(jsonError: CmonRequestServiceResponseData) {
        super(jsonError);
    }

}
