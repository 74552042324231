import React, { AnchorHTMLAttributes } from 'react';

export default ExternalLink;
type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {};

function ExternalLink({ children, ...rest }: ExternalLinkProps) {
    return (
        <a target="_blank" rel="noopener noreferrer" {...rest}>
            {children}
        </a>
    );
}
