import { Alert, Col, Row, Space } from 'antd';
import TypographyText from '../../../common/TypographyText';
import React, { ReactNode } from 'react';
import ExternalLink from '../../../common/Navigation/ExternalLink';
import { FileTextOutlined } from '@ant-design/icons';
import SwitchBox from '../../../common/DataEntry/SwitchBox';
import { NamePath } from 'rc-field-form/lib/interface';

export default DatabaseExtensionsForm;

type ExtensionItem = {
    name: string;
    title?: ReactNode;
    description?: ReactNode;
    url?: string;
};

export const AVAILABLE_DB_EXTENSIONS: ExtensionItem[] = [
    {
        name: 'pgvector',
        title: 'pgvector',
        description: (
            <Space direction="vertical">
                <TypographyText muted={true}>
                    pgvector is an open-source PostgreSQL extension for vector
                    similarity search. It can also be used for storing
                    embeddings.
                </TypographyText>
                <Alert
                    showIcon={true}
                    type="warning"
                    message={
                        <span>
                            After the deployment job is finished{' '}
                            <b>
                                you will need to enable pgvector extension
                                manually
                            </b>{' '}
                            by connection to PostgreSQL database and execute the
                            following command - CREATE EXTENSION vector
                        </span>
                    }
                />
            </Space>
        ),
        url: 'https://github.com/pgvector/pgvector',
    },
];

type DatabaseExtensionsFormProps = {};

function DatabaseExtensionsForm({}: DatabaseExtensionsFormProps) {
    return (
        <Space direction="vertical">
            <Row>
                <TypographyText>
                    Select extension from the list you want to be installed
                    together with the PostgreSQL database. Once the cluster is
                    deployed you will still be able to install extensions. You
                    can select more then one extension.
                </TypographyText>
            </Row>
            <Row gutter={[24, 0]}>
                <Col>
                    {AVAILABLE_DB_EXTENSIONS.map(({ name, ...item }) => {
                        return (
                            <DatabaseExtensionSwitch
                                name={['databaseExtension', name]}
                                {...item}
                            />
                        );
                    })}
                </Col>
            </Row>
        </Space>
    );
}

type DatabaseExtensionSwitchProps = Omit<ExtensionItem, 'name'> & {
    name: NamePath;
};

function DatabaseExtensionSwitch({
    url,
    ...rest
}: DatabaseExtensionSwitchProps) {
    return (
        <SwitchBox
            {...rest}
            extra={
                <ExternalLink href={url}>
                    <FileTextOutlined /> Documentation
                </ExternalLink>
            }
        />
    );
}
