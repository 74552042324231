import * as CcModels from '../models';
import * as CMONModels from './models';
import * as CMONErrors from './errors';
import CmonError from "./errors/CmonError";
import RequestService from './RequestService';

export interface CmonRequestServiceResponseData {
    request_created?: string;
    request_id?: number;
    request_processed?: string;
    request_status?: string;
    request_user_id?: number;
    error_string?: string;
    [key: string]: any;
};

let cmonApiUrl: string = 'http://localhost:8080';

export default class CmonRequestService extends RequestService {
    protected static addr(): string {
        return `${cmonApiUrl}/${this.module()}`;
    }

    public static setAddr(addr: string): void {
        cmonApiUrl = addr;
    }

    public static module(): void {
        throw new Error(`module() is not implemented`);
    }

    public static async doRequest(
        operation: string,
        data?: any,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        const cookies = Object.fromEntries(
            document.cookie.split(/; */).map((cookie) => cookie.split('=', 2))
        );
        let path = operation && operation.indexOf('/') === 0 ? operation : '';
        const {path: optsPath} = opts || {};
        if (optsPath) {
            path += optsPath;
        }
        const resData: any = await super.doRequest(
            path,
            {
                ...data,
                operation: path ? undefined : operation,
            },
            {
                ...opts,
                ...(cookies['cmon-sid']
                    ? { Cookie: `cmon-sid=${cookies['cmon-sid']}` }
                    : {}),
            }
        );

        if (resData.request_status !== 'Ok') {
            const errorModule = CMONErrors[`Cmon${resData.request_status}Error`];
            if (!errorModule) {
                throw new CmonError(resData);
            }
            throw new errorModule(resData);
        }

        return await transformInstances(resData);
    }
}


function getModule(className: string) {
    switch (className) {
        
        case 'CmonUser':
            return CcModels['CcUser'];

        case 'CmonGroup':
            return CcModels['CcGroup'];

        case 'CmonClusterInfo':
            return CcModels['CcCluster'];

        case 'CmonJobInstance':
            return CcModels['CcJob'];

        case 'CmonBackupRecord':
            return CcModels['CcBackup'];

        case 'CmonLogMessage':
            return CcModels['CcLogEntry'];

        case 'CmonAlarm':
            return CcModels['CcAlarm'];

        case 'CmonRepository':
            return CcModels['CcRepository'];

        case 'CmonLicense':
            return CcModels['CcLicense'];

        case 'CmonLicenseCheck':
            return CcModels['CcLicenseCheck'];

        case 'CmonHost':
            return CcModels['CcNode'];

        case 'CmonHostReplicationSlave':
            return CcModels['CcNodeReplicationSlave'];

        case 'CmonMySqlHost':
            return CcModels['CcMySqlNode'];

        case 'CmonGaleraHost':
            return CcModels['CcGaleraNode'];

        case 'CmonMsSqlHost':
            return CcModels['CcMsSqlNode'];

        case 'CmonAuditEntry':
            return CcModels['CcAuditLog'];

        case 'CmonProxySqlSchedule':
            return CcModels['CcProxySqlSchedule'];

        case 'CmonProxySqlUser':
            return CcModels['CcProxySqlUser'];

        case 'CmonTreeItem':
            return CcModels['CcTreeItem'];

        case 'CmonProxySqlProcessList':
            return CcModels['CcProxySqlProcessList'];

        case 'CmonAccount':
            return CcModels['CcDatabaseAccount'];

        case 'CmonStats':
            return CcModels['CcStats'];

        case 'CmonDbStats':
            return CcModels['CcDbStats'];

        case 'CmonAgentHost':
            return CcModels['CcAgentNode'];

        case 'CmonPkgInfo':
            return CcModels['CcPkgInfo'];

        case 'CmonReport':
            return CcModels['CcReport'];
        default:
            return CMONModels[className];
    }
}

/**
 * Recursively convert objects that has class_name defined
 * to the actual instance of it
 * @param data
 */
export async function transformInstances(data: any) {
    if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data) && data.length !== undefined) {
            data = await Promise.all(data.map(transformInstances));
        } else {
            for (let prop in data) {
                if (prop === 'class_name') {
                    try {
                        const module = getModule(data[prop]);
                        return new module(data);
                    } catch (err) {
                        console.debug(err.message);
                        return data;
                    }
                } else {
                    if (typeof data[prop] === 'object' && data[prop] !== null) {
                        data[prop] = await transformInstances(data[prop]);
                    }
                }
            }
        }
    }
    return data;
}