import React from 'react';
import CcCluster from '../../services/models/CcCluster';
import ClusterTypeFormat from './ClusterTypeFormat';
import WrapFormat, { WrapFormatProps } from '../../common/Format/WrapFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import { Space, Tag } from 'antd';
import { ClustersIcon } from '../../common/icons/icons';
import TypographyText from '../../common/TypographyText';
import ClusterStateFormat, {
    getClusterStateFormatType,
} from './ClusterStateFormat';
import AppDivider from '../../common/AppDivider';
import { StatusFormatType } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { TooltipPlacement } from 'antd/es/tooltip';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';
import AppLink from '../../common/AppLink';
import ClusterTopologySmall from './ClusterTopologySmall';

export default ClusterFormat;

export type ClusterFormatProps = WrapFormatProps & {
    cluster?: CcCluster;
    size?: 'default' | 'large';
    fullData?: boolean;
    withTooltip?: boolean;
    showId?: boolean;
    showStatus?: boolean;
    showType?: boolean;
    children?: React.ReactNode;
    popoverPlacement?: TooltipPlacement;
    wide?: boolean;
    clusterLink?: boolean;
    linkDestination?: string;
    clusterNameMaxWidth?: number;
};

function ClusterFormat({
    cluster,
    size,
    fullData = true,
    showId = true,
    children,
    showStatus,
    showType = true,
    popoverPlacement = 'right',
    wide,
    popoverProps,
    clusterLink,
    linkDestination,
    clusterNameMaxWidth = 250,
    ...rest
}: ClusterFormatProps) {
    const content =
        size === 'large' ? (
            <Space size={15} className="ClusterFormat">
                {showType && (
                    <ClusterTypeFormat
                        type={cluster?.clusterType}
                        vendor={cluster?.vendor}
                        showIcon={true}
                        showDesc={false}
                    />
                )}
                <Space direction="vertical" size={0}>
                    <TypographyText
                        style={{ maxWidth: clusterNameMaxWidth }}
                        ellipsis={
                            !rest?.showPopover
                                ? {
                                      tooltip: cluster?.clusterName,
                                  }
                                : {}
                        }
                    >
                        {cluster?.clusterName}
                    </TypographyText>
                    <span>
                        <TypographyText muted>
                            {showId ? ` ID: ${cluster?.clusterId} |` : null}{' '}
                            <ClusterTypeFormat
                                type={cluster?.clusterType}
                                vendor={cluster?.vendor}
                                showIcon={false}
                            />
                            {' v'}
                            {cluster?.version}
                        </TypographyText>
                    </span>
                </Space>
                {showStatus && cluster ? (
                    <ClusterStateFormat
                        cluster={cluster}
                        type={StatusFormatType.iconFilled}
                        showMaintenance
                    />
                ) : null}
            </Space>
        ) : (
            <Space className="ClusterFormat" size={0}>
                {showStatus && cluster ? (
                    <ClusterStateFormat
                        cluster={cluster}
                        type={StatusFormatType.dot}
                        text={''}
                    />
                ) : null}
                <Space>
                    {showType && (
                        <ClusterTypeFormat
                            type={cluster?.clusterType}
                            vendor={cluster?.vendor}
                            showIcon={true}
                            showDesc={false}
                        />
                    )}
                    <span>
                        <span>
                            {' '}
                            <TypographyText
                                style={{ maxWidth: clusterNameMaxWidth }}
                                ellipsis={
                                    rest.showPopover === undefined ||
                                    rest.showPopover === true
                                        ? {}
                                        : { tooltip: cluster?.clusterName }
                                }
                            >
                                {cluster?.clusterName}
                            </TypographyText>
                        </span>
                        {showId ? (
                            <span>
                                {' ('}ID:{cluster?.clusterId}
                                {')'}
                            </span>
                        ) : null}
                    </span>
                </Space>
            </Space>
        );
    return cluster ? (
        <WrapFormat
            size={size}
            popoverStatus={getClusterStateFormatType(cluster.state)}
            popoverProps={{
                title: (
                    <Space>
                        <ClustersIcon /> Cluster information
                    </Space>
                ),
                destroyTooltipOnHide: true,
                overlayInnerStyle: { minWidth: '490px' },
                placement: popoverPlacement || undefined,
                ...popoverProps,
            }}
            popoverContent={
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                    {fullData ? (
                        <SpaceDescriptions direction="horizontal" size={20}>
                            <SpaceDescriptions.Item
                                label="ID"
                                direction="vertical"
                                colon={false}
                            >
                                {cluster.clusterId}
                            </SpaceDescriptions.Item>

                            <SpaceDescriptions.Item
                                label="Name"
                                direction="vertical"
                                colon={false}
                                style={{ width: 400 }}
                            >
                                <TypographyText strong>
                                    {cluster.clusterName}
                                </TypographyText>
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Status"
                                direction="vertical"
                                colon={false}
                            >
                                <ClusterStateFormat cluster={cluster} />
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                label="Vendor and version"
                                direction="vertical"
                                colon={false}
                            >
                                <ClusterTypeFormat
                                    type={cluster.clusterType}
                                    vendor={cluster.vendor}
                                    showIcon={true}
                                />
                                {cluster.version ? ` v${cluster.version}` : ''}
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    ) : null}

                    <SpaceDescriptions
                        direction="vertical"
                        itemSplit={<AppDivider />}
                    >
                        <SpaceDescriptions direction="horizontal" size={20}>
                            <SpaceDescriptions.Item
                                direction="vertical"
                                label="Tags"
                                colon={false}
                            >
                                {cluster.tagList.length
                                    ? cluster.tagList.map((t) => (
                                          <Tag key={t}>{t}</Tag>
                                      ))
                                    : 'No tags'}
                            </SpaceDescriptions.Item>
                            <SpaceDescriptions.Item
                                direction="vertical"
                                label="Maintenance"
                                colon={false}
                            >
                                <MaintenanceFormat
                                    cluster={cluster}
                                    emptyText="No schedules"
                                    asText={true}
                                />
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                        <SpaceDescriptions.Item
                            direction="vertical"
                            label="Topology"
                            colon={false}
                        >
                            <ClusterTopologySmall cluster={cluster} />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                </Space>
            }
            contentProps={{
                ...rest.contentProps,
                ...(wide
                    ? { style: { width: '100%', ...rest.contentProps?.style } }
                    : {}),
            }}
            {...rest}
        >
            {clusterLink && cluster ? (
                <AppLink
                    to={`/clusters/${cluster?.clusterId}/${
                        linkDestination ? linkDestination : 'dashboard'
                    }`}
                    hoverable
                >
                    {children || content}
                </AppLink>
            ) : (
                children || content
            )}
        </WrapFormat>
    ) : (
        <span>N/A</span>
    );
}
