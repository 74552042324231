import React, { useEffect } from 'react';
import JobDetailsModal from '../Jobs/JobDetailsModal';
import { NumberParam, useQueryParam } from 'use-query-params';
import useJobRecord from '../Jobs/useJobRecord';
import useAlarmRecord from '../Alarms/useAlarmRecord';
import AlarmDetailsModal from '../Alarms/AlarmDetailsModal';

export default GlobalViewer;

export type GlobalViewerProps = {};

/**
 * GlobalViewer component is a global viewer for the application.
 * It is used to display global modals and other global components.
 */
function GlobalViewer({}: GlobalViewerProps) {
    const [openJobId, setOpenJobId] = useQueryParam('openJobId', NumberParam);
    const [openAlarmId, setOpenAlarmId] = useQueryParam(
        'openAlarmId',
        NumberParam
    );

    const {
        refresh: refreshJob,
        record: job,
        loading: loadingJob,
        stopAutoRefresh: stopAutoRefreshJob,
        reset: resetJob,
    } = useJobRecord({});

    const {
        refresh: refreshAlarm,
        record: alarm,
        loading: loadingAlarm,
        reset: resetAlarm,
    } = useAlarmRecord({});

    useEffect(() => {
        if (openJobId) {
            (async () => {
                await refreshJob({ autoRefresh: 5000, jobId: openJobId });
            })();
        }

        if (openAlarmId) {
            (async () => {
                await refreshAlarm({ alarmId: openAlarmId });
            })();
        }

        return () => {
            stopAutoRefreshJob();
        };
    }, [openJobId]);

    const handleJobDetailsModalCancel = () => {
        setOpenJobId(undefined);
        resetJob();
    };
    const handleJobDetailsModalActionPerformed = (action: string) => {
        if (action === 'delete') {
            setOpenJobId(undefined);
            resetJob();
        }
    };

    const handleAlarmDetailsModalCancel = () => {
        setOpenAlarmId(undefined);
        resetAlarm();
    };
    const handleAlarmDetailsModalActionPerformed = (action: string) => {
        if (action === 'delete') {
            setOpenAlarmId(undefined);
            resetAlarm();
        }
    };

    return (
        <div className="GlobalViewer">
            {openJobId ? (
                job ? (
                    <JobDetailsModal
                        loading={loadingJob}
                        job={job}
                        onCancel={handleJobDetailsModalCancel}
                        onActionPerformed={handleJobDetailsModalActionPerformed}
                    />
                ) : null
            ) : null}
            {openAlarmId ? (
                alarm ? (
                    <AlarmDetailsModal
                        loading={loadingAlarm}
                        alarm={alarm}
                        onCancel={handleAlarmDetailsModalCancel}
                        onActionPerformed={
                            handleAlarmDetailsModalActionPerformed
                        }
                    />
                ) : null
            ) : null}
        </div>
    );
}

export function getJobDetailsLinkInActivityViewer(jobId: number) {
    return `${window.location.origin}/activity-center/jobs?openJobId=${jobId}`;
}
export function getJobDetailsLink(jobId: number) {
    const url = new URL(window.location.href);
    url.searchParams.set('openJobId', String(jobId));
    return url.href;
}

export function getAlarmDetailsLinkInActivityViewer(alarmId: number) {
    return `${window.location.origin}/activity-center/alarms?openAlarmId=${alarmId}`;
}

export function getAlarmDetailsLink(alarmId: number) {
    const url = new URL(window.location.href);
    url.searchParams.set('openAlarmId', String(alarmId));
    return url.href;
}
