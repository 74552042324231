import { Col, Row } from 'antd';
import React from 'react';
import NodeAutoRecovery from '../../FormParts/NodeAutoRecovery';

export default RedisShardedAdvancedNodeConfiguration;
export type RedisShardedAdvancedNodeConfigurationProps = {};
function RedisShardedAdvancedNodeConfiguration({}: RedisShardedAdvancedNodeConfigurationProps) {
    return (
        <div className="RedisShardedAdvancedNodeConfiguration">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Advanced configuration</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <NodeAutoRecovery />
                </Col>
            </Row>
        </div>
    );
}
