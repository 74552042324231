import './ClusterListMini.less';
import CcCluster, { CcClusterState } from '../../services/models/CcCluster';
import React, { useEffect } from 'react';
import useClusterList from './useClusterList';
import {
    getClusterStateFormatText,
    getClusterStateFormatType,
} from './ClusterStateFormat';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Form, Select, Space } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { getSortNumberFn } from '../../common/sorting';
import BooleanStatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/BooleanStatusFormat';
import AppDivider from '../../common/AppDivider';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import ClusterFormat from './ClusterFormat';
import AppLink from '../../common/AppLink';
import MaintenanceFormat from '../Maintenance/MaintenanceFormat';
import ClustersEmpty from './ClustersEmpty';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import TableWithJobRow from '../../common/DataDisplay/TableWithJobRow';

export function getClusterListStateFormatSortIndex(
    state: CcClusterState
): number {
    const index = [
        StatusFormatStatus.error,
        StatusFormatStatus.warning,
        StatusFormatStatus.info,
        StatusFormatStatus.unknown,
        StatusFormatStatus.default,
    ].indexOf(getClusterStateFormatType(state));
    return index < 0 ? state.length : index;
}

export type ClusterListMiniProps = {};

export default function ClusterListMini() {
    const {
        list: clusters,
        loading: loadingClusters,
        refresh: refreshClusters,
        filter: filterClusters,
        statuses,
        page,
        pageSize,
        total,
    } = useClusterList({
        name: 'cluster-list',
        pageSize: 10,
    });

    const order = getSortNumberFn('ascend', (cluster) => {
        return getClusterListStateFormatSortIndex(cluster.state);
    });

    useEffect(() => {
        (async () => {
            await refreshClusters({
                order,
            });
        })();
    }, []);

    const handleTableChange = (pagination: any, filter: any, sorters: any) => {
        filterClusters({
            page: pagination.current,
            order,
        });
    };

    const handleStatusChange = (value: string) => {
        pagination.current = 1;
        filterClusters({
            page: pagination.current,
            filters: [
                (cluster: CcCluster) =>
                    'all' === value ? true : value === cluster.state,
            ],
            order,
        });
    };

    const pagination: TablePaginationConfig = {
        pageSize,
        current: page,
        total,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
    };

    const columns = [
        {
            title: 'ID',
            key: 'id',
            render: (cluster: CcCluster) => cluster.clusterId,
        },
        {
            title: 'Name',
            key: 'name',
            width: '100%',
            render: (cluster: CcCluster) =>
                cluster.clusterId ? (
                    <AppLink
                        to={`/clusters/${cluster.clusterId}/dashboard`}
                        hoverable
                    >
                        <ClusterFormat
                            cluster={cluster}
                            showPopover={true}
                            showId={false}
                            popoverPlacement="left"
                            clusterNameMaxWidth={400}
                        />
                    </AppLink>
                ) : (
                    <ClusterFormat
                        cluster={cluster}
                        showPopover={true}
                        showId={false}
                        popoverPlacement="left"
                        clusterNameMaxWidth={400}
                    />
                ),
        },
        {
            title: <span>Auto-recovery Cluster/Node</span>,
            key: 'node_auto_recovery',
            align: 'center',
            render: (cluster: CcCluster) => (
                <Space>
                    <BooleanStatusFormat
                        className={'ClusterListMini_BooleanFormat'}
                        booleanVar={cluster.clusterAutoRecovery}
                    />
                    <AppDivider type="vertical" />
                    <BooleanStatusFormat
                        className={'ClusterListMini_BooleanFormat'}
                        booleanVar={cluster.nodeAutoRecovery}
                    />
                </Space>
            ),
            hideWithJob: true,
        },
        {
            title: 'Warning',
            key: 'warnings',
            align: 'center',
            render: (cluster: CcCluster) =>
                cluster.alarmStat.warning ? cluster.alarmStat.warning : '-',
            hideWithJob: true,
        },
        {
            title: 'Critical',
            key: 'critical',
            align: 'center',
            render: (cluster: CcCluster) =>
                cluster.alarmStat.critical ? cluster.alarmStat.critical : '-',
            hideWithJob: true,
        },
        {
            title: 'Status',
            key: 'status',
            className: 'ClusterListMini_status-column',
            render: (cluster: CcCluster) => (
                <Space>
                    <StatusFormat
                        status={getClusterStateFormatType(cluster.state)}
                        text={getClusterStateFormatText(cluster.state)}
                    />
                    <MaintenanceFormat cluster={cluster} />
                </Space>
            ),
            progress: true,
        },
    ];
    return (
        <div className="ClusterListMini">
            <SpaceDescriptions
                direction="vertical"
                title="Clusters"
                wrapperSize={0}
                extra={
                    <Space>
                        Filter by status:
                        <Form.Item noStyle={true}>
                            <Select
                                defaultValue="all"
                                onChange={handleStatusChange}
                                style={{ width: 120 }}
                                size="small"
                            >
                                <Select.Option value="all">All</Select.Option>
                                {statuses &&
                                    statuses.map((status) => (
                                        <Select.Option
                                            value={status}
                                            key={status}
                                        >
                                            {getClusterStateFormatText(status)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Space>
                }
            >
                <TableWithJobRow
                    command={CmonJobInstanceCommand.CREATE_CLUSTER}
                    jobRecord={(job) =>
                        new CcCluster({
                            deployment_job_id: job.jobId,
                            cluster_name: job.spec.job_data.cluster_name,
                            cluster_type:
                                job.spec.job_data.cluster_type?.toUpperCase(),
                            vendor: job.spec.job_data.vendor,
                            version: job.spec.job_data.version,
                        })
                    }
                    loading={loadingClusters}
                    rowKey={(record: CcCluster) => record.getKey()}
                    dataSource={clusters || []}
                    columns={columns}
                    pagination={pagination}
                    size="middle"
                    onChange={handleTableChange}
                    className="ClusterListMini_table"
                    renderEmpty={<ClustersEmpty loading={loadingClusters} />}
                    responsive={false}
                    onRow={(record: CcCluster, index: number) => ({
                        'data-testid': `cluster-list-row-${index}`,
                    })}
                />
            </SpaceDescriptions>
        </div>
    );
}
