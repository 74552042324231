import React from 'react';
import { Col, Row, InputNumber } from 'antd';
import FormItem from '../../../common/DataEntry/FormItem';
import { FormInstance } from 'antd/lib/form';
import SpaceWide from '../../../common/SpaceWide';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';

export default ClusterInPlaceUpgradeAdvanced;
type ClusterInPlaceUpgradeAdvancedProps = {
    form: FormInstance;
};

function ClusterInPlaceUpgradeAdvanced({
    form,
}: ClusterInPlaceUpgradeAdvancedProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row justify="start">
                <Col span={24}>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <FormItem
                                label="Temporary master port"
                                name={'temporaryMaster'}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="please enter port"
                                ></InputNumber>
                            </FormItem>
                        </Col>

                        <Col span={12}>
                            <FormItem
                                label="Temporary upgrade candidate port"
                                name="temporaryUtcPort"
                                rules={[
                                    {
                                        validator: async (
                                            rule: any,
                                            value: number
                                        ) => {
                                            if (
                                                value ===
                                                    form.getFieldValue(
                                                        'temporaryMaster'
                                                    ) &&
                                                value !== undefined
                                            ) {
                                                throw new Error(
                                                    `Please enter different port number`
                                                );
                                            }
                                            return true;
                                        },
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="please enter port"
                                ></InputNumber>
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <FormItem shouldUpdate={true}>
                                {() => {
                                    const disableDeleteNode =
                                        form.getFieldValue('method') === 'link';
                                    return (
                                        <FormItemInlineSwitch
                                            style={{ margin: '0.8rem 0' }}
                                            justify={true}
                                            disabled={disableDeleteNode}
                                            label={'Delete old node'}
                                            name="deleteNode"
                                            labelStrong={false}
                                            valuePropName="checked"
                                        />
                                    );
                                }}
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={12}>
                                    <FormItem>
                                        <SpaceWide direction="vertical">
                                            <Alert
                                                showIcon
                                                type="warning"
                                                message={
                                                    'All previous data will be also deleted'
                                                }
                                            />
                                        </SpaceWide>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
