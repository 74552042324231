import PostgreSqlConfigurator from '../PostgreSql/PostgreSqlConfigurator';

export default class TimescaleDbConfigurator extends PostgreSqlConfigurator {
    public static getVendors() {
        return [
            {
                name: 'TimescaleDB',
                value: 'postgresql',
            },
        ];
    }
}
