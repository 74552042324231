
import CcNode, { CcNodeProps } from '../../models/CcNode';

export interface RedisShardedHostRedisShardedProps {
    bus_link?: string;
    flags?: string;
    hash_slot_info?: string;
    node_id?: string;
}

export interface RedisShardedHostRedisProps {
    sharded?: RedisShardedHostRedisShardedProps;
}


export interface RedisShardedHostProps  extends CcNodeProps {
    redis?: RedisShardedHostRedisProps;

}

export class RedisShardedHostRedisSharded {
    public busLink?: string;
    public flags?: string;
    public hashSlotInfo?: string;
    public nodeId?: string;

    constructor(props: RedisShardedHostRedisShardedProps) {
        this.busLink = props.bus_link;
        this.flags = props.flags;
        this.hashSlotInfo = props.hash_slot_info;
        this.nodeId = props.node_id;

    }}

export class RedisShardedHostRedis {
    public sharded?: RedisShardedHostRedisSharded;

    constructor(props: RedisShardedHostRedisProps) {
        this.sharded = props.sharded && new RedisShardedHostRedisSharded(props.sharded);

    }}



export default class RedisShardedHost extends CcNode {
    public redis?: RedisShardedHostRedis;


    constructor(props: RedisShardedHostProps) {
        super(props);
        this.redis = props.redis && new RedisShardedHostRedis(props.redis);

    }

}
