import postgresSingleNode from './postgresSingleNode';

const mock = {
    ...postgresSingleNode,
    cluster_id: 15,
    version: '15',
    cluster_name: 'PostgreSQL 12 single node with pgvecotor',
    hosts: [
        {
            ...postgresSingleNode.hosts[0],
            extensions: [
                { name: 'vector', version: '1.0'  },
                { name: 'plpgsql', version: '1.0' },
                { name: 'pg_stat_statements', version: '1.6' },
            ],
        },
        {
            ...postgresSingleNode.hosts[1],
        },
    ],
};

export default mock;
