const result = {
    authenticated: true,
    cluster_type: 'GALERA',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: [
        '8.0.21',
        '8.0.22',
        '8.0.23',
        '8.0.25',
        '8.0.26',
        '8.0.27',
        '8.0.28',
        '8.0.29',
        '8.0.30',
        '8.0.31',
        '8.0.32',
        '8.0.33',
        '8.0.34',
    ],
    request_processed: '2024-01-16T09:20:23.774Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    use_vendor_api: false,
    vendor: 'percona',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
