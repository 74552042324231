const result = {
    authenticated: true,
    cluster_type: 'MONGODB',
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    db_versions: ['4.4', '5.0', '6.0'],
    request_processed: '2024-03-14T10:03:13.497Z',
    request_status: 'Ok',
    request_user_id: 6,
    rpc_version: '2.0',
    vendor: '10gen',
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
};
export default result;
