import React, { useState } from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster from '../../../services/models/CcCluster';
import AddNodeReplicationModal from './AddNode/Replication/AddNodeReplicationModal';
import { ButtonProps } from 'antd/lib/button';
import {CcNodeRole} from "../../../services/models/CcNode";

export default ClusterAddReplicationNode;

export type ClusterAddReplicationSecondaryProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
    nodeRole?: CcNodeRole.PRIMARY | CcNodeRole.REPLICA;
};

function ClusterAddReplicationNode({
    cluster,
    onSuccess,
    children,
    nodeRole = CcNodeRole.REPLICA,
    ...rest
}: ClusterAddReplicationSecondaryProps) {
    const [command, setCommand] = useState<CmonJobInstanceCommand>(
        CmonJobInstanceCommand.ADD_REPLICATION_SLAVE
    );
    const handleCommandChange = (command: CmonJobInstanceCommand) => {
        setCommand(command);
    };

    let title = children;
    if (!title) {
        if (nodeRole === 'replica') {
            title = 'Add a replica node';
        } else if (nodeRole === 'primary') {
            title = 'Add a primary node';
        }
    }

    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={command}
                    clusterId={cluster.clusterId}
                    {...rest}
                >
                    {title}
                </JobActionButton>
            }
            form={
                <AddNodeReplicationModal
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onCommandChange={handleCommandChange}
                    type={nodeRole}
                />
            }
        />
    );
}
