import { useCallback, useState } from 'react';
import { addNewRunningJob } from '../../appReducer';
import { useDispatch } from 'react-redux';
import CmonJobService from '../../services/cmon/CmonJobsService';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import { useNotificationContext } from '../Notifications/NotificationProvider';

export type UseCreateJobProps = {
    title: string;
    clusterId?: number;
    command: CmonJobInstanceCommand;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
    successTitle?: React.ReactNode;
    errorTitle?: React.ReactNode;
};

export default function useCreateJob({
    title,
    clusterId,
    command,
    onSuccess,
    onError,
    successTitle,
    errorTitle,
}: UseCreateJobProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {
        notifyJobCreationError,
        notifyJobCreationSuccess,
    } = useNotificationContext();

    const send = useCallback(
        async (jobData?, extra?: any): Promise<false | any> => {
            setLoading(true);
            try {
                const jobSpec = { command, job_data: jobData };
                const { job } = await CmonJobService.createJobInstance({
                    ...extra,
                    cluster_id: clusterId,
                    job: {
                        class_name: 'CmonJobInstance',
                        title,
                        ...extra?.job,
                        job_spec: JSON.stringify(jobSpec),
                    },
                });
                dispatch(addNewRunningJob(job));
                setLoading(false);
                notifyJobCreationSuccess({
                    job: job,
                    ...(successTitle ? { title: successTitle } : {}),
                });
                onSuccess?.();
                return job || true;
            } catch (err) {
                setLoading(false);
                notifyJobCreationError({
                    ...(errorTitle ? { title: errorTitle } : {}),
                    content: err.message,
                });
                onError?.(err);
                console.error(err);
                return false;
            }
        },
        [command, title, clusterId]
    );

    return { loading, send };
}
