import FileSaver from 'file-saver';

export enum ApiErrorType {
    UnknownError = 'UnknownError',
}

export type ApiErrorProps = {
    message?: string;
    type?: ApiErrorType | string;
    code?: number;
};
export class ApiError extends Error {
    public code: number;
    public message: string;
    public type: ApiErrorType | string;

    constructor({ message, type, code }: ApiErrorProps) {
        super(message);
        this.code = code || 500;
        this.type = type || ApiErrorType.UnknownError;
        this.message = message || 'Unknown error';
    }
}

export type RequestOpts = {
    headers?: any;
    requestId?: string;
    requestName?: string;
    useCache?: boolean;
    cancelPrevious?: boolean;
    override?: {
        addr?: string;
    };
};

export class RequestCancelledError extends Error {
    public isCanceled: boolean;
    constructor() {
        super('Request cancelled');
        this.name = 'RequestCancelledError';
        this.isCanceled = true;
    }
}

// CACHE
let REQUEST_CACHE_MS = 0;
type RequestCacheObject = {
    promise: any;
    expires: number;
    requestId: string;
};
// Request cache map
// Cache is based on path and data params
let requestCacheMap: {
    [key: string]: RequestCacheObject;
} = {};

// Request abort controllers map
let requestControllersMap: {
    [key: string]: {
        controller: AbortController | { abort: () => void };
    };
} = {};

let errorHandlerFn = (path?: string, data?: any, res?: any, resData?: any) => {
    console.warn('Error handler not implemented');
};

let fixedRequestParams = {};

export default class RequestService {
    protected static addr(): string {
        throw new Error('addr() not implemented');
    }

    public static setAddr(addr: string): void {
        throw new Error('setAddr() not implemented');
    }

    public static setRequestCacheMs(ms: number): void {
        REQUEST_CACHE_MS = ms;
    }

    public static getRequestCacheMs(): number {
        return REQUEST_CACHE_MS;
    }

    public static errorHandler(
        path?: string,
        data?: any,
        res?: any,
        resData?: any
    ): void {
        errorHandlerFn(path, data, res, resData);
    }

    public static setErrorHandlerFn(fn: typeof errorHandlerFn) {
        errorHandlerFn = fn;
    }

    public static setFixedRequestParams(params: typeof fixedRequestParams) {
        fixedRequestParams = params;
    }
    public static getFixedRequestParams(): typeof fixedRequestParams {
        return fixedRequestParams;
    }

    public static async cancelRequest(
        id: string,
        name?: string
    ): Promise<boolean> {
        if (requestControllersMap[id]) {
            await requestControllersMap[id].controller.abort();
            console.debug(`Canceled request '${id}', name: ${name}.`);
            RequestService.deleteRequestController(id);
            return true;
        } else {
            console.debug(`No request '${id}' to cancel.`);
            return false;
        }
    }

    public static addRequestController(
        id: string,
        controller: AbortController
    ): void {
        console.debug('Adding request to controller Map', id);
        requestControllersMap[id] = {
            controller,
        };
    }

    public static hasRequestController(id: string): boolean {
        return !!requestControllersMap[id];
    }

    public static deleteRequestController(id: string): boolean {
        if (requestControllersMap[id]) {
            delete requestControllersMap[id];
            console.debug(`Deleted controller '${id}'.`);
            return true;
        } else {
            console.debug(`No request '${id}' to delete.`);
            return false;
        }
    }

    public static addRequestCache(
        id: string,
        object: RequestCacheObject
    ): void {
        console.debug('Adding request to cache Map', id);
        requestCacheMap[id] = object;
    }

    public static hasRequestCache(id: string): boolean {
        return !!requestCacheMap[id];
    }

    public static deleteRequestCache(id: string): boolean {
        if (requestCacheMap[id]) {
            delete requestCacheMap[id];
            console.debug(`Deleted cache '${id}'.`);
            return true;
        } else {
            console.debug(`No cache '${id}' to delete.`);
            return false;
        }
    }

    public static async doRequest(
        path?: string,
        data?: any,
        opts: RequestOpts = {}
    ): Promise<any> {
        const dataToSend = { ...fixedRequestParams, ...data };
        const body = JSON.stringify(dataToSend);
        const {
            headers,
            requestId,
            requestName,
            useCache,
            cancelPrevious,
            override,
            ...restOpts
        } = opts;

        const cacheId = RequestService.generateId(path, dataToSend);
        const cachedRequest = requestCacheMap[cacheId];

        let promise;
        const abortControllerId =
            requestId || cacheId || `${new Date().getTime()}`;

        const expirationMs = new Date().getTime() + REQUEST_CACHE_MS;
        if (
            useCache &&
            cachedRequest &&
            new Date().getTime() < cachedRequest.expires
        ) {
            console.debug(
                `Using cache ${RequestService.generateId(
                    path,
                    dataToSend
                )}, requestName: ${requestName},requestId: ${requestId}, cacheId: ${cacheId}`
            );
            await RequestService.cancelRequest(abortControllerId, requestName);
            promise = cachedRequest.promise;
        } else {
            promise = new Promise((resolve, reject) => {
                console.debug(
                    `Fetching ${RequestService.generateId(
                        path,
                        dataToSend
                    )}, requestName: ${requestName}, requestId: ${requestId}`
                );

                const controller = new AbortController();
                const fetchPromise = fetch(
                    `${override?.addr || this.addr()}${path ? `${path}` : ''}`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            ...headers,
                        },
                        credentials: 'include',
                        body,
                        signal: controller ? controller.signal : null,
                        ...{ restOpts },
                    }
                );

                // cancelling previous request
                RequestService.cancelRequest(
                    abortControllerId,
                    requestName
                ).then(() => {
                    fetchPromise
                        .then((res) => {
                            RequestService.deleteRequestController(
                                abortControllerId
                            );
                            resolve(res);
                        })
                        .catch((e) => {
                            RequestService.deleteRequestController(
                                abortControllerId
                            );
                            RequestService.deleteRequestCache(
                                RequestService.generateId(path, dataToSend)
                            );
                            reject(e);
                        });

                    // handling onabort event from signal controller
                    controller.signal.onabort = () => {
                        // rejecting request as cancelled
                        reject(new RequestCancelledError());
                    };

                    RequestService.addRequestController(
                        abortControllerId,
                        controller
                    );
                });
            });

            // caching the request
            RequestService.addRequestCache(
                RequestService.generateId(path, dataToSend),
                {
                    promise,
                    expires: expirationMs,
                    requestId: abortControllerId,
                }
            );
        }

        const res: any = await promise;
        let resData;
        if (res.status !== 201 && res.status !== 202) {
            try {
                resData = await res.clone().json();
            } catch (e) {
                if (res.status >= 500) {
                    throw new Error('Server error');
                }
                if (res.status >= 400) {
                    throw new Error('Request error');
                }
                throw new Error('Server response is not valid');
            }
        }
        if (res.status > 300) {
            this.errorHandler(path, dataToSend, res, resData);
            // throw new ApiError({ ...resData, code: res.status });
        }

        return resData;
    }

    protected static generateId(path?: string, data?: any): string {
        return `${path}${JSON.stringify(data)}`;
    }

    public static async downloadFile({
        body,
        defaultFileName = 'cc-downloaded-file',
    }: {
        body: any;
        defaultFileName?: string;
    }) {
        const response = await fetch(this.addr(), {
            method: 'post',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const responseHeaders = response.headers;

        const fileName = await responseHeaders
            .get('Content-Disposition')
            ?.split(';')
            .find((part) => part.indexOf('filename=') !== -1)
            ?.split('=')[1]
            ?.replaceAll('"', '');

        const blob = await response.blob();

        FileSaver.saveAs(blob, fileName || defaultFileName);
    }
}
