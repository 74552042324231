import mysqlReplication from './../../clusters/mysqlReplication';

const mock: any = {
    controller_id: '4efbf326-7087-47d7-9200-6c4a135494af',
    request_processed: '2023-12-26T10:15:14.821Z',
    request_status: 'Ok',
    request_user_id: 6,
    total: 1,
    debug_messages: ["RPC V2 authenticated user is 'ccadmin'."],
    maintenance_records: [
        {
            class_name: 'CmonMaintenanceInfo',
            cluster_id: 2,
            is_active: true,
            cluster: mysqlReplication,
            maintenance_periods: [
                {
                    UUID: '798c66a8-0cf0-4806-b967-d91ab157b0a0',
                    deadline: '2023-12-26T11:15:08.338Z',
                    groupid: 1,
                    groupname: 'admins',
                    initiate: '2023-12-26T10:15:08.338Z',
                    is_active: true,
                    userid: 6,
                    username: 'ccadmin',
                },
            ],
        },
        {
            class_name: 'CmonMaintenanceInfo',
            hostname: '10.10.10.17',
            is_active: true,
            cluster: mysqlReplication,
            maintenance_periods: [
                {
                    UUID: '798c66a8-0cf0-4806-b967-d91ab157b0a0',
                    deadline: '2023-12-26T11:15:08.338Z',
                    groupid: 1,
                    groupname: 'admins',
                    initiate: '2023-12-26T10:15:08.338Z',
                    is_active: true,
                    userid: 6,
                    username: 'ccadmin',
                },
            ],
        },
    ],
};
export default mock;
