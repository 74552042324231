import React, { Fragment } from 'react';
import NodeFormat from '../../Nodes/NodeFormat';
import NodeActionsMenu from '../../Nodes/Actions/NodeActionsMenu';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import SpaceWide from '../../../common/SpaceWide';
import AppCard from '../../../common/DataDisplay/AppCard';
import { Space } from 'antd';
import { IDiagramItem } from '@severalnines/bar-frontend-components/build/lib/DataDisplay/Diagram';
import ClusterTopologyConfigurator from './ClusterTopologyConfigurator';
import classNames from 'classnames';
import AppDivider from '../../../common/AppDivider';
import './ClusterTopologyNodeItem.less';
import ShowMore from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ShowMore';
import CardSlider from '@severalnines/bar-frontend-components/build/lib/DataDisplay/CardSlider';
import NodeAlert from '../../Nodes/NodeAlert';
import MaintenanceFormat from '../../Maintenance/MaintenanceFormat';
import NodeRunningJobsFormat from '../../Nodes/NodeRunningJobsFormat';
import UserAclManageCluster from '../../User/UserAclManageCluster';

export default ClusterTopologyNodeItem;

export type ClusterTopologyNodeItemProps = {
    item: IDiagramItem;
    configurator: ClusterTopologyConfigurator;
    className?: string;
};

function ClusterTopologyNodeItem({
    item,
    configurator,
    className,
}: ClusterTopologyNodeItemProps) {
    const coverCard = configurator.getNodeCoverCardConfig(item.data.node);
    const moreCards = configurator.getNodeExtraCardsConfig(item.data.node);
    return (
        <AppCard
            size="small"
            className={classNames('ClusterTopologyNodeItem', className)}
            title={
                <Space>
                    <NodeFormat
                        node={item.data.node}
                        showStatus={true}
                        showText={true}
                        showType={false}
                        truncate={true}
                        extraRight={
                            <NodeRunningJobsFormat node={item.data.node} />
                        }
                    />
                    <MaintenanceFormat node={item.data.node} />
                </Space>
            }
            extra={
                <UserAclManageCluster cluster={item.data.cluster}>
                    <NodeActionsMenu
                        node={item.data.node}
                        cluster={item.data.cluster}
                    />
                </UserAclManageCluster>
            }
        >
            <SpaceDescriptions direction="vertical">
                <NodeAlert node={item.data.node} />
                {coverCard.map((row) => (
                    <SpaceWide justify="space-between">
                        {row.map(({ label, value }) => (
                            <SpaceDescriptions.Item label={label}>
                                {value}
                            </SpaceDescriptions.Item>
                        ))}
                    </SpaceWide>
                ))}
            </SpaceDescriptions>
            {moreCards.length > 0 && (
                <Fragment>
                    <AppDivider />
                    <ShowMore>
                        <CardSlider
                            items={moreCards.map((c, index) => ({
                                key: `${index}`,
                                content: (
                                    <SpaceDescriptions
                                        direction="vertical"
                                        className={
                                            'ClusterTopologyNodeItem_extra-card'
                                        }
                                    >
                                        {c.map((row) => (
                                            <SpaceWide justify="space-between">
                                                {row.map(({ label, value }) => (
                                                    <SpaceDescriptions.Item
                                                        label={label}
                                                    >
                                                        {value}
                                                    </SpaceDescriptions.Item>
                                                ))}
                                            </SpaceWide>
                                        ))}
                                    </SpaceDescriptions>
                                ),
                            }))}
                        />
                    </ShowMore>
                </Fragment>
            )}
        </AppCard>
    );
}
