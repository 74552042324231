import React, { useState } from 'react';
import JobActionButton from '../../Jobs/JobActionButton';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import ButtonWithForm from '../../../common/General/ButtonWithForm';
import CcCluster, { CcClusterType } from '../../../services/models/CcCluster';
import { ButtonProps } from 'antd/lib/button';
import AddNodeDatabaseModal from './AddNode/Database/AddNodeDatabaseModal';
import { AddNodeWizardStep } from './AddNode/AddNodeWizard';

export default ClusterAddNode;

export type ClusterAddNodeProps = ButtonProps & {
    cluster: CcCluster;
    children?: React.ReactNode;
    onSuccess?: () => void;
    wizardTitle?: string;
};

function ClusterAddNode({
    cluster,
    onSuccess,
    children,
    wizardTitle,
    ...rest
}: ClusterAddNodeProps) {
    const [command, setCommand] = useState<CmonJobInstanceCommand>(
        CmonJobInstanceCommand.ADDNODE
    );
    const handleCommandChange = (command: CmonJobInstanceCommand) => {
        setCommand(command);
    };
    return (
        <ButtonWithForm
            button={
                <JobActionButton
                    command={command}
                    clusterId={cluster.clusterId}
                    clusterType={cluster.clusterType}
                    {...rest}
                >
                    {children || 'Add node'}
                </JobActionButton>
            }
            form={
                <AddNodeDatabaseModal
                    cluster={cluster}
                    onSuccess={onSuccess}
                    onCommandChange={handleCommandChange}
                    title={wizardTitle}
                    defaultWizardStep={
                        [
                            CcClusterType.TYPE_ELASTIC,
                            CcClusterType.TYPE_REDIS,
                            CcClusterType.TYPE_REDIS_SHARDED,
                            CcClusterType.TYPE_MSSQL_AO_ASYNC,
                        ].includes(cluster?.clusterType) // import node isn't supported atm
                            ? AddNodeWizardStep.FORM_NEW
                            : undefined
                    }
                />
            }
        />
    );
}
