import React, { useMemo } from 'react';
import ActionsMenu, {
    ActionsMenuProps,
} from '../../common/Navigation/ActionsMenu';
import { ActionMenuItem } from '@severalnines/bar-frontend-components/build/lib/Navigation/ActionMenu';
import CcGroup from '../../services/models/CcGroup';
import GroupDeleteButton from './Actions/GroupDeleteButton';
import GroupDetailsButton from './Actions/GroupDetailsButton';
import GroupEditButton from './Actions/GroupEditButton';
import {
    DeleteOutlined,
    EditOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import AppDivider from '../../common/AppDivider';

export default GroupActionMenu;

export type GroupActionMenuProps = ActionsMenuProps & {
    group: CcGroup;
};

function GroupActionMenu({
    group,
    onActionPerformed,
    ...rest
}: GroupActionMenuProps) {
    const handleGroupDeleteSuccess = () => {
        onActionPerformed?.();
    };
    const handleGroupEditSuccess = () => {
        onActionPerformed?.();
    };
    const items = useMemo(() => {
        const deleteActionButton = !group.isSystemUserOwnedGroup() && {
            key: 'delete',
            waitForConfirm: true,
            critical: true,
            icon: <DeleteOutlined />,
            label: (
                <GroupDeleteButton
                    group={group}
                    onSuccess={handleGroupDeleteSuccess}
                />
            ),
        };

        const criticalDivider = deleteActionButton
            ? {
                  type: 'divider',
                  label: <AppDivider noMargin={true} />,
              }
            : undefined;
        return [
            {
                key: 'view',
                icon: <ProfileOutlined />,
                label: (
                    <GroupDetailsButton
                        group={group}
                        onEdit={handleGroupEditSuccess}
                    />
                ),
            },
            {
                key: 'edit',
                icon: <EditOutlined />,
                label: (
                    <GroupEditButton
                        group={group}
                        onSuccess={handleGroupEditSuccess}
                    />
                ),
            },
            criticalDivider,
            deleteActionButton,
        ].filter(Boolean);
    }, [group]);
    return <ActionsMenu items={items as ActionMenuItem[]} {...rest} />;
}
