import React, { useContext } from 'react';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import TableWithJobRow, {
    TableWithJobRowProps,
} from '../../common/DataDisplay/TableWithJobRow';
import CmonReport from '../../services/cmon/models/CmonReport';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import AppDateFormat from '../../common/AppDateFormat';
import AppEmpty from '../../common/Feedback/AppEmpty';
import ActionsMenu from '../../common/Navigation/ActionsMenu';
import DeleteReportActionButton from './DeleteReportActionButton';
import DownloadReportActionButton from './DownloadReportActionButton';
import { DeleteOutlined, DownCircleOutlined } from '@ant-design/icons';

export type ReportItem = {
    created: string;
    clusterId: number;
    id: number;
    path: string;
    size: string;
    www: boolean;
    deploymentJobId?: number;
};

export default ReportsTable;
export type ReportsTableProps = Omit<TableWithJobRowProps, 'command'> & {
    clusterId?: number;
    onJobFinished?: () => void;
    onDeleted?: () => void;
};

function ReportsTable({ dataSource, onDeleted, ...rest }: ReportsTableProps) {
    const { responsive } = useContext(ResponsiveContext);

    const columns = [
        {
            title: 'File path',
            key: 'file_path',
            render: (record: ReportItem) => record.path,
            hideWithJob: true,
        },
        {
            title: 'Size',
            key: 'size',
            render: (record: ReportItem) => record.size,
            hideWithJob: true,
        },
        {
            title: 'Created',
            key: 'created',
            render: (record: ReportItem) => (
                <AppDateFormat fromNow>
                    {record.created ? new Date(record.created) : undefined}
                </AppDateFormat>
            ),
            progress: true,
            progressProps: {
                fakeProgress: true,
            },
        },

        {
            key: 'actions',
            title: 'Actions',
            align: 'center',
            render: (record: ReportItem) => (
                <ActionsMenu
                    items={[
                        {
                            key: 'download',
                            icon: <DownCircleOutlined />,
                            label: (
                                <DownloadReportActionButton report={record} />
                            ),
                        },
                        {
                            key: 'delete',
                            waitForConfirm: true,
                            critical: true,
                            icon: <DeleteOutlined />,
                            label: (
                                <DeleteReportActionButton
                                    report={record}
                                    onSuccess={onDeleted}
                                />
                            ),
                        },
                    ]}
                />
            ),
            actions: true,
            onCell: () => ({ style: { padding: '2px 10px' } }),
        },
    ];

    const handleRow = (record: CmonReport, index: number) => {
        return { 'data-testid': `report-list-row-${index}` };
    };

    return (
        <TableWithJobRow
            className="ReportsList"
            rowKey={(record: ReportItem) => record.id}
            dataSource={dataSource}
            columns={columns}
            onRow={handleRow}
            command={CmonJobInstanceCommand.ERROR_REPORT}
            responsive={responsive}
            pagination={false}
            jobRecord={(job) => ({
                deploymentJobId: job.jobId,
            })}
            renderEmpty={
                <AppEmpty
                    loading={false}
                    description="You haven’t created reports yet. When you do, it'll show up here."
                />
            }
            {...rest}
        />
    );
}
