import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import AppEmpty from '../../common/Feedback/AppEmpty';
import { Space } from 'antd';
import useSnapshotRepositoryList from './useSnapshotRepositoryList';
import ClusterSelectDropdown from '../Clusters/ClusterSelectDropdown';
import CcCluster, { CcClusterType } from '../../services/models/CcCluster';
import CcSnapshotRepository from '../../services/models/CcSnapshotRepository';
import useClusterList from '../Clusters/useClusterList';
import SnapshotRepositoryType from './SnapshotRepositoryType';
import SnapshotRepositoryActionsMenu from './SnapshotRepositoryActionsMenu';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import SpaceWide from '../../common/SpaceWide';
import AppTable from '../../common/DataDisplay/AppTable';

export default forwardRef(ElasticSnapshotRepositoryList);

export interface ElasticSnapshotRepositoryListApi {
    refresh: () => Promise<void>;
}

export type ElasticSnapshotRepositoryListProps = {};

function ElasticSnapshotRepositoryList(
    { ...rest }: ElasticSnapshotRepositoryListProps,
    ref: any
) {
    const [cluster, setCluster] = useState<CcCluster>();
    const {
        list: clusterList,
        loading: loadingClusters,
        refresh: refreshClusters,
    } = useClusterList({ useCache: true });
    const { responsive } = useContext(ResponsiveContext);
    const { data, loading, refresh } = useSnapshotRepositoryList({
        clusterId: cluster?.clusterId,
    });
    useImperativeHandle(
        ref,
        (): ElasticSnapshotRepositoryListApi => ({
            async refresh() {
                await refresh();
            },
        })
    );

    useEffect(() => {
        (async () => {
            await refreshClusters({
                filters: [
                    (cluster: CcCluster) =>
                        cluster.isType(CcClusterType.TYPE_ELASTIC),
                ],
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (cluster) {
                await refresh();
            }
        })();
    }, [cluster]);

    const handleClusterSelect = (cluster?: CcCluster) => {
        setCluster(cluster);
    };

    const columns = [
        {
            title: 'Repository name',
            key: 'name',
            render: (record: CcSnapshotRepository) => record.name,
        },
        {
            title: 'Type',
            key: 'type',
            render: (record: CcSnapshotRepository) =>
                record.type ? (
                    <SnapshotRepositoryType type={record.type} />
                ) : (
                    ''
                ),
        },
        {
            title: 'Location',
            key: 'location',
            render: (record: CcSnapshotRepository) => record.getLocation(),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 30,
            render: (record: CcSnapshotRepository) =>
                cluster && (
                    <SnapshotRepositoryActionsMenu
                        repository={record}
                        clusterId={cluster?.clusterId as number}
                        onActionPerformed={async () => {
                            await refresh();
                        }}
                    />
                ),
        },
    ];
    return clusterList && clusterList?.length > 0 ? (
        <SpaceWide direction="vertical" size={15}>
            <ClusterSelectDropdown
                selectFirst={true}
                useGlobalState
                showId
                filterClusters={(cluster) =>
                    cluster.clusterType === CcClusterType.TYPE_ELASTIC
                }
                onClusterSelect={handleClusterSelect}
            />
            <AppTable
                loading={loading || loadingClusters}
                rowKey={(record: CcSnapshotRepository) => record.name || ''}
                dataSource={data}
                renderEmpty={
                    <AppEmpty
                        loading={loading || loadingClusters}
                        description="You haven’t created repositories yet. When you do, it'll show up here."
                    />
                }
                columns={columns}
                size="middle"
                responsive={responsive}
                footer={() => <Space size="large" />}
                pagination={false}
            />
        </SpaceWide>
    ) : (
        <AppEmpty
            loading={loading || loadingClusters}
            description="Nothing to see here... yet. You have to deploy an Elasticsearch cluster first."
        />
    );
}
