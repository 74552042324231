import React, { ReactElement, useMemo } from 'react';
import { AddNodeJobDataDTO, AddNodeWizardAction } from '../AddNodeWizard';
import AddNodeFormWizard, {
    AddNodeFormWizardFields,
    AddNodeFormWizardProps,
    AddNodeFormWizardStep,
    getAddNodeWizardStepName,
} from '../AddNodeFormWizard';
import { FormInstance } from 'antd/lib/form';
import CcCluster, {
    CcClusterType,
} from '../../../../../services/models/CcCluster';
import AddNodeConfigurationForm, {
    getImportDatabaseNodeConfigurationFields,
    getNewDatabaseNodeConfigurationFields,
    getNodeConfigurationValidatingFields,
} from '../AddNodeConfigurationForm';
import {
    getAdvancedValidatingFields,
    getDatabaseNodeAdvancedForm,
} from '../AddNodeAdvancedForm';
import AddNodeHostForm, {
    getNodeHostValidatingFields,
} from '../AddNodeHostForm';
import AddNodeSummary from '../AddNodeSummary';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { MongoNodeType } from '../../../../../common/Form/Fields/MongoNodeTypeSelectField';
import { topologyItemValidator } from '../../../../Services/Cluster/Mssql/MssqlConfigurator';
import { CcElasticRole } from '../../../../../services/models/CcElasticNode';

export default DatabaseFormWizard;

export type DatabaseFormWizardProps = Omit<
    AddNodeFormWizardProps,
    'steps' | 'onSubmit'
> & {
    form: FormInstance<AddNodeFormWizardFields>;
    cluster: CcCluster;
    action: AddNodeWizardAction;
    activeStep?: AddNodeFormWizardStep;
    onSubmit?: (
        fields: AddNodeFormWizardFields,
        action: AddNodeWizardAction
    ) => void;
};

const MONGO_DB_PORT = 27017;
const MONGO_ROUTER_PORT = 27018;

function DatabaseFormWizard({
    form,
    cluster,
    action,
    activeStep,
    onSubmit,
    ...rest
}: DatabaseFormWizardProps) {
    const handleSubmit = () => {
        onSubmit?.(form.getFieldsValue(true), action);
    };

    const handleValuesChange = (values: AddNodeFormWizardFields) => {
        if (
            (cluster.isType(CcClusterType.TYPE_MONGODB) ||
                cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS)) &&
            values.isMongoRouter !== undefined
        ) {
            form.setFieldsValue({
                nodePort: values.isMongoRouter
                    ? MONGO_ROUTER_PORT
                    : MONGO_DB_PORT,
            });
        }
    };
    const steps = useMemo(() => {
        const actionSteps =
            action === AddNodeWizardAction.NEW
                ? cluster.isType([
                      CcClusterType.TYPE_MONGODB,
                      CcClusterType.TYPE_MONGODB_SHARDS,
                      CcClusterType.TYPE_ELASTIC,
                      CcClusterType.TYPE_REDIS,
                      CcClusterType.TYPE_REDIS_SHARDED,
                      CcClusterType.TYPE_MSSQL_AO_ASYNC,
                  ])
                    ? [
                          AddNodeFormWizardStep.CONFIG,
                          AddNodeFormWizardStep.NODE,
                          AddNodeFormWizardStep.SUMMARY,
                      ]
                    : [
                          AddNodeFormWizardStep.CONFIG,
                          AddNodeFormWizardStep.ADVANCED,
                          AddNodeFormWizardStep.NODE,
                          AddNodeFormWizardStep.SUMMARY,
                      ]
                : [
                      AddNodeFormWizardStep.CONFIG,
                      AddNodeFormWizardStep.NODE,
                      AddNodeFormWizardStep.SUMMARY,
                  ];
        return actionSteps
            .map((step) =>
                getDatabaseNodeFromWizardStep(cluster, form, step, action)
            )
            .filter((step) => !!step) as ReactElement[];
    }, [cluster, action]);

    return (
        <AddNodeFormWizard
            form={form}
            activeKey={activeStep}
            onSubmit={handleSubmit}
            onValuesChange={handleValuesChange}
            initialValues={
                action === AddNodeWizardAction.IMPORT
                    ? getImportDatabaseNodeDefaultValues(cluster)
                    : getNewDatabaseNodeDefaultValues(cluster)
            }
            {...rest}
            steps={steps}
        />
    );
}

export function getDatabaseNodeFromWizardStep(
    cluster: CcCluster,
    form: FormInstance,
    step: AddNodeFormWizardStep,
    action: AddNodeWizardAction
): React.ReactElement | undefined {
    switch (step) {
        case AddNodeFormWizardStep.CONFIG:
            const availableFields =
                action === AddNodeWizardAction.NEW
                    ? getNewDatabaseNodeConfigurationFields(cluster.clusterType)
                    : getImportDatabaseNodeConfigurationFields(
                          cluster.clusterType
                      );
            /**
             * @todo check if field list in other wizard steps needs to be adjusted
             */
            const validateFields = getNodeConfigurationValidatingFields().filter(
                (field) => availableFields.includes(field)
            );
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={validateFields}
                    hasRequiredFields={true}
                >
                    <AddNodeConfigurationForm
                        cluster={cluster}
                        form={form}
                        availableFields={availableFields}
                    />
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.ADVANCED:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={getAdvancedValidatingFields()}
                    hasRequiredFields={true}
                >
                    {getDatabaseNodeAdvancedForm(
                        cluster.clusterId as number,
                        cluster.clusterType
                    )}
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.NODE:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                    validate={() =>
                        getNodeHostValidatingFields(
                            form.getFieldValue('topology')
                        )
                    }
                    hasRequiredFields={true}
                >
                    <AddNodeHostForm
                        cluster={cluster}
                        form={form}
                        hasPrimary={false}
                        hasDataIp={true}
                        isImport={action === AddNodeWizardAction.IMPORT}
                        validateItem={getTopologyItemValidator(cluster)}
                    />
                </WizardFormConfiguration.Step>
            );
        case AddNodeFormWizardStep.SUMMARY:
            return (
                <WizardFormConfiguration.Step
                    key={step}
                    title={getAddNodeWizardStepName(step)}
                    subTitle=" "
                >
                    <AddNodeSummary
                        form={form}
                        hasAdvanced={
                            action === AddNodeWizardAction.NEW &&
                            ![
                                CcClusterType.TYPE_REDIS,
                                CcClusterType.TYPE_REDIS_SHARDED,
                                CcClusterType.TYPE_MSSQL_AO_ASYNC,
                                CcClusterType.TYPE_ELASTIC,
                            ].includes(cluster.clusterType)
                        }
                        hasSecurity={action === AddNodeWizardAction.NEW}
                        clusterType={cluster.clusterType}
                    />
                </WizardFormConfiguration.Step>
            );
        default:
            return undefined;
    }
}

export function getNewDatabaseNodeDefaultValues(
    cluster: CcCluster
): AddNodeFormWizardFields {
    const node = cluster.primaryNode || cluster.nodes?.[0];
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_GALERA:
            return {
                netcatPorts: '9999, 9990-9998',
                nodePort: 3306,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
                dataDir: node.datadir || '/var/lib/mysql/',
                delayReplica: false,
                delayReplicaSeconds: 3600,
                rebuildFromBackup: false,
                includeLB: false,
                semiSynchronous: true,
                galeraSegment: 0,
            };
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return {
                nodePort: MONGO_DB_PORT,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
                mongoNodeType: MongoNodeType.DB,
            };
        case CcClusterType.TYPE_ELASTIC:
            return {
                nodePort: 9200,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
                nodeRole: CcElasticRole.MASTER_DATA,
            };
        case CcClusterType.TYPE_REDIS:
            return {
                nodePort: 6379,
                redisSentinelPort: 26379,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
            };
        case CcClusterType.TYPE_REDIS_SHARDED:
            return {
                nodePort: 6379,
                isPrimaryNode: false,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
            };
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return {
                mssqlServerPort: 1433,
                installSoftware: true,
                disableFirewall: true,
                disableSeLinux: true,
            };
        default:
            return {};
    }
}

export function getImportDatabaseNodeDefaultValues(
    cluster: CcCluster
): AddNodeFormWizardFields {
    const node = cluster.primaryNode || cluster.nodes?.[0];
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_GALERA:
            return {
                nodePort: 3306,
                includeLB: false,
                dataDir: node.datadir || '/var/lib/mysql/',
            };
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return {
                nodePort: 27017,
            };
        default:
            return {};
    }
}

export function getNewDatabaseNodeJobDataDTO(
    fields: AddNodeFormWizardFields,
    cluster: CcCluster
): AddNodeJobDataDTO {
    const defaultData: AddNodeJobDataDTO = {
        node: {
            hostname: fields.topology?.[0].extraData.hostname,
            hostname_internal: fields.nodeDataIp || '',
        },
        install_software: fields.installSoftware,
        disable_selinux: fields.disableSeLinux,
        disable_firewall: fields.disableFirewall,
        clusterId: cluster.clusterId,
    };
    let data = defaultData;
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_GALERA:
            data = {
                ...defaultData,
                master_address: fields.nodePrimary,
                update_lb: fields.includeLB,
                config_template: fields.configurationTemplate,
                datadir: fields.dataDir,
                backupid: fields.backup?.getId(),
                galera_segment: fields.galeraSegment,
            };
            break;
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            data = {
                ...defaultData,
                node: {
                    ...(defaultData.node as any),
                    port: fields.nodePort,
                },
                replicaset: fields.mongoReplicaSet,
            };
            data.node_type = fields.mongoNodeType;
            if (fields.isMongoRouter) {
                data.node_type = MongoNodeType.CONFIG;
            }
            break;
        case CcClusterType.TYPE_ELASTIC:
            data = {
                install_software: fields.installSoftware,
                disable_selinux: fields.disableSeLinux,
                disable_firewall: fields.disableFirewall,
                nodes: [
                    {
                        class_name: 'CmonElasticHost',
                        protocol: 'elastic',
                        roles: fields.nodeRole,
                        ...(defaultData.node as any),
                        port: fields.nodePort,
                    },
                ],
            };
            break;
        case CcClusterType.TYPE_REDIS:
            data = {
                install_software: fields.installSoftware,
                disable_selinux: fields.disableSeLinux,
                disable_firewall: fields.disableFirewall,
                enable_uninstall: false,
                nodes: [
                    {
                        class_name: 'CmonRedisHost',
                        ...(defaultData.node as any),
                        port: fields.nodePort,
                    },
                    {
                        class_name: 'CmonRedisSentinelHost',
                        ...(defaultData.node as any),
                        port: fields.redisSentinelPort,
                    },
                ],
            };
            break;
        case CcClusterType.TYPE_REDIS_SHARDED:
            data = {
                install_software: fields.installSoftware,
                disable_selinux: fields.disableSeLinux,
                disable_firewall: fields.disableFirewall,
                nodes: [
                    {
                        class_name: 'RedisShardedHost',
                        hostname: defaultData.node?.hostname,
                        port: fields.nodePort,
                        protocol: 'redis-sharded',
                        role: !fields.isPrimaryNode ? 'replica' : 'primary',
                    },
                ],
            };
            break;
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            data = {
                install_software: fields.installSoftware,
                disable_selinux: fields.disableSeLinux,
                disable_firewall: fields.disableFirewall,
                enable_uninstall: false,
                nodes: [
                    {
                        class_name: 'CmonMsSqlHost',
                        ...(defaultData.node as any),
                        port: fields.mssqlServerPort,
                    },
                ],
            };
            break;
    }
    return data;
}

export function getImportDatabaseNodeJobDataDTO(
    fields: AddNodeFormWizardFields,
    cluster: CcCluster
): AddNodeJobDataDTO {
    return {
        node: {
            hostname: fields.topology?.[0].extraData.hostname,
            hostname_internal: fields.nodeDataIp || '',
            port: fields.nodePort,
        },
        update_lb: fields.includeLB,
        datadir: fields.dataDir,
        clusterId: cluster.clusterId,
    };
}

export function getTopologyItemValidator(cluster: CcCluster) {
    switch (cluster.clusterType) {
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return topologyItemValidator;
        default:
            return undefined;
    }
}
