import merge from 'deepmerge';
import {
    ClusterConfiguratorFormValues,
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
    ClusterConfiguratorVendor,
} from '../ClusterConfigurator';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';

export interface MysqlFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddClusterNodeConfigFormValues & {
        serverPort?: number;
        serverDataDirectory?: string;
        configurationTemplate?: string;
        adminUser?: string;
        adminPassword?: string;
        repository?: string;
        informationSchemaQueries?: boolean;
        clusterAutoRecovery?: boolean;
        nodeAutoRecovery?: boolean;
        nodeAutoDetect?: boolean;
        sslEncryption?: boolean;
        semiSynchronous?: boolean;
    };
}

export default class MysqlConfigurator extends ClusterConfigurator {
    public static getDefaults(): MysqlFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                adminUser: 'root',
                serverPort: 3306,
                serverDataDirectory: '/var/lib/mysql',
            },
        });
    }

    public static getVendors(): ClusterConfiguratorVendor[] {
        return [
            {
                name: 'MariaDB',
                value: 'mariadb',
                icon: 'img-logo-mariadb.svg',
            },
            {
                name: 'Percona',
                value: 'percona',
                icon: 'img-logo-percona.svg',
            },
        ];
    }

    public static getJobData(formValues: MysqlFormValues): any {
        const { topology, sshConfig, nodeConfig, topologyDataIps } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_REPLICATION.toLowerCase(),
            nodes: topology.map((currentNode: TopologyItem) => ({
                port: nodeConfig.serverPort,
                hostname: currentNode.extraData.hostname,
                hostname_data: currentNode.extraData.hostname,
                hostname_internal:
                    topologyDataIps?.[currentNode.extraData.hostname] || '',
            })),
            db_user: nodeConfig.adminUser,
            db_password: nodeConfig.adminPassword,
            datadir: nodeConfig.serverDataDirectory,
            config_template: nodeConfig.configurationTemplate,
            port: nodeConfig.serverPort,
        });
    }

    public static getNodeConfigurationValidate(): string[][] {
        return [
            ['nodeConfig', 'serverPort'],
            ['nodeConfig', 'serverDataDirectory'],
            ['nodeConfig', 'configurationTemplate'],
            ['nodeConfig', 'adminUser'],
            ['nodeConfig', 'adminPassword'],
            ['nodeConfig', 'repository'],
            ['details', 'version'],
        ];
    }
}
